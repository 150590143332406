<template>
  <div>
    <section class="domains">
      <div class="tw-mb-2 tw-p-2">
        <cl-heading :size="'h3'">
          {{ $t("All Domains") }}
        </cl-heading>
      </div>
      <cl-data-table
        :data-test-id="'domains-data-table'"
        :fetching="fetchingDomains"
        :headers="tableHeaders"
        :items="items"
        :total="serverTotal"
        :default-sort-by="sortByCookie"
        :default-sort-desc="sortDirCookie"
        :default-items-on-page="itemsPerPageCookie"
        with-search
        with-delete
        with-select
        with-set-columns
        with-server-side-pagination
        :searchBy="'accountname'"
        :searchPlaceholder="$t('Search Domains')"
        @change-page="changePage"
        @search="debouncedSearch"
        @on-delete-items="onDelete"
        @change-items-on-page="onChangeItemsOnPageClick"
        @sort-items="onSortItemsClick"
        @change-selected-columns="setSelectedColumnsCookie"
      >
        <template v-slot:actions>
          <cl-button
            :variant="'secondary'"
            type="button"
            @on-click="$emit('add')"
            data-test-id="domains-data-table-add"
          >
            {{ $t("Add Domain") }}
          </cl-button>
        </template>

        <template v-slot:[`body.cell.actions`]="props">
          <cl-data-table-view-action
            data-test-id="domains-data-table-view-btn"
            @on-click="onView(props.trItem)"
          />
        </template>
        <template v-slot:[`body.cell.rbl_checks_label`]="props">
          <cl-pill
            :variant="
              props.tdValue.toLowerCase() === 'yes' ? 'success' : 'danger'
            "
            size="sm"
          >
            {{ props.tdValue }}
          </cl-pill>
        </template>
        <template v-slot:[`body.cell.spf_checks_label`]="props">
          <cl-pill
            :variant="
              props.tdValue.toLowerCase() === 'yes' ? 'success' : 'danger'
            "
            size="sm"
          >
            {{ props.tdValue }}
          </cl-pill>
        </template>
        <template v-slot:[`body.cell.grey_checks_label`]="props">
          <cl-pill
            :variant="
              props.tdValue.toLowerCase() === 'yes' ? 'success' : 'danger'
            "
            size="sm"
          >
            {{ props.tdValue }}
          </cl-pill>
        </template>
      </cl-data-table>
    </section>
    <t-add-domain :tier="tier" :id="id" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import { DEFAULT_OPTIONS } from "@/constants";
import { useDataTable } from "@/composables/useDataTable";
import { useDataTableCookies } from "@/composables/useDataTableCookies";
import { usePills } from "@/composables/usePills";

export default {
  name: "DomainsOverviewBlock",
  props: {
    tier: {
      type: String,
      required: true,
    },
    id: {
      type: [String, Number],
      required: true,
    },
  },
  setup(props) {
    const cookieKey = `overview-${props.tier || "customer"}`;
    const {
      sortByCookie,
      sortDirCookie,
      itemsPerPageCookie,
      selectedColumnsCookie,
      setSortItemsCookie,
      setItemsPerPageCookie,
      setSelectedColumnsCookie,
    } = useDataTableCookies(
      {
        sortByKey: `${cookieKey}-sort-by`,
        sortDirKey: `${cookieKey}-sort-dir`,
        itemsPerPageKey: `${cookieKey}-items-per-page`,
        selectedColumnsKey: `${cookieKey}-selected-columns`,
      },
      {
        ...DEFAULT_OPTIONS,
        sortBy: "domain",
      }
    );

    const optionsBasedOnCookies = {
      sortBy: sortByCookie,
      sortDir: sortDirCookie,
      results: itemsPerPageCookie,
      page: 1,
    };

    const {
      dataOptions,
      sortItems,
      changePage,
      changeItemsPerPage,
      debouncedSearch,
    } = useDataTable(optionsBasedOnCookies);

    const { deletePill } = usePills({});

    return {
      dataOptions,
      debouncedSearch,
      sortItems,
      changePage,
      changeItemsPerPage,
      selectedColumnsCookie,
      setSortItemsCookie,
      setItemsPerPageCookie,
      setSelectedColumnsCookie,
      sortByCookie,
      sortDirCookie,
      itemsPerPageCookie,
      deletePill,
    };
  },
  data() {
    return {
      fetching: false,
      items: [],
    };
  },
  computed: {
    ...mapGetters("domains", ["domains", "serverTotal", "fetchingDomains"]),
    tableHeaders() {
      const headers = [
        { key: "domain", label: this.$t("Domain"), sortable: true },
        {
          key: "destination",
          label: this.$t("Destination Server"),
          sortable: true,
        },
        {
          key: "rbl_checks_label",
          label: this.$t("RBL Checks"),
          sortable: false,
        },
        {
          key: "spf_checks_label",
          label: this.$t("SPF Checks"),
          sortable: false,
        },
        {
          key: "grey_checks_label",
          label: this.$t("Greylisting"),
          sortable: false,
        },
        { key: "actions", label: this.$t("Actions"), sticky: true },
      ];

      if (!this.selectedColumnsCookie?.length) {
        return headers;
      }

      return headers.map((header) => ({
        ...header,
        enabled: this.selectedColumnsCookie.includes(header.key),
      }));
    },
  },
  watch: {
    dataOptions: {
      handler() {
        this.fetchNewDomains();
      },
      deep: true,
    },
    domains: {
      handler(domains) {
        this.items = domains.map((domain) => {
          return {
            ...domain,
            rbl_checks_label: domain.rbl_checks
              ? this.$t("Yes")
              : this.$t("No"),
            spf_checks_label: domain.spf_checks
              ? this.$t("Yes")
              : this.$t("No"),
            grey_checks_label: domain.grey_checks
              ? this.$t("Yes")
              : this.$t("No"),
          };
        });
      },
      immediate: true,
    },
  },
  methods: {
    ...mapActions("domains", [
      "batchDeleteDomains",
      "fetchDomains",
      "clearCache",
    ]),
    ...mapActions("authentication", ["setDomain"]),
    ...mapActions("toast", ["displayToast"]),
    onSortItemsClick(by, dir) {
      this.setSortItemsCookie(by, dir);
      this.sortItems(by, dir);
    },

    onChangeItemsOnPageClick(number) {
      this.setItemsPerPageCookie(number);
      this.changeItemsPerPage(number);
    },
    async onDelete(items) {
      try {
        this.fetching = true;
        await this.batchDeleteDomains({
          tier: this.tier,
          id: this.id,
          domains: items,
        });

        this.displayToast({
          title: this.$t("Success"),
          message: this.$t("Domain has been deleted"),
          duration: 2000,
          variant: "success",
        });
      } catch (_err) {
        // stub
      } finally {
        if (this.domains.length === 0 && this.dataOptions.page > 0) {
          this.dataOptions.page = this.dataOptions.page - 1;
        }
        this.deletePill(`q-${this.tier}-${this.id}-domain`);
      }
    },
    async onView(domain) {
      this.setDomain(domain);
      this.$router.push({
        path: `/domain/overview`,
      });
    },
    async fetchNewDomains() {
      try {
        this.clearCache();

        await this.fetchDomains({
          id: this.id,
          tier: this.tier,
          ...this.dataOptions,
        });
      } catch (_err) {
        // stub
      } finally {
        // stub
      }
    },
  },
};
</script>
