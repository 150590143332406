export const QUARANTINE_MESSAGE_TYPE = Object.freeze({
  all: "All",
  virus: "Virus",
  sandbox: "Sandbox",
  spam: "Spam",
  banned: "Banned",
  "content-filter": "Content-filter",
});

export default {};
