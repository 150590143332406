<template>
  <div class="scheduled-reports-list">
    <cl-data-table
      :data-test-id="'scheduled-reports-data-table'"
      :fetching="fetching"
      :headers="tableHeaders"
      :items="items"
      :total="serverTotal"
      :default-sort-by="sortByCookie"
      :default-sort-desc="sortDirCookie"
      :default-items-on-page="itemsPerPageCookie"
      with-delete
      with-select
      with-set-columns
      :searchPlaceholder="$t('Search')"
      @change-page="changePage"
      @search="debouncedSearch"
      @on-delete-items="onDelete"
      @change-items-on-page="onChangeItemsOnPageClick"
      @sort-items="onSortItemsClick"
      @change-selected-columns="setSelectedColumnsCookie"
    >
      <template v-slot:actions>
        <cl-button
          :variant="'secondary'"
          type="button"
          @on-click="onAdd"
          data-test-id="scheduled-reports-data-table-add"
        >
          {{ $t("Add") }}
        </cl-button>
      </template>
      <template v-slot:[`body.cell.actions`]="props">
        <span
          class="tw-cursor-pointer hover:tw-text-secondary-500"
          @click="onEdit(props.trItem)"
        >
          <cl-icon-tabler-icon-edit />
        </span>
      </template>
    </cl-data-table>
    <t-add-scheduled-report :id="id" :tier="tier" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import {
  REPORT_DOMAIN_TYPE,
  REPORT_CUSTOMER_TYPE,
  TIER,
  DATE_FORMAT,
  DEFAULT_OPTIONS,
} from "@/constants";
import dayjs from "dayjs";
import TAddScheduledReport from "@/modals/TAddScheduledReport.vue";
import { useDataTable } from "@/composables/useDataTable";
import { useDataTableCookies } from "@/composables/useDataTableCookies";

export default {
  name: "ScheduledReportsList",
  setup(props) {
    const cookieKey = `reporting-scheduled-${props.tier || "customer"}`;
    const {
      sortByCookie,
      sortDirCookie,
      itemsPerPageCookie,
      selectedColumnsCookie,
      setSortItemsCookie,
      setItemsPerPageCookie,
      setSelectedColumnsCookie,
    } = useDataTableCookies(
      {
        sortByKey: `${cookieKey}-sort-by`,
        sortDirKey: `${cookieKey}-sort-dir`,
        itemsPerPageKey: `${cookieKey}-items-per-page`,
        selectedColumnsKey: `${cookieKey}-selected-columns`,
      },
      {
        ...DEFAULT_OPTIONS,
        sortBy: "id",
      }
    );

    const optionsBasedOnCookies = {
      sortBy: sortByCookie,
      sortDir: sortDirCookie,
      results: itemsPerPageCookie,
      page: 1,
    };

    const {
      dataOptions,
      sortItems,
      changePage,
      changeItemsPerPage,
      debouncedSearch,
    } = useDataTable(optionsBasedOnCookies);

    return {
      dataOptions,
      debouncedSearch,
      sortItems,
      changePage,
      changeItemsPerPage,
      selectedColumnsCookie,
      setSortItemsCookie,
      setItemsPerPageCookie,
      setSelectedColumnsCookie,
      sortByCookie,
      sortDirCookie,
      itemsPerPageCookie,
    };
  },

  components: {
    "t-add-scheduled-report": TAddScheduledReport,
  },
  props: {
    id: {
      type: [String, Number],
      default: null,
    },
    tier: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      fetching: false,
      items: [],
    };
  },
  computed: {
    ...mapGetters("scheduledReports", ["reports", "serverTotal"]),
    tableHeaders() {
      const headers = [
        { key: "id", label: this.$t("ID"), sortable: true },
        { key: "subject", label: this.$t("Subject"), sortable: false },
        { key: "frequency", label: this.$t("Frequency"), sortable: true },
        { key: "recipients", label: this.$t("Recipients"), sortable: false },
        { key: "type", label: this.$t("Type"), sortable: true },
        { key: "domain", label: this.$t("Domain"), sortable: false },
        { key: "created_at", label: this.$t("Created"), sortable: true },
        { key: "actions", label: this.$t("Actions"), sortable: false },
      ];

      if (!this.selectedColumnsCookie?.length) {
        return headers;
      }

      return headers.map((header) => ({
        ...header,
        enabled: this.selectedColumnsCookie.includes(header.key),
      }));
    },
  },
  watch: {
    dataOptions: {
      handler() {
        this.fetchNewReports();
      },
      deep: true,
      immediate: true,
    },
    reports: {
      immediate: true,
      handler() {
        this.items = this.reports.map(this.mapReports);
      },
    },
  },
  methods: {
    ...mapActions("modal", ["openModal", "closeModal"]),
    ...mapActions("toast", ["displayToast"]),
    ...mapActions("scheduledReports", [
      "fetchReports",
      "batchDeleteReports",
      "clearCache",
    ]),
    onSortItemsClick(by, dir) {
      this.setSortItemsCookie(by, dir);
      this.sortItems(by, dir);
    },

    onChangeItemsOnPageClick(number) {
      this.setItemsPerPageCookie(number);
      this.changeItemsPerPage(number);
    },
    mapReports(report) {
      const frequencies = {
        yesterday: this.$t("Daily"),
        prev_week: this.$t("Weekly"),
        prev_month: this.$t("Monthly"),
      };

      return {
        ...report,
        frequency: frequencies[report.frequency],
        domain: report.domain || "---",
        recipients: report.recipients.join(", "),
        type:
          this.tier === TIER.domains
            ? REPORT_DOMAIN_TYPE[report.type]
            : REPORT_CUSTOMER_TYPE[report.type],
        created_at: `${dayjs(report.created_at).format(DATE_FORMAT.SHORT)}`,
      };
    },
    onAdd() {
      this.openModal();
    },
    onEdit(report) {
      if (this.tier === TIER.domainGroups) {
        this.$router.push({
          name: "customer-reports-scheduled-edit",
          params: {
            id: report.id,
          },
        });
      }

      if (this.tier === TIER.domains) {
        this.$router.push({
          name: "domain-reports-scheduled-edit",
          params: {
            id: report.id,
          },
        });
      }
    },
    async onDelete(reports) {
      try {
        this.fetching = true;

        await this.batchDeleteReports({
          tier: this.tier,
          id: this.id,
          reports,
        });
        this.displayToast({
          title: this.$t("Success"),
          message: this.$t("Report has been deleted"),
          duration: 2000,
          variant: "success",
        });
        if (this.reports.length === 0 && this.dataOptions.page > 0) {
          this.dataOptions.page = this.dataOptions.page - 1;
        }
      } catch (_err) {
        // stub
      } finally {
        this.fetching = false;
      }
    },
    async fetchNewReports() {
      try {
        this.fetching = true;

        await this.fetchReports({
          tier: this.tier,
          id: this.id,
          ...this.dataOptions,
        });
      } catch (_err) {
        // stub
      } finally {
        this.fetching = false;
      }
    },
  },
  created() {
    this.clearCache();
  },
};
</script>
