<template>
  <div
    class="bg-no-repeat tw-flex tw-h-full tw-flex-col tw-justify-center tw-overflow-hidden tw-bg-hero tw-bg-cover tw-bg-fixed tw-bg-center"
  >
    <t-auth-header />
    <section
      class="tw-relative tw-left-1/2 tw-top-[20%] tw-flex tw-max-w-lg tw--translate-x-1/2 tw--translate-y-1/2 tw-flex-col tw-items-center tw-justify-center tw-rounded-md tw-bg-light tw-p-6 tw-text-center tw-shadow-sm"
    >
      <cl-heading size="h2">{{ $t("Set new password") }}</cl-heading>
      <form @submit.prevent="submit" class="tw-w-full">
        <cl-form-group class="tw-my-4 tw-mb-4 tw-block">
          <cl-form-input
            id="email"
            name="email"
            type="email"
            v-model:value="form.email"
            :placeholder="$t('Your Email')"
            @on-blur="v$.form.email.$touch"
            :state="setInputState(v$.form.email)"
            size="large"
          />
          <div
            class="tw-block tw-pt-1 tw-text-sm tw-text-danger-500"
            v-if="v$.form.email.$error"
          >
            {{ $t("Please enter a valid email") }}
          </div>
        </cl-form-group>
        <cl-form-group class="tw-my-4 tw-mb-4 tw-block">
          <cl-form-input
            id="password"
            name="password"
            type="password"
            v-model:value="form.password"
            :placeholder="$t('Password')"
            @on-blur="v$.form.password.$touch"
            :state="setInputState(v$.form.password)"
            size="large"
          />
          <span
            class="tw-block tw-pt-1 tw-text-sm tw-text-danger-500"
            v-if="v$.form.password.$error"
            >{{
              $t(
                "The password must contain at least one uppercase, one lowercase letter, one symbol must be at least 12 characters"
              )
            }}</span
          >
        </cl-form-group>
        <cl-form-group class="tw-my-4 tw-mb-4 tw-block">
          <cl-form-input
            id="confirm_password"
            name="confirm_password"
            type="password"
            v-model:value="form.confirm_password"
            :placeholder="$t('Confirm New Password')"
            @on-blur="v$.form.confirm_password.$touch"
            :state="setInputState(v$.form.confirm_password)"
            size="large"
          />
          <span
            class="tw-block tw-pt-1 tw-text-sm tw-text-danger-500"
            v-if="v$.form.confirm_password.$error"
            >{{
              $t("The Password and the Confirm Password fields should match")
            }}</span
          >
        </cl-form-group>
        <div class="tw-flex tw-justify-center">
          <cl-button
            variant="secondary"
            class="tw-mt-8 tw-px-28"
            size="large"
            type="submit"
          >
            <span v-if="!fetching">{{ this.$t("Continue") }}</span>
            <cl-spinner v-else size="small" />
          </cl-button>
        </div>
      </form>
      <div class="tw-my-4">
        <p>
          {{ $t("Return to") }}
          <a href="/auth/sign-in">{{ $t("sign in") }}</a>
        </p>
      </div>
    </section>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { useVuelidate } from "@vuelidate/core";
import { required, sameAs, email } from "@vuelidate/validators";

import { STRONG_PASSWORD } from "@/constants";
import TAuthHeader from "@/components/TAuthHeader";

export default {
  name: "SetPassword",
  components: {
    TAuthHeader,
  },

  setup() {
    return { v$: useVuelidate() };
  },
  validations() {
    return {
      form: {
        email: {
          email,
          required,
        },
        password: {
          strongPassword: (password) => STRONG_PASSWORD.test(password),
          required,
        },
        confirm_password: {
          sameAsPassword: sameAs(this.form.password),
          required,
        },
      },
    };
  },
  data() {
    return {
      fetching: false,
      form: {
        email: "",
        password: "",
        confirm_password: "",
      },
    };
  },
  methods: {
    ...mapActions("passwordReset", ["setNewPassword"]),
    ...mapActions("toast", ["displayToast"]),
    setInputState(input) {
      return input.$dirty ? !input.$error : null;
    },
    async submit() {
      const isValid = await this.v$.form.$validate();

      if (!isValid) {
        return;
      }

      try {
        this.fetching = true;

        const res = await this.setNewPassword({
          email: this.form.email,
          password: this.form.password,
          confirm_password: this.form.confirm_password,
          token: this.$route.query.token,
        });

        this.displayToast({
          title: this.$t("Success"),
          message: res.data.message,
          duration: 2000,
          variant: "success",
        });

        this.$router.push({ name: "auth-sign-in" });
      } catch (_err) {
        // stub
      } finally {
        this.fetching = false;
      }
    },
  },
};
</script>
