<template>
  <t-block-allow-email
    v-if="selectedMsp.id"
    :id="selectedMsp.id"
    :tier="TIER.msps"
  ></t-block-allow-email>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import TBlockAllowEmail from "@/views/Common/TBlockAllowEmail";

import { TIER } from "@/constants/tier";

export default {
  name: "Emails",
  components: {
    TBlockAllowEmail,
  },
  data() {
    return {
      TIER,
    };
  },
  computed: {
    ...mapGetters("authentication", ["selectedMsp"]),
  },
  methods: {
    ...mapActions("pageMeta", ["setPageTitle", "setPageSubtitle"]),
    ...mapActions("modal", ["openModal", "closeModal"]),
  },
  mounted() {
    this.setPageTitle(this.$t("Allow & Block List"));
    this.setPageSubtitle([]);
  },
};
</script>
