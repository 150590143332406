<template>
  <div class="filtering-wrap">
    <router-view> </router-view>
  </div>
</template>

<script>
export default {
  name: "Filtering",
};
</script>
