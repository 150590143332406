<template>
  <Teleport to="body">
    <cl-modal
      :visible="showModal"
      @on-close="onCloseModal"
      headerLabel="Create A Single Pattern Filter"
    >
      <div class="modal-wrap">
        <div class="tw-mb-3">
          <cl-heading size="h4">
            {{ $t("Pattern Details") }}
          </cl-heading>
        </div>
        <form class="modal-form" @submit.prevent="submit" autocomplete="off">
          <!-- row pattern name & description (comment) -->
          <div class="tw-mb-3 tw-grid tw-gap-4 sm:tw-grid-cols-2 sm:tw-gap-6">
            <div>
              <cl-form-group>
                <cl-form-label label-for="pattern_name">
                  {{ $t("Pattern Name") }}
                </cl-form-label>
                <cl-form-input
                  id="pattern_name"
                  name="pattern_name"
                  v-model:value="form.name"
                  :state="setInputState(v$.form.name)"
                  @on-blur="v$.form.name.$touch"
                />
                <div
                  class="tw-block tw-text-sm tw-text-danger-500"
                  v-if="v$.form.name.$error"
                >
                  {{ $t("This field is a required field") }}
                </div>
              </cl-form-group>
            </div>
            <div>
              <cl-form-group>
                <cl-form-label label-for="pattern-description-input">
                  {{ $t("Pattern Description") }}
                </cl-form-label>
                <cl-form-input
                  id="pattern-description-input"
                  name="pattern-description-input"
                  v-model:value="form.comment"
                />
              </cl-form-group>
            </div>
          </div>
          <cl-hr />
          <!-- row filter expression & value -->
          <div class="tw-mb-3 tw-grid tw-gap-4 sm:tw-grid-cols-2 sm:tw-gap-6">
            <div>
              <cl-form-group>
                <cl-form-label label-for="filter-expression">
                  {{ $t("Pattern Expression") }}
                </cl-form-label>
                <cl-form-select
                  id="filter-expression"
                  v-model:value="form.type"
                  :options="filterTypeOptions"
                ></cl-form-select>
              </cl-form-group>
            </div>
            <div>
              <cl-form-group>
                <cl-form-label label-for="pattern-value">
                  {{ $t("Value") }}
                </cl-form-label>
                <cl-form-input
                  id="pattern-value"
                  name="pattern-value"
                  v-model:value="form.pattern"
                  :state="setInputState(v$.form.pattern)"
                  @on-blur="v$.form.pattern.$touch"
                />
                <div
                  class="tw-block tw-text-sm tw-text-danger-500"
                  v-if="v$.form.pattern.$error"
                >
                  {{ $t("This field is a required field") }}
                </div>
              </cl-form-group>
            </div>
          </div>
          <div class="tw-mb-3 tw-grid tw-gap-4 sm:tw-grid-cols-2 sm:tw-gap-6">
            <div>
              <cl-form-group>
                <cl-form-label label-for="filter-type">
                  {{ $t("Rule Type") }}
                </cl-form-label>
                <cl-form-select
                  id="filter-type"
                  v-model:value="form.score"
                  @change="onScoreChanged($event)"
                  :options="filterScoreOptions"
                ></cl-form-select>
              </cl-form-group>
            </div>
            <div>
              <cl-form-group>
                <cl-form-label label-for="filter-type">
                  {{ $t("Score") }}
                </cl-form-label>
                <cl-form-input
                  id="filter-soft-score"
                  type="number"
                  v-model:value="softScore"
                  step="0.001"
                  :min="softScoreMin"
                  :max="softScoreMax"
                  :state="setInputState(v$.softScore)"
                  @on-blur="v$.softScore.$touch"
                  :disabled="isSoftScoreDisabled"
                ></cl-form-input>
                <div
                  class="tw-block tw-text-sm tw-text-danger-500"
                  v-if="v$.softScore.$error && form.score === 5"
                >
                  {{
                    $t("The score should be in range between 0.001 and 99.999")
                  }}
                </div>
                <div
                  class="tw-block tw-text-sm tw-text-danger-500"
                  v-if="v$.softScore.$error && form.score === -5"
                >
                  {{
                    $t(
                      "The score should be in range between -99.999 and -0.001"
                    )
                  }}
                </div>
              </cl-form-group>
            </div>
          </div>
          <div v-if="!isSoftScoreDisabled">
            <div>
              <div
                class="tw-rounded-md tw-border tw-border-info-500 tw-bg-info-100 tw-px-6 tw-py-5 tw-text-base tw-text-info-800"
                v-if="this.form.score === SOFT_ALLOW_DEFAULT"
              >
                {{
                  $t(
                    "Soft Allow Subtracts a configurable negative score, between -0.001 and -99.999 (default: -5)."
                  )
                }}
              </div>
              <div
                class="tw-rounded-md tw-border tw-border-info-500 tw-bg-info-100 tw-px-6 tw-py-5 tw-text-base tw-text-info-800"
                v-if="this.form.score === SOFT_BLOCK_DEFAULT"
              >
                {{
                  $t(
                    "Soft Block Adds a configurable positive score, between 0.001 and 99.999 (default: 5)."
                  )
                }}
              </div>
            </div>
          </div>
          <cl-hr />
          <div v-if="!isAppliedToHeaderOrBody">
            <div class="tw-mb-6">
              <div
                class="tw-rounded-md tw-border tw-border-danger-500 tw-bg-danger-100 tw-px-6 tw-py-5 tw-text-base tw-text-danger-800"
              >
                {{ $t("Pattern must apply to either body or headers") }}
              </div>
            </div>
          </div>
          <!-- row apply to headers -->
          <div class="tw-mb-6">
            <div>
              <div
                class="tw-rounded-md tw-border tw-border-dorian-gray-200 tw-p-4"
                :class="{
                  active: form.apply_headers,
                  invalid: !isAppliedToHeaderOrBody,
                }"
              >
                <div class="tw-mb-2 tw-flex tw-items-center tw-justify-between">
                  <cl-form-label for="apply-headers-checkbox">
                    {{ $t("Apply to Headers") }}
                  </cl-form-label>
                  <cl-checkbox
                    id="apply-headers-checkbox"
                    :round="true"
                    v-model:model-value="form.apply_headers"
                    @update:model-value="onApplyHeadersChange"
                  />
                </div>
                <cl-form-group>
                  <cl-form-textarea
                    v-model:value="form.headers"
                    rows="3"
                    max-rows="6"
                    :placeholder="$t('Add one header per line')"
                    :disabled="!form.apply_headers"
                  ></cl-form-textarea>
                </cl-form-group>
              </div>
            </div>
          </div>
          <!-- row apply to body -->
          <div>
            <div>
              <div
                class="tw-rounded-md tw-border tw-border-dorian-gray-200 tw-p-4"
                :class="{
                  active: form.apply_body,
                  invalid: !isAppliedToHeaderOrBody,
                }"
              >
                <div class="tw-mb-2 tw-flex tw-items-center tw-justify-between">
                  <cl-form-label for="apply-body-checkbox">
                    {{ $t("Apply to Body") }}
                  </cl-form-label>
                  <cl-checkbox
                    id="apply-body-checkbox"
                    :round="true"
                    v-model:model-value="form.apply_body"
                    @update:model-value="onApplyBodyChange"
                  />
                </div>
                <cl-checkbox
                  id="use-raw-body-checkbox"
                  v-model:model-value="form.rawbody"
                  :disabled="!form.apply_body"
                >
                  {{ $t("Use Raw-body") }}
                </cl-checkbox>
              </div>
            </div>
          </div>
        </form>
      </div>
      <template v-slot:[`footer.decline`]>
        <cl-button
          :disabled="fetching"
          @on-click="closeModal"
          variant="link-secondary"
        >
          <span>{{ $t("Cancel") }}</span>
        </cl-button>
      </template>
      <template v-slot:[`footer.accept`]>
        <cl-button
          :variant="'secondary'"
          @on-click="submit"
          data-test-id="modal-single-pattern-create-button"
        >
          <span v-if="!fetching">{{ this.$t("Create") }}</span>
          <cl-spinner v-else :size="'small'" />
        </cl-button>
      </template>
    </cl-modal>
  </Teleport>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { useFocusElement } from "@/composables/useFocusElement";
import { SINGLE_PATTERN_RULE_OPTIONS, PATTERN_SCORE_LABEL } from "@/constants";
import { useVuelidate } from "@vuelidate/core";
import { required } from "@vuelidate/validators";

const DEFAULT_FORM = Object.freeze({
  name: "",
  comment: "",
  pattern: "",
  type: "contains",
  score: 100,
  apply_headers: true,
  headers: "",
  apply_body: true,
  rawbody: false,
  active: true,
});

export default {
  setup() {
    const { focusElementById } = useFocusElement();
    return { v$: useVuelidate(), focusElementById };
  },
  validations() {
    return {
      form: {
        name: {
          required,
        },
        pattern: {
          required,
        },
      },
      softScore: {
        required,
        matchSoftScore: (value) => {
          if (this.form.score === 5) {
            return value >= 0.0001 && value <= 99.999;
          }
          if (this.form.score === -5) {
            return value >= -99.999 && value <= -0.001;
          }
          return true;
        },
      },
    };
  },
  props: {
    id: {
      type: [String, Number],
      required: true,
    },
    tier: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      fetching: false,
      SOFT_BLOCK_DEFAULT: 5,
      SOFT_ALLOW_DEFAULT: -5,
      softScore: 100,
      form: {
        name: "",
        comment: "",
        pattern: "",
        type: "contains",
        score: 100,
        apply_headers: true,
        headers: "",
        apply_body: true,
        rawbody: false,
        active: true,
      },
      filterTypeOptions: Object.entries(SINGLE_PATTERN_RULE_OPTIONS).map(
        ([value, text]) => ({
          value,
          text: this.$t(text),
        })
      ),
      filterScoreOptions: [
        { value: 100, text: this.$t(PATTERN_SCORE_LABEL.HARD_BLOCK) },
        { value: -100, text: this.$t(PATTERN_SCORE_LABEL.HARD_ALLOW) },
        { value: 5, text: this.$t(PATTERN_SCORE_LABEL.SOFT_BLOCK) },
        { value: -5, text: this.$t(PATTERN_SCORE_LABEL.SOFT_ALLOW) },
        { value: 0, text: this.$t(PATTERN_SCORE_LABEL.TEST_MODE) },
      ],
    };
  },
  computed: {
    ...mapGetters("modal", ["showModal"]),
    softScoreMin() {
      if (this.form.score === 5) {
        return 0.001;
      }

      if (this.form.score === -5) {
        return -99.999;
      }

      return null;
    },
    softScoreMax() {
      if (this.form.score === 5) {
        return 99.999;
      }

      if (this.form.score === -5) {
        return -0.001;
      }

      return null;
    },
    isSoftScoreDisabled() {
      if (this.form.score === 5 || this.form.score === -5) {
        return false;
      }

      return true;
    },
    isAppliedToHeaderOrBody() {
      return this.form.apply_headers || this.form.apply_body;
    },
  },
  watch: {
    showModal: {
      handler() {
        this.focusElementById("pattern_name");
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    ...mapActions("toast", ["displayToast"]),
    ...mapActions("patternFilters", ["addPattern"]),
    ...mapActions("modal", ["closeModal"]),
    onApplyBodyChange(enabled) {
      if (!enabled) {
        this.form.rawbody = false;
      }
    },
    onApplyHeadersChange(enabled) {
      if (!enabled) {
        this.form.headers = "";
      }
    },
    onScoreChanged(score) {
      if (score === this.SOFT_BLOCK_DEFAULT) {
        this.softScore = this.SOFT_BLOCK_DEFAULT;

        return;
      }

      if (score === this.SOFT_ALLOW_DEFAULT) {
        this.softScore = this.SOFT_ALLOW_DEFAULT;

        return;
      }

      this.softScore = score;
    },
    setInputState(input) {
      return input.$dirty ? !input.$error : null;
    },
    onCloseModal() {
      this.closeModal();
      this.form = { ...DEFAULT_FORM };
      this.v$.$reset();
    },
    async submit() {
      const isValid = await this.v$.form.$validate();

      if (!isValid || !this.isAppliedToHeaderOrBody) {
        return;
      }

      try {
        this.fetching = true;

        const formData = {
          ...this.form,
          score: this.softScore,
          headers: this.form.apply_headers
            ? this.form.headers
                .split(/\n|,/)
                .map((item) => item.trim())
                .filter(Boolean)
            : [],
        };

        await this.addPattern({
          tier: this.$props.tier,
          id: this.$props.id,
          formData,
        });

        this.displayToast({
          title: this.$t("Success"),
          message: this.$t("Pattern has been created"),
          duration: 2000,
          variant: "success",
        });

        this.onCloseModal();
      } catch (_err) {
        // stub
      } finally {
        this.fetching = false;
      }
    },
  },
};
</script>
