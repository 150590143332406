<template>
  <Teleport to="body">
    <cl-modal
      :visible="showModal"
      @on-close="onCloseModal"
      :headerLabel="title"
    >
      <form class="modal-form" @submit.prevent="submit" autocomplete="off">
        <!-- row domain to block email -->
        <div>
          <cl-form-group>
            <cl-form-label label-for="sender_input">
              {{ inputLabel }}
            </cl-form-label>
            <cl-form-input
              id="sender_input"
              name="sender_input"
              v-model:value="form.sender"
              :state="setInputState(v$.form.sender)"
              @on-blur="v$.form.sender.$touch"
            />
            <div
              class="tw-block tw-text-sm tw-text-danger-500"
              v-if="v$.form.sender.$error"
            >
              {{ $t("Please enter a valid email") }}
            </div>
          </cl-form-group>
        </div>
        <!-- row comment -->
        <div>
          <cl-form-group>
            <cl-form-label label-for="comment-textarea">
              {{ $t("Comments (optional)") }}
            </cl-form-label>
            <cl-form-textarea
              id="comment-textarea"
              name="comment-textarea"
              v-model:value="form.comment"
              rows="3"
              max-rows="6"
            ></cl-form-textarea>
          </cl-form-group>
        </div>
      </form>

      <template v-slot:[`footer.decline`]>
        <cl-button
          :disabled="fetching"
          @on-click="closeModal"
          variant="link-secondary"
        >
          <span>{{ $t("Cancel") }}</span>
        </cl-button>
      </template>
      <template v-slot:[`footer.accept`]>
        <cl-button v-if="fetching" :variant="'secondary'">
          <cl-spinner :size="'small'" />
        </cl-button>
        <cl-button
          v-else
          :variant="'secondary'"
          @on-click="submit"
          data-test-id="modal-allow-block-add-email-button"
        >
          <span v-if="isAllow">{{ this.$t("Allow") }}</span>
          <span v-else>{{ this.$t("Block") }}</span>
        </cl-button>
      </template>
    </cl-modal>
  </Teleport>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { useFocusElement } from "@/composables/useFocusElement";
import { useVuelidate } from "@vuelidate/core";
import { required, email } from "@vuelidate/validators";

const DEFAULT_FORM = Object.freeze({ sender: "", comment: "" });

export default {
  setup() {
    const { focusElementById } = useFocusElement();
    return { v$: useVuelidate(), focusElementById };
  },
  validations() {
    return {
      form: {
        sender: {
          required,
          email,
        },
      },
    };
  },
  props: {
    isAllow: {
      type: Boolean,
      required: true,
    },
    id: {
      type: [String, Number],
      required: true,
    },
    tier: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      fetching: false,
      form: {
        ...DEFAULT_FORM,
      },
    };
  },
  computed: {
    ...mapGetters("modal", ["showModal"]),
    inputLabel() {
      return this.$props.isAllow
        ? this.$t("Email to Allow")
        : this.$t("Email to Block");
    },
    title() {
      return this.$props.isAllow
        ? this.$t("Allow Email")
        : this.$t("Block Email");
    },
  },
  watch: {
    showModal: {
      handler() {
        this.focusElementById("sender_input");
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    ...mapActions("blockAllowEmailsList", ["blockEmail", "allowEmail"]),
    ...mapActions("toast", ["displayToast"]),
    ...mapActions("modal", ["closeModal"]),
    setInputState(input) {
      return input.$dirty ? !input.$error : null;
    },
    onCloseModal() {
      this.closeModal();
      this.form = { ...DEFAULT_FORM };
      this.v$.$reset();
    },
    async submit() {
      const isValid = await this.v$.form.$validate();

      if (!isValid) {
        return;
      }

      if (this.$props.isAllow) {
        await this.submitAllowEmail();
      } else {
        await this.submitBlockEmail();
      }
    },
    async submitAllowEmail() {
      try {
        this.fetching = true;

        await this.allowEmail({
          entry: this.form,
          tier: this.$props.tier,
          id: this.$props.id,
        });

        this.displayToast({
          title: this.$t("Success"),
          message: this.$t("Email has been Allowed", {
            domain: this.form.domain,
          }),
          duration: 2000,
          variant: "success",
        });
        this.onCloseModal();
      } catch (_err) {
        // stub
      } finally {
        this.fetching = false;
      }
    },
    async submitBlockEmail() {
      try {
        this.fetching = true;

        await this.blockEmail({
          entry: this.form,
          tier: this.$props.tier,
          id: this.$props.id,
        });

        this.displayToast({
          title: this.$t("Success"),
          message: this.$t("Email has been blocked", {
            name: this.form.sender,
          }),
          duration: 2000,
          variant: "success",
        });
        this.onCloseModal();
      } catch (_err) {
        // stub
      } finally {
        this.fetching = false;
      }
    },
  },
};
</script>
