import { ref } from "vue";
import { useProgress } from "@marcoschulte/vue3-progress";

const useUploadLogo = () => {
  const imageUploaderRules = {
    maxSize: 2048000,
    maxWidth: 800,
    maxHeight: 800,
    supportedTypes: "image/*",
  };

  const loading = ref(true);
  const errorMessage = ref(null);
  const errorCatch = ref(null);
  const uploadedImage = ref({
    size: null,
    width: null,
    height: null,
  });
  const logoPreview = ref(null);
  const customLogoFile = ref(null);

  const upload = (event) => {
    if (logoPreview.value) {
      logoPreview.value = null;
    }
    const progress = useProgress().start();
    try {
      const image = event.target.files[0];
      const reader = new FileReader();

      reader.readAsDataURL(image);
      reader.onload = (event) => {
        const file = image;
        uploadedImage.value.size = file.size;

        let img = new Image();
        img.src = reader.result;
        img.onload = () => {
          uploadedImage.value.height = img.height;
          uploadedImage.value.width = img.width;
          progress.finish();

          if (
            img.height > imageUploaderRules.maxHeight ||
            img.width > imageUploaderRules.maxWidth
          ) {
            errorMessage.value = "The image dimensions are too big.";
            return;
          }
        };

        if (file.size > imageUploaderRules.maxSize) {
          errorMessage.value = "File size exceeds maximum of 1mb.";
          return;
        }

        customLogoFile.value = image;
        logoPreview.value = event.target.result;
      };
    } catch (err) {
      errorCatch.value = err;
    } finally {
      progress.finish();
      loading.value = false;
    }
  };

  const removeLogo = () => {
    customLogoFile.value = null;
    logoPreview.value = null;
    errorMessage.value = null;
  };

  return {
    loading,
    errorMessage,
    errorCatch,
    uploadedImage,
    logoPreview,
    customLogoFile,
    imageUploaderRules,
    upload,
    removeLogo,
  };
};

export default { useUploadLogo };
