<template>
  <div>
    <section class="user-policies">
      <cl-data-table
        :data-test-id="'user-policies-data-table'"
        :fetching="fetching"
        :headers="tableHeaders"
        :items="items"
        :total="serverTotal"
        :default-sort-by="sortByCookie"
        :default-sort-desc="sortDirCookie"
        :default-items-on-page="itemsPerPageCookie"
        with-search
        with-delete
        with-select
        with-server-side-pagination
        with-set-columns
        :searchBy="'email'"
        :searchPlaceholder="$t('Search User Policy')"
        @change-page="changePage"
        @search="debouncedSearch"
        @on-delete-items="onDelete"
        @change-items-on-page="onChangeItemsOnPageClick"
        @sort-items="onSortItemsClick"
        @change-selected-columns="setSelectedColumnsCookie"
      >
        <template v-slot:actions>
          <cl-button
            :variant="'secondary'"
            type="button"
            @on-click="onAdd"
            data-test-id="user-policies-data-table-add"
          >
            {{ $t("Add") }}
          </cl-button>
        </template>
        <template v-slot:[`body.cell.actions`]="props">
          <cl-data-table-edit-action
            data-test-id="user-policies-data-table-edit-btn"
            @on-click="onEdit(props.trItem)"
          />
        </template>
        <template v-slot:[`body.cell.spam_enabled`]="props">
          <cl-pill
            :variant="
              props.tdValue.toLowerCase() === 'off' ? 'danger' : 'success'
            "
            size="sm"
          >
            {{ props.tdValue }}
            <span v-if="props.trItem.marketing"
              >[{{ props.trItem.marketing }}]</span
            >
          </cl-pill>
        </template>
        <template v-slot:[`body.cell.virus_enabled`]="props">
          <cl-pill
            :variant="
              props.tdValue.toLowerCase() === 'off' ? 'danger' : 'success'
            "
            size="sm"
          >
            {{ props.tdValue }}
          </cl-pill>
        </template>
        <template v-slot:[`body.cell.banned_enabled`]="props">
          <cl-pill
            :variant="
              props.tdValue.toLowerCase() === 'off' ? 'danger' : 'success'
            "
            size="sm"
          >
            {{ props.tdValue }}
          </cl-pill>
        </template>
        <template v-slot:[`body.cell.digest`]="props">
          <cl-pill
            :variant="
              props.tdValue.toLowerCase() === 'inactive' ? 'danger' : 'success'
            "
            size="sm"
          >
            {{ props.tdValue }}
          </cl-pill>
        </template>
        <template v-slot:[`body.cell.archive`]="props">
          <cl-pill
            :variant="
              props.tdValue.toLowerCase() === 'inactive' ? 'danger' : 'success'
            "
            size="sm"
          >
            {{ props.tdValue }}
          </cl-pill>
        </template>
      </cl-data-table>
    </section>

    <t-add-users />
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";

import { TIER, DEFAULT_OPTIONS } from "@/constants";
import TAddUsers from "@/modals/TAddUsers.vue";
import { useDataTable } from "@/composables/useDataTable";
import { useDataTableCookies } from "@/composables/useDataTableCookies";

export default {
  name: "UserPolicies",
  components: {
    "t-add-users": TAddUsers,
  },
  setup(props) {
    const cookieKey = `user-policies-${props.tier || "domain"}`;
    const {
      sortByCookie,
      sortDirCookie,
      itemsPerPageCookie,
      selectedColumnsCookie,
      setSortItemsCookie,
      setItemsPerPageCookie,
      setSelectedColumnsCookie,
    } = useDataTableCookies(
      {
        sortByKey: `${cookieKey}-sort-by`,
        sortDirKey: `${cookieKey}-sort-dir`,
        itemsPerPageKey: `${cookieKey}-items-per-page`,
        selectedColumnsKey: `${cookieKey}-selected-columns`,
      },
      {
        ...DEFAULT_OPTIONS,
        sortBy: "id",
      }
    );

    const optionsBasedOnCookies = {
      sortBy: sortByCookie,
      sortDir: sortDirCookie,
      results: itemsPerPageCookie,
      page: 1,
    };

    const {
      dataOptions,
      sortItems,
      changePage,
      changeItemsPerPage,
      debouncedSearch,
    } = useDataTable(optionsBasedOnCookies);

    return {
      dataOptions,
      debouncedSearch,
      sortItems,
      changePage,
      changeItemsPerPage,
      selectedColumnsCookie,
      setSortItemsCookie,
      setItemsPerPageCookie,
      setSelectedColumnsCookie,
      sortByCookie,
      sortDirCookie,
      itemsPerPageCookie,
    };
  },
  data() {
    return {
      fetching: false,
      columnNumber: 8,
      items: [],
    };
  },
  computed: {
    ...mapGetters("userPolicies", ["users", "serverTotal"]),
    ...mapGetters("authentication", ["selectedDomain"]),
    tableHeaders() {
      const headers = [
        {
          key: "id",
          label: this.$t("ID"),
          sortable: true,
        },
        {
          key: "email",
          label: this.$t("Policy"),
        },
        {
          key: "role",
          label: this.$t("User Role"),
        },
        {
          key: "spam_enabled",
          label: this.$t("Spam"),
        },
        {
          key: "virus_enabled",
          label: this.$t("Virus"),
        },
        {
          key: "banned_enabled",
          label: this.$t("Banned"),
        },
        {
          key: "digest",
          label: this.$t("Digest"),
        },
        {
          key: "archive",
          label: this.$t("Archive"),
        },
        { key: "actions", label: this.$t("Actions"), sticky: true },
      ];

      if (!this.selectedColumnsCookie?.length) {
        return headers;
      }

      return headers.map((header) => ({
        ...header,
        enabled: this.selectedColumnsCookie.includes(header.key),
      }));
    },
  },
  watch: {
    dataOptions: {
      handler() {
        this.fetchNewUserPolicies();
      },
      deep: true,
      immediate: true,
    },
    users: {
      handler() {
        if (this.users) {
          this.items = this.users.map((user) => {
            return {
              ...user,
              role: user.roles[0].name.replace("Domain Group", "Customer"),
              archive: user.policy.archive_clean_mail ? "active" : "inactive",
              digest: user.policy.qreport.enabled ? "active" : "inactive",
              spam_enabled: user.policy.spam.enabled
                ? `On[${user.policy.spam.score}:${user.policy.spam.action}]`
                : this.$t("Off"),
              virus_enabled: user.policy.virus.enabled
                ? `On[${user.policy.virus.action}]`
                : this.$t("Off"),
              banned_enabled: user.policy.banned.enabled
                ? `On[${user.policy.banned.action}]`
                : this.$t("Off"),
              marketing: user.policy.spam.block_marketing
                ? this.$t("Marketing")
                : "",
            };
          });
        }
      },
      immediate: true,
    },
  },
  methods: {
    ...mapActions("pageMeta", ["setPageTitle", "setPageSubtitle"]),
    ...mapActions("userPolicies", [
      "fetchUserPolicies",
      "clearList",
      "batchDeleteUserPolicies",
    ]),
    ...mapActions("modal", ["openModal", "closeModal"]),
    onSortItemsClick(by, dir) {
      this.setSortItemsCookie(by, dir);
      this.sortItems(by, dir);
    },

    onChangeItemsOnPageClick(number) {
      this.setItemsPerPageCookie(number);
      this.changeItemsPerPage(number);
    },
    onAdd() {
      this.openModal();
    },
    async onEdit(rowObj) {
      this.$store.commit("userPolicies/setSelectedUser", rowObj);
      this.$router.push(`/domain/policies/user_policies/${rowObj.id}`);
    },
    async onDelete(users) {
      try {
        this.fetching = true;

        await this.batchDeleteUserPolicies({
          users,
          tier: TIER.domains,
          tier_id: this.selectedDomain.id,
        });
        if (this.users.length === 0 && this.dataOptions.page > 0) {
          this.dataOptions.page = this.dataOptions.page - 1;
        }
      } catch (_err) {
        // stub
      } finally {
        this.fetching = false;
      }
    },
    async fetchNewUserPolicies() {
      try {
        this.fetching = true;
        this.clearList();

        await this.fetchUserPolicies({
          tier: TIER.domains,
          id: this.selectedDomain.id,
          ...this.dataOptions,
        });
      } catch (_err) {
        // stub
      } finally {
        this.fetching = false;
      }
    },
  },
  mounted() {
    this.setPageTitle("");
    this.setPageTitle(this.$t("User Policies"));

    this.setPageSubtitle([]);
  },
};
</script>
