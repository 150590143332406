<template>
  <div>
    <section class="users">
      <div class="tw-mb-2 tw-p-2">
        <cl-heading :size="'h3'">
          {{ $t("All Users") }}
        </cl-heading>
      </div>
      <cl-data-table
        :data-test-id="'users-data-table'"
        :fetching="fetching"
        :headers="tableHeaders"
        :items="items"
        :total="serverTotal"
        :default-sort-by="sortByCookie"
        :default-sort-desc="sortDirCookie"
        :default-items-on-page="itemsPerPageCookie"
        with-search
        with-set-columns
        with-server-side-pagination
        :searchBy="'first_name'"
        :searchPlaceholder="$t('Search user')"
        @change-page="changePage"
        @search="debouncedSearch"
        @change-items-on-page="onChangeItemsOnPageClick"
        @sort-items="onSortItemsClick"
        @change-selected-columns="setSelectedColumnsCookie"
      >
        <template v-slot:[`body.cell.actions`]="props">
          <cl-data-table-view-action
            data-test-id="users-data-table-view-btn"
            @on-click="onView(props.trItem)"
          />
        </template>
      </cl-data-table>
    </section>
    <t-add-administrator :tier="$props.tier" :id="$props.id" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { TIER, DEFAULT_OPTIONS } from "@/constants";
import TAddAdministrator from "@/modals/TAddAdministrator.vue";
import { useDataTable } from "@/composables/useDataTable";
import { useDataTableCookies } from "@/composables/useDataTableCookies";

export default {
  name: "UsersList",
  components: {
    "t-add-administrator": TAddAdministrator,
  },
  props: {
    tier: {
      type: String,
      required: true,
      default: TIER.domains,
    },
    id: {
      type: [String, Number],
      required: true,
    },
  },
  setup(props) {
    const cookieKey = `reporting-archive-${props.tier || "customer"}`;
    const {
      sortByCookie,
      sortDirCookie,
      itemsPerPageCookie,
      selectedColumnsCookie,
      setSortItemsCookie,
      setItemsPerPageCookie,
      setSelectedColumnsCookie,
    } = useDataTableCookies(
      {
        sortByKey: `${cookieKey}-sort-by`,
        sortDirKey: `${cookieKey}-sort-dir`,
        itemsPerPageKey: `${cookieKey}-items-per-page`,
        selectedColumnsKey: `${cookieKey}-selected-columns`,
      },
      {
        ...DEFAULT_OPTIONS,
        sortBy: "user_name",
      }
    );

    const optionsBasedOnCookies = {
      sortBy: sortByCookie,
      sortDir: sortDirCookie,
      results: itemsPerPageCookie,
      page: 1,
    };

    const {
      dataOptions,
      sortItems,
      changePage,
      changeItemsPerPage,
      debouncedSearch,
    } = useDataTable(optionsBasedOnCookies);

    return {
      dataOptions,
      debouncedSearch,
      sortItems,
      changePage,
      changeItemsPerPage,
      selectedColumnsCookie,
      setSortItemsCookie,
      setItemsPerPageCookie,
      setSelectedColumnsCookie,
      sortByCookie,
      sortDirCookie,
      itemsPerPageCookie,
    };
  },
  data() {
    return {
      fetching: false,
      items: [],
    };
  },
  computed: {
    ...mapGetters("users", ["userAccounts", "serverTotal"]),
    ...mapGetters("authentication", ["selectedDomain"]),
    tableHeaders() {
      const headers = [
        { key: "first_name", label: this.$t("First Name"), sortable: true },
        { key: "last_name", label: this.$t("Last Name"), sortable: true },
        { key: "user_name", label: this.$t("Email"), sortable: true },
        { key: "actions", label: this.$t("Actions"), sortable: false },
      ];

      if (!this.selectedColumnsCookie?.length) {
        return headers;
      }

      return headers.map((header) => ({
        ...header,
        enabled: this.selectedColumnsCookie.includes(header.key),
      }));
    },
  },
  watch: {
    dataOptions: {
      handler() {
        this.fetchNewUsers();
      },
      deep: true,
      immediate: true,
    },
    userAccounts() {
      this.items = this.userAccounts;
    },
  },
  methods: {
    ...mapActions("users", ["fetchUsers", "clearList"]),
    ...mapActions("authentication", ["setUser"]),
    ...mapActions("pageMeta", ["setPageTitle", "setPageSubtitle"]),
    ...mapActions("modal", ["openModal", "closeModal"]),
    onSortItemsClick(by, dir) {
      this.setSortItemsCookie(by, dir);
      this.sortItems(by, dir);
    },

    onChangeItemsOnPageClick(number) {
      this.setItemsPerPageCookie(number);
      this.changeItemsPerPage(number);
    },

    async onView(user) {
      this.setUser(user);
      this.$router.push({
        path: `/user`,
      });
    },

    async fetchNewUsers() {
      try {
        this.fetching = true;
        this.clearList();
        await this.fetchUsers({
          tier: this.tier,
          id: this.id,
          ...this.dataOptions,
        });
      } catch (_err) {
        // stub
      } finally {
        this.fetching = false;
      }
    },
  },
};
</script>
