<template>
  <edit-email
    v-if="selectedUser.id"
    :tier="tierType"
    :id="selectedUser.id"
    :emailId="emailId"
  />
</template>
<script>
import { mapActions, mapGetters } from "vuex";

import EditEmail from "@/views/Common/TBlockAllowEmail/Edit/index.vue";

import { TIER } from "@/constants";

export default {
  name: "EmailEditWrap",
  components: {
    EditEmail,
  },
  data() {
    return {
      tierType: TIER.users,
      emailId: this.$route.params.id,
    };
  },
  methods: {
    ...mapActions("pageMeta", ["setPageTitle", "setPageSubtitle"]),
  },
  computed: {
    ...mapGetters("authentication", ["selectedUser"]),
  },
  created() {
    this.setPageTitle(this.$t("Email Id:") + this.emailId);
    this.setPageSubtitle([this.$t("Edit Allow/Block Email")]);
  },
};
</script>
