import { v4 as uuid } from "uuid";

import {
  PATTERN_SCORE_LABEL,
  PATTERN_STATUS_LABEL,
  PATTERN_TYPES,
} from "@/constants";

export function getLabelByScore(score) {
  if (score === 100) {
    return PATTERN_SCORE_LABEL.HARD_BLOCK;
  }

  if (score === -100) {
    return PATTERN_SCORE_LABEL.HARD_ALLOW;
  }

  if (score === 0) {
    return PATTERN_SCORE_LABEL.TEST_MODE;
  }

  if (score >= -99.999 && score <= -0.001) {
    return PATTERN_SCORE_LABEL.SOFT_ALLOW;
  }

  if (score >= 0.001 && score <= 99.999) {
    return PATTERN_SCORE_LABEL.SOFT_BLOCK;
  }

  return score; // default
}

export function getLabelByStatus(boolValue) {
  return boolValue
    ? PATTERN_STATUS_LABEL.ACTIVE
    : PATTERN_STATUS_LABEL.INACTIVE;
}

export function getLableByPatternType(type) {
  return PATTERN_TYPES[type] || type;
}

export function getLableForMetaPattern(pattern) {
  const label = [];

  for (let index = 0; index < pattern.group_count; index++) {
    const patternsInGroup = pattern[`group_${index + 1}_data`];

    // Open group
    label.push("(");

    for (let index = 0; index < patternsInGroup.pattern_count; index++) {
      const pattern = patternsInGroup[`pattern_${index + 1}`];

      // Add Pattern itself
      label.push(pattern);

      // Add operator if exist
      const patternOperator =
        patternsInGroup[`pattern_${index + 1}_operator`] || null;
      if (patternOperator) {
        label.push(` ${patternOperator} `);
      }
    }

    // Close group
    label.push(")");

    // Add group operator if exist
    const groupOperator = patternsInGroup.group_operator || null;
    if (groupOperator) {
      label.push(` ${groupOperator} `);
    }
  }

  return label.join("");
}

export function generateCondition(type = "other", pattern = "") {
  return {
    id: uuid(),
    nextSibling: null,
    type,
    pattern,
  };
}

export function generateGroup(conditions = [generateCondition()]) {
  return {
    id: uuid(),
    nextSibling: null,
    conditions,
  };
}

export function parsePattern(pattern) {
  if (!pattern) {
    return [];
  }

  if (pattern.type !== "meta") {
    const conditions = [generateCondition(pattern.type, pattern.pattern)];
    const group = generateGroup(conditions);

    return [group];
  }

  const parsedGroups = [];

  // Collect groups
  for (let index = 0; index < pattern.pattern.group_count; index++) {
    const conditions = [];
    const currentPatternsKey = `group_${index + 1}_data`;
    const patterns = pattern.pattern[currentPatternsKey];

    // Collect conditions
    for (let index = 0; index < patterns.pattern_count; index++) {
      const parsedPattern = patterns[`pattern_${index + 1}`];
      const parsedPatternType = parsedPattern.includes("RULE_")
        ? Number(parsedPattern.replace("RULE_", "")) || "other"
        : "other";
      const condition = generateCondition(parsedPatternType, parsedPattern);

      condition.nextSibling = patterns[`pattern_${index + 1}_operator`] || null;
      conditions.push(condition);
    }

    const group = generateGroup(conditions);
    group.nextSibling =
      pattern.pattern[currentPatternsKey].group_operator || null;

    parsedGroups.push(group);
  }

  return parsedGroups;
}

export function stringifyGroupsToPattern(groups) {
  const patternLabel = [];

  for (let index = 0; index < groups.length; index++) {
    // Open group
    patternLabel.push("(");

    const conditions = groups[index].conditions;

    for (let index = 0; index < conditions.length; index++) {
      // Add Pattern itself
      patternLabel.push(conditions[index].pattern);

      // Add operator if exist
      const patternOperator = conditions[index].nextSibling || null;
      if (patternOperator) {
        const operatorSymbol = patternOperator === "and" ? "&&" : "||";

        patternLabel.push(` ${operatorSymbol} `);
      }
    }

    // Close group
    patternLabel.push(")");

    // Add group operator if exist
    const groupOperator = groups[index].nextSibling;
    if (groupOperator) {
      const operatorSymbol = groupOperator === "and" ? "&&" : "||";

      patternLabel.push(` ${operatorSymbol} `);
    }
  }

  return patternLabel.join("");
}

export default {};
