<template>
  <edit-pattern-filter :id="null" :tier="null" />
</template>

<script>
import { mapActions } from "vuex";

import EditPatternFilter from "@/views/Common/PatternFilters/Edit/Single.vue";

import { TIER } from "@/constants";

export default {
  name: "EditAttachmentFilterWrapper",
  components: {
    EditPatternFilter,
  },
  data() {
    return {
      TIER,
    };
  },
  methods: {
    ...mapActions("pageMeta", ["setPageTitle", "setPageSubtitle"]),
  },
  created() {
    this.setPageTitle(
      this.$t("Edit Pattern: X", { id: this.$route.params.id })
    );
    this.setPageSubtitle([]);
  },
};
</script>
