<template>
  <edit-ip :ip-id="ipId" :tier="tierType" :id="selectedMsp.id" />
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import EditIp from "@/views/Common/TBlockAllowIP/Edit/index.vue";
import { TIER } from "@/constants";

export default {
  name: "IpEditWrap",
  components: {
    EditIp,
  },
  data() {
    return {
      ipId: this.$route.params.id,
      tierType: TIER.msps,
      domainId: this.$route.params.id,
    };
  },
  methods: {
    ...mapActions("pageMeta", ["setPageTitle", "setPageSubtitle"]),
  },
  computed: {
    ...mapGetters("authentication", ["selectedMsp"]),
  },
  created() {
    this.setPageTitle(this.$t("IP Id:") + this.ipId);
    this.setPageSubtitle([this.$t("Edit Allow/Block IP")]);
  },
};
</script>
