<template>
  <t-skeleton v-if="loading" />

  <form v-else @submit.prevent autocomplete="off">
    <div class="tw-grid tw-gap-4 md:tw-grid-cols-2 lg:tw-grid-cols-3">
      <cl-form-group>
        <cl-form-label label-for="domain">
          {{ $t("Domain") }}
        </cl-form-label>
        <cl-form-input
          disabled
          id="domain"
          name="domain"
          :value="form.domain"
          size="medium"
        />
      </cl-form-group>

      <cl-form-group>
        <cl-form-label label-for="destination">
          {{ $t("Destination Server") }}
        </cl-form-label>
        <cl-form-input
          autofocus
          id="destination"
          name="destination"
          v-model:value="form.destination"
          :placeholder="$t('Email server hostname / IP')"
          size="medium"
          :state="setInputState(v$.form.destination)"
          @on-blur="v$.form.destination.$touch"
        />
        <span
          class="tw-block tw-pt-1 tw-text-sm tw-text-danger-500"
          v-if="v$.form.destination.$error"
          >{{ $t("You should provide valid domain or IP address") }}</span
        >
      </cl-form-group>
      <cl-form-group>
        <cl-form-label label-for="port">
          {{ $t("Port") }}
        </cl-form-label>
        <cl-form-input
          id="port"
          name="port"
          v-model:value="form.port"
          size="medium"
          placeholder="25"
          :state="setInputState(v$.form.port)"
          @on-blur="v$.form.port.$touch"
          @keyup="restrictInput(form, 'port')"
        />
        <span
          class="tw-block tw-pt-1 tw-text-sm tw-text-danger-500"
          v-if="v$.form.port.$error"
        >
          {{ setCurrentErrorMessage(v$.form.port.$errors) }}
        </span>
      </cl-form-group>
    </div>
    <cl-hr />
    <div class="tw-flex tw-flex-row tw-items-center tw-justify-between">
      <span>
        {{ $t("MX Lookup") }}
      </span>
      <cl-form-radio-group
        id="use_mx-switcher"
        name="use_mx-switcher"
        :selectedValue="form.use_mx"
        @update:selected="(selected) => (form.use_mx = selected)"
        button
        :options="radioOptions"
      />
    </div>
    <cl-hr />
    <div class="tw-flex tw-flex-row tw-items-center tw-justify-between">
      <span>
        {{ $t("RBL Checks") }}
      </span>
      <cl-form-radio-group
        id="rbl_checks-switcher"
        name="rbl_checks-switcher"
        :selectedValue="form.rbl_checks"
        @update:selected="(selected) => (form.rbl_checks = selected)"
        button
        :options="radioOptions"
      />
    </div>
    <cl-hr />
    <div class="tw-flex tw-flex-row tw-items-center tw-justify-between">
      <span>
        {{ $t("SPF Checks") }}
      </span>
      <cl-form-radio-group
        id="spf_checks-switcher"
        name="spf_checks-switcher"
        :selectedValue="form.spf_checks"
        @update:selected="(selected) => (form.spf_checks = selected)"
        button
        :options="radioOptions"
      />
    </div>
    <cl-hr />
    <div class="tw-flex tw-flex-row tw-items-center tw-justify-between">
      <span>
        {{ $t("Grey Listing Checks") }}
      </span>
      <cl-form-radio-group
        id="grey_checks-switcher"
        name="grey_checks-switcher"
        :selectedValue="form.grey_checks"
        @update:selected="(selected) => (form.grey_checks = selected)"
        button
        :options="radioOptions"
      />
    </div>
    <cl-hr />
    <cl-form-group>
      <cl-form-label label-for="recipient-verification-type">
        {{ $t("Recipient Verification") }}
      </cl-form-label>
      <cl-form-select
        id="recipient-verification-type"
        v-model:value="form.rv_type"
        :options="recipientVerificationTypeOptions"
      />
    </cl-form-group>
    <!-- RV Type LDAP -->
    <template v-if="form.rv_type === RV_VALUE.ldap">
      <div>
        <div class="tw-grid tw-gap-x-4 md:tw-grid-flow-col">
          <cl-form-group class="md:tw-col-span-4">
            <cl-form-label label-for="ldap-server">
              {{ $t("Server") }}
            </cl-form-label>
            <cl-form-input
              id="ldap-server"
              name="ldap-server"
              v-model:value="ldap.server"
            />
          </cl-form-group>
          <cl-form-group>
            <cl-form-label label-for="ldap-port">
              {{ $t("Port") }}
            </cl-form-label>
            <cl-form-input
              id="ldap-port"
              name="ldap-port"
              v-model:value="ldap.port"
              placeholder="389"
            />
          </cl-form-group>
        </div>
      </div>
      <cl-hr />
      <div class="tw-flex tw-flex-row tw-items-center tw-justify-between">
        <span>
          {{ $t("Anonymous Search") }}
        </span>
        <cl-form-radio-group
          id="anonymous-switcher"
          name="anonymous-switcher"
          :selectedValue="ldap.search.anonymous"
          @update:selected="(selected) => (ldap.search.anonymous = selected)"
          button
          :options="radioOptions"
        />
      </div>
      <div
        v-if="ldap.search.anonymous"
        class="tw-grid tw-gap-x-4 md:tw-grid-cols-2"
      >
        <cl-form-group>
          <cl-form-label label-for="user-dn">
            {{ $t("User DN") }}
          </cl-form-label>
          <cl-form-input
            id="user-dn"
            name="user-dn"
            v-model:value="ldap.search.user_dn"
          />
        </cl-form-group>

        <cl-form-group>
          <cl-form-label label-for="user-password">
            {{ $t("User Password") }}
          </cl-form-label>
          <cl-form-input
            autofocus
            id="user-password"
            name="user-password"
            type="password"
            v-model:value="ldap.search.user_password"
          />
        </cl-form-group>
      </div>
      <cl-hr />
      <cl-form-group>
        <cl-form-label label-for="search-base">
          {{ $t("Search Base") }}
        </cl-form-label>
        <cl-form-input
          id="search-base"
          name="search-base"
          v-model:value="ldap.search.base"
        />
      </cl-form-group>
      <cl-form-group>
        <cl-form-label label-for="query-filter">
          {{ $t("Query Filter") }}
        </cl-form-label>
        <cl-form-input
          id="query-filter"
          name="query-filter"
          v-model:value="ldap.query_filter"
        />
      </cl-form-group>
      <cl-form-group>
        <cl-form-label label-for="Result Attribute">
          {{ $t("Result Attribute") }}
        </cl-form-label>
        <cl-form-input
          id="Result Attribute"
          name="Result Attribute"
          v-model:value="ldap.result_attribute"
        />
      </cl-form-group>
    </template>
    <!-- RV Type Dynamic -->
    <div
      v-if="form.rv_type === RV_VALUE.dynamic"
      class="tw-grid tw-gap-x-4 md:tw-grid-flow-col"
    >
      <cl-form-group class="md:tw-col-span-4">
        <cl-form-label label-for="dynamic-server">
          {{ $t("Server") }}
        </cl-form-label>
        <cl-form-input
          id="dynamic-server"
          name="dynamic-server"
          v-model:value="dynamic.server"
        />
      </cl-form-group>

      <cl-form-group>
        <cl-form-label label-for="dynamic-port">
          {{ $t("Port") }}
        </cl-form-label>
        <cl-form-input
          autofocus
          id="dynamic-port"
          name="dynamic-port"
          v-model:value="dynamic.port"
        />
      </cl-form-group>
    </div>
    <!-- RV Type List -->
    <cl-form-group v-if="form.rv_type === RV_VALUE.list">
      <cl-form-label label-for="list">
        {{ $t("List") }}
      </cl-form-label>

      <cl-form-textarea
        id="list"
        v-model:value="list"
        rows="3"
      ></cl-form-textarea>
    </cl-form-group>
    <!-- RV Type Regexp -->
    <cl-form-group v-if="form.rv_type === RV_VALUE.regexp">
      <cl-form-label label-for="regexp">
        {{ $t("Regexp") }}
      </cl-form-label>

      <cl-form-textarea
        id="regexp"
        v-model:value="regexp"
        rows="3"
      ></cl-form-textarea>
    </cl-form-group>
    <!-- Submit -->
    <cl-hr />
    <div class="tw-flex tw-justify-end">
      <cl-button
        :variant="'secondary'"
        @on-click="sendTestEmail"
        data-test-id="send-test-button"
      >
        <span>{{ this.$t("Send Test Email") }}</span>
      </cl-button>
      <cl-button
        :variant="'secondary'"
        @on-click="submit"
        data-test-id="submit-button"
      >
        <span v-if="!saving">{{ this.$t("Save Changes") }}</span>
        <cl-spinner v-else :size="'small'" />
      </cl-button>
    </div>
  </form>
  <t-test-email
    :domain="selectedDomain.domain"
    :destination="selectedDomain.destination"
  />
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import { useVuelidate } from "@vuelidate/core";
import { required } from "@vuelidate/validators";

import { RV_VALUE, REGEX } from "@/constants";
import InputMixin from "@/mixins/InputMixin.js";
import TTestEmail from "@/modals/TTestEmail.vue";
import TSkeleton from "@/components/TSkeleton";
import port from "@/constants/port.js";

export default {
  name: "DomainConfiguration",
  components: {
    "t-test-email": TTestEmail,
    TSkeleton,
  },
  mixins: [InputMixin], // call this.restrictInput() on template
  setup() {
    return { v$: useVuelidate() };
  },
  validations() {
    return {
      form: {
        destination: {
          required,
          matchRegex: (value) => {
            const destinations = value.split(/[ ,]+/);

            if (destinations.length <= 0) {
              return false;
            }

            return destinations.every((destination) =>
              REGEX.IPORFQDN.test(destination)
            );
          },
        },
        port: port.validation,
      },
    };
  },
  data() {
    return {
      RV_VALUE,
      DOMAIN: REGEX.DOMAIN,
      loading: true,
      saving: false,
      form: {},
      dynamic: {
        server: null,
        port: 25,
      },
      regexp: "",
      list: "",
      ldap: {
        server: null,
        port: 389,
        search: {
          anonymous: true,
          user_dn: "",
          user_password: "",
          base: "",
        },
        query_filter:
          "(|(proxyaddresses=smtp$%s)(proxyaddresses=smtp:%s)(mail=%s)(userPrincipalName=%s))",
        result_attribute: "mail",
      },
      radioOptions: [
        { label: this.$t("Yes"), value: true },
        { label: this.$t("No"), value: false },
      ],
      recipientVerificationTypeOptions: [
        { text: this.$t("None"), value: RV_VALUE.none },
        { text: this.$t("LDAP"), value: RV_VALUE.ldap },
        { text: this.$t("Dynamic"), value: RV_VALUE.dynamic },
        { text: this.$t("List"), value: RV_VALUE.list },
        { text: this.$t("Regexp"), value: RV_VALUE.regexp },
      ],
      validationErrors: port.errorMessages,
    };
  },
  computed: {
    ...mapGetters("authentication", ["selectedDomain"]),
    domain() {
      if (this.form.rv_type === RV_VALUE.ldap) {
        return { ...this.form, ldap: this.ldap };
      }

      if (this.form.rv_type === RV_VALUE.dynamic) {
        return { ...this.form, dynamic: this.dynamic };
      }

      if (this.form.rv_type === RV_VALUE.list) {
        const list = this.list.replace(/\r\n/g, "\n").split("\n");

        return { ...this.form, list };
      }

      if (this.form.rv_type === RV_VALUE.regexp) {
        const regexp = this.regexp.replace(/\r\n/g, "\n").split("\n");

        return { ...this.form, regexp };
      }

      // Default
      return { ...this.form };
    },
  },
  methods: {
    ...mapActions("pageMeta", ["setPageTitle"]),
    ...mapActions("pageMeta", { setBreadcrumbDomain: "setDomain" }),
    ...mapActions("domains", ["updateDomain", "fetchDomainDetails"]),
    ...mapActions("toast", ["displayToast"]),
    ...mapActions("authentication", ["setDomain"]),
    ...mapActions("modal", ["openModal", "closeModal"]),
    resetToDefaults() {
      this.form.domain = this.selectedDomain.domain;
      this.form.description = this.selectedDomain.description;
      this.form.destination = this.selectedDomain.destination;
      this.form.port = this.selectedDomain.port;
      this.form.use_mx = this.selectedDomain.use_mx;
      this.form.rbl_checks = this.selectedDomain.rbl_checks;
      this.form.spf_checks = this.selectedDomain.spf_checks;
      this.form.grey_checks = this.selectedDomain.grey_checks;
      this.form.rv_type = this.selectedDomain.recipient_verification.type;

      if (this.selectedDomain.recipient_verification.type === RV_VALUE.ldap) {
        this.ldap.server = this.selectedDomain.recipient_verification.server;
        this.ldap.port = this.selectedDomain.recipient_verification.port;

        this.ldap.search.anonymous =
          this.selectedDomain.recipient_verification.anon;
        this.ldap.search.user_dn =
          this.selectedDomain.recipient_verification.username;
        this.ldap.search.user_password =
          this.selectedDomain.recipient_verification.password;
        this.ldap.search.base = this.selectedDomain.recipient_verification.sb;

        this.ldap.query_filter =
          this.selectedDomain.recipient_verification.filter;
        this.ldap.result_attribute =
          this.selectedDomain.recipient_verification.attribute;
      }

      if (
        this.selectedDomain.recipient_verification.type === RV_VALUE.dynamic
      ) {
        this.dynamic.server =
          this.selectedDomain.recipient_verification.server.split(":")[1];
        this.dynamic.port =
          this.selectedDomain.recipient_verification.server.split(":")[2];
      }

      if (this.selectedDomain.recipient_verification.type === RV_VALUE.list) {
        this.list = this.selectedDomain.recipient_verification.users.join("\n");
      }

      if (this.selectedDomain.recipient_verification.type === RV_VALUE.regexp) {
        this.regexp =
          this.selectedDomain.recipient_verification.users.join("\n");
      }

      this.isChanged = false;
    },
    async getDomainDetails() {
      try {
        this.loading = true;

        const domainDetails = await this.fetchDomainDetails({
          id: null,
          tier: null,
          domainId: this.selectedDomain.id,
        });

        this.setDomain(domainDetails);
        this.setBreadcrumbDomain(domainDetails.domain);
        this.resetToDefaults();
      } catch (_err) {
        // stub
      } finally {
        this.loading = false;
      }
    },
    setInputState(input) {
      return input.$dirty ? !input.$error : null;
    },
    setCurrentErrorMessage(errors) {
      if (!errors.length) {
        return;
      }
      const validator = errors[0].$validator;
      return this.$t(port.errorMessages[validator]);
    },
    async submit() {
      const isValid = await this.v$.form.$validate();

      if (!isValid) {
        return;
      }

      try {
        this.saving = true;

        await this.updateDomain({
          id: null,
          tier: null,
          domainId: this.selectedDomain.id,
          formData: this.domain,
        });

        this.setDomain({
          ...this.selectedDomain,
          ...this.domain,
        });

        this.displayToast({
          title: this.$t("Success"),
          message: this.$t("Updated"),
          duration: 2000,
          variant: "success",
        });
      } catch (_err) {
        // stub
      } finally {
        this.saving = false;
      }
    },
    sendTestEmail() {
      this.openModal();
    },
  },
  beforeMount() {
    this.setPageTitle("");
  },
  mounted() {
    this.getDomainDetails();
    this.setPageTitle(this.selectedDomain.domain || this.selectedDomain.name);
  },
};
</script>
