<template>
  <edit-Exemption
    v-if="selectedCustomer.id"
    :tier="tierType"
    :id="selectedCustomer.id"
  />
</template>
<script>
import { mapActions, mapGetters } from "vuex";

import EditExemption from "@/views/Common/GeoBlocking/Exemptions/Edit";

import { TIER } from "@/constants";

export default {
  name: "customerGeoblockingExemptionEdit",
  components: {
    EditExemption,
  },
  data() {
    return {
      tierType: TIER.domainGroups,
      geoblockingId: null,
    };
  },
  methods: {
    ...mapActions("pageMeta", ["setPageTitle", "setPageSubtitle"]),
  },
  computed: {
    ...mapGetters("authentication", ["selectedCustomer"]),
  },
  created() {
    this.geoblockingId = this.$route.params.id;
  },
  mounted() {
    this.setPageTitle(this.$t("Id:") + this.geoblockingId);
    this.setPageSubtitle("");
  },
};
</script>
