<template>
  <header>
    <section>
      <div class="tw-flex tw-items-center tw-justify-center">
        <router-link :to="{ name: 'auth-sign-in' }"> </router-link>
      </div>
    </section>
  </header>
</template>

<script>
export default {
  name: "TAuthHeader",
  methods: {
    routeTryAndBuy() {
      this.$router.push("#");
    },
  },
};
</script>
