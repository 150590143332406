<template>
  <patterns-list
    v-if="selectedDomain.id"
    :id="selectedDomain.id"
    :tier="TIER.domains"
  ></patterns-list>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import PatternsList from "@/views/Common/PatternFilters/List/index.vue";

import { TIER } from "@/constants/tier";

export default {
  name: "PatternsListWrapper",
  components: {
    PatternsList,
  },
  data() {
    return {
      TIER,
    };
  },
  computed: {
    ...mapGetters("authentication", ["selectedDomain"]),
  },
  methods: {
    ...mapActions("pageMeta", ["setPageTitle", "setPageSubtitle"]),
  },
  mounted() {
    this.setPageTitle(this.$t("Pattern Filtering"));
    this.setPageSubtitle([
      this.$t(
        "Pattern filtering allows you to block or accept emails based on filter rules that can be applied to an email subject, header or body."
      ),
    ]);
  },
};
</script>
