<template>
  <Teleport to="body">
    <cl-modal
      :visible="showModal"
      @on-close="onCloseModal"
      headerLabel="Add allowed URL"
    >
      <form @submit.prevent="submit" autocomplete="off">
        <div>
          <cl-form-group>
            <cl-form-label label-for="url">
              {{ $t("Allowed URL") }}
            </cl-form-label>
            <cl-form-input
              id="url"
              name="url"
              v-model:value="form.url"
              :state="setInputState(v$.form.url)"
              @on-blur="v$.form.url.$touch"
            />
            <div
              class="tw-block tw-text-sm tw-text-danger-500"
              v-if="v$.form.url.$error"
            >
              {{ $t("This field is a required field") }}
            </div>
          </cl-form-group>
        </div>
        <div>
          <cl-form-group>
            <cl-form-label label-for="comment">
              {{ $t("Comments (optional)") }}
            </cl-form-label>
            <cl-form-textarea
              id="textarea"
              v-model:value="form.comment"
              rows="3"
              max-rows="6"
            ></cl-form-textarea>
          </cl-form-group>
        </div>
      </form>

      <template v-slot:[`footer.decline`]>
        <cl-button
          :disabled="fetching"
          @on-click="closeModal"
          variant="link-secondary"
        >
          <span>{{ $t("Cancel") }}</span>
        </cl-button>
      </template>
      <template v-slot:[`footer.accept`]>
        <cl-button
          :variant="'secondary'"
          @on-click="submit"
          data-test-id="modal-allowed-url-add-button"
        >
          <span v-if="!fetching">{{ this.$t("Add") }}</span>
          <cl-spinner v-else :size="'small'" />
        </cl-button>
      </template>
    </cl-modal>
  </Teleport>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { useFocusElement } from "@/composables/useFocusElement";
import { useVuelidate } from "@vuelidate/core";
import { required } from "@vuelidate/validators";

const DEFAULT_FORM = Object.freeze({
  url: "",
  strict: true,
  comment: "",
});

export default {
  setup() {
    const { focusElementById } = useFocusElement();
    return { v$: useVuelidate(), focusElementById };
  },
  validations() {
    return {
      form: {
        url: {
          required,
        },
      },
    };
  },
  props: {
    id: {
      type: [String, Number],
      required: true,
    },
    tier: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      fetching: false,
      form: {
        ...DEFAULT_FORM,
      },
    };
  },
  computed: {
    ...mapGetters("modal", ["showModal"]),
  },
  watch: {
    showModal: {
      handler() {
        this.focusElementById("url");
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    ...mapActions("toast", ["displayToast"]),
    ...mapActions("linkLock", ["createLinkLockExemption"]),
    ...mapActions("modal", ["closeModal"]),
    setInputState(input) {
      return input.$dirty ? !input.$error : null;
    },
    onCloseModal() {
      this.closeModal();
      this.form = { ...DEFAULT_FORM };
      this.v$.$reset();
    },
    async submit() {
      const isValid = await this.v$.form.$validate();

      if (!isValid) {
        return;
      }

      try {
        this.fetching = true;

        await this.createLinkLockExemption({
          tier: this.$props.tier,
          id: this.$props.id,
          formData: this.form,
        });

        this.displayToast({
          title: this.$t("Success"),
          message: this.$t("Created"),
          duration: 2000,
          variant: "success",
        });

        this.onCloseModal();
      } catch (_err) {
        // stub
      } finally {
        this.fetching = false;
      }
    },
  },
};
</script>
