<template>
  <quarantine-common
    v-if="selectedUser"
    :tier="tierType"
    :tier_id="selectedUser.id"
    class="tw-mb-6"
  />
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import QuarantineCommon from "@/views/Common/Quarantine";

import { TIER } from "@/constants";

export default {
  components: {
    QuarantineCommon,
  },
  data() {
    return {
      tierType: TIER.users,
    };
  },
  methods: {
    ...mapActions("pageMeta", ["setPageTitle", "setPageSubtitle", "setUser"]),
  },
  computed: {
    ...mapGetters("authentication", ["selectedUser"]),
  },
  beforeMount() {
    this.setUser(this.selectedUser.email);
  },
  async mounted() {
    this.setPageTitle(this.$t("Quarantine"));
    this.$store.commit(
      "quarantinedMessages/setRecipient",
      this.selectedUser.email
    );
    if (!this.selectedUser) {
      this.$router.push("/logout");
    }
  },
  beforeUnmount() {
    this.$store.commit("emailsHistory/setRecipient", "");
  },
};
</script>
