<template>
  <div class="anti-span-engine-wrap">
    <router-view> </router-view>
  </div>
</template>

<script>
export default {
  name: "SpamPolicies",
};
</script>
