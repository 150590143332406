<template>
  <div>
    <div class="tw-mb-6">
      <div>
        <span>
          {{
            this.$t(
              "Other products in our cyber security product suite include: "
            )
          }}
        </span>
        <b> {{ this.$t("WebTitan, ArcTitan, SafeTitan ") }} </b>
        {{ this.$t("and") }}
        <b> {{ this.$t("PhishTitan. ") }} </b>
        <span>
          {{
            this.$t(
              "Choose 'Try Now' to explore the features and benefits of these products."
            )
          }}
        </span>
      </div>
    </div>

    <div
      class="tw-flex tw-flex-wrap tw-justify-center tw-gap-2 sm:tw-gap-4 md:tw-gap-6 lg:tw-gap-8 xl:tw-gap-10"
    >
      <div
        v-for="(productLink, idx) in productLinks"
        :key="idx"
        class="tw-w-80"
      >
        <cl-card
          :data-test-id="`user-menu-${productLink.title.toLowerCase()}titan`"
        >
          <template #title>
            <div class="tw-justify-self-auto">
              <img
                :src="productLink.img"
                alt="Product logo"
                class="tw-h-16 tw-bg-[#fff]"
              />
            </div>
          </template>
          <template #text>
            <div class="tw-h-48 tw-pb-6">
              <ul>
                <li
                  v-for="(line, key) in productLink.content"
                  class="tw-mt-4"
                  :key="key"
                >
                  {{ $t(line) }}
                </li>
              </ul>
            </div>
          </template>
          <template #footer>
            <cl-button
              :variant="'secondary'"
              id="button"
              class="float-right"
              @on-click="cardClick(productLink.link)"
            >
              {{ $t("Try Now") }}
            </cl-button>
          </template>
        </cl-card>
      </div>
    </div>
    <router-view />
  </div>
</template>

<script>
import { mapActions } from "vuex";
import {
  PRODUCTS_IMG,
  ARC_CONTENT,
  SAFE_CONTENT,
  WEB_CONTENT,
  ARC_CONFIG,
  SAFE_CONFIG,
  WEB_CONFIG,
  PHISH_CONTENT,
  PHISH_CONFIG,
} from "@/constants";
export default {
  data() {
    return {
      productLinks: [
        {
          title: PHISH_CONFIG.title,
          link: PHISH_CONFIG.link,
          img: this.$getImageUrl(PRODUCTS_IMG.PHISH),
          content: PHISH_CONTENT,
        },
        {
          title: ARC_CONFIG.title,
          link: ARC_CONFIG.link,
          img: this.$getImageUrl(PRODUCTS_IMG.ARC),
          content: ARC_CONTENT,
        },
        {
          title: SAFE_CONFIG.title,
          link: SAFE_CONFIG.link,
          img: this.$getImageUrl(PRODUCTS_IMG.SAFE),
          content: SAFE_CONTENT,
        },
        {
          title: WEB_CONFIG.title,
          link: WEB_CONFIG.link,
          img: this.$getImageUrl(PRODUCTS_IMG.WEB),
          content: WEB_CONTENT,
        },
      ],
    };
  },
  methods: {
    ...mapActions("pageMeta", ["setPageTitle", "setPageSubtitle"]),
    cardClick(link) {
      window.open(link, "_blank");
    },
  },
  created() {
    this.setPageTitle([]);
    this.setPageSubtitle([]);
    this.setPageTitle(this.$t("TitanHQ Products"));
  },
};
</script>
<style lang="scss" scoped>
ul li:before {
  content: "\2713";
  color: #00baa0;
  padding: 0 6px 0 0;
}

ul li {
  list-style-type: none;
  font-size: 14px;
}
</style>
