<template>
  <div class="linklock-policy-configuration tw-my-6" v-if="policy">
    <form @submit.prevent autocomplete="off" @change="onChange">
      <!-- Rewrite DKIM-signed mail -->
      <cl-hr />
      <div class="tw-flex tw-flex-row tw-items-center tw-justify-between">
        <span>
          {{ $t("Rewrite DKIM-signed mail") }}
        </span>
        <cl-form-radio-group
          :class="{ 'tw-opacity-50 hover:tw-opacity-100': isInherited }"
          id="DKIM-switcher"
          name="DKIM-switcher"
          :selectedValue="form.rewrite_dkim"
          @update:selected="(selected) => (form.rewrite_dkim = selected)"
          :options="options"
          button
        />
      </div>
      <!-- Follow redirection links -->
      <cl-hr />
      <div class="tw-flex tw-flex-row tw-items-center tw-justify-between">
        <span>
          {{ $t("Follow redirection links") }}
        </span>
        <cl-form-radio-group
          :class="{ 'tw-opacity-50 hover:tw-opacity-100': isInherited }"
          id="redirection-links-switcher"
          name="redirection-links-switcher"
          :selectedValue="form.follow_url_redirection"
          @update:selected="
            (selected) => (form.follow_url_redirection = selected)
          "
          :options="options"
          button
        />
      </div>
      <!-- Display a button on the block page to continue to the blocked site -->
      <cl-hr />
      <div class="tw-flex tw-flex-row tw-items-center tw-justify-between">
        <span>
          {{
            $t(
              "Display a button on the block page to continue to the blocked site"
            )
          }}
        </span>
        <cl-form-radio-group
          :class="{ 'tw-opacity-50 hover:tw-opacity-100': isInherited }"
          id="show-continue-links-switcher"
          name="show-continue-links-switcher"
          :selectedValue="form.block_page_show_continue"
          @update:selected="
            (selected) => (form.block_page_show_continue = selected)
          "
          :options="options"
          button
        />
      </div>
      <!-- Show the original website URL on the block page -->
      <cl-hr />
      <div class="tw-flex tw-flex-row tw-items-center tw-justify-between">
        <span>
          {{ $t("Show the original website URL on the block page") }}
        </span>
        <cl-form-radio-group
          :class="{ 'tw-opacity-50 hover:tw-opacity-100': isInherited }"
          id="show-url-links-switcher"
          name="show-url-links-switcher"
          :selectedValue="form.block_page_show_url"
          @update:selected="(selected) => (form.block_page_show_url = selected)"
          :options="options"
          button
        />
      </div>
      <!-- Show logo on the block page -->
      <cl-hr />
      <div class="tw-flex tw-flex-row tw-items-center tw-justify-between">
        <span>
          {{ $t("Show logo on the block page") }}
        </span>
        <cl-form-radio-group
          :class="{ 'tw-opacity-50 hover:tw-opacity-100': isInherited }"
          id="show-logo-links-switcher"
          name="show-logo-links-switcher"
          :selectedValue="form.block_page_show_logo"
          @update:selected="
            (selected) => (form.block_page_show_logo = selected)
          "
          :options="options"
          button
        />
      </div>
      <!-- Custom logo on the block page -->
      <cl-hr />
      <div class="tw-flex tw-flex-row tw-items-center tw-justify-between">
        <span>
          {{ $t("Use custom logo on the block page") }}
        </span>
        <cl-form-radio-group
          :class="{ 'tw-opacity-50 hover:tw-opacity-100': isInherited }"
          id="custom-logo-links-switcher"
          name="custom-logo-links-switcher"
          :selectedValue="form.block_page_use_custom_logo"
          @update:selected="
            (selected) => (form.block_page_use_custom_logo = selected)
          "
          :options="options"
          button
        />
      </div>
      <!-- Custom logo file -->
      <div v-if="form.block_page_use_custom_logo">
        <div class="tw-grid tw-grid-cols-12 tw-gap-4">
          <div class="tw-col-span-12 sm:tw-col-span-4 md:tw-col-span-2">
            <img
              :src="logoPreview"
              class="logo-image tw-h-full tw-w-full tw-bg-repeat tw-p-2"
            />
          </div>
          <div class="tw-col-span-12 sm:tw-col-span-8 md:tw-col-span-10">
            <cl-form-group v-if="!loading">
              <p>
                {{
                  $t(
                    "Custom logo file in format: png,jpg,jpeg; Max size 2048KB"
                  )
                }}
              </p>
              <input
                id="logo-input"
                type="file"
                class="file:tw-mr-4 file:tw-cursor-pointer file:tw-rounded-md file:tw-border-none file:tw-bg-secondary-600 file:tw-px-6 file:tw-py-2 file:tw-text-white focus:tw-outline-none"
                :accept="imageUploaderRules.supportedTypes"
                @change="uploadLogo"
              />
            </cl-form-group>
          </div>
        </div>
      </div>
      <!-- Use custom message on the block page -->
      <cl-hr />
      <div class="tw-flex tw-flex-row tw-items-center tw-justify-between">
        <span>
          {{ $t("Use custom message on the block page") }}
        </span>
        <cl-form-radio-group
          :class="{ 'tw-opacity-50 hover:tw-opacity-100': isInherited }"
          id="custom-message-links-switcher"
          name="custom-message-links-switcher"
          :selectedValue="form.block_page_use_custom_message"
          @update:selected="
            (selected) => (form.block_page_use_custom_message = selected)
          "
          :options="options"
          button
        />
      </div>
      <div v-if="form.block_page_use_custom_message">
        <div class="tw-grid tw-gap-4 sm:tw-grid-cols-2">
          <!-- Block page message header -->
          <div>
            <cl-form-group>
              <cl-form-label label-for="message-header-input">
                {{ $t("Block page message header") }}
              </cl-form-label>
              <cl-form-input
                autofocus
                id="message-header-input"
                name="message-header-input"
                v-model:value="form.block_page_message_header"
                size="medium"
                :state="setInputState(v$.form.block_page_message_header)"
                @on-blur="v$.form.block_page_message_header.$touch"
              />
              <span
                class="tw-block tw-pt-1 tw-text-sm tw-text-danger-500"
                v-if="v$.form.block_page_message_header.$error"
                >{{
                  $t("The message must be no more than 255 characters length")
                }}</span
              >
            </cl-form-group>
          </div>
          <!-- Block page message body -->
          <div>
            <cl-form-group>
              <cl-form-label label-for="message-body-input">
                {{ $t("Block page message body") }}
              </cl-form-label>
              <cl-form-input
                id="message-body-input"
                name="message-body-input"
                v-model:value="form.block_page_message_body"
                size="medium"
                :state="setInputState(v$.form.block_page_message_body)"
                @on-blur="v$.form.block_page_message_body.$touch"
              />
              <span
                class="tw-block tw-pt-1 tw-text-sm tw-text-danger-500"
                v-if="v$.form.block_page_message_body.$error"
                >{{
                  $t("The message must be no more than 255 characters length")
                }}</span
              >
            </cl-form-group>
          </div>
        </div>
      </div>
      <!-- Comment -->
      <cl-hr />
      <cl-form-group>
        <cl-form-label label-for="comment-input">
          {{ $t("Comment") }}
        </cl-form-label>
        <cl-form-textarea
          id="comment-input"
          v-model:value="form.comment"
          rows="3"
          maxlength="250"
          class="tw-w-full"
        ></cl-form-textarea>
      </cl-form-group>
      <!-- Submit -->
      <div class="tw-flex tw-justify-end">
        <cl-button
          type="submit"
          :disabled="!isChanged"
          @on-click="submit"
          variant="secondary"
        >
          <span v-if="!loading"> {{ $t("Save Changes") }} </span>
          <span class="px-2" v-else><cl-spinner size="small" /></span>
        </cl-button>
      </div>
    </form>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { useVuelidate } from "@vuelidate/core";
import { required, maxLength } from "@vuelidate/validators";
import uploadLogo from "@/composables/useUploadLogo";

export default {
  name: "Policy",
  props: {
    id: {
      type: [String, Number],
      default: null,
    },
    tier: {
      type: String,
      default: null,
    },
  },
  setup() {
    const {
      loading,
      errorMessage,
      uploadedImage,
      logoPreview,
      customLogoFile,
      imageUploaderRules,
      upload,
    } = uploadLogo.useUploadLogo();

    return {
      v$: useVuelidate(),
      imageLoading: loading,
      errorMessage,
      uploadedImage,
      logoPreview,
      customLogoFile,
      imageUploaderRules,
      upload,
    };
  },
  validations() {
    return {
      form: {
        block_page_message_header: {
          required,
          maxLengthValue: maxLength(255),
        },
        block_page_message_body: {
          required,
          maxLengthValue: maxLength(255),
        },
      },
    };
  },
  data() {
    return {
      loading: false,
      isChanged: false,
      form: {
        rewrite_dkim: false,
        follow_url_redirection: false,
        block_page_show_continue: false,
        block_page_show_url: false,
        block_page_show_logo: false,
        block_page_use_custom_logo: false,
        block_page_custom_logo_file: null,
        block_page_use_custom_message: false,
        block_page_message_header: "",
        block_page_message_body: "",
        comment: "",
      },
      options: [
        { label: this.$t("Yes"), value: true },
        { label: this.$t("No"), value: false },
      ],
    };
  },
  computed: {
    ...mapGetters("linkLock", ["policy", "inheritedStatus"]),
    isInherited() {
      if (this.inheritedStatus && !this.isChanged) {
        return this.inheritedStatus;
      } else {
        return false;
      }
    },
  },
  watch: {
    policy: {
      handler() {
        this.mapPolicy();
      },
      deep: true,
    },
    errorMessage() {
      this.form.block_page_custom_logo_file = null;
      this.logoPreview = this.uiLogo;
      this.displayToast({
        title: this.$t("Error"),
        message: this.$t(this.errorMessage),
        duration: 2000,
        variant: "danger",
      });
    },
    customLogoFile() {
      this.form.block_page_custom_logo_file = this.customLogoFile;
    },
  },
  methods: {
    ...mapActions("toast", ["displayToast"]),
    ...mapActions("linkLock", ["updateLinkLockPolicy", "createLinkLockPolicy"]),
    setInputState(input) {
      return input.$dirty ? !input.$error : null;
    },
    async submit() {
      if (this.form.block_page_use_custom_message) {
        const isValid = await this.v$.form.$validate();

        if (!isValid) {
          return;
        }
      }

      const formData = new FormData();

      for (const [key, value] of Object.entries(this.form)) {
        if (value === "" || value === null) {
          continue;
        }

        formData.append(key, value);
      }
      if (
        formData.get("block_page_use_custom_logo") === "true" &&
        !formData.has("block_page_custom_logo_file") &&
        this.logoPreview !== null
      ) {
        formData.delete("block_page_use_custom_logo");
      }

      if (formData.get("block_page_use_custom_message") === "false") {
        formData.delete("block_page_message_header");
        formData.delete("block_page_message_body");
      }

      const booleanFields = [
        "rewrite_dkim",
        "follow_url_redirection",
        "block_page_show_continue",
        "block_page_show_url",
        "block_page_show_logo",
        "block_page_use_custom_logo",
        "block_page_use_custom_message",
        "enabled",
      ];

      for (const field of booleanFields) {
        formData.set(field, formData.get(field) === "false" ? 0 : 1);
      }
      try {
        this.loading = true;

        if (this.inheritedStatus) {
          await this.createLinkLockPolicy({
            tier: this.tier,
            id: this.id,
            formData: formData,
          });
        } else {
          formData.append("_method", "PATCH");
          await this.updateLinkLockPolicy({
            tier: this.tier,
            id: this.id,
            formData: formData,
          });
        }

        this.displayToast({
          title: this.$t("Success"),
          message: this.$t("Link Lock policy has been updated"),
          duration: 2000,
          variant: "success",
        });
      } catch (_err) {
        // stub
      } finally {
        this.loading = false;
      }
    },
    onChange() {
      this.isChanged = true;

      if (
        !this.form.block_page_use_custom_logo &&
        !this.form.block_page_custom_logo_file
      ) {
        this.logoPreview = this.policy.block_page.custom_logo_path || null;
      }
    },
    uploadLogo(event) {
      this.upload(event);
    },
    async mapPolicy() {
      const {
        enabled,
        block_page,
        rewrite_dkim,
        follow_url_redirection,
        comment,
      } = this.policy;

      this.form = {
        block_page_show_continue: block_page.show_continue,
        block_page_show_url: block_page.show_url,
        block_page_show_logo: block_page.show_logo,
        block_page_use_custom_logo: block_page.use_custom_logo,
        block_page_use_custom_message: block_page.use_custom_message,
        block_page_message_header: block_page.message_header || "",
        block_page_message_body: block_page.message_body || "",
        rewrite_dkim,
        follow_url_redirection,
        comment,
        enabled,
      };

      if (block_page.use_custom_logo && block_page.custom_logo_path) {
        this.logoPreview = block_page.custom_logo_path;
      }
    },
  },
  mounted() {
    this.mapPolicy();
  },
};
</script>
<style scoped lang="scss">
.logo-image {
  background-size: 1.4rem 1.4rem;
  background-position:
    0 0,
    0 0,
    -0.7rem -0.7rem,
    0.7rem 0.7rem;
  background-image: linear-gradient(45deg, #000 25%, transparent 25%),
    linear-gradient(45deg, transparent 75%, #000 75%),
    linear-gradient(45deg, transparent 75%, #000 75%),
    linear-gradient(45deg, #000 25%, #fff 25%);
}
</style>
