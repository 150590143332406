<template>
  <div
    class="bg-no-repeat tw-flex tw-h-full tw-flex-col tw-justify-center tw-overflow-hidden tw-bg-hero tw-bg-cover tw-bg-fixed tw-bg-center"
  >
    <t-auth-header />
    <section
      class="tw-relative tw-left-1/2 tw-top-[20%] tw-flex tw-max-w-lg tw--translate-x-1/2 tw--translate-y-1/2 tw-flex-col tw-items-center tw-justify-center tw-rounded-md tw-bg-light tw-p-6 tw-text-center tw-shadow-sm"
    >
      <cl-heading size="h2">{{ $t("Password Reset") }}</cl-heading>
      <div class="content text-center tw-my-1">
        <p>
          {{
            $t(
              "Enter the email address associated with your account, and we'll send you a link to reset your password."
            )
          }}
        </p>
      </div>
      <form
        @submit.prevent="submit"
        class="tw-flex tw-w-full tw-flex-col tw-items-center"
      >
        <cl-form-group class="tw-my-4 tw-block tw-w-full">
          <cl-form-input
            id="email"
            name="email"
            v-model:value="form.email"
            :placeholder="$t('Email Address')"
            @on-blur="v$.form.email.$touch"
            :state="setInputState(v$.form.email)"
            size="large"
          />
          <span
            class="tw-block tw-pt-1 tw-text-sm tw-text-danger-500"
            v-if="v$.form.email.$error"
            >{{ $t("Please enter a valid email") }}</span
          >
        </cl-form-group>
        <cl-button
          variant="secondary"
          class="tw-mt-8 tw-w-3/5 tw-justify-center"
          size="large"
          type="submit"
        >
          <span v-if="!fetching">{{ this.$t("Continue") }}</span>
          <cl-spinner v-else size="small" />
        </cl-button>
      </form>
      <div class="tw-mt-4">
        <p>
          {{ $t("Return to") }}
          <a href="/auth/sign-in">{{ $t("sign in") }}</a>
        </p>
      </div>
    </section>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { useVuelidate } from "@vuelidate/core";
import { required, email } from "@vuelidate/validators";

import TAuthHeader from "@/components/TAuthHeader";

export default {
  name: "ResetPassword",
  components: {
    TAuthHeader,
  },
  setup() {
    return { v$: useVuelidate() };
  },
  validations() {
    return {
      form: {
        email: {
          required,
          email,
        },
      },
    };
  },
  data() {
    return {
      fetching: false,
      form: {
        email: "",
      },
    };
  },
  methods: {
    ...mapActions("passwordReset", ["requestResetLink"]),
    ...mapActions("toast", ["displayToast"]),
    setInputState(input) {
      return input.$dirty ? !input.$error : null;
    },
    async submit() {
      const isValid = await this.v$.form.$validate();

      if (!isValid) {
        return;
      }

      try {
        this.fetching = true;

        const res = await this.requestResetLink({
          email: this.form.email,
          domain: location.origin,
        });

        this.displayToast({
          title: this.$t("Success"),
          message: res.data.message,
          duration: 10000,
          variant: "success",
        });
      } catch (_err) {
        // stub
      } finally {
        this.fetching = false;
      }
    },
  },
};
</script>
