<template>
  <section>
    <header class="tw-mb-4">
      <h4>{{ $t("Pattern Details") }}</h4>
    </header>
    <t-skeleton v-if="loading" />
    <div v-else>
      <form v-if="pattern" @submit.prevent="submit" autocomplete="off">
        <!-- row pattern details -->
        <div class="tw-mb-2 tw-grid tw-grid-cols-12 tw-gap-4 sm:tw-gap-6">
          <div class="tw-col-span-4 sm:tw-col-span-2">
            <cl-form-group>
              <cl-form-label label-for="pattern-id-input">ID</cl-form-label>
              <cl-form-input
                id="pattern-id-input"
                name="pattern-id-input"
                v-model:value="pattern.id"
                size="medium"
                disabled
              />
            </cl-form-group>
          </div>
          <div class="tw-col-span-8 sm:tw-col-span-5">
            <cl-form-group>
              <cl-form-label label-for="pattern-name-input">
                {{ $t("Pattern Name") }}
              </cl-form-label>
              <cl-form-input
                id="pattern-name-input"
                name="pattern-name-input"
                v-model:value="pattern.name"
                size="medium"
                :state="setInputState(v$.pattern.name)"
                @on-blur="v$.pattern.name.$touch"
                :disabled="saving"
              />
              <span
                class="tw-block tw-pt-1 tw-text-sm tw-text-danger-500"
                v-if="v$.pattern.name.$error"
                >{{ $t("This field is a required field") }}</span
              >
            </cl-form-group>
          </div>
          <div class="tw-col-span-12 sm:tw-col-span-5">
            <cl-form-group>
              <cl-form-label label-for="pattern-description-input">
                {{ $t("Pattern Description") }}
              </cl-form-label>
              <cl-form-input
                id="pattern-description-input"
                name="pattern-description-input"
                v-model:value="pattern.comment"
                size="medium"
                :disabled="saving"
              />
            </cl-form-group>
          </div>
        </div>
        <cl-hr class="tw-my-4" />
        <!-- row filter expression -->
        <div class="tw-mb-4 tw-grid tw-gap-4 sm:tw-grid-cols-3 sm:tw-gap-6">
          <div>
            <cl-form-group class="tw-mb-2">
              <cl-form-label label-for="filter-type-select">
                {{ $t("Rule Type") }}
              </cl-form-label>

              <cl-form-select
                id="filter-type-select"
                v-model:value="score"
                @change="onScoreChanged($event)"
                :options="scoreOptions"
                :disabled="saving"
              ></cl-form-select>
            </cl-form-group>
          </div>
          <div>
            <cl-form-group>
              <cl-form-label label-for="filter-soft-score">
                {{ $t("Score") }}
              </cl-form-label>
              <cl-form-input
                id="filter-soft-score"
                name="filter-soft-score"
                type="number"
                step="0.001"
                :min="softScoreMin"
                :max="softScoreMax"
                v-model:value="softScore"
                size="medium"
                :state="setInputState(v$.softScore)"
                :disabled="isSoftScoreDisabled || saving"
                @on-blur="v$.softScore.$touch"
              />
              <span
                class="tw-block tw-pt-1 tw-text-sm tw-text-danger-500"
                v-if="v$.softScore.$error && score === 5"
                >{{
                  $t("The score should be in range between 0.001 and 99.999")
                }}</span
              >
              <span
                class="tw-block tw-pt-1 tw-text-sm tw-text-danger-500"
                v-if="v$.softScore.$error && score === -5"
                >{{
                  $t("The score should be in range between -99.999 and -0.001")
                }}</span
              >
            </cl-form-group>
          </div>
          <div>
            <cl-form-group class="tw-mb-2">
              <cl-form-label label-for="filter-status-select">
                {{ $t("Status") }}
              </cl-form-label>

              <cl-form-select
                id="filter-status-select"
                v-model:value="pattern.active"
                :options="statusOptions"
                :disabled="saving"
              ></cl-form-select>
            </cl-form-group>
          </div>
        </div>
        <div v-if="!isSoftScoreDisabled" class="tw-mb-2">
          <div class="alert alert-info" v-if="score === SOFT_ALLOW_DEFAULT">
            {{
              $t(
                "Soft Allow Subtracts a configurable negative score, between -0.001 and -99.999 (default: -5)."
              )
            }}
          </div>
          <div class="alert alert-info" v-if="score === SOFT_BLOCK_DEFAULT">
            {{
              $t(
                "Soft Block Adds a configurable positive score, between 0.001 and 99.999 (default: 5)."
              )
            }}
          </div>
        </div>
        <!-- Group Start -->
        <div
          class="tw-border-top-left-radius tw-border-top-right-radius tw-relative tw-mb-6 tw-border-solid tw-border-dorian-gray-100 tw-bg-white tw-p-3"
        >
          <div class="tw-mb-4 tw-grid tw-gap-4 sm:tw-grid-cols-2 sm:tw-gap-6">
            <div>
              <cl-form-group class="tw-mb-2">
                <cl-form-label label-for="filter-status-select">
                  {{ $t("Pattern Expression") }}
                </cl-form-label>

                <cl-form-select
                  id="filter-status-select"
                  v-model:value="pattern.type"
                  :options="expressionOptions"
                  :disabled="saving"
                ></cl-form-select>
              </cl-form-group>
            </div>
            <div>
              <cl-form-group>
                <cl-form-label label-for="filter-value-input">
                  {{ $t("Value") }}
                </cl-form-label>
                <cl-form-input
                  id="filter-value-input"
                  name="filter-value-input"
                  v-model:value="pattern.pattern"
                  size="medium"
                  :disabled="saving"
                  :state="setInputState(v$.pattern.pattern)"
                  @on-blur="v$.pattern.pattern.$touch"
                />
                <span
                  class="tw-block tw-pt-1 tw-text-sm tw-text-danger-500"
                  v-if="v$.pattern.pattern.$error"
                  >{{ $t("This field is a required field") }}</span
                >
              </cl-form-group>
            </div>
          </div>
        </div>
        <!-- Group Ends -->
        <!-- row apply to headers & body -->
        <div v-if="!isAppliedToHeaderOrBody">
          <div class="tw-text-danger-500">
            {{ $t("Pattern must apply to either body or headers") }}
          </div>
        </div>
        <div class="tw-mb-3 tw-grid tw-gap-4 sm:tw-grid-cols-2 sm:tw-gap-6">
          <div>
            <div
              class="tw-min-h-[150px] tw-rounded-md tw-border tw-border-dorian-gray-100 tw-bg-white tw-p-3"
              :class="{
                active: pattern.apply_body,
                invalid: !isAppliedToHeaderOrBody,
              }"
            >
              <div class="tw-mb-2 tw-flex tw-items-center tw-justify-between">
                <label for="apply-body-checkbox" class="tw-mb-0">{{
                  $t("Apply to Body")
                }}</label>
                <cl-checkbox
                  id="apply-body-checkbox"
                  :round="true"
                  :disabled="saving"
                  v-model:model-value="pattern.apply_body"
                  @update:model-value="onApplyBodyChange"
                />
              </div>
              <cl-checkbox
                class="tw-mb-3 tw-mt-2 tw-bg-dorian-gray-50 tw-p-2"
                id="use-raw-body-checkbox"
                v-model:model-value="pattern.rawbody"
                :disabled="!pattern.apply_body || saving"
              >
                {{ $t("Use Raw-body") }}
              </cl-checkbox>
            </div>
          </div>
          <div>
            <div
              class="tw-min-h-[150px] tw-rounded-md tw-border tw-border-dorian-gray-100 tw-bg-white tw-p-3"
              :class="{
                active: pattern.apply_headers,
                invalid: !isAppliedToHeaderOrBody,
              }"
            >
              <div class="tw-mb-2 tw-flex tw-items-center tw-justify-between">
                <label for="apply-headers-checkbox" class="tw-mb-0">{{
                  $t("Apply to Headers")
                }}</label>
                <cl-checkbox
                  id="apply-headers-checkbox"
                  :round="true"
                  :disabled="saving"
                  v-model:model-value="pattern.apply_headers"
                />
              </div>
              <cl-form-group>
                <cl-form-input
                  v-model:value="pattern.headers"
                  rows="1"
                  :placeholder="$t('Add one header per line')"
                  :disabled="!pattern.apply_headers || saving"
                ></cl-form-input>
              </cl-form-group>
            </div>
          </div>
        </div>
        <!-- row submit -->
        <div class="tw-mb-4 tw-flex tw-justify-end">
          <cl-button type="submit" :disabled="saving" variant="secondary">
            <span v-if="!saving"> {{ $t("Save Changes") }} </span>
            <span class="px-2" v-else><cl-spinner size="small" /></span>
          </cl-button>
        </div>
      </form>
    </div>
    <div
      v-if="fetchError"
      class="tw-full tw-rounded-md tw-bg-danger-100 tw-px-4 tw-py-2 tw-text-danger-700"
    >
      {{ $t("Error. Pattern not found") }}
    </div>
  </section>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { useVuelidate } from "@vuelidate/core";
import { required } from "@vuelidate/validators";

import {
  PATTERN_SCORE_LABEL,
  PATTERN_STATUS_LABEL,
  SINGLE_PATTERN_RULE_OPTIONS,
} from "@/constants";
import TSkeleton from "@/components/TSkeleton";

export default {
  name: "EditPattern",
  components: {
    TSkeleton,
  },
  props: {
    id: {
      type: [String, Number],
      default: null,
    },
    tier: {
      type: String,
      default: null,
    },
  },
  setup() {
    return { v$: useVuelidate() };
  },
  validations() {
    return {
      pattern: {
        pattern: {
          required,
        },
        name: {
          required,
        },
      },

      softScore: {
        required,
        matchSoftScore: (value) => {
          if (this.score === 5) {
            return value >= 0.0001 && value <= 99.999;
          }
          if (this.score === -5) {
            return value >= -99.999 && value <= -0.001;
          }
          return true;
        },
      },
    };
  },
  data() {
    return {
      SOFT_BLOCK_DEFAULT: 5,
      SOFT_ALLOW_DEFAULT: -5,
      loading: true,
      saving: false,
      fetchError: false,
      score: "",
      softScore: "",
      scoreOptions: [
        { value: 100, text: this.$t(PATTERN_SCORE_LABEL.HARD_BLOCK) },
        { value: -100, text: this.$t(PATTERN_SCORE_LABEL.HARD_ALLOW) },
        { value: 5, text: this.$t(PATTERN_SCORE_LABEL.SOFT_BLOCK) },
        { value: -5, text: this.$t(PATTERN_SCORE_LABEL.SOFT_ALLOW) },
        { value: 0, text: this.$t(PATTERN_SCORE_LABEL.TEST_MODE) },
      ],
      expressionOptions: Object.entries(SINGLE_PATTERN_RULE_OPTIONS).map(
        ([value, text]) => ({
          value,
          text: this.$t(text),
        })
      ),
      statusOptions: [
        { value: true, text: this.$t(PATTERN_STATUS_LABEL.ACTIVE) },
        { value: false, text: this.$t(PATTERN_STATUS_LABEL.INACTIVE) },
      ],
    };
  },
  computed: {
    ...mapGetters("patternFilters", ["patterns", "pattern"]),
    softScoreDynamicRule() {
      if (this.score === 5) {
        return "required|min_value:0.001|max_value:99.999";
      }

      if (this.score === -5) {
        return "required|min_value:-99.999|max_value:-0.001";
      }

      return "required";
    },
    softScoreMin() {
      if (this.score === 5) {
        return 0.001;
      }

      if (this.score === -5) {
        return -99.999;
      }

      return null;
    },
    softScoreMax() {
      if (this.score === 5) {
        return 99.999;
      }

      if (this.score === -5) {
        return -0.001;
      }

      return null;
    },
    isSoftScoreDisabled() {
      if (this.score === 5 || this.score === -5) {
        return false;
      }

      return true;
    },
    isAppliedToHeaderOrBody() {
      return this.pattern.apply_headers || this.pattern.apply_body;
    },
  },
  methods: {
    ...mapActions("toast", ["displayToast"]),
    ...mapActions("pageMeta", ["setPageTitle"]),
    ...mapActions("patternFilters", [
      "clearPattern",
      "fetchPatternDetails",
      "savePatternDetails",
    ]),
    onApplyBodyChange(enabled) {
      if (!enabled) {
        this.pattern.rawbody = false;
      }
    },
    onScoreChanged(score) {
      if (score === this.SOFT_BLOCK_DEFAULT) {
        this.softScore = this.SOFT_BLOCK_DEFAULT;

        return;
      }

      if (score === this.SOFT_ALLOW_DEFAULT) {
        this.softScore = this.SOFT_ALLOW_DEFAULT;

        return;
      }

      this.softScore = score;
    },
    setInputState(input) {
      return input.$dirty ? !input.$error : null;
    },
    async submit() {
      const isValid = await (this.v$.pattern.pattern.$validate() &&
        this.v$.pattern.name.$validate());

      if (!isValid || !this.isAppliedToHeaderOrBody) {
        return;
      }

      const requiredFormatHeaders = Array.isArray(this.pattern.headers)
        ? this.pattern.headers.join(", ")
        : this.pattern.headers;

      try {
        this.saving = true;

        const pattern = {
          id: this.pattern.id,
          name: this.pattern.name,
          comment: this.pattern.comment,
          apply_body: this.pattern.apply_body,
          rawbody: this.pattern.rawbody,
          apply_headers: this.pattern.apply_headers,
          type: this.pattern.type,
          pattern: this.pattern.pattern,
          score: this.softScore,
          disable_rule: !this.pattern.active,
        };
        if (this.pattern.apply_headers) {
          requiredFormatHeaders.length > 0
            ? (pattern.headers = requiredFormatHeaders
                .split(/\n|,/)
                .map((item) => item.trim()))
            : (pattern.headers = []);
        }

        await this.savePatternDetails({
          tier: this.tier,
          id: this.id,
          pattern,
        });

        this.displayToast({
          title: this.$t("Changes Saved"),
          message: this.$t("X has been successfully updated", {
            pattern: this.pattern.name,
          }),
          duration: 2000,
          variant: "success",
        });
      } finally {
        this.saving = false;
      }
    },
    async fetchPattern() {
      const patternId = this.$route.params.id;
      this.fetchError = false;

      try {
        this.loading = true;

        await this.fetchPatternDetails({
          tier: this.tier,
          id: this.id,
          patternId,
        });
      } catch (_err) {
        this.fetchError = true;
      } finally {
        const patternScore = this.pattern?.score || 0;
        this.softScore = patternScore;

        if (patternScore >= -99.999 && patternScore <= -0.001) {
          this.score = this.SOFT_ALLOW_DEFAULT;
        } else if (patternScore >= 0.001 && patternScore <= 99.999) {
          this.score = this.SOFT_BLOCK_DEFAULT;
        } else {
          this.score = patternScore;
        }
        this.softScore = this.pattern.score;
        this.loading = false;
      }
    },
  },
  mounted() {
    this.fetchPattern();
  },
  created() {
    this.clearPattern();
  },
};
</script>
