<template>
  <section>
    <header class="tw-mb-6">
      <div class="tw-grid tw-place-items-stretch tw-gap-4 sm:tw-grid-cols-2">
        <h3 class="tw-mb-3 tw-text-3xl tw-font-medium tw-text-dorian-gray-500">
          {{ $t("All Protection and Filtering") }}
        </h3>
        <cl-form-group class="tw-flex tw-items-end">
          <cl-form-radio-group
            :selectedValue="selectedPeriod"
            @update:selected="(selected) => (selectedPeriod = selected)"
            button
            :options="options"
          />
        </cl-form-group>
      </div>
    </header>
    <div class="tw-grid tw-gap-4 sm:tw-grid-cols-2 md:tw-grid-cols-4">
      <cl-card>
        <template #text>
          <cl-heading size="h5">
            {{ $t("Messages Processed") }}
          </cl-heading>
          <div class="tw-flex tw-justify-end">
            <div
              v-if="loading"
              role="status"
              class="tw-max-w-sm tw-animate-pulse"
            >
              <div
                class="tw-bg-gray-200 tw-h-10 tw-w-16 tw-rounded tw-bg-dorian-gray-100"
              ></div>
            </div>
            <span class="tw-text-4xl" v-else>{{ messagesProcessed }}</span>
          </div>
        </template>
      </cl-card>
      <cl-card>
        <template #text>
          <cl-heading size="h5"> {{ $t("Clean Messages") }} </cl-heading>
          <div class="tw-flex tw-justify-end">
            <div
              v-if="loading"
              role="status"
              class="tw-max-w-sm tw-animate-pulse"
            >
              <div
                class="tw-bg-gray-200 tw-h-10 tw-w-16 tw-rounded tw-bg-dorian-gray-100"
              ></div>
            </div>
            <span class="tw-text-4xl" v-else>{{ cleanMessages }}</span>
          </div>
        </template>
      </cl-card>
      <cl-card>
        <template #text>
          <cl-heading size="h5"> {{ $t("Spam Messages") }} </cl-heading>
          <div class="tw-flex tw-justify-end">
            <div
              v-if="loading"
              role="status"
              class="tw-max-w-sm tw-animate-pulse"
            >
              <div
                class="tw-bg-gray-200 tw-h-10 tw-w-16 tw-rounded tw-bg-dorian-gray-100"
              ></div>
            </div>
            <span class="tw-text-4xl" v-else>{{ spamMessages }}</span>
          </div>
        </template>
      </cl-card>
      <cl-card>
        <template #text>
          <cl-heading size="h5"> {{ $t("Virus Messages") }} </cl-heading>
          <div class="tw-flex tw-justify-end">
            <div
              v-if="loading"
              role="status"
              class="tw-max-w-sm tw-animate-pulse"
            >
              <div
                class="tw-bg-gray-200 tw-h-10 tw-w-16 tw-rounded tw-bg-dorian-gray-100"
              ></div>
            </div>
            <span class="tw-text-4xl" v-else>{{ virusMessages }}</span>
          </div>
        </template>
      </cl-card>
      <!-- Remove for now only for template, all logic is safe {{ othersProcessed }} -->
    </div>
  </section>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import { PERIOD } from "@/constants";

export default {
  name: "TMessagesStats",
  props: {
    tier: {
      type: String,
      required: true,
    },
    id: {
      type: [String, Number],
      required: true,
    },
  },
  data() {
    return {
      loading: true,
      selectedPeriod: PERIOD.week,
      others: false,
      boxSize: 3,
      options: [
        {
          value: PERIOD.month,
          label: this.$t("Last Month"),
        },
        {
          value: PERIOD.week,
          label: this.$t("Last Week"),
        },
      ],
    };
  },
  computed: {
    ...mapGetters("scanSummary", [
      "cleanMessages",
      "spamMessages",
      "virusMessages",
      "geoblockingReject",
      "heloReject",
      "invalidRecipients",
      "rblReject",
      "otherFrontlineReject",
    ]),
    messagesProcessed() {
      return (
        this.cleanMessages +
        this.spamMessages +
        this.virusMessages +
        this.geoblockingReject +
        this.heloReject +
        this.invalidRecipients +
        this.rblReject +
        this.otherFrontlineReject
      );
    },
    othersProcessed() {
      return (
        this.geoblockingReject +
        this.heloReject +
        this.invalidRecipients +
        this.rblReject
      );
    },
  },
  methods: {
    ...mapActions("scanSummary", [
      "fetchWeekScanSummary",
      "fetchMonthScanSummary",
    ]),
    setPeriod(period) {
      this.selectedPeriod = period;
    },
    async fetchWeekStats() {
      try {
        this.loading = true;
        await this.fetchWeekScanSummary({
          tier: this.tier,
          id: this.id,
        });
      } finally {
        if (this.othersProcessed > 0) {
          this.others = true;
          this.boxSize = 2;
        }
        this.loading = false;
      }
    },
    async fetchMonthStats() {
      try {
        this.loading = true;
        await this.fetchMonthScanSummary({
          tier: this.tier,
          id: this.id,
        });
      } finally {
        this.loading = false;
      }
    },
  },
  watch: {
    selectedPeriod() {
      if (this.selectedPeriod === PERIOD.month) {
        this.fetchMonthStats();
      } else {
        this.fetchWeekStats();
      }
    },
  },
  mounted() {
    this.fetchWeekStats();
  },
};
</script>
