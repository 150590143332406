import Attachments from "@/views/Customer/ContentFiltering/Attachments";
import ContentFiltering from "@/views/Customer/ContentFiltering";
import Settings from "@/views/Customer/Settings";

import { ACCOUNT_TYPE } from "@/constants";

const customerRoutes = [
  {
    path: "",
    redirect: {
      name: "customerOver",
    },
    meta: {
      authorize: [ACCOUNT_TYPE.SYSTEM, ACCOUNT_TYPE.MSP, ACCOUNT_TYPE.CUSTOMER],
    },
  },
  {
    path: "overview",
    name: "customerOver",
    component: () => import("@/views/Customer/Overview"),
  },
  {
    path: "settings",
    name: "customer-settings",
    component: Settings,
    redirect: {
      name: "customer-settings-administrators",
    },
    children: [
      {
        path: "administrators",
        name: "customer-settings-administrators",
        component: () =>
          import("@/views/Customer/Settings/Administrators/index.vue"),
      },
      {
        path: "administrators/:id",
        name: "customer-settings-administrators-edit",
        component: () => import("@/views/Common/Administrators/Edit"),
      },
    ],
  },
  {
    path: "history",
    name: "customer-history",
    component: () => import("@/views/Customer/History/index.vue"),
  },
  {
    path: "history/message_overview",
    redirect: {
      name: "customer-history-message-overview",
    },
  },
  {
    path: "history/message_overview/:id",
    component: () =>
      import("@/views/Customer/History/MessageOverview/index.vue"),
    name: "customer-history-message-overview",
    redirect: {
      name: "customer-history-message-overview-details",
    },
    children: [
      {
        path: "details",
        name: "customer-history-message-overview-details",
        component: () =>
          import("@/views/Customer/History/MessageOverview/Tabs/Details.vue"),
      },
      {
        path: "message",
        name: "customer-history-message-overview-message",
        component: () =>
          import("@/views/Customer/History/MessageOverview/Tabs/Message.vue"),
      },
      {
        path: "source",
        name: "customer-history-message-overview-source",
        component: () =>
          import("@/views/Customer/History/MessageOverview/Tabs/Source.vue"),
      },
    ],
  },
  {
    path: "filtering",
    redirect: {
      name: "customer-filtering-attachments",
    },
    component: ContentFiltering,
    children: [
      {
        path: "attachments",
        name: "customer-filtering-attachments",
        component: Attachments,
        redirect: {
          name: "customer-filtering-attachments-extension",
        },
        children: [
          {
            path: "extension_filters",
            name: "customer-filtering-attachments-extension",
            component: () =>
              import(
                "@/views/Customer/ContentFiltering/Attachments/Filters/Extension.vue"
              ),
          },
          {
            path: "file_name_filters",
            name: "customer-filtering-attachments-filename",
            component: () =>
              import(
                "@/views/Customer/ContentFiltering/Attachments/Filters/FileName.vue"
              ),
          },
          {
            path: "file_type_filters",
            name: "customer-filtering-attachments-filetype",
            component: () =>
              import(
                "@/views/Customer/ContentFiltering/Attachments/Filters/FileType.vue"
              ),
          },
          {
            path: "mime_type_filters",
            name: "customer-filtering-attachments-mimetype",
            component: () =>
              import(
                "@/views/Customer/ContentFiltering/Attachments/Filters/MimeType.vue"
              ),
          },
          {
            path: "password_protected_archive",
            name: "customer-filtering-attachments-ppa",
            component: () =>
              import(
                "@/views/Customer/ContentFiltering/Attachments/Filters/PasswordProtectedArchive.vue"
              ),
          },
        ],
      },
      {
        path: "attachments/:id",
        name: "customer-filtering-attachments-edit",
        component: () =>
          import(
            "@/views/Customer/ContentFiltering/Attachments/Filters/Edit/index.vue"
          ),
      },
      {
        path: "pattern_filtering",
        name: "customer-filtering-patterns-list",
        component: () =>
          import("@/views/Customer/ContentFiltering/Patterns/index.vue"),
      },
      {
        path: "pattern_filtering/edit_single_pattern",
        redirect: {
          name: "customer-filtering-patterns-list",
        },
      },
      {
        path: "pattern_filtering/edit_multi_pattern",
        redirect: {
          name: "customer-filtering-patterns-list",
        },
      },
      {
        path: "pattern_filtering/edit_multi_pattern/:id",
        name: "customer-filtering-patterns-edit-multi",
        component: () =>
          import("@/views/Customer/ContentFiltering/Patterns/Edit/Multi.vue"),
      },
      {
        path: "pattern_filtering/edit_single_pattern/:id",
        name: "customer-filtering-patterns-edit-single",
        component: () =>
          import("@/views/Customer/ContentFiltering/Patterns/Edit/Single.vue"),
      },
      {
        path: "allow_block_list",
        name: "customer-block-allow-list",
        component: () =>
          import("@/views/Customer/ContentFiltering/BlockAllowList/index.vue"),
        redirect: {
          name: "customer-block-allow-list-emails",
        },
        children: [
          {
            path: "emails",
            name: "customer-block-allow-list-emails",
            component: () =>
              import(
                "@/views/Customer/ContentFiltering/BlockAllowList/Emails/index.vue"
              ),
          },
          {
            path: "domains",
            name: "customer-block-allow-list-domains",
            component: () =>
              import(
                "@/views/Customer/ContentFiltering/BlockAllowList/Domains/index.vue"
              ),
          },
        ],
      },
      {
        path: "allow_block_list/emails/:id",
        name: "customer-block-allow-list-emails-edit",
        component: () =>
          import(
            "@/views/Customer/ContentFiltering/BlockAllowList/Emails/Edit"
          ),
      },
      {
        path: "allow_block_list/domains/:id",
        name: "customer-block-allow-list-domains-edit",
        component: () =>
          import(
            "@/views/Customer/ContentFiltering/BlockAllowList/Domains/Edit"
          ),
      },
      {
        path: "geoblocking",
        name: "customer-geoblocking",
        component: () =>
          import("@/views/Customer/ContentFiltering/Geoblocking/index.vue"),
        redirect: {
          name: "customer-geoblocking-rule",
        },
        children: [
          {
            path: "rules",
            name: "customer-geoblocking-rule",
            component: () =>
              import(
                "@/views/Customer/ContentFiltering/Geoblocking/Tabs/Rules"
              ),
          },
          {
            path: "exemptions",
            name: "customer-geoblocking-exemptions",
            component: () =>
              import(
                "@/views/Customer/ContentFiltering/Geoblocking/Tabs/Exemptions"
              ),
          },
        ],
      },
      {
        path: "geoblocking/rules/:iso",
        name: "customer-geoblocking-rule-edit",
        component: () =>
          import(
            "@/views/Customer/ContentFiltering/Geoblocking/Tabs/Rules/Edit/index.vue"
          ),
      },
      {
        path: "geoblocking/exemptions/:id",
        name: "customer-geoblocking-exemptions-edit",
        component: () =>
          import(
            "@/views/Customer/ContentFiltering/Geoblocking/Tabs/Exemptions/Edit"
          ),
      },
      {
        path: "link_lock",
        name: "customer-link-lock",
        component: () =>
          import("@/views/Customer/ContentFiltering/LinkLock/index.vue"),
        children: [
          {
            path: "policy_configuration",
            name: "customer-link-lock-policy-configuration",
            component: () =>
              import(
                "@/views/Customer/ContentFiltering/LinkLock/Tabs/PolicyConfiguration/index.vue"
              ),
          },
          {
            path: "exemptions",
            name: "customer-link-lock-exemptions",
            component: () =>
              import(
                "@/views/Customer/ContentFiltering/LinkLock/Tabs/Exemptions/index.vue"
              ),
          },
        ],
      },
      {
        path: "link_lock/exemptions/:id",
        name: "customer-link-lock-exemptions-edit",
        component: () =>
          import(
            "@/views/Customer/ContentFiltering/LinkLock/Tabs/Exemptions/Edit/index.vue"
          ),
      },
    ],
  },
  {
    path: "quarantine",
    component: () => import("@/views/Customer/Quarantine"),
    name: "customer-quarantine",
  },
  {
    path: "quarantine/message_overview",
    name: "customer-quarantine-message",
    redirect: {
      name: "customer-quarantine",
    },
  },
  {
    path: "quarantine/message_overview/:id",
    component: () =>
      import("@/views/Customer/Quarantine/MessageOverview/index.vue"),
    name: "customer-quarantine-message-overview",
    redirect: {
      name: "customer-quarantine-message-overview-details",
    },
    children: [
      {
        path: "details",
        name: "customer-quarantine-message-overview-details",
        component: () =>
          import(
            "@/views/Customer/Quarantine/MessageOverview/Tabs/Details.vue"
          ),
      },
      {
        path: "message",
        name: "customer-quarantine-message-overview-message",
        component: () =>
          import(
            "@/views/Customer/Quarantine/MessageOverview/Tabs/Message.vue"
          ),
      },
      {
        path: "source",
        name: "customer-quarantine-message-overview-source",
        component: () =>
          import("@/views/Customer/Quarantine/MessageOverview/Tabs/Source.vue"),
      },
    ],
  },
  {
    path: "policies",
    component: () => import("@/views/Customer/Policies"),
    redirect: {
      name: "customer-policies-domain-policies",
    },
    children: [
      {
        path: "domain_policies",
        name: "customer-policies-domain-policies",
        component: () => import("@/views/Customer/Policies/DomainPolicies"),
      },
      {
        path: "domain_policies/:id",
        name: "customer-policies-domain-policy",
        component: () =>
          import("@/views/Customer/Policies/DomainPolicies/Details"),
      },
    ],
  },
  {
    path: "reporting",
    name: "customer-reports",
    redirect: {
      name: "customer-reports-scheduled",
    },
    component: () => import("@/views/Customer/Reporting"),
    children: [
      {
        path: "scheduled",
        name: "customer-reports-scheduled",
        component: () =>
          import("@/views/Customer/Reporting/ScheduledReports/List"),
      },
      {
        path: "scheduled/:id",
        name: "customer-reports-scheduled-edit",
        component: () =>
          import("@/views/Customer/Reporting/ScheduledReports/Edit"),
      },
      {
        path: "on-demand",
        name: "customer-reports-on-demand",
        component: () => import("@/views/Customer/Reporting/OnDemand/List"),
      },
      {
        path: "on-demand/:id",
        name: "customer-reports-on-demand-preview",
        component: () => import("@/views/Customer/Reporting/OnDemand/Preview"),
      },
      {
        path: "archive",
        name: "customer-reports-archive",
        component: () => import("@/views/Customer/Reporting/Archive/List"),
      },
      {
        path: "archive/:id",
        name: "customer-reports-archive-preview",
        component: () => import("@/views/Customer/Reporting/Archive/Preview"),
      },
    ],
  },
  {
    path: "domain",
    redirect: {
      name: "domain-overview",
    },
  },
  {
    path: "domain/anti-spoofing",
    redirect: {
      name: "anti-spoofing",
    },
  },
  {
    path: "domain/history",
    redirect: {
      name: "domain-history",
    },
  },
  {
    path: "domain/history/message_overview",
    redirect: {
      name: "domain-history",
    },
  },
  {
    path: "domain/history/message_overview/:id",
    redirect: {
      name: "domain-history-message-overview",
    },
  },
  {
    path: "domain/quarantine",
    redirect: {
      name: "domain-quarantine",
    },
  },
  {
    path: "domain/quarantine/message_overview",
    redirect: {
      name: "domain-quarantine",
    },
  },
  {
    path: "domain/quarantine/message_overview/:id",
    redirect: {
      name: "domain-quarantine-message-overview",
    },
  },
  {
    path: "domain/filtering",
    redirect: {
      name: "domain-filtering-attachments",
    },
  },
  {
    path: "domain/filtering/pattern_filtering",
    redirect: {
      name: "domain-filtering-patterns-list",
    },
  },
  {
    path: "domain/filtering/pattern_filtering/edit_single_pattern",
    redirect: {
      name: "domain-filtering-patterns-list",
    },
  },
  {
    path: "domain/filtering/pattern_filtering/edit_multi_pattern",
    redirect: {
      name: "domain-filtering-patterns-list",
    },
  },
  {
    path: "domain/filtering/attachments",
    redirect: {
      name: "domain-filtering-attachments",
    },
  },
  {
    path: "domain/filtering/allow_block_list",
    redirect: {
      name: "domain-block-allow-list",
    },
  },
  {
    path: "domain/filtering/allow_block_list/emails",
    redirect: {
      name: "domain-block-allow-list-emails",
    },
  },
  {
    path: "domain/filtering/allow_block_list/domains",
    redirect: {
      name: "domain-block-allow-list-domains",
    },
  },
  {
    path: "domain/filtering/geoblocking",
    redirect: {
      name: "domain-geoblocking",
    },
  },
  {
    path: "domain/filtering/geoblocking/exemptions",
    redirect: {
      name: "domain-geoblocking-exemptions",
    },
  },
  {
    path: "domain/filtering/geoblocking/rules",
    redirect: {
      name: "domain-geoblocking-rule",
    },
  },
  {
    path: "domain/filtering/link_lock",
    redirect: {
      name: "domain-link-lock",
    },
  },
  {
    path: "domain/filtering/link_lock/exemptions",
    redirect: {
      name: "domain-link-lock-exemptions",
    },
  },
  {
    path: "domain/policies",
    redirect: {
      name: "domain-policies",
    },
  },
  {
    path: "domain/policies/user_policies",
    redirect: {
      name: "domain-policies-user-policies",
    },
  },
  {
    path: "domain/reporting",
    redirect: {
      name: "domain-reports-scheduled",
    },
  },
  {
    path: "domain/reporting/scheduled",
    redirect: {
      name: "domain-reports-scheduled",
    },
  },
  {
    path: "domain/reporting/on-demand",
    redirect: {
      name: "domain-reports-on-demand",
    },
  },
  {
    path: "domain/reporting/archive",
    redirect: {
      name: "domain-reports-archive",
    },
  },
  {
    path: "domain/settings",
    redirect: {
      name: "domain-settings-administrators",
    },
  },
  {
    path: "domain/settings/administrators",
    redirect: {
      name: "domain-settings-administrators",
    },
  },
  {
    path: "domain/user",
    redirect: {
      name: "user-quarantine",
    },
  },
  {
    path: "domain/user/filtering",
    redirect: {
      name: "user-block-allow-list",
    },
  },
  {
    path: "domain/user/filtering/allow_block_list",
    redirect: {
      name: "user-block-allow-list",
    },
  },
  {
    path: "domain/user/filtering/allow_block_list/emails",
    redirect: {
      name: "user-block-allow-list-emails",
    },
  },
  {
    path: "domain/user/filtering/allow_block_list/domains",
    redirect: {
      name: "user-block-allow-list-domains",
    },
  },
  {
    path: "domain/user/filtering/geoblocking",
    redirect: {
      name: "user-geoblocking",
    },
  },
  {
    path: "domain/user/filtering/geoblocking/exemptions",
    redirect: {
      name: "user-geoblocking-exemptions",
    },
  },
  {
    path: "domain/user/filtering/geoblocking/rules",
    redirect: {
      name: "user-geoblocking-rule",
    },
  },
  {
    path: "domain/user/filtering/link_lock",
    redirect: {
      name: "user-link-lock",
    },
  },
  {
    path: "domain/user/filtering/link_lock/exemptions",
    redirect: {
      name: "user-link-lock-exemptions",
    },
  },
  {
    path: "domain/user/history",
    redirect: {
      name: "user-history",
    },
  },
  {
    path: "domain/user/history/message_overview",
    redirect: {
      name: "user-history",
    },
  },
  {
    path: "domain/user/history/message_overview/:id",
    redirect: {
      name: "user-history-message-overview",
    },
  },
  {
    path: "domain/user/quarantine",
    redirect: {
      name: "user-quarantine",
    },
  },
  {
    path: "domain/user/quarantine/message_overview",
    redirect: {
      name: "user-quarantine",
    },
  },
  {
    path: "domain/user/quarantine/message_overview/:id",
    redirect: {
      name: "user-quarantine-message-overview",
    },
  },
];

export default customerRoutes;
