export const SPAM_ACTION = Object.freeze({
  quarantine: "quarantined",
  passtag: "passtag",
  reject: "rejected",
});

export const VIRUS_ACTION = Object.freeze({
  quarantine: "quarantined",
  passtag: "passtag",
  reject: "rejected",
});

export const BANNED_ACTION = Object.freeze({
  quarantine: "quarantined",
  passtag: "passtag",
  reject: "rejected",
});

export const QREPORT_LANGUAGE = Object.freeze({
  en_us: "English (English)",
  cs_cz: "Česky (Czech)",
  zh_cn: "中文 (Chinese)",
  da_dk: "Dansk (Danish)",
  nl_nl: "Nederlands (Dutch)",
  fr_fr: "Français (French)",
  de_de: "Deutsch (German)",
  he_il: "עִברִית (Hebrew)",
  it_it: "Italiano (Italian)",
  ja_jp: "日本語 (Japanese)",
  nb_no: "Norsk (Norwegian)",
  pl_pl: "Polski (Polish)",
  pt_br: "Portugues do Brasil (Brazilian Portuguese)",
  ru_ru: "Pусский (Russian)",
  es_es: "Español (Spanish)",
  sv_se: "Svenska (Swedish)",
  th_th: "ไทย (Thai)",
  tr_tr: "Türk (Turkish)",
});

export const QREPORT_FREQUENCY = Object.freeze({
  D: "Daily",
  W: "Weekly",
  WD: "Every Week Day",
  M: "Monthly",
});

export const QREPORT_CONTAINS = Object.freeze({
  A: "All items in quarantine",
  N: "New items since last report",
  AX: "All items except Viruses in Quarantine",
  NX: "New items except Viruses since last report",
});

export default {};
