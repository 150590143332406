<template>
  <link-lock-policy-configuration
    v-if="selectedDomain.id"
    :tier="tierType"
    :id="selectedDomain.id"
  />
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import LinkLockPolicyConfiguration from "@/views/Common/LinkLock/PolicyConfiguration/index.vue";

import { TIER } from "@/constants";

export default {
  name: "PolicyConfiguration",
  components: {
    LinkLockPolicyConfiguration,
  },
  data() {
    return {
      tierType: TIER.domains,
    };
  },
  computed: {
    ...mapGetters("authentication", ["selectedDomain"]),
  },
  methods: {
    ...mapActions("pageMeta", ["setPageTitle", "setPageSubtitle"]),
  },
  created() {
    this.setPageTitle(this.$t("Link Lock"));
    this.setPageSubtitle([
      "Link Lock removes the possibility of a user clicking on a malicious link by rewriting all links in every inbound email. If a link is malicious, the user is sees a block page indicating that there is a security risk.",
    ]);
  },
};
</script>
