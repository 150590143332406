<template>
  <message-details v-if="messageDetails" :messageDetails="messageContent" />
  <div v-else><cl-spinner size="small" /></div>
</template>

<script>
import { mapGetters } from "vuex";

import MessageDetails from "@/views/Common/MessageOverview/Details";
import dayjs from "dayjs";
import { DATE_FORMAT } from "@/constants";
import getMessageType from "@/utils/get-message-type-quarantine";

export default {
  name: "DetailsTab",
  components: {
    MessageDetails,
  },
  data() {
    return {
      messageContent: "",
    };
  },
  computed: {
    ...mapGetters("message", ["messageDetails"]),
  },
  methods: {
    replaceContentType(key) {
      return this.$t(getMessageType(key));
    },
  },
  watch: {
    messageDetails: {
      handler() {
        if (this.messageDetails) {
          this.messageContent = this.messageDetails;
          this.messageContent["message_type"] = this.replaceContentType(
            this.messageContent.content_type
          );
          this.messageContent["message_received"] = `${dayjs(
            this.messageContent.message_received
          ).format(DATE_FORMAT.SHORT)}`;
        }
      },
      immediate: true,
    },
  },
};
</script>
