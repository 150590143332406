<template>
  <section>
    <p>end user report settings page</p>
  </section>
</template>

<script>
import { mapActions } from "vuex";

export default {
  methods: {
    ...mapActions("pageMeta", ["setPageTitle", "setPageSubtitle"]),
  },
  mounted() {
    this.setPageTitle(this.$t("Report Settings"));
    this.setPageSubtitle([]);
  },
};
</script>
