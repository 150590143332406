<template>
  <Teleport to="body">
    <cl-modal
      :visible="showModal"
      @on-close="onCloseModal"
      headerLabel="Add Customer"
    >
      <div class="tw-mb-4 tw-rounded-md tw-bg-light tw-p-3 sm:tw-col-span-2">
        <cl-stepper :steps="steps" />
      </div>
      <!-- First step - create Domain Group -->
      <form @submit.prevent="submit" autocomplete="off" v-if="isFirstStep">
        <div class="tw-grid sm:tw-grid-cols-2 sm:tw-gap-4">
          <div>
            <cl-form-group>
              <cl-form-label label-for="customer_name">
                {{ $t("Name") }}
              </cl-form-label>
              <cl-form-input
                data-lpignore="true"
                id="customer_name"
                name="customer_name"
                v-model:value="createDomainGroupForm.name"
                :placeholder="$t('e.g.Customer Name')"
                :state="setInputState(v$.createDomainGroupForm.name)"
                @on-blur="v$.createDomainGroupForm.name.$touch"
              />
              <div
                class="tw-block tw-text-sm tw-text-danger-500"
                v-if="v$.createDomainGroupForm.name.$error"
              >
                {{ $t("The name must be at least 2 characters long") }}
              </div>
            </cl-form-group>
          </div>
          <div>
            <cl-form-group>
              <cl-form-label label-for="description">
                {{ $t("Description (optional)") }}
              </cl-form-label>
              <cl-form-input
                id="description"
                v-model:value="createDomainGroupForm.description"
                :placeholder="$t('Description')"
              />
            </cl-form-group>
          </div>
        </div>
      </form>
      <!-- Second step - create DG Admin -->
      <form @submit.prevent="submit" autocomplete="off" v-else>
        <div class="tw-grid sm:tw-grid-cols-2 sm:tw-gap-4">
          <div>
            <cl-form-group>
              <cl-form-label label-for="first_name">
                {{ $t("First name") }}
              </cl-form-label>
              <cl-form-input
                data-lpignore="true"
                id="first_name"
                name="first_name"
                v-model:value="createAdminForm.first_name"
                :placeholder="$t('First name')"
              />
            </cl-form-group>
          </div>
          <div>
            <cl-form-group>
              <cl-form-label label-for="last_name">
                {{ $t("Last name") }}
              </cl-form-label>
              <cl-form-input
                autofocus
                data-lpignore="true"
                id="last_name"
                name="last_name"
                v-model:value="createAdminForm.last_name"
                :placeholder="$t('Last name')"
              />
            </cl-form-group>
          </div>
          <div>
            <cl-form-group>
              <cl-form-label label-for="email">
                {{ $t("Email") }}
              </cl-form-label>
              <cl-form-input
                id="email"
                v-model:value="createAdminForm.email"
                placeholder="e.g. name@company.com"
                :state="setInputState(v$.createAdminForm.email)"
                @on-blur="v$.createAdminForm.email.$touch"
              />
              <div
                class="tw-block tw-text-sm tw-text-danger-500"
                v-if="v$.createAdminForm.email.$error"
              >
                {{ $t("Please enter a valid email") }}
              </div>
            </cl-form-group>
          </div>
          <div
            class="tw-flex tw-items-center tw-justify-between"
            v-if="hasLinkLockPermission"
          >
            <cl-form-label>
              {{ $t("Link Lock Admin") }}
            </cl-form-label>
            <cl-form-radio-group
              :selectedValue="createAdminForm.linklock"
              @update:selected="
                (selected) => (createAdminForm.linklock = selected)
              "
              switcher
              button
              :options="radioOptions"
            />
          </div>
          <div>
            <cl-form-group>
              <cl-form-label label-for="password">
                {{ $t("Password") }}
              </cl-form-label>
              <cl-form-input
                id="password"
                type="password"
                v-model:value="createAdminForm.password"
                :state="setInputState(v$.createAdminForm.password)"
                @on-blur="v$.createAdminForm.password.$touch"
              />
              <div
                class="tw-block tw-text-sm tw-text-danger-500"
                v-if="v$.createAdminForm.password.$error"
              >
                {{
                  $t(
                    "The password must contain at least one uppercase, one lowercase letter, one symbol must be at least 12 characters"
                  )
                }}
              </div>
            </cl-form-group>
          </div>
          <div>
            <cl-form-group>
              <cl-form-label label-for="confirm_password">
                {{ $t("Confirm Password") }}
              </cl-form-label>
              <cl-form-input
                id="confirm_password"
                type="password"
                :state="setInputState(createAdminForm.confirm_password)"
                v-model:value="createAdminForm.confirm_password"
                @on-blur="v$.createAdminForm.confirm_password.$touch"
              />
              <div
                class="tw-block tw-text-sm tw-text-danger-500"
                v-if="v$.createAdminForm.confirm_password.$error"
              >
                {{
                  $t(
                    "The Password and the Confirm Password fields should match"
                  )
                }}
              </div>
            </cl-form-group>
          </div>
        </div>
      </form>
      <template v-slot:[`footer.decline`]>
        <cl-button
          :disabled="fetching"
          @on-click="onCloseModal"
          variant="link-secondary"
        >
          <span>{{ $t("Cancel") }}</span>
        </cl-button>
      </template>
      <template v-slot:[`footer.accept`]>
        <cl-button
          :variant="'secondary'"
          @on-click="submit"
          data-test-id="modal-customer-add-button"
        >
          <span v-if="!fetching">{{ this.$t("Add") }}</span>
          <cl-spinner v-else :size="'small'" />
        </cl-button>
      </template>
    </cl-modal>
  </Teleport>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { useVuelidate } from "@vuelidate/core";
import { required, minLength, email, sameAs } from "@vuelidate/validators";
import { useFocusElement } from "@/composables/useFocusElement";
import { TIER, STRONG_PASSWORD, STEPS } from "@/constants";
import checkPermission from "@/utils/check-permission";

const DEFAULT_CREATE_DOMAIN_GROUP_FROM = Object.freeze({
  mspId: "",
  name: "",
  description: "",
});

const DEFAULT_CREATE_ADMIN_FORM = Object.freeze({
  first_name: "",
  last_name: "",
  email: "",
  password: "",
  confirm_password: "",
  linklock: false,
});

export default {
  setup() {
    const { focusElementById } = useFocusElement();
    return { v$: useVuelidate(), focusElementById };
  },
  validations() {
    return {
      createDomainGroupForm: {
        name: {
          minLengthValue: minLength(2),
          required,
        },
      },
      createAdminForm: {
        email: {
          email,
          required,
        },
        password: {
          strongPassword: (password) => STRONG_PASSWORD.test(password),
          required,
        },
        confirm_password: {
          sameAsPassword: sameAs(this.createAdminForm.password),
          required,
        },
      },
    };
  },
  data() {
    return {
      fetching: false,
      step: STEPS.first,
      tierType: TIER.domainGroups,
      domainGroupId: "",
      hasLinkLockPermission: false,
      createDomainGroupForm: {
        ...DEFAULT_CREATE_DOMAIN_GROUP_FROM,
      },
      createAdminForm: {
        ...DEFAULT_CREATE_ADMIN_FORM,
      },
      radioOptions: [
        { label: this.$t("Yes"), value: true },
        { label: this.$t("No"), value: false },
      ],
    };
  },
  computed: {
    ...mapGetters("authentication", ["selectedMsp", "permissions"]),
    ...mapGetters("modal", ["showModal"]),
    isFirstStep() {
      return this.step === STEPS.first;
    },
    steps() {
      return [
        {
          index: 1,
          label: this.$t("Add Customer"),
          current: this.isFirstStep,
          complited: !this.isFirstStep,
          actionable: false,
        },
        {
          index: 2,
          label: this.$t("Add Customer Administrator"),
          current: !this.isFirstStep,
          complited: false,
          actionable: false,
        },
      ];
    },
  },
  watch: {
    showModal: {
      handler() {
        this.focusElementById("customer_name");
      },
      deep: true,
      immediate: true,
    },
    isFirstStep: {
      handler() {
        this.focusElementById("first_name");
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    ...mapActions("msps", ["createMspCustomer"]),
    ...mapActions("administrators", ["createAdministrator"]),
    ...mapActions("toast", ["displayToast"]),
    ...mapActions("modal", ["closeModal"]),

    setInputState(input) {
      return input.$dirty ? !input.$error : null;
    },
    onCloseModal() {
      this.closeModal();
      this.v$.$reset();
      this.step = STEPS.first;
      this.createDomainGroupForm = { ...DEFAULT_CREATE_DOMAIN_GROUP_FROM };
      this.createAdminForm = { ...DEFAULT_CREATE_ADMIN_FORM };
    },
    async submit() {
      if (this.step === STEPS.first) {
        const isValid = await this.v$.createDomainGroupForm.$validate();
        if (!isValid) return;

        this.createDomainGroupForm.mspId = this.selectedMsp.id;

        try {
          this.fetching = true;

          const response = await this.createMspCustomer({
            formData: this.createDomainGroupForm,
          });

          if (response.status === 200 || response.status === 201) {
            this.displayToast({
              title: this.$t("Success"),
              message: this.$t("Customer has been created"),
              duration: 2000,
              variant: "success",
            });
          }

          this.domainGroupId = response.data.id;
          this.step = STEPS.second;
        } catch (_err) {
          // stub
        } finally {
          this.fetching = false;
        }

        return;
      }

      if (this.step === STEPS.second) {
        const isValid = await this.v$.createAdminForm.$validate();
        if (!isValid) return;

        try {
          this.fetching = true;

          const response = await this.createAdministrator({
            tier: this.tierType,
            id: this.domainGroupId,
            formData: this.createAdminForm,
          });

          if (response.status === 200 || response.status === 201) {
            this.displayToast({
              title: this.$t("Success"),
              message: this.$t("Customer Administrator has been created"),
              duration: 2000,
              variant: "success",
            });
          }

          this.onCloseModal();
        } catch (_err) {
          // stub
        } finally {
          this.fetching = false;
        }
      }
    },
  },
  created() {
    this.hasLinkLockPermission = checkPermission(this.permissions, "link-lock");
  },
};
</script>
