<template>
  <div class="block-allow-list-wrap">
    <t-tabs :items="tabNavItems" />
    <router-view> </router-view>
  </div>
</template>

<script>
import TTabs from "@/components/TTabs";

export default {
  name: "CustomerBlockAllowList",
  components: {
    TTabs,
  },
  data() {
    return {
      tabNavItems: this.$secondaryNav.domainBlockAllowList(),
    };
  },
};
</script>
