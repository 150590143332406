import { i18n } from "@/i18n";

export default {
  install(app) {
    app.prototype.$secondaryNav.policyNavItems = function (id) {
      return [
        {
          title: i18n.global.t("Policy Details"),
          url: `/filtering/policies/${id}`,
        },
        {
          title: i18n.global.t("Allowed & Blocked Domains"),
          url: `/filtering/policies/${id}/domains`,
        },
        {
          title: i18n.global.t("Notifications"),
          url: `/filtering/policies/${id}/notifications`,
        },
        {
          title: i18n.global.t("Locations"),
          url: `/filtering/policies/${id}/locations`,
        },
        {
          title: i18n.global.t("Devices"),
          url: `/filtering/policies/${id}/devices`,
        },
      ];
    };
  },
};
