<template>
  <div>
    <h2>{{ this.$t("Change Password") }}</h2>
    <p>
      {{
        $t(
          "It can be difficult to know if someone else is using your account, so by changing your password consistently, you reduce the risk that other people will have frequent access to your account. Consider changing your password every few months for peace of mind."
        )
      }}
    </p>
  </div>
  <section>
    <form class="tw-mb-4">
      <div>
        <div class="tw-grid tw-gap-4 sm:tw-grid-cols-2">
          <cl-form-group>
            <cl-form-label label-for="new-password">
              {{ $t("New Password") }}
            </cl-form-label>
            <cl-form-input
              data-test-id="profile-security-new-password"
              type="password"
              id="new-password"
              name="new-password"
              :state="setInputState(v$.form.password)"
              @on-blur="v$.form.password.$touch"
              :placeholder="$t('New Password')"
              v-model:value="form.password"
            />
            <span
              class="tw-block tw-pt-1 tw-text-sm tw-text-danger-500"
              v-if="v$.form.password.$error"
              >{{
                $t(
                  "The password must contain at least one uppercase, one lowercase letter, one symbol must be at least 12 characters"
                )
              }}</span
            >
          </cl-form-group>
          <cl-form-group>
            <cl-form-label label-for="new-password-confirmation">
              {{ $t("Re-enter New Password") }}
            </cl-form-label>
            <cl-form-input
              data-test-id="profile-security-confirm-password"
              type="password"
              id="new-password-confirmation"
              name="new-password-confirmation"
              :state="setInputState(v$.form.confirm_password)"
              @on-blur="v$.form.confirm_password.$touch"
              :placeholder="$t('Re-enter New Password')"
              v-model:value="form.confirm_password"
            />
            <span
              class="tw-block tw-pt-1 tw-text-sm tw-text-danger-500"
              v-if="v$.form.confirm_password.$error"
              >{{
                $t("The Password and the Confirm Password fields should match")
              }}</span
            >
          </cl-form-group>
        </div>
      </div>
      <div class="tw-flex tw-justify-end">
        <cl-button v-if="loading" variant="secondary">
          <cl-spinner :size="'small'" />
          <span>Saving...</span>
        </cl-button>
        <cl-button
          v-else
          variant="secondary"
          @on-click="onChangePasswordSubmit"
          data-test-id="profile-security-change-password-save"
        >
          <span>{{ this.$t("Save changes") }}</span>
        </cl-button>
      </div>
    </form>
  </section>
  <cl-hr />
  <two-factor-auth />
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { useVuelidate } from "@vuelidate/core";
import { required, sameAs } from "@vuelidate/validators";

import { STRONG_PASSWORD } from "@/constants";

import twoFactorAuth from "@/views/Profile/Security/2fa";

export default {
  name: "Security",
  components: {
    twoFactorAuth,
  },
  setup() {
    return { v$: useVuelidate() };
  },
  validations() {
    return {
      form: {
        password: {
          strongPassword: (password) => STRONG_PASSWORD.test(password),
          required,
        },
        confirm_password: {
          sameAsPassword: sameAs(this.form.password),
          required,
        },
      },
    };
  },
  data() {
    return {
      loading: false,
      form: {
        password: "",
        confirm_password: "",
      },
    };
  },
  computed: {
    ...mapGetters("authentication", ["authUser", "backupCodes"]),
  },
  methods: {
    ...mapActions("pageMeta", ["setPageTitle", "setPageSubtitle"]),
    ...mapActions("toast", ["displayToast"]),
    ...mapActions("authentication", ["updatePassword"]),
    setInputState(input) {
      return input.$dirty ? !input.$error : null;
    },
    async onChangePasswordSubmit() {
      const isValid = await this.v$.form.$validate();

      if (!isValid) {
        return;
      }

      try {
        this.loading = true;
        await this.updatePassword({
          body: this.form,
        });

        this.displayToast({
          title: this.$t("Success"),
          message: this.$t("Your password successfully updated"),
          duration: 2000,
          variant: "success",
        });

        localStorage.setItem("logoutFlag", "true");
      } finally {
        this.loading = false;
        this.$router.push("/logout");
      }
    },
  },
  mounted() {
    this.setPageTitle(this.$t("Security"));
    this.setPageSubtitle([
      this.$t("Manage your account security to ensure you are fully protected"),
    ]);
  },
};
</script>
