<template>
  <Teleport to="body">
    <cl-modal
      :visible="showModal"
      :headerLabel="$t('Add Administrator')"
      @on-close="onCloseModal"
    >
      <form class="modal-form" @submit.prevent="submit" autocomplete="off">
        <div class="tw-grid sm:tw-grid-cols-2 sm:tw-gap-6">
          <div>
            <cl-form-group>
              <cl-form-label label-for="first_name">
                {{ $t("First name") }}
              </cl-form-label>
              <cl-form-input
                data-lpignore="true"
                id="first_name"
                name="first_name"
                v-model:value="form.first_name"
                :placeholder="$t('First name')"
              />
            </cl-form-group>
          </div>
          <div>
            <cl-form-group>
              <cl-form-label label-for="last_name">
                {{ $t("Last name") }}
              </cl-form-label>
              <cl-form-input
                data-lpignore="true"
                id="last_name"
                name="last_name"
                v-model:value="form.last_name"
                :placeholder="$t('Last name')"
              />
            </cl-form-group>
          </div>
        </div>
        <div class="tw-grid sm:tw-grid-cols-2 sm:tw-gap-6">
          <div>
            <cl-form-group>
              <cl-form-label label-for="email">
                {{ $t("Email") }}
              </cl-form-label>
              <cl-form-input
                id="email"
                v-model:value="form.email"
                placeholder="e.g. name@company.com"
                :state="setInputState(v$.form.email)"
                @on-blur="v$.form.email.$touch"
              />
              <div
                class="tw-block tw-text-sm tw-text-danger-500"
                v-if="v$.form.email.$error"
              >
                {{ $t("Please enter a valid email") }}
              </div>
            </cl-form-group>
          </div>
          <div
            class="tw-flex tw-items-center tw-justify-between"
            v-if="hasLinkLockPermission"
          >
            <cl-form-label label-for="switcher">
              {{ $t("Link Lock Admin") }}
            </cl-form-label>
            <cl-form-radio-group
              name="switcher"
              :selectedValue="form.linklock"
              @update:selected="(selected) => (form.linklock = selected)"
              :options="radioOptions"
              button
              data-test-id="add-administrator-switcher"
            />
          </div>
        </div>
        <div class="tw-grid sm:tw-grid-cols-2 sm:tw-gap-6">
          <div>
            <cl-form-group>
              <cl-form-label label-for="password">
                {{ $t("Password") }}
              </cl-form-label>
              <cl-form-input
                id="password"
                name="password"
                type="password"
                :state="setInputState(v$.form.password)"
                @on-blur="v$.form.password.$touch"
                v-model:value="form.password"
              />

              <div
                class="tw-block tw-text-sm tw-text-danger-500"
                v-if="v$.form.password.$error"
              >
                {{
                  $t(
                    "The password must contain at least one uppercase, one lowercase letter, one symbol must be at least 12 characters"
                  )
                }}
              </div>
            </cl-form-group>
          </div>
          <div>
            <cl-form-group>
              <cl-form-label label-for="confirm_password">
                {{ $t("Confirm Password") }}
              </cl-form-label>
              <cl-form-input
                id="confirm_password"
                type="password"
                :state="setInputState(v$.form.confirm_password)"
                @on-blur="v$.form.confirm_password.$touch"
                v-model:value="form.confirm_password"
                name="password_confirmation"
              />
              <div
                class="tw-block tw-text-sm tw-text-danger-500"
                v-if="v$.form.confirm_password.$error"
              >
                {{
                  $t(
                    "The Password and the Confirm Password fields should match"
                  )
                }}
              </div>
            </cl-form-group>
          </div>
        </div>
      </form>

      <template v-slot:[`footer.decline`]>
        <cl-button
          :disabled="fetching"
          @on-click="closeModal"
          variant="link-secondary"
        >
          <span>{{ $t("Cancel") }}</span>
        </cl-button>
      </template>
      <template v-slot:[`footer.accept`]>
        <cl-button
          :variant="'secondary'"
          @on-click="submit"
          data-test-id="modal-admin-add-button"
        >
          <span v-if="!fetching">{{ this.$t("Add") }}</span>
          <cl-spinner v-else :size="'small'" />
        </cl-button>
      </template>
    </cl-modal>
  </Teleport>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { useFocusElement } from "@/composables/useFocusElement";
import checkPermission from "@/utils/check-permission";
import { useVuelidate } from "@vuelidate/core";
import { required, email, sameAs } from "@vuelidate/validators";
import { STRONG_PASSWORD } from "@/constants";

const DEFAULT_FORM = Object.freeze({
  first_name: "",
  last_name: "",
  email: "",
  password: "",
  confirm_password: "",
  linklock: false,
});

export default {
  setup() {
    const { focusElementById } = useFocusElement();
    return { v$: useVuelidate(), focusElementById };
  },
  validations() {
    return {
      form: {
        email: {
          email,
          required,
        },
        password: {
          strongPassword: (password) => STRONG_PASSWORD.test(password),
          required,
        },
        confirm_password: {
          sameAsPassword: sameAs(this.form.password),
          required,
        },
      },
    };
  },
  props: {
    tier: {
      type: String,
      required: true,
    },
    id: {
      type: [String, Number],
      required: true,
    },
  },
  data() {
    return {
      fetching: false,
      password: "admin12345",
      hasLinkLockPermission: false,
      form: {
        ...DEFAULT_FORM,
      },
      radioOptions: [
        { label: this.$t("Yes"), value: true },
        { label: this.$t("No"), value: false },
      ],
    };
  },
  computed: {
    ...mapGetters("modal", ["showModal"]),
    ...mapGetters("authentication", ["permissions"]),
  },
  watch: {
    showModal: {
      handler() {
        this.focusElementById("first_name");
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    ...mapActions("toast", ["displayToast"]),
    ...mapActions("administrators", ["createAdministrator"]),
    ...mapActions("modal", ["closeModal"]),
    setInputState(input) {
      return input.$dirty ? !input.$error : null;
    },
    onCloseModal() {
      this.closeModal();
      this.form = { ...DEFAULT_FORM };
      this.v$.$reset();
    },
    async submit() {
      const isValid = await this.v$.form.$validate();

      if (!isValid) {
        return;
      }

      try {
        this.fetching = true;

        const response = await this.createAdministrator({
          tier: this.$props.tier,
          id: this.$props.id,
          formData: this.form,
        });

        if (response.status === 200 || response.status === 201) {
          this.displayToast({
            title: this.$t("Success"),
            message: this.$t("Administrator has been created"),
            duration: 2000,
            variant: "success",
          });
        }

        this.onCloseModal();
      } catch (_err) {
        // stub
      } finally {
        this.fetching = false;
      }
    },
  },
  created() {
    this.hasLinkLockPermission = checkPermission(this.permissions, "link-lock");
  },
};
</script>
