<template>
  <div class="administrators-list">
    <div class="tw-mb-2 tw-p-2">
      <cl-heading :size="'h3'">
        {{ $t("All Administrators") }}
      </cl-heading>
    </div>
    <cl-data-table
      data-test-id="administrators-data-table"
      :fetching="fetching"
      :headers="tableHeaders"
      :items="items"
      :total="serverTotal"
      :default-sort-by="sortByCookie"
      :default-sort-desc="sortDirCookie"
      :default-items-on-page="itemsPerPageCookie"
      with-search
      with-select
      with-set-columns
      with-server-side-pagination
      searchBy="first_name"
      :searchPlaceholder="$t('Search Admin')"
      @change-page="changePage"
      @search="debouncedSearch"
      @change-items-on-page="onChangeItemsOnPageClick"
      @sort-items="onSortItemsClick"
      @change-selected-columns="setSelectedColumnsCookie"
    >
      <template v-slot:actions="{ selectedItems }">
        <div class="tw-relative" v-if="selectedItems.length > 0">
          <span class="tw-pr-2 tw-text-sm tw-text-dorian-gray-500">
            {{ selectedItems.length }} Selected
          </span>
          <cl-button
            variant="i-am-the-danger"
            data-test-id="administrators-data-table-delete"
            @on-click="onConfirm"
          >
            {{ $t("Delete") }}
          </cl-button>
        </div>
        <component
          v-if="currentModal === 't-delete-confirm'"
          :is="'t-delete-confirm'"
          :visible="isDeleteConfirmDialogVisible"
          :fetching="fetching"
          @on-accept="onDelete(selectedItems)"
          @on-decline="isDeleteConfirmDialogVisible = false"
          @on-close="isDeleteConfirmDialogVisible = false"
        />
        <cl-button
          :variant="'secondary'"
          type="button"
          @on-click="onAdd"
          data-test-id="administrators-data-table-add"
        >
          {{ $t("Add") }}
        </cl-button>
      </template>

      <template v-slot:[`body.cell.actions`]="props">
        <cl-data-table-edit-action
          data-test-id="administrators-data-table-edit-btn"
          @on-click="onEdit(props.trItem)"
        />
      </template>
    </cl-data-table>
    <component
      v-if="currentModal === 't-add-administrator'"
      :is="'t-add-administrator'"
      :tier="tier"
      :id="id"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";

import { TIER, DEFAULT_OPTIONS } from "@/constants";
import TAddAdministrator from "@/modals/TAddAdministrator";
import { useDataTable } from "@/composables/useDataTable";
import { useDataTableCookies } from "@/composables/useDataTableCookies";
import TDeleteConfirm from "@/modals/TDeleteConfirm.vue";

export default {
  name: "AdministratorsList",
  props: {
    id: {
      type: [String, Number],
      default: null,
    },
    tier: {
      type: String,
      default: null,
    },
  },
  setup(props) {
    const cookieKey = `administrators-${props.tier || "system"}`;

    const {
      sortByCookie,
      sortDirCookie,
      itemsPerPageCookie,
      selectedColumnsCookie,
      setSortItemsCookie,
      setItemsPerPageCookie,
      setSelectedColumnsCookie,
    } = useDataTableCookies(
      {
        sortByKey: `${cookieKey}-sort-by`,
        sortDirKey: `${cookieKey}-sort-dir`,
        itemsPerPageKey: `${cookieKey}-items-per-page`,
        selectedColumnsKey: `${cookieKey}-selected-columns`,
      },
      {
        ...DEFAULT_OPTIONS,
        sortBy: "email",
      }
    );

    const optionsBasedOnCookies = {
      sortBy: sortByCookie,
      sortDir: sortDirCookie,
      results: itemsPerPageCookie,
      page: 1,
    };

    const {
      dataOptions,
      sortItems,
      changePage,
      changeItemsPerPage,
      debouncedSearch,
    } = useDataTable(optionsBasedOnCookies);

    return {
      debouncedSearch,
      sortByCookie,
      sortDirCookie,
      itemsPerPageCookie,
      selectedColumnsCookie,
      setSortItemsCookie,
      setItemsPerPageCookie,
      setSelectedColumnsCookie,
      dataOptions,
      sortItems,
      changePage,
      changeItemsPerPage,
    };
  },
  components: {
    "t-add-administrator": TAddAdministrator,
    "t-delete-confirm": TDeleteConfirm,
  },
  data() {
    return {
      fetching: false,
      currentModal: "t-add-administrator",
      isDeleteConfirmDialogVisible: false,
      items: [],
    };
  },
  watch: {
    dataOptions: {
      handler() {
        this.fetchNewItems();
      },
      deep: true,
      immediate: true,
    },
    administrators: {
      handler() {
        this.items = this.administrators;
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {
    ...mapGetters("administrators", ["administrators", "serverTotal"]),
    tableHeaders() {
      const headers = [
        { key: "first_name", label: this.$t("First Name"), sortable: true },
        { key: "last_name", label: this.$t("Last Name"), sortable: true },
        { key: "email", label: this.$t("Email"), sortable: true },
        { key: "actions", label: this.$t("Actions"), sortable: false },
      ];

      if (!this.selectedColumnsCookie?.length) {
        return headers;
      }

      return headers.map((header) => ({
        ...header,
        enabled: this.selectedColumnsCookie.includes(header.key),
      }));
    },
  },
  methods: {
    ...mapActions("modal", ["openModal", "closeModal"]),
    ...mapMutations("administrators", ["setSelectedTier", "setSelectedTierId"]),
    ...mapActions("toast", ["displayToast"]),
    ...mapActions("administrators", [
      "getAdministrators",
      "batchDeleteAdministrators",
      "clearList",
    ]),
    onSortItemsClick(by, dir) {
      this.setSortItemsCookie(by, dir);
      this.sortItems(by, dir);
    },

    onChangeItemsOnPageClick(number) {
      this.setItemsPerPageCookie(number);
      this.changeItemsPerPage(number);
    },
    onConfirm() {
      this.currentModal = "t-delete-confirm";
      this.isDeleteConfirmDialogVisible = true;
    },
    onAdd() {
      this.currentModal = "t-add-administrator";
      this.openModal();
    },
    onEdit(administrator) {
      const routeMap = {
        [TIER.msps]: "msp-settings-administrators-edit",
        [TIER.domainGroups]: "customer-settings-administrators-edit",
        [TIER.domains]: "domain-settings-administrators-edit",
      };
      let routeName;
      routeName = routeMap[this.tier];
      if (this.tier === null) {
        routeName = "system-settings-administrators-edit";
      }

      if (routeName) {
        this.setSelectedTier(this.tier);
        this.setSelectedTierId(this.id);

        this.$router.push({
          name: routeName,
          params: {
            id: administrator.id,
          },
        });
      }
    },

    async onDelete(administrators) {
      try {
        this.fetching = true;

        const res = await this.batchDeleteAdministrators({
          tier: this.tier,
          id: this.id,
          administrators,
        });

        for (let [key, value] of Object.entries(res.data)) {
          if (Number(value.code) >= 400 && Number(value.code) <= 499) {
            this.displayToast({
              title: this.$t("Admin Id: X", { id: key }),
              message: value.response.error,
              duration: 3000,
              variant: "danger",
            });
          }

          if (Number(value.code) >= 200 && Number(value.code) <= 299) {
            this.displayToast({
              title: this.$t("Success"),
              message: this.$t("Administrator with id: X has been deleted", {
                id: key,
              }),
              duration: 3000,
              variant: "success",
            });
          }
        }
      } catch (_err) {
        // stub
      } finally {
        this.isDeleteConfirmDialogVisible = false;
        this.fetching = false;
      }
    },
    async fetchNewItems() {
      try {
        this.fetching = true;

        this.clearList();
        await this.getAdministrators({
          tier: this.tier,
          id: this.id,
          ...this.dataOptions,
        });
      } catch (_err) {
        // stub
      } finally {
        this.fetching = false;
      }
    },
  },
};
</script>
