import { ref } from "vue";
import { useDebounceFn } from "@vueuse/core";

export const useDataTable = (defaultOptions) => {
  const dataOptions = ref({ ...defaultOptions });

  const search = (phrase) => {
    dataOptions.value.filter = phrase;
    dataOptions.value.page = 1;
  };

  const debouncedSearch = useDebounceFn((phrase) => {
    search(phrase);
  }, 1000);

  const sortItems = (sortBy, sortDir) => {
    dataOptions.value.sortBy = sortBy;
    dataOptions.value.sortDir = sortDir;
  };
  const changePage = (page) => {
    dataOptions.value.page = page;
  };
  const changeItemsPerPage = (itemsPerPage, currentPage) => {
    dataOptions.value.results = itemsPerPage;
    dataOptions.value.page = currentPage;
  };

  return {
    dataOptions,
    debouncedSearch,
    sortItems,
    changePage,
    changeItemsPerPage,
  };
};

export default { useDataTable };
