<template>
  <message v-if="!loading" :message="messageBody" />
  <div v-else><cl-spinner :size="'small'" /></div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { TIER } from "@/constants";

import Message from "@/views/Common/MessageOverview/Message";

export default {
  name: "DetailsTab",
  components: {
    Message,
  },
  data() {
    return {
      loading: true,
      tierType: TIER.users,
    };
  },
  computed: {
    ...mapGetters("message", ["messageBody"]),
    ...mapGetters("authentication", ["selectedUser"]),
  },
  methods: {
    ...mapActions("message", ["fetchMessageBody"]),
  },
  async mounted() {
    try {
      this.loading = true;

      await this.fetchMessageBody({
        id: this.$route.params.id,
        tier: this.tierType,
        tier_id: this.selectedUser.id,
      });
    } catch (_err) {
      // stub
    } finally {
      this.loading = false;
    }
  },
};
</script>
