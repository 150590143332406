<template>
  <div class="ips-list-page">
    <section class="ips-list-page-section">
      <cl-data-table
        :data-test-id="'ips-list-data-table'"
        :fetching="fetching"
        :headers="tableHeaders"
        :items="items"
        :total="serverTotal"
        :default-sort-by="sortByCookie"
        :default-sort-desc="sortDirCookie"
        :default-items-on-page="itemsPerPageCookie"
        with-search
        with-select
        with-server-side-pagination
        with-set-columns
        :searchBy="'cidr'"
        :searchPlaceholder="$t('Search')"
        @change-page="changePage"
        @search="debouncedSearch"
        @sort-items="onSortItemsClick"
        @change-items-on-page="onChangeItemsOnPageClick"
        @change-selected-columns="setSelectedColumnsCookie"
      >
        <template v-slot:[`body.cell.comment`]="props">
          <template v-if="props.tdValue.length > truncateMaxNumber">
            <cl-tooltip :text="props.tdValue" :id="props.tdValue">
              <span v-truncate> {{ props.tdValue }}</span>
            </cl-tooltip>
          </template>
          <template v-else>{{ props.tdValue }}</template>
        </template>

        <template v-slot:actions="{ selectedItems }">
          <div class="tw-relative" v-if="selectedItems.length > 0">
            <span class="tw-pr-2 tw-text-sm tw-text-dorian-gray-500">
              {{ selectedItems.length }} Selected
            </span>
            <cl-button variant="i-am-the-danger" @on-click="onConfirm">
              {{ $t("Delete") }}
            </cl-button>
          </div>
          <component
            v-if="currentModal === 't-delete-confirm'"
            :is="'t-delete-confirm'"
            :visible="isDeleteConfirmDialogVisible"
            :fetching="fetching"
            @on-accept="onDelete(selectedItems)"
            @on-decline="isDeleteConfirmDialogVisible = false"
            @on-close="isDeleteConfirmDialogVisible = false"
          />
          <cl-dropdown :title="'Add'" data-test-id="ips-list-data-table-add">
            <cl-dropdown-item
              @on-click="onAdd('allow')"
              data-test-id="ips-list-data-table-allow-ip"
            >
              {{ $t("Allow an IP") }}
            </cl-dropdown-item>
            <cl-dropdown-item
              @on-click="onAdd('block')"
              data-test-id="ips-list-data-table-block-ip"
            >
              {{ $t("Block an IP") }}
            </cl-dropdown-item>
          </cl-dropdown>
        </template>
        <template v-slot:[`body.cell.actions`]="props">
          <cl-data-table-edit-action
            data-test-id="ips-list-data-table-edit-btn"
            @on-click="onEdit(props.trItem)"
          />
        </template>
        <template v-slot:[`body.cell.status`]="props">
          <cl-pill
            :variant="
              props.tdValue.toLowerCase() === 'allow' ? 'success' : 'danger'
            "
            size="sm"
          >
            {{ props.tdValue }}
          </cl-pill>
        </template>
      </cl-data-table>
    </section>
    <component
      v-if="currentModal === 't-add-allow-block-ip'"
      :is="'t-add-allow-block-ip'"
      :isAllow="isAllow"
      :tier="tier"
      :title="modalTitle"
      :id="id"
    />
  </div>
</template>

<script>
import dayjs from "dayjs";
import { mapActions, mapGetters } from "vuex";

import { DATE_FORMAT, DEFAULT_OPTIONS, TIER, TRUNCATE } from "@/constants";
import { useDataTable } from "@/composables/useDataTable";
import { useDataTableCookies } from "@/composables/useDataTableCookies";
import TAddAllowBlockIp from "@/modals/TAddAllowBlockIp.vue";
import TDeleteConfirm from "@/modals/TDeleteConfirm.vue";

export default {
  name: "BlockAllowIp",
  components: {
    "t-add-allow-block-ip": TAddAllowBlockIp,
    "t-delete-confirm": TDeleteConfirm,
  },
  props: {
    tier: {
      type: String,
      default: null,
    },
    id: {
      type: [String, Number],
      default: null,
    },
  },
  setup(props) {
    const cookieKey = `allow-block-ip-${props.tier || "system"}`;

    const {
      sortByCookie,
      sortDirCookie,
      itemsPerPageCookie,
      selectedColumnsCookie,
      setSortItemsCookie,
      setItemsPerPageCookie,
      setSelectedColumnsCookie,
    } = useDataTableCookies(
      {
        sortByKey: `${cookieKey}-sort-by`,
        sortDirKey: `${cookieKey}-sort-dir`,
        itemsPerPageKey: `${cookieKey}-items-per-page`,
        selectedColumnsKey: `${cookieKey}-selected-columns`,
      },
      {
        ...DEFAULT_OPTIONS,
        sortBy: "cidr",
      }
    );

    const optionsBasedOnCookies = {
      sortBy: sortByCookie,
      sortDir: sortDirCookie,
      results: itemsPerPageCookie,
      page: 1,
    };

    const {
      dataOptions,
      sortItems,
      changePage,
      changeItemsPerPage,
      debouncedSearch,
    } = useDataTable(optionsBasedOnCookies);

    const truncateMaxNumber = TRUNCATE.max;

    return {
      dataOptions,
      debouncedSearch,
      sortItems,
      changePage,
      changeItemsPerPage,
      selectedColumnsCookie,
      setSortItemsCookie,
      setItemsPerPageCookie,
      setSelectedColumnsCookie,
      sortByCookie,
      sortDirCookie,
      itemsPerPageCookie,
      truncateMaxNumber,
    };
  },
  data() {
    return {
      currentModal: "t-add-allow-block-ip",
      fetching: false,
      isAllow: true,
      modalTitle: "",
      isDeleteConfirmDialogVisible: false,
      items: [],
    };
  },
  computed: {
    ...mapGetters("blockAllowIPsList", ["ips", "serverTotal"]),
    routeName() {
      if (this.tier === TIER.msps) {
        return "msp-block-allow-list-ips-edit";
      }

      return "system-block-allow-list-ips-edit";
    },
    tableHeaders() {
      const headers = [
        {
          key: "cidr",
          label: this.$t("IP/Network"),
          sortable: true,
        },
        {
          key: "status",
          label: this.$t("Status"),
        },
        {
          key: "lastmodified",
          label: this.$t("Last Modified"),
          sortable: true,
        },
        {
          key: "comment",
          label: this.$t("Comment"),
          sortable: true,
        },
        { key: "actions", label: this.$t("Actions"), sortable: false },
      ];

      if (!this.selectedColumnsCookie?.length) {
        return headers;
      }

      return headers.map((header) => ({
        ...header,
        enabled: this.selectedColumnsCookie.includes(header.key),
      }));
    },
  },
  watch: {
    dataOptions: {
      handler() {
        this.fetchNewIPs();
      },
      deep: true,
      immediate: true,
    },
    ips: {
      handler(newIPs) {
        this.items = newIPs.map((message) => ({
          ...message,
          lastmodified: `${dayjs(message.lastmodified).format(
            DATE_FORMAT.SHORT
          )}`,
          cidr: `${message.value?.ip}/${message.value?.mask}`,
          status:
            message.section === "allowip" ? this.$t("Allow") : this.$t("Block"),
          comment: message.comment ?? "",
        }));
      },
      immediate: true,
    },
  },
  methods: {
    ...mapActions("blockAllowIPsList", [
      "fetchBlockAllowIPs",
      "batchDeleteIPs",
      "clearIPsCache",
    ]),
    ...mapActions("toast", ["displayToast"]),
    ...mapActions("modal", ["openModal", "closeModal"]),
    onSortItemsClick(by, dir) {
      this.setSortItemsCookie(by, dir);
      this.sortItems(by, dir);
    },

    onChangeItemsOnPageClick(number) {
      this.setItemsPerPageCookie(number);
      this.changeItemsPerPage(number);
    },
    onConfirm() {
      this.currentModal = "t-delete-confirm";
      this.isDeleteConfirmDialogVisible = true;
    },
    async onDelete(items) {
      try {
        this.fetching = true;

        await this.batchDeleteIPs({
          ips: items,
          tier: this.tier,
          id: this.id,
        });
        await this.displayToast({
          title: this.$t("Success"),
          message:
            items.length > 1
              ? this.$t("Selected IP's has been deleted")
              : this.$t("Selected IP has been deleted"),
          duration: 2000,
          variant: "success",
        });
        if (this.ips.length === 0 && this.dataOptions.page > 0) {
          this.dataOptions.page = this.dataOptions.page - 1;
        }
      } catch (_err) {
        // stub
      } finally {
        this.isDeleteConfirmDialogVisible = false;
        this.fetching = false;
      }
    },
    onAdd(ipType) {
      this.currentModal = "t-add-allow-block-ip";

      this.isAllow = Boolean(ipType === "allow");
      this.openModal();
      if (ipType === "allow") {
        this.modalTitle = "Allow an IP";
      }
      if (ipType === "block") {
        this.modalTitle = "Block an IP";
      }
    },
    onEdit(ip) {
      this.$router.push({
        name: this.routeName,
        params: {
          id: ip.id,
        },
      });
    },
    async fetchNewIPs() {
      try {
        this.fetching = true;

        await this.fetchBlockAllowIPs({
          id: this.id,
          tier: this.tier,
          ...this.dataOptions,
        });
      } catch (_err) {
        // stub
      } finally {
        this.fetching = false;
      }
    },
  },
  created() {
    this.clearIPsCache();
  },
};
</script>
