export const MESSAGE_TYPE = Object.freeze({
  clean: "Clean",
  spam: "Spam",
  virus: "Virus",
  geoblocked: "Geoblocked",
  banned: "Banned",
  greylisted: "Greylisted",
  sandboxed: "Sandboxed",
  tagged: "Tagged",
  quarantine: "Quarantined",
  invalid_recipient: "Invalid Recipient",
  spf_failed: "SPF Failed",
  allowed_ip: "Allowed IP",
  blocked_ip: "Blocked IP",
  rbl_reject: "Blocked using RBL",
  blocked_tld: "Blocked TLD",
  helo_reject: "Blocked Invalid HELO",
  relay_denied: "Relay Denied",
  blocked_sender: "Sender Blocked",
  allowed_sender: "Allowed Sender",
  false_positive: "False Positive",
  false_negative: "False Negative",
  unknown_sender_domain: "Unknown Sender Domain",
  sender_not_fqdn: "Sender Address Rejected: Not Fully Qualified Domain Name",
  content_filter_allowed: "Content Filter: Allowed",
  content_filter_discarded: "Content Filter: Discarded",
  content_filter_bounced: "Content Filter: Bounced",
  content_filter_false_positive: "Content Filter: False Positive",
  content_filter_quarantined: "Content Filter: Quarantined",
  rate_controlled_rejected: "Rate Controlled: Rejected",
  rate_controlled_deferred: "Rate Controlled: Deferred",
  dmarc_reject: "DMARC Reject",
  dmarc_quarantine: "DMARC Quarantine",
  passed_unchecked: "Passed Unchecked",
});

export default {};
