<template>
  <section class="tw-mb-4">
    <header class="tw-mb-4">
      <h4>{{ $t("Pattern Details") }}</h4>
    </header>
    <t-skeleton v-if="loading" />
    <div v-else>
      <form v-if="pattern" @submit.prevent="submit" autocomplete="off">
        <!-- row pattern details -->
        <div class="tw-mb-2 tw-grid tw-grid-cols-12 tw-gap-4 sm:tw-gap-6">
          <div class="tw-col-span-4 sm:tw-col-span-2">
            <cl-form-group>
              <cl-form-label label-for="pattern-id-input"> ID </cl-form-label>
              <cl-form-input
                id="pattern-id-input"
                name="pattern-id-input"
                disabled
                v-model:value="pattern.id"
                size="medium"
              />
            </cl-form-group>
          </div>
          <div class="tw-col-span-8 sm:tw-col-span-5">
            <cl-form-group>
              <cl-form-label label-for="pattern-name-input">
                {{ $t("Pattern Name") }}
              </cl-form-label>
              <cl-form-input
                id="pattern-name-input"
                name="pattern-name-input"
                v-model:value="pattern.name"
                :state="setInputState(v$.pattern.name)"
                @on-blur="v$.pattern.name.$touch"
                :disabled="saving"
                size="medium"
              />
              <span
                class="tw-block tw-pt-1 tw-text-sm tw-text-danger-500"
                v-if="v$.pattern.name.$error"
                >{{ $t("This field is a required field") }}</span
              >
            </cl-form-group>
          </div>
          <div class="tw-col-span-12 sm:tw-col-span-5">
            <cl-form-group>
              <cl-form-label label-for="pattern-description-input">
                {{ $t("Pattern Description") }}
              </cl-form-label>
              <cl-form-input
                id="pattern-description-input"
                name="pattern-description-input"
                :disabled="saving"
                v-model:value="pattern.comment"
                size="medium"
              />
            </cl-form-group>
          </div>
        </div>
        <cl-hr class="tw-my-4" />
        <!-- row filter expression -->
        <div class="tw-mb-2 tw-grid tw-gap-4 sm:tw-grid-cols-3 sm:tw-gap-6">
          <div>
            <cl-form-group>
              <cl-form-label label-for="filter-type-select">
                {{ $t("Rule Type") }}
              </cl-form-label>
              <cl-form-select
                id="filter-type-select"
                v-model:value="score"
                @change="onScoreChanged($event)"
                :options="scoreOptions"
                :disabled="saving"
              ></cl-form-select>
            </cl-form-group>
          </div>
          <div>
            <cl-form-group class="tw-mb-2">
              <cl-form-label label-for="filter-type">
                {{ $t("Score") }}
              </cl-form-label>
              <cl-form-input
                id="filter-soft-score"
                name="filter-soft-score"
                type="number"
                step="0.001"
                :min="softScoreMin"
                :max="softScoreMax"
                :state="setInputState(v$.softScore)"
                @on-blur="v$.softScore.$touch"
                :disabled="isSoftScoreDisabled || saving"
                v-model:value="softScore"
                size="medium"
              />
              <span
                class="tw-block tw-pt-1 tw-text-sm tw-text-danger-500"
                v-if="v$.softScore.$error && score === 5"
                >{{
                  $t("The score should be in range between 0.001 and 99.999")
                }}</span
              >
              <span
                class="tw-block tw-pt-1 tw-text-sm tw-text-danger-500"
                v-if="v$.softScore.$error && score === -5"
                >{{
                  $t("The score should be in range between -99.999 and -0.001")
                }}</span
              >
            </cl-form-group>
          </div>
          <div>
            <cl-form-group class="tw-mb-2">
              <cl-form-label label-for="filter-status-select">
                {{ $t("Status") }}
              </cl-form-label>
              <cl-form-select
                id="filter-status-select"
                v-model:value="pattern.active"
                :options="statusOptions"
                :disabled="saving"
              ></cl-form-select>
            </cl-form-group>
          </div>
        </div>
        <div v-if="!isSoftScoreDisabled" class="tw-mb-2">
          <div class="alert alert-info" v-if="score === SOFT_ALLOW_DEFAULT">
            {{
              $t(
                "Soft Allow Subtracts a configurable negative score, between -0.001 and -99.999 (default: -5)."
              )
            }}
          </div>
          <div class="alert alert-info" v-if="score === SOFT_BLOCK_DEFAULT">
            {{
              $t(
                "Soft Block Adds a configurable positive score, between 0.001 and 99.999 (default: 5)."
              )
            }}
          </div>
        </div>
        <!-- Group Start -->
        <template v-for="(group, groupIndex) in groups" :key="group.id">
          <div
            class="tw-relative tw-rounded-t-md tw-border-b-dorian-gray-100 tw-bg-white tw-p-3"
          >
            <template
              v-for="(condition, conditionIndex) in group.conditions"
              :key="condition.id"
            >
              <div
                class="tw-mb-2 tw-grid tw-grid-cols-12 tw-justify-end tw-gap-4 sm:tw-gap-6"
              >
                <div class="tw-col-span-6 sm:tw-col-span-4">
                  <cl-form-group class="tw-mb-2">
                    <cl-form-label label-for="filter-status-select">
                      {{ $t("Pattern Filter") }}
                    </cl-form-label>

                    <cl-form-select
                      id="filter-status-select"
                      v-model:value="condition.type"
                      :options="expressionOptions"
                      @change="
                        onConditionChange($event, group.id, condition.id)
                      "
                      :disabled="saving"
                    ></cl-form-select>
                  </cl-form-group>
                </div>
                <div class="tw-col-span-6 sm:tw-col-span-5">
                  <cl-form-group class="tw-mb-2">
                    <cl-form-label label-for="filter-value-input">
                      {{ $t("Value") }}
                    </cl-form-label>
                    <cl-form-input
                      id="filter-value-input"
                      name="filter-value-input"
                      v-model:value="
                        groups[groupIndex].conditions[conditionIndex].pattern
                      "
                      size="medium"
                      :disabled="condition.type !== 'other'"
                    />
                    <span
                      class="tw-block tw-pt-1 tw-text-sm tw-text-danger-500"
                      v-if="
                        v$.groups.$each.$response.$errors[groupIndex].conditions
                          .length
                      "
                      >{{ $t("This field is a required field") }}</span
                    >
                  </cl-form-group>
                </div>
                <div class="tw-col-span-12 sm:tw-col-span-3">
                  <template v-if="condition.nextSibling === null">
                    <div class="tw-inline-flex">
                      <cl-dropdown :title="$t('Add Condition')">
                        <cl-dropdown-item
                          @on-click="onAddCondition(group.id, 'and')"
                        >
                          {{ $t("and").toUpperCase() }}
                        </cl-dropdown-item>
                        <cl-dropdown-item
                          @on-click="onAddCondition(group.id, 'or')"
                        >
                          {{ $t("or").toUpperCase() }}
                        </cl-dropdown-item>
                      </cl-dropdown>
                    </div>
                  </template>
                  <template v-else>
                    <div class="tw-inline-flex">
                      <cl-dropdown
                        :title="$t(condition.nextSibling).toUpperCase()"
                      >
                        <cl-dropdown-item
                          @on-click="onUpdateCondition(condition.id, 'and')"
                        >
                          {{ $t("and").toUpperCase() }}
                        </cl-dropdown-item>
                        <cl-dropdown-item
                          @on-click="onUpdateCondition(condition.id, 'or')"
                        >
                          {{ $t("or").toUpperCase() }}
                        </cl-dropdown-item>
                      </cl-dropdown>
                    </div>
                  </template>
                  <template v-if="isConditionDeleteAvailable">
                    <div class="tw-inline-flex">
                      <cl-button
                        @on-click="onRemoveCondition(group.id, condition.id)"
                        variant="link-secondary"
                        :disabled="saving"
                      >
                        <cl-icon-tabler-trash />
                      </cl-button>
                    </div>
                  </template>
                </div>
              </div>
            </template>
            <template v-if="group.nextSibling === null">
              <div
                class="tw-absolute tw-bottom-[-25px] tw-left-1/2 tw-z-10 tw-translate-x-[-50%]"
              >
                <cl-dropdown :title="$t('Add Group')">
                  <cl-dropdown-item @on-click="onAddGroup('and')">
                    {{ $t("and").toUpperCase() }}
                  </cl-dropdown-item>
                  <cl-dropdown-item @on-click="onAddGroup('or')">
                    {{ $t("or").toUpperCase() }}
                  </cl-dropdown-item>
                </cl-dropdown>
              </div>
            </template>
            <template v-else>
              <div
                class="tw-absolute tw-bottom-[-25px] tw-left-1/2 tw-z-10 tw-translate-x-[-50%]"
              >
                <cl-dropdown :title="$t(group.nextSibling).toUpperCase()">
                  <cl-dropdown-item @on-click="onUpdateGroup(group.id, 'and')">
                    {{ $t("and").toUpperCase() }}
                  </cl-dropdown-item>
                  <cl-dropdown-item @on-click="onUpdateGroup(group.id, 'or')">
                    {{ $t("or").toUpperCase() }}
                  </cl-dropdown-item>
                </cl-dropdown>
              </div>
            </template>
          </div>
        </template>
        <!-- Group Ends -->
        <!-- row submit -->
        <div>
          <div class="tw-mb-4 tw-mt-8 tw-text-right">
            <cl-button type="submit" :disabled="saving" variant="secondary">
              <span v-if="!saving"> {{ $t("Save Changes") }} </span>
              <span class="tw-px-2" v-else><cl-spinner size="small" /></span>
            </cl-button>
          </div>
        </div>
      </form>
    </div>
    <div
      v-if="fetchError"
      class="tw-full tw-rounded-md tw-bg-danger-100 tw-px-4 tw-py-2 tw-text-danger-700"
    >
      {{ $t("Error. Pattern not found") }}
    </div>
  </section>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { useVuelidate } from "@vuelidate/core";
import { required, helpers } from "@vuelidate/validators";

import {
  parsePattern,
  generateCondition,
  generateGroup,
  stringifyGroupsToPattern,
} from "@/utils/pattern-filtering";
import {
  PATTERN_SCORE_LABEL,
  PATTERN_STATUS_LABEL,
  MULTI_PATTERN_RULE_OPTIONS,
} from "@/constants";
import TSkeleton from "@/components/TSkeleton";

export default {
  name: "EditPattern",
  components: {
    TSkeleton,
  },
  props: {
    id: {
      type: [String, Number],
      default: null,
    },
    tier: {
      type: String,
      default: null,
    },
  },
  setup() {
    return { v$: useVuelidate() };
  },
  validations() {
    return {
      groups: {
        $each: helpers.forEach({
          conditions: {
            $each: helpers.forEach({
              pattern: {
                required,
              },
            }),
          },
        }),
      },
      pattern: {
        name: {
          required,
        },
      },
      softScore: {
        required,
        matchSoftScore: (value) => {
          if (this.score === 5) {
            return value >= 0.0001 && value <= 99.999;
          }
          if (this.score === -5) {
            return value >= -99.999 && value <= -0.001;
          }
          return true;
        },
      },
    };
  },
  data() {
    return {
      SOFT_BLOCK_DEFAULT: 5,
      SOFT_ALLOW_DEFAULT: -5,
      TYPES: {
        OTHER: "other",
        META: "meta",
      },
      loading: true,
      saving: false,
      fetchError: false,
      score: "",
      softScore: 0,
      groups: [],
      scoreOptions: [
        { value: 100, text: this.$t(PATTERN_SCORE_LABEL.HARD_BLOCK) },
        { value: -100, text: this.$t(PATTERN_SCORE_LABEL.HARD_ALLOW) },
        { value: 5, text: this.$t(PATTERN_SCORE_LABEL.SOFT_BLOCK) },
        { value: -5, text: this.$t(PATTERN_SCORE_LABEL.SOFT_ALLOW) },
        { value: 0, text: this.$t(PATTERN_SCORE_LABEL.TEST_MODE) },
      ],
      expressionOptions: [
        {
          id: "regular-patterns",
          label: this.$t("CREATE NEW PATTERN"),
          options: Object.entries(MULTI_PATTERN_RULE_OPTIONS).map(
            ([value, text]) => ({
              value,
              text: this.$t(text),
            })
          ),
        },
      ],
      statusOptions: [
        { value: true, text: this.$t(PATTERN_STATUS_LABEL.ACTIVE) },
        { value: false, text: this.$t(PATTERN_STATUS_LABEL.INACTIVE) },
      ],
    };
  },
  computed: {
    ...mapGetters("patternFilters", ["patterns", "pattern"]),
    softScoreDynamicRule() {
      if (this.score === 5) {
        return "required|min_value:0.001|max_value:99.999";
      }

      if (this.score === -5) {
        return "required|min_value:-99.999|max_value:-0.001";
      }

      return "required";
    },
    softScoreMin() {
      if (this.score === 5) {
        return 0.001;
      }

      if (this.score === -5) {
        return -99.999;
      }

      return null;
    },
    softScoreMax() {
      if (this.score === 5) {
        return 99.999;
      }

      if (this.score === -5) {
        return -0.001;
      }

      return null;
    },
    isSoftScoreDisabled() {
      if (this.score === 5 || this.score === -5) {
        return false;
      }

      return true;
    },
    isConditionDeleteAvailable() {
      if (this.groups.length <= 1 && this.groups[0].conditions.length <= 1) {
        return false;
      }

      return true;
    },
  },
  watch: {
    pattern: {
      handler(pattern) {
        if (pattern === null) {
          return;
        }

        if (pattern.name) {
          this.setPageTitle(pattern.name);
        }

        this.groups = parsePattern(pattern);
      },
      immediate: true,
      deep: true,
    },
    patterns: {
      handler(patterns) {
        const patternsOptions = [];

        for (const pattern of patterns) {
          if (Number(pattern.id) === Number(this.$route.params.id)) {
            continue;
          }

          if (!pattern.active) {
            continue;
          }

          if (pattern.type === this.TYPES.META) {
            continue;
          }

          patternsOptions.push({
            value: pattern.id,
            text: pattern.name || `RULE_${pattern.id}`,
          });
        }

        this.expressionOptions[1] = {
          id: "existing-patterns",
          label: this.$t("EXISTING PATTERNS"),
          options: patternsOptions,
        };
      },
      immediate: true,
    },
  },
  methods: {
    ...mapActions("pageMeta", ["setPageTitle"]),
    ...mapActions("patternFilters", [
      "clearPattern",
      "fetchPatterns",
      "fetchPatternDetails",
      "savePatternDetails",
    ]),
    ...mapActions("toast", ["displayToast"]),
    isValueEditAllowed(type) {
      return Object.keys(MULTI_PATTERN_RULE_OPTIONS).some(
        (key) => key === type
      );
    },
    onScoreChanged(score) {
      if (score === this.SOFT_BLOCK_DEFAULT) {
        this.softScore = this.SOFT_BLOCK_DEFAULT;

        return;
      }

      if (score === this.SOFT_ALLOW_DEFAULT) {
        this.softScore = this.SOFT_ALLOW_DEFAULT;

        return;
      }

      this.softScore = score;
    },
    onConditionChange(selectedValue, groupId, conditionId) {
      const groupIndex = this.groups.findIndex(({ id }) => id === groupId);
      if (groupIndex === -1) {
        return;
      }

      const conditionIndex = this.groups[groupIndex].conditions.findIndex(
        ({ id }) => id === conditionId
      );
      if (conditionIndex === -1) {
        return;
      }

      this.groups[groupIndex].conditions[conditionIndex].pattern =
        selectedValue === this.TYPES.OTHER ? "" : `RULE_${selectedValue}`;
    },
    onRemoveCondition(groupID, conditionID) {
      const group = this.groups.find((group) => group.id === groupID);

      if (!group) {
        return;
      }

      // Means that it needs to remove the entire group,
      // because there is only one condition in the group
      // otherwise just remove the condition
      if (group.conditions.length <= 1) {
        const groupIndex = this.groups.findIndex(
          (group) => group.id === groupID
        );

        this.groups.splice(groupIndex, 1);
        this.groups[this.groups.length - 1].nextSibling = null;
      } else {
        const conditionIndex = group.conditions.findIndex(
          (condition) => condition.id === conditionID
        );
        group.conditions.splice(conditionIndex, 1);

        const prevSibling = group.conditions[group.conditions.length - 1];
        if (prevSibling) {
          group.conditions[group.conditions.length - 1].nextSibling = null;
        }
      }
    },
    onAddCondition(groupID, siblingType) {
      const group = this.groups.find((group) => group.id === groupID);

      if (!group) {
        return;
      }

      const prevSibling = group.conditions[group.conditions.length - 1];

      if (prevSibling) {
        group.conditions[group.conditions.length - 1].nextSibling = siblingType;
      }

      group.conditions.push(generateCondition());
    },
    onUpdateCondition(conditionID, siblingType) {
      for (const group of this.groups) {
        const condition = group.conditions.find(
          (condition) => condition.id === conditionID
        );

        if (condition) {
          condition.nextSibling = siblingType;
        }
      }
    },
    onAddGroup(siblingType) {
      const prevSibling = this.groups[this.groups.length - 1];

      if (prevSibling) {
        this.groups[this.groups.length - 1].nextSibling = siblingType;
      }

      this.groups.push(generateGroup());
    },
    onUpdateGroup(groupID, siblingType) {
      const group = this.groups.find((group) => group.id === groupID);

      if (group) {
        group.nextSibling = siblingType;
      }
    },
    setInputState(input) {
      return input.$dirty ? !input.$error : null;
    },
    async submit() {
      const isGroupsValid = await this.v$.groups.$validate();
      const isPatternValid = await this.v$.pattern.$validate();

      if (!isGroupsValid || !isPatternValid) {
        return;
      }

      try {
        this.saving = true;

        const pattern = {
          id: this.pattern.id,
          type: this.pattern.type,
          apply_body: false,
          rawbody: false,
          apply_headers: false,
          headers: this.pattern.headers,
          pattern: stringifyGroupsToPattern(this.groups),
          name: this.pattern.name,
          comment: this.pattern.comment,
          score: this.softScore,
          disable_rule: !this.pattern.active,
        };

        await this.savePatternDetails({
          tier: this.tier,
          id: this.id,
          pattern,
        });

        this.displayToast({
          title: this.$t("Changes Saved"),
          message: this.$t("X has been successfully updated", {
            pattern: this.pattern.name,
          }),
          duration: 2000,
          variant: "success",
        });
      } finally {
        this.saving = false;
      }
    },
  },
  async mounted() {
    const patternId = this.$route.params.id;
    this.fetchError = false;

    try {
      this.loading = true;

      await this.fetchPatterns({
        tier: this.tier,
        id: this.id,
      });

      await this.fetchPatternDetails({
        tier: this.tier,
        id: this.id,
        patternId,
      });
    } catch (_err) {
      this.fetchError = true;
    } finally {
      const patternScore = this.pattern?.score || 0;
      this.softScore = patternScore;

      if (patternScore >= -99.999 && patternScore <= -0.001) {
        this.score = this.SOFT_ALLOW_DEFAULT;
      } else if (patternScore >= 0.001 && patternScore <= 99.999) {
        this.score = this.SOFT_BLOCK_DEFAULT;
      } else {
        this.score = patternScore;
      }
      this.loading = false;
    }
  },
  created() {
    this.clearPattern();
  },
};
</script>
