<template>
  <div class="tw-mb-4">
    <form class="modal-form" @submit.prevent autocomplete="off">
      <!-- row domain to block email -->
      <div>
        <cl-form-group>
          <cl-form-label label-for="sender-input">
            {{ $t("Email") }}
          </cl-form-label>
          <cl-form-input
            id="sender-input"
            name="sender-input"
            v-model:value="form.sender"
            size="medium"
            :state="setInputState(v$.form.sender)"
            @on-blur="v$.form.sender.$touch"
          />
          <span
            class="tw-block tw-pt-1 tw-text-sm tw-text-danger-500"
            v-if="v$.form.sender.$error"
            >{{ $t("Please enter a valid email") }}</span
          >
        </cl-form-group>
      </div>
      <cl-hr />
      <!-- row comment -->
      <div>
        <cl-form-group>
          <cl-form-label label-for="comment-textarea">
            {{ $t("Comments (optional)") }}
          </cl-form-label>

          <cl-form-textarea
            id="comment-textarea"
            v-model:value="form.comment"
            rows="3"
          ></cl-form-textarea>
        </cl-form-group>
      </div>
      <!-- Submit -->
      <div>
        <div class="tw-flex tw-justify-end">
          <cl-button
            variant="link-secondary"
            class="tw-mr-2"
            @on-click="onCancel"
            >{{ $t("Cancel") }}</cl-button
          >
          <cl-button type="submit" @on-click="submit" variant="secondary">
            <span v-if="!saving"> {{ $t("Save") }} </span>
            <span class="px-2" v-else><cl-spinner size="small" /></span>
          </cl-button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { useVuelidate } from "@vuelidate/core";
import { required } from "@vuelidate/validators";

import { REGEX, TIER } from "@/constants";

const ALLOW_LIST = "Allow List";

export default {
  name: "EditDomain",
  props: {
    id: {
      type: [String, Number],
      default: null,
    },
    tier: {
      type: String,
      default: null,
    },
    emailId: {
      type: [String, Number],
      required: true,
    },
  },
  setup() {
    return { v$: useVuelidate() };
  },
  validations() {
    return {
      form: {
        sender: {
          required,
        },
      },
    };
  },
  data() {
    return {
      domainRegex: REGEX.DOMAIN,
      saving: false,
      form: {
        sender: "",
        comment: "",
      },
    };
  },
  computed: {
    ...mapGetters("blockAllowEmailsList", ["emails"]),
    routeName() {
      if (this.tier === TIER.msps) {
        return "msp-block-allow-list-emails";
      }

      if (this.tier === TIER.domainGroups) {
        return "customer-block-allow-list-emails";
      }

      if (this.tier === TIER.domains) {
        return "domain-block-allow-list-emails";
      }

      if (this.tier === TIER.users) {
        return "user-block-allow-list-emails";
      }

      return null;
    },
  },
  methods: {
    ...mapActions("blockAllowEmailsList", [
      "updateAllowedEmail",
      "updateBlockedEmail",
    ]),
    ...mapActions("toast", ["displayToast"]),
    setInputState(input) {
      return input.$dirty ? !input.$error : null;
    },
    async submit() {
      const isValid = await this.v$.form.$validate();

      if (!isValid) {
        return;
      }

      try {
        this.saving = true;

        if (this.allowedList) {
          await this.updateAllowedEmail({
            formData: this.form,
            emailId: this.emailId,
            tier: this.tier,
            id: this.id,
          });
        } else {
          await this.updateBlockedEmail({
            formData: this.form,
            emailId: this.emailId,
            tier: this.tier,
            id: this.id,
          });
        }

        this.displayToast({
          title: this.$t("Success"),
          message: this.$t("Email has been updated"),
          duration: 2000,
          variant: "success",
        });
      } catch (_err) {
        // stub
      } finally {
        this.saving = false;
      }
    },
    onCancel() {
      this.$router.push({
        name: this.routeName,
      });
    },
  },
  created() {
    const id = Number(this.emailId);
    const email = this.emails.find((email) => email.id === id);

    this.form = {
      sender: email.sender,
      comment: email.comment || "",
    };

    this.allowedList = email.type === ALLOW_LIST;
  },
};
</script>
