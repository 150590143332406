<template>
  <div class="geoblocking-exemptions-list">
    <cl-data-table
      :data-test-id="'dashboard-data-table'"
      :fetching="fetching"
      :headers="tableHeaders"
      :items="items"
      :total="serverTotal"
      :default-sort-by="sortByCookie"
      :default-sort-desc="sortDirCookie"
      :default-items-on-page="itemsPerPageCookie"
      with-search
      with-delete
      with-select
      with-set-columns
      with-server-side-pagination
      :searchPlaceholder="$t('Search Exemptions')"
      @change-page="changePage"
      @search="debouncedSearch"
      @on-delete-items="onDelete"
      @change-items-on-page="onChangeItemsOnPageClick"
      @sort-items="onSortItemsClick"
      @change-selected-columns="setSelectedColumnsCookie"
    >
      <template v-slot:actions>
        <cl-dropdown :title="'Add'" data-test-id="dashboard-data-table-add">
          <cl-dropdown-item
            @on-click="onAdd('ip')"
            data-test-id="dashboard-data-table-sender-ip"
          >
            {{ $t("Add sender IP") }}
          </cl-dropdown-item>
          <cl-dropdown-item
            @on-click="onAdd('domain')"
            data-test-id="dashboard-data-table-sender-domain"
          >
            {{ $t("Add sender domain") }}
          </cl-dropdown-item>
          <cl-dropdown-item
            @on-click="onAdd('email')"
            data-test-id="dashboard-data-table-sender-email"
          >
            {{ $t("Add sender email") }}
          </cl-dropdown-item>
        </cl-dropdown>
      </template>
      <template v-slot:[`body.cell.actions`]="props">
        <cl-data-table-edit-action
          data-test-id="dashboard-data-table-edit-btn"
          @on-click="onEdit(props.trItem)"
        />
      </template>
    </cl-data-table>
    <component :is="activeModal" :id="id" :tier="tier" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { TIER, DEFAULT_OPTIONS } from "@/constants";
import TAddSenderIp from "@/modals/TAddSenderIp";
import TAddSenderDomain from "@/modals/TAddSenderDomain";
import TAddSenderEmail from "@/modals/TAddSenderEmail";
import { useDataTable } from "@/composables/useDataTable";
import { useDataTableCookies } from "@/composables/useDataTableCookies";

export default {
  name: "GeoblockingExemptionsList",
  components: {
    "t-add-sender-ip": TAddSenderIp,
    "t-add-sender-domain": TAddSenderDomain,
    "t-add-sender-email": TAddSenderEmail,
  },
  setup(props) {
    const cookieKey = `geoblocking-exemptions${props.tier || "msp"}`;
    const {
      sortByCookie,
      sortDirCookie,
      itemsPerPageCookie,
      selectedColumnsCookie,
      setSortItemsCookie,
      setItemsPerPageCookie,
      setSelectedColumnsCookie,
    } = useDataTableCookies(
      {
        sortByKey: `${cookieKey}-sort-by`,
        sortDirKey: `${cookieKey}-sort-dir`,
        itemsPerPageKey: `${cookieKey}-items-per-page`,
        selectedColumnsKey: `${cookieKey}-selected-columns`,
      },
      {
        ...DEFAULT_OPTIONS,
        sortBy: "type",
      }
    );

    const optionsBasedOnCookies = {
      sortBy: sortByCookie,
      sortDir: sortDirCookie,
      results: itemsPerPageCookie,
      page: 1,
    };

    const {
      dataOptions,
      sortItems,
      changePage,
      changeItemsPerPage,
      debouncedSearch,
    } = useDataTable(optionsBasedOnCookies);

    return {
      dataOptions,
      debouncedSearch,
      sortItems,
      changePage,
      changeItemsPerPage,
      selectedColumnsCookie,
      setSortItemsCookie,
      setItemsPerPageCookie,
      setSelectedColumnsCookie,
      sortByCookie,
      sortDirCookie,
      itemsPerPageCookie,
    };
  },
  props: {
    id: {
      type: [String, Number],
      default: null,
    },
    tier: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      fetching: false,
      searchExports: [],
      activeModal: "t-add-sender-email",
      items: [],
    };
  },
  computed: {
    ...mapGetters("geoBlockingExemptions", [
      "geoBlockingExemptions",
      "serverTotal",
    ]),
    tableHeaders() {
      const headers = [
        { key: "type", label: this.$t("Type"), sortable: true },
        { key: "exemption", label: this.$t("Exemption") },
        {
          key: "include_subdomains",
          label: this.$t("Include subdomains"),
          sortable: false,
        },
        { key: "created_at", label: this.$t("Date Created") },
        { key: "comment", label: this.$t("Comment") },
        { key: "actions", label: this.$t("Actions") },
      ];

      if (!this.selectedColumnsCookie?.length) {
        return headers;
      }

      return headers.map((header) => ({
        ...header,
        enabled: this.selectedColumnsCookie.includes(header.key),
      }));
    },
  },
  watch: {
    dataOptions: {
      handler() {
        this.fetchNewExemptions();
      },
      deep: true,
      immediate: true,
    },
    geoBlockingExemptions: {
      handler() {
        this.items = this.geoBlockingExemptions.map((ex) => ({
          ...ex,
          type: ex.email
            ? this.$t("Sender Email")
            : ex.domain
            ? this.$t("Sender Domain")
            : this.$t("Sender IP"),
          exemption: ex.email ? ex.email : ex.domain ? ex.domain : ex.ip,
          include_subdomains: this.mapSubdomains(ex.subdomains),
        }));
      },
      immediate: true,
    },
  },
  methods: {
    ...mapActions("modal", ["openModal", "closeModal"]),
    ...mapActions("toast", ["displayToast"]),
    ...mapActions("geoBlockingExemptions", [
      "getGeoExemptions",
      "batchDeleteExemptions",
      "clearList",
    ]),
    onSortItemsClick(by, dir) {
      this.setSortItemsCookie(by, dir);
      this.sortItems(by, dir);
    },

    onChangeItemsOnPageClick(number) {
      this.setItemsPerPageCookie(number);
      this.changeItemsPerPage(number);
    },
    onAdd(senderType) {
      const activeModalName = {
        ip: "t-add-sender-ip",
        domain: "t-add-sender-domain",
        email: "t-add-sender-email",
      }[senderType];

      this.activeModal = activeModalName;
      this.openModal();
    },
    async onDelete(exemptions) {
      try {
        this.fetching = true;

        await this.batchDeleteExemptions({
          tier: this.tier,
          id: this.id,
          exemptions,
        });
        this.displayToast({
          title: this.$t("Success"),
          message: this.$t("Exemption has been deleted"),
          duration: 2000,
          variant: "success",
        });
        if (
          this.geoBlockingExemptions.length === 0 &&
          this.dataOptions.page > 0
        ) {
          this.dataOptions.page = this.dataOptions.page - 1;
        }
      } catch (_err) {
        // stub
      } finally {
        this.fetching = false;
      }
    },
    onEdit(exemption) {
      const routeMap = {
        [TIER.msps]: "msp-geoblocking-exemptions-edit",
        [TIER.domainGroups]: "customer-geoblocking-exemptions-edit",
        [TIER.domains]: "domain-geoblocking-exemptions-edit",
        [TIER.users]: "user-geoblocking-exemptions-edit",
      };

      const routeName = routeMap[this.tier];
      if (routeName) {
        this.$router.push({
          name: routeName,
          params: {
            id: exemption.id,
          },
        });
      }
    },

    mapSubdomains(subdomains) {
      if (subdomains === null) {
        return "---";
      }

      return subdomains ? this.$t("Yes") : this.$t("No");
    },
    async fetchNewExemptions() {
      try {
        this.fetching = true;
        this.clearList();
        await this.getGeoExemptions({
          tier: this.tier,
          id: this.id,
          ...this.dataOptions,
        });
      } catch (_err) {
        // stub
      } finally {
        this.fetching = false;
      }
    },
  },
};
</script>
