<template>
  <div class="protected-rachive-page">
    <section class="rachives-list-section">
      <cl-data-table
        :data-test-id="'archive-reports-data-table'"
        :fetching="fetching"
        :headers="dataSet.fields"
        :items="dataSet.items"
        :total="serverTotal"
        with-search
        with-delete
        with-select
        with-set-columns
        with-server-side-pagination
        :searchPlaceholder="$t('Search')"
        @change-page="changePage"
        @search="debouncedSearch"
        @change-items-on-page="changeItemsPerPage"
        @sort-items="sortItems"
        @on-delete-items="onDelete"
      >
        <template v-slot:[`body.cell.actions`]="props">
          <cl-data-table-edit-action
            v-if="!props.trItem.metadata.inherited"
            data-test-id="archive-reports-data-table-edit-btn"
            @on-click="onEdit(props.trItem)"
          />
        </template>
        <template v-slot:actions>
          <cl-button
            :variant="'secondary'"
            type="button"
            @on-click="onAdd"
            data-test-id="t-add-attachment-filter"
          >
            {{ $t("Create") }}
          </cl-button>
        </template>
      </cl-data-table>
    </section>
    <t-add-attachment-filter :id="id" :tier="tier" :type="modalType" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { useDataTable } from "@/composables/useDataTable";

import { ATTACHMENT_FILTER_TYPE, DEFAULT_OPTIONS } from "@/constants";
import TAddAttachmentFilter from "@/modals/TAddAttachmentFilter";

export default {
  name: "PasswordProtectedArchive",
  components: {
    "t-add-attachment-filter": TAddAttachmentFilter,
  },
  props: {
    id: {
      type: [String, Number],
      default: null,
    },
    tier: {
      type: String,
      default: null,
    },
  },
  setup() {
    const {
      dataOptions,
      debouncedSearch,
      sortItems,
      changePage,
      changeItemsPerPage,
    } = useDataTable({ ...DEFAULT_OPTIONS, sortBy: "message_received" });

    return {
      dataOptions,
      debouncedSearch,
      sortItems,
      changePage,
      changeItemsPerPage,
    };
  },
  data() {
    return {
      loading: false,
      fetching: false,
      currentHeaders: null,
      tableData: {
        fields: [
          { value: "inbound", text: this.$t("Inbound") },
          { value: "comment", text: this.$t("Comment") },
        ],
        items: [],
      },
      dataSet: {
        fields: [
          { key: "inbound", label: this.$t("Inbound") },
          { key: "comment", label: this.$t("Comment") },
          { key: "actions", label: this.$t("Actions") },
        ],
        items: [],
      },
    };
  },
  computed: {
    ...mapGetters("attachmentFilters", ["filtersList"]),
    modalType() {
      return ATTACHMENT_FILTER_TYPE.ppa;
    },
  },
  watch: {
    dataOptions: {
      handler() {
        this.fetchPasProtectedFilters();
      },
      deep: true,
      immediate: true,
    },
    filtersList() {
      this.dataSet.items = this.filtersList.map((filter) => {
        return {
          ...filter,
          name: filter.metadata.inherited
            ? `${filter.name} (${this.inheritedLabel})`
            : filter.name,
          isSelectable: !filter.metadata.inherited,
        };
      });
    },
  },
  methods: {
    ...mapActions("modal", ["openModal", "closeModal"]),
    ...mapActions("toast", ["displayToast"]),
    ...mapActions("attachmentFilters", [
      "fetchFiltersList",
      "batchDeleteFilters",
      "clearCache",
    ]),
    async onDelete(filters) {
      try {
        this.loading = true;

        await this.batchDeleteFilters({
          tier: this.tier,
          id: this.id,
          filters,
        });
        this.displayToast({
          title: this.$t("Success"),
          message: this.$t("Filter has been deleted"),
          duration: 2000,
          variant: "success",
        });
        if (this.filtersList.length === 0 && this.dataOptions.page > 0) {
          this.dataOptions.page = this.dataOptions.page - 1;
        }
      } catch (_err) {
        // stub
      } finally {
        this.loading = false;
      }
    },
    onAdd() {
      this.openModal();
    },
    async serverPagination() {
      try {
        this.fetching = true;

        await this.fetchPasProtectedFilters({
          tier: this.tier,
          id: this.id,
          type: ATTACHMENT_FILTER_TYPE.ppa,
          ...this.dataOptions,
        });
      } catch (_err) {
        // stub
      } finally {
        this.fetching = false;
      }
    },
  },
  created() {
    this.clearCache();
  },
};
</script>
