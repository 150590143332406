<template>
  <form @submit.prevent="submit">
    <h2>{{ $t("Links") }}</h2>
    <div class="tw-grid tw-gap-4 tw-p-4 md:tw-grid-cols-2">
      <cl-form-group>
        <cl-form-label label-for="link">
          {{ $t("Support Desk URL") }}
        </cl-form-label>
        <cl-form-input
          id="link"
          name="link"
          v-model:value="form.link"
          :state="setInputState(v$.form.link)"
          :placeholder="$t('Enter URL')"
          @on-blur="v$.form.link.$touch"
        />
        <span
          class="tw-block tw-pt-1 tw-text-sm tw-text-danger-500"
          v-if="v$.form.link.$error"
          >{{
            $t(
              "You should provide valid URL with the protocol. ('https://example.com')"
            )
          }}</span
        >
      </cl-form-group>
      <cl-form-group>
        <cl-form-label label-for="email">
          {{ $t("Contact") }}
        </cl-form-label>
        <cl-form-input
          id="email"
          name="email"
          data-test-id="support-email"
          v-model:value="form.email"
          :placeholder="$t('Enter email address')"
          :state="setInputState(v$.form.email)"
          @on-blur="v$.form.link.$touch"
        />
        <span
          class="tw-block tw-pt-1 tw-text-sm tw-text-danger-500"
          v-if="v$.form.email.$error"
          >{{ $t("Please enter a valid email") }}</span
        >
      </cl-form-group>
    </div>
    <div class="tw-flex tw-justify-end">
      <cl-button type="submit" variant="secondary" data-test-id="support">
        <span v-if="!fetching"> {{ $t("Save Changes") }} </span>
        <span class="px-2" v-else><cl-spinner size="small" /></span>
      </cl-button>
    </div>
  </form>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { useVuelidate } from "@vuelidate/core";
import { required, email, url } from "@vuelidate/validators";
import { useFocusElement } from "@/composables/useFocusElement";
import { TIER, REGEX } from "@/constants";

export default {
  name: "mspSupport",
  setup() {
    const { focusElementById } = useFocusElement();
    return { v$: useVuelidate(), focusElementById };
  },
  validations() {
    return {
      form: {
        email: {
          required,
          email,
        },
        link: {
          required,
          url,
        },
      },
    };
  },
  data() {
    return {
      fetching: false,
      loading: true,
      form: {
        link: null,
        email: null,
      },
      URL: REGEX.URL,
    };
  },
  computed: {
    ...mapGetters("authentication", ["selectedMsp"]),
    ...mapGetters("support", ["support"]),
  },
  methods: {
    ...mapActions("pageMeta", ["setPageTitle", "setPageSubtitle"]),
    ...mapActions("support", ["fetchSupport", "updateSupport"]),
    ...mapActions("toast", ["displayToast"]),
    setInputState(input) {
      return input.$dirty ? !input.$error : null;
    },
    async submit() {
      const isValid = await this.v$.form.$validate();

      if (!isValid) {
        return;
      }

      try {
        this.fetching = true;
        await this.updateSupport({
          type_id: this.selectedMsp.id,
          type: TIER.msps,
          support: this.form,
        });
        this.displayToast({
          title: this.$t("Success"),
          message: this.$t("Your support links have been updated"),
          duration: 2000,
          variant: "success",
        });
      } catch (_err) {
        // stub
      } finally {
        this.fetching = false;
      }
    },
  },
  async created() {
    try {
      this.loading = true;
      await this.fetchSupport({
        type_id: this.selectedMsp.id,
        type: TIER.msps,
      });
      this.form = this.support;
    } catch (_err) {
      // stub
    } finally {
      this.loading = false;
    }
  },
  mounted() {
    this.setPageTitle(this.$t("Support"));
    this.setPageSubtitle([
      this.$t(
        "Customize the links to your support services offered to your customers in their profile menu."
      ),
    ]);
    this.focusElementById("link");
  },
};
</script>
