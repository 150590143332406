<template>
  <Teleport to="body">
    <cl-modal
      :visible="showModal"
      @on-close="onCloseModal"
      headerLabel="Add Geoblocking Rule"
    >
      <form @submit.prevent="submit" autocomplete="off">
        <div class="tw-grid tw-grid-cols-3 tw-gap-4">
          <div class="tw-col-span-2">
            <cl-form-group>
              <cl-form-label label-for="country">
                {{ $t("Country") }}
              </cl-form-label>
              <country-select
                id="country"
                v-model="form.iso"
                :country="form.iso"
                :countryName="false"
                :usei18n="false"
                topCountry="US"
                class="tw-block tw-w-full tw-rounded tw-border tw-border-dorian-gray-100 tw-bg-white tw-px-3 tw-py-2 tw-text-base tw-text-dorian-gray-500 tw-outline-none tw-ring-offset-1 tw-transition-all focus:tw-border-secondary-500 focus:tw-ring-4 focus:tw-ring-secondary-500/10 disabled:tw-cursor-not-allowed disabled:tw-bg-dorian-gray-50 disabled:tw-text-dorian-gray-500/50 disabled:tw-outline-none"
              />
              <div
                class="tw-block tw-text-sm tw-text-danger-500"
                v-if="v$.form.iso.$error"
              >
                {{ $t("This field is a required field") }}
              </div>
            </cl-form-group>
          </div>
          <div>
            <cl-form-group>
              <cl-form-label label-for="geoblock">
                {{ $t("Action") }}
              </cl-form-label>
              <cl-form-select
                data-test-id="modal-add-georule-action"
                name="geoblock"
                id="geoblock"
                v-model:value="form.geoblock"
                :options="geoblockOptions"
              />
            </cl-form-group>
          </div>
          <div class="tw-col-span-3">
            <cl-form-group>
              <cl-form-label label-for="comment">
                {{ $t("Comments (optional)") }}
              </cl-form-label>
              <cl-form-textarea
                id="textarea"
                v-model:value="form.comment"
                rows="3"
                max-rows="6"
              ></cl-form-textarea>
            </cl-form-group>
          </div>
        </div>
      </form>

      <template v-slot:[`footer.decline`]>
        <cl-button
          :disabled="fetching"
          @on-click="closeModal"
          variant="link-secondary"
        >
          <span>{{ $t("Cancel") }}</span>
        </cl-button>
      </template>
      <template v-slot:[`footer.accept`]>
        <cl-button
          :variant="'secondary'"
          @on-click="submit"
          data-test-id="modal-rule-add-button"
        >
          <span v-if="!fetching">{{ this.$t("Add rule") }}</span>
          <cl-spinner v-else :size="'small'" />
        </cl-button>
      </template>
    </cl-modal>
  </Teleport>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { useFocusElement } from "@/composables/useFocusElement";
import { useVuelidate } from "@vuelidate/core";
import { required } from "@vuelidate/validators";

const DEFAULT_FORM = Object.freeze({
  iso: "",
  comment: "",
  geoblock: true,
});

export default {
  setup() {
    const { focusElementById } = useFocusElement();
    return { v$: useVuelidate(), focusElementById };
  },
  validations() {
    return {
      form: {
        iso: {
          required,
        },
      },
    };
  },
  props: {
    id: {
      type: [String, Number],
      required: true,
    },
    tier: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      fetching: false,
      form: {
        ...DEFAULT_FORM,
      },
      geoblockOptions: [
        {
          text: this.$t("Block"),
          value: true,
        },
        {
          text: this.$t("Allow"),
          value: false,
        },
      ],
    };
  },
  computed: {
    ...mapGetters("modal", ["showModal"]),
  },
  watch: {
    showModal: {
      handler() {
        this.focusElementById("country");
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    ...mapActions("geoBlockingRules", ["createGeoRule"]),
    ...mapActions("toast", ["displayToast"]),
    ...mapActions("modal", ["closeModal"]),
    setInputState(input) {
      return input.$dirty ? !input.$error : null;
    },
    onCloseModal() {
      this.closeModal();
      this.form = { ...DEFAULT_FORM };
      this.v$.$reset();
    },
    async submit() {
      const isValid = await this.v$.form.$validate();

      if (!isValid) {
        return;
      }

      try {
        this.fetching = true;

        await this.createGeoRule({
          tier: this.$props.tier,
          id: this.$props.id,
          rule: this.form,
        });

        this.displayToast({
          title: this.$t("Success"),
          message: this.$t("Geoblocking rule has been created"),
          duration: 2000,
          variant: "success",
        });

        this.onCloseModal();
      } catch (_err) {
        // stub
      } finally {
        this.fetching = false;
      }
    },
  },
};
</script>
