import { maxValue, required, numeric, minValue } from "@vuelidate/validators";

// https://en.wikipedia.org/wiki/List_of_TCP_and_UDP_port_numbers
const MIN_NUMBER = 0;
const MAX_NUMBER = 65535;

const validation = Object.freeze({
  required,
  numeric,
  maxValue: maxValue(MAX_NUMBER),
  minValue: minValue(MIN_NUMBER),
});

const errorMessages = {
  required: "This field is a required field",
  minValue: `Port number cannot be grater than 0`,
  maxValue: `Port number cannot be less than 65535`,
  numeric: "This filed must be numeric",
};

export default {
  validation,
  errorMessages,
};
