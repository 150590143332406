<template>
  <Teleport to="body">
    <cl-modal
      :visible="visible"
      @on-close="onCloseModal"
      data-test-id="delete-confirmation-modal"
    >
      <template v-slot:[`header.title`]>
        <slot name="title">
          <cl-heading :size="'h4'">{{ this.$t("Attention") }}</cl-heading>
        </slot>
      </template>
      <div>
        <slot name="body">
          {{
            this.$t(
              "Are you sure you want to delete these items? This action cannot be undone."
            )
          }}
        </slot>
      </div>
      <template v-slot:[`footer.decline`]>
        <slot name="decline">
          <cl-button
            @on-click="onDecline"
            :variant="'link-secondary'"
            :disabled="fetching"
            data-test-id="delete-confirmation-modal-decline"
          >
            {{ this.$t("Decline") }}
          </cl-button>
        </slot>
      </template>
      <template v-slot:[`footer.accept`]>
        <slot name="accept">
          <cl-button
            @on-click="onAccept"
            :variant="'danger'"
            :disabled="fetching"
            data-test-id="delete-confirmation-modal-delete"
          >
            <cl-spinner v-if="fetching" :size="'small'" />
            <span v-else>{{ this.$t("Delete") }}</span>
          </cl-button>
        </slot>
      </template>
    </cl-modal>
  </Teleport>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  emits: ["on-accept", "on-decline"],
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    fetching: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters("modal", ["showModal"]),
  },
  methods: {
    ...mapActions("modal", ["closeModal"]),

    onCloseModal() {
      this.$emit("on-decline");
    },
    onDecline() {
      this.$emit("on-decline");
    },
    onAccept() {
      this.$emit("on-accept");
    },
  },
};
</script>
