<template>
  <Teleport to="body">
    <cl-modal
      :visible="showModal"
      @on-close="onCloseModal"
      headerLabel="Add User"
    >
      <form @submit.prevent="submit" autocomplete="off">
        <div class="tw-grid sm:tw-grid-cols-2 sm:tw-gap-4">
          <div>
            <cl-form-group>
              <cl-form-label label-for="first_name">
                {{ $t("First Name") }}
              </cl-form-label>
              <cl-form-input
                data-lpignore="true"
                id="first_name"
                v-model:value="form.first_name"
                :placeholder="$t('User First Name')"
                :state="setInputState(v$.form.first_name)"
                @on-blur="v$.form.first_name.$touch"
              />
              <div
                class="tw-block tw-text-sm tw-text-danger-500"
                v-if="v$.form.first_name.$error"
              >
                {{ error }}
              </div>
            </cl-form-group>
          </div>
          <div>
            <cl-form-group>
              <cl-form-label label-for="name">
                {{ $t("Last Name") }}
              </cl-form-label>
              <cl-form-input
                data-lpignore="true"
                id="last_name"
                v-model:value="form.last_name"
                :placeholder="$t('User Last Name')"
                :state="setInputState(v$.form.last_name)"
                @on-blur="v$.form.last_name.$touch"
              />

              <div
                class="tw-block tw-text-sm tw-text-danger-500"
                v-if="v$.form.last_name.$error"
              >
                {{ error }}
              </div>
            </cl-form-group>
          </div>
          <div class="sm:tw-col-span-2">
            <cl-form-group>
              <cl-form-label label-for="email">
                {{ $t("Email") }}
              </cl-form-label>
              <cl-form-input
                id="email"
                v-model:value="form.email"
                placeholder="e.g. name@company.com"
                :state="setInputState(v$.form.email)"
                @on-blur="v$.form.email.$touch"
              />

              <div
                class="tw-block tw-text-sm tw-text-danger-500"
                v-if="v$.form.email.$error"
              >
                {{ $t("Please enter a valid email") }}
              </div>
            </cl-form-group>
          </div>
        </div>
      </form>

      <template v-slot:[`footer.decline`]>
        <cl-button
          :disabled="fetching"
          @on-click="closeModal"
          variant="link-secondary"
        >
          <span>{{ $t("Cancel") }}</span>
        </cl-button>
      </template>
      <template v-slot:[`footer.accept`]>
        <cl-button
          :variant="'secondary'"
          @on-click="submit"
          data-test-id="modal-user-add-button"
        >
          <span v-if="!fetching">{{ this.$t("Add") }}</span>
          <cl-spinner v-else :size="'small'" />
        </cl-button>
      </template>
    </cl-modal>
  </Teleport>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { useFocusElement } from "@/composables/useFocusElement";
import { TIER } from "@/constants";
import { useVuelidate } from "@vuelidate/core";
import { required, email } from "@vuelidate/validators";

const DEFAULT_FORM = Object.freeze({
  first_name: "",
  last_name: "",
  email: "",
});

export default {
  setup() {
    const { focusElementById } = useFocusElement();
    return { v$: useVuelidate(), focusElementById };
  },
  validations() {
    return {
      form: {
        first_name: {
          required,
        },
        last_name: {
          required,
        },
        email: {
          required,
          email,
        },
      },
    };
  },
  data() {
    return {
      fetching: false,
      defaultPolicy: true,
      error: this.$t("This field is a required field"),
      form: {
        ...DEFAULT_FORM,
      },
    };
  },
  computed: {
    ...mapGetters("authentication", ["selectedDomain"]),
    ...mapGetters("modal", ["showModal"]),
  },
  watch: {
    showModal: {
      handler() {
        this.focusElementById("first_name");
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    ...mapActions("userPolicies", ["createUserPolicy"]),
    ...mapActions("toast", ["displayToast"]),
    ...mapActions("modal", ["closeModal"]),
    setInputState(input) {
      return input.$dirty ? !input.$error : null;
    },
    onCloseModal() {
      this.closeModal();
      this.form = { ...DEFAULT_FORM };
      this.v$.$reset();
    },
    async submit() {
      const isValid = await this.v$.form.$validate();

      if (!isValid) {
        return;
      }

      this.fetching = true;
      try {
        await this.createUserPolicy({
          formData: this.form,
          id: this.selectedDomain.id,
          tier: TIER.domains,
        });
        this.displayToast({
          title: this.$t("Success"),
          message: this.$t("User created successfully"),
          duration: 2000,
          variant: "success",
        });
        this.onCloseModal();
      } catch (_err) {
        // stub
      } finally {
        this.fetching = false;
      }
    },
  },
};
</script>
