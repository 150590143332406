<template>
  <edit-domain
    v-if="selectedMsp.id"
    :tier="tierType"
    :id="selectedMsp.id"
    :domainId="domainId"
  />
</template>
<script>
import { mapActions, mapGetters } from "vuex";

import EditDomain from "@/views/Common/TBlockAllowDomain/Edit/index.vue";

import { TIER } from "@/constants";

export default {
  name: "DomainEditWrap",
  components: {
    EditDomain,
  },
  data() {
    return {
      tierType: TIER.msps,
      domainId: this.$route.params.id,
    };
  },
  methods: {
    ...mapActions("pageMeta", ["setPageTitle", "setPageSubtitle"]),
  },
  computed: {
    ...mapGetters("authentication", ["selectedMsp"]),
  },
  created() {
    this.setPageTitle(this.$t("Domain Id:") + this.domainId);
    this.setPageSubtitle([this.$t("Edit Allow/Block Domain")]);
  },
};
</script>
