<template>
  <geoblocking-rules-list
    v-if="selectedMsp.id"
    :tier="tierType"
    :id="selectedMsp.id"
  />
</template>
<script>
import { mapActions, mapGetters } from "vuex";

import GeoblockingRulesList from "@/views/Common/GeoBlocking/Rules/index.vue";

import { TIER } from "@/constants";

export default {
  name: "GeoblockingRules",
  components: {
    GeoblockingRulesList,
  },
  data() {
    return {
      tierType: TIER.msps,
      content: {
        setPageSubtitle: this.$t(
          "Geoblocking restricts email based on a sender's geographic location. Once enabled, it can be managed at all levels. Each level can apply its own rules, with rules being inherited from a higher level by default. Exemptions exclude mail from geoblocking rules."
        ),
      },
    };
  },
  methods: {
    ...mapActions("pageMeta", ["setPageTitle", "setPageSubtitle"]),
  },
  computed: {
    ...mapGetters("authentication", ["selectedMsp"]),
  },
  created() {
    this.setPageTitle(this.$t("Geoblocking"));
    this.setPageSubtitle([
      this.content.setPageSubtitle,
      this.content.setPageSubtitle_two,
    ]);
  },
};
</script>
