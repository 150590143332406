<template>
  <scheduled-report-edit
    v-if="selectedCustomer.id"
    :tier="tierType"
    :id="selectedCustomer.id"
    :reportId="reportId"
  />
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import ScheduledReportEdit from "@/views/Common/Reports/Scheduled/Edit/index.vue";

import { TIER } from "@/constants";

export default {
  name: "ScheduledReportWrapper",
  components: {
    ScheduledReportEdit,
  },
  data() {
    return {
      tierType: TIER.domainGroups,
    };
  },
  computed: {
    ...mapGetters("authentication", ["selectedCustomer"]),
    reportId() {
      return this.$route.params.id || null;
    },
  },
  methods: {
    ...mapActions("pageMeta", ["setPageTitle", "setPageSubtitle"]),
  },
  created() {
    this.setPageTitle(this.$t("Edit Report: X", { id: this.$route.params.id }));
    this.setPageSubtitle([]);
  },
};
</script>
