<template>
  <section class="domain-policies">
    <cl-data-table
      :data-test-id="'domain-policies-data-table'"
      :fetching="fetching"
      :headers="tableHeaders"
      :items="items"
      :total="serverTotal"
      :default-sort-by="sortByCookie"
      :default-sort-desc="sortDirCookie"
      :default-items-on-page="itemsPerPageCookie"
      with-search
      with-delete
      with-select
      with-set-columns
      with-server-side-pagination
      :searchBy="'email'"
      :searchPlaceholder="$t('Search Domain Policy')"
      @change-page="changePage"
      @search="debouncedSearch"
      @on-delete-items="onDelete"
      @change-items-on-page="onChangeItemsOnPageClick"
      @sort-items="onSortItemsClick"
      @change-selected-columns="setSelectedColumnsCookie"
    >
      <template v-slot:[`body.cell.actions`]="props">
        <cl-data-table-edit-action
          data-test-id="domain-policies-data-table-edit-btn"
          @on-click="onEdit(props.trItem)"
        />
      </template>
      <template v-slot:[`body.cell.spam_enabled`]="props">
        <cl-pill
          :variant="
            props.tdValue.toLowerCase() === 'off' ? 'danger' : 'success'
          "
          size="sm"
        >
          {{ props.tdValue }}
          <span v-if="props.trItem.marketing"
            >[{{ props.trItem.marketing }}]</span
          >
        </cl-pill>
      </template>
      <template v-slot:[`body.cell.virus_enabled`]="props">
        <cl-pill
          :variant="
            props.tdValue.toLowerCase() === 'off' ? 'danger' : 'success'
          "
          size="sm"
        >
          {{ props.tdValue }}
        </cl-pill>
      </template>
      <template v-slot:[`body.cell.banned_enabled`]="props">
        <cl-pill
          :variant="
            props.tdValue.toLowerCase() === 'off' ? 'danger' : 'success'
          "
          size="sm"
        >
          {{ props.tdValue }}
        </cl-pill>
      </template>
      <template v-slot:[`body.cell.status`]="props">
        <cl-pill
          :variant="
            props.tdValue.toLowerCase() === 'inactive' ? 'danger' : 'success'
          "
          size="sm"
        >
          {{ props.tdValue }}
        </cl-pill>
      </template>
    </cl-data-table>
  </section>
</template>
<script>
import { mapActions, mapGetters } from "vuex";

import { TIER, DEFAULT_OPTIONS } from "@/constants";
import { useDataTable } from "@/composables/useDataTable";
import { useDataTableCookies } from "@/composables/useDataTableCookies";

export default {
  name: "DomainPolicy",
  setup(props) {
    const cookieKey = `domain-policies-${props.tier || "customer"}`;
    const {
      sortByCookie,
      sortDirCookie,
      itemsPerPageCookie,
      selectedColumnsCookie,
      setSortItemsCookie,
      setItemsPerPageCookie,
      setSelectedColumnsCookie,
    } = useDataTableCookies(
      {
        sortByKey: `${cookieKey}-sort-by`,
        sortDirKey: `${cookieKey}-sort-dir`,
        itemsPerPageKey: `${cookieKey}-items-per-page`,
        selectedColumnsKey: `${cookieKey}-selected-columns`,
      },
      {
        ...DEFAULT_OPTIONS,
        sortBy: "domain",
      }
    );

    const optionsBasedOnCookies = {
      sortBy: sortByCookie,
      sortDir: sortDirCookie,
      results: itemsPerPageCookie,
      page: 1,
    };

    const {
      dataOptions,
      sortItems,
      changePage,
      changeItemsPerPage,
      debouncedSearch,
    } = useDataTable(optionsBasedOnCookies);

    return {
      dataOptions,
      debouncedSearch,
      sortItems,
      changePage,
      changeItemsPerPage,
      selectedColumnsCookie,
      setSortItemsCookie,
      setItemsPerPageCookie,
      setSelectedColumnsCookie,
      sortByCookie,
      sortDirCookie,
      itemsPerPageCookie,
    };
  },
  data() {
    return {
      fetching: false,
      items: [],
    };
  },
  computed: {
    ...mapGetters("domains", ["domains", "fetchingDomains", "serverTotal"]),
    ...mapGetters("authentication", ["selectedCustomer"]),
    tableHeaders() {
      const headers = [
        {
          key: "domain",
          label: this.$t("Policy"),
          sortable: true,
        },
        {
          key: "spam_enabled",
          label: this.$t("Spam"),
          sortable: false,
        },
        {
          key: "virus_enabled",
          label: this.$t("Virus"),
        },
        {
          key: "banned_enabled",
          label: this.$t("Banned"),
        },
        {
          key: "status",
          label: this.$t("Archive"),
        },
        { key: "actions", label: this.$t("Actions"), sortable: false },
      ];

      if (!this.selectedColumnsCookie?.length) {
        return headers;
      }

      return headers.map((header) => ({
        ...header,
        enabled: this.selectedColumnsCookie.includes(header.key),
      }));
    },
  },
  watch: {
    dataOptions: {
      handler() {
        this.fetchDomainsWithPolicy();
      },
      deep: true,
      immediate: true,
    },
    domains() {
      this.items = this.domains.map((domain) => {
        return {
          ...domain,
          status: domain.policy.archive_clean_mail ? "active" : "inactive",
          spam_enabled: domain.policy.spam.enabled
            ? `On[${domain.policy.spam.score}:${domain.policy.spam.action}]`
            : this.$t("Off"),
          virus_enabled: domain.policy.virus.enabled
            ? `On[${domain.policy.virus.action}]`
            : this.$t("Off"),
          banned_enabled: domain.policy.banned.enabled
            ? `On[${domain.policy.banned.action}]`
            : this.$t("Off"),
          marketing: domain.policy.spam.block_marketing
            ? this.$t("Marketing")
            : "",
        };
      });
    },
  },
  methods: {
    ...mapActions("pageMeta", ["setPageTitle", "setPageSubtitle"]),
    ...mapActions("domains", [
      "fetchDomains",
      "batchDeleteDomains",
      "clearCache",
    ]),
    ...mapActions("toast", ["displayToast"]),

    onSortItemsClick(by, dir) {
      this.setSortItemsCookie(by, dir);
      this.sortItems(by, dir);
    },

    onChangeItemsOnPageClick(number) {
      this.setItemsPerPageCookie(number);
      this.changeItemsPerPage(number);
    },

    async fetchDomainsWithPolicy() {
      try {
        this.fetching = true;
        this.domainsWithPolicy = [];

        await this.fetchDomains({
          tier: TIER.domainGroups,
          id: this.selectedCustomer.id,
          params: "additional_data[]=policy",
          ...this.dataOptions,
        });
      } catch (_err) {
        // stub
      } finally {
        this.fetching = false;
      }
    },
    async onDelete(items) {
      try {
        this.fetching = true;

        await this.batchDeleteDomains({
          tier: TIER.domainGroups,
          id: this.selectedCustomer.id,
          domains: items,
        });

        this.domainsWithPolicy = [];

        this.fetchDomainsWithPolicy();

        this.displayToast({
          title: this.$t("Success"),
          message: this.$t("Domain Policies has been deleted"),
          duration: 2000,
          variant: "success",
        });
        if (this.domains.length === 0 && this.dataOptions.page > 0) {
          this.dataOptions.page = this.dataOptions.page - 1;
        }
      } catch (_err) {
        // stub
      } finally {
        this.fetching = false;
      }
    },
    onEdit(domainPolicy) {
      this.$router.push({
        path: `/customer/policies/domain_policies/${domainPolicy.id}`,
        query: { name: domainPolicy.domain },
      });
    },
  },
  created() {
    this.setPageTitle(this.$t("Domain Policies"));
    this.setPageSubtitle([]);
  },
};
</script>
