<template>
  <t-skeleton v-if="loading" />
  <cl-card class="tw-mb-4" v-else>
    <template #text>
      <div v-html="sanitizedReport"></div>
    </template>
  </cl-card>
  <div class="tw-flex tw-justify-end" v-if="selectedReport !== null">
    <cl-button
      @on-click="archive"
      type="submit"
      class="tw-mb-4"
      variant="secondary"
    >
      <span v-if="!archiving"> {{ $t("Archive") }} </span>
      <span v-else><cl-spinner size="small" /></span>
    </cl-button>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import DOMPurify from "dompurify";

import { TIER } from "@/constants";
import TSkeleton from "@/components/TSkeleton";

export default {
  name: "OneOffReportPreview",
  components: { TSkeleton },
  props: {
    id: {
      type: [String, Number],
      default: null,
    },
    tier: {
      type: String,
      default: null,
    },
    reportId: {
      type: [String, Number],
      default: null,
    },
  },
  data() {
    return {
      loading: true,
      archiving: false,
    };
  },
  computed: {
    ...mapGetters("onDemandReports", ["selectedReport"]),
    sanitizedReport() {
      return DOMPurify.sanitize(this.selectedReport, {
        FORBID_TAGS: ["script"],
      });
    },
  },
  methods: {
    ...mapActions("modal", ["openModal", "closeModal"]),
    ...mapActions("toast", ["displayToast"]),
    ...mapActions("onDemandReports", [
      "fetchReport",
      "archiveReport",
      "clearCache",
    ]),
    redirect() {
      if (this.tier === TIER.domainGroups) {
        this.$router.push({
          name: "customer-reports-on-demand",
        });
      }

      if (this.tier === TIER.domains) {
        this.$router.push({
          name: "domain-reports-on-demand",
        });
      }
    },
    async archive() {
      try {
        this.archiving = true;

        await this.archiveReport({
          tier: this.tier,
          id: this.id,
          reportId: this.reportId,
        });

        this.displayToast({
          title: this.$t("Success"),
          message: this.$t("OneOffArchive", {
            id: this.reportId,
          }),
          duration: 2000,
          variant: "success",
        });

        this.redirect();
      } catch (_err) {
        // stub
      } finally {
        this.archiving = false;
      }
    },
  },
  async mounted() {
    try {
      await this.fetchReport({
        tier: this.tier,
        id: this.id,
        reportId: this.reportId,
      });
    } catch (_err) {
      // stub
    } finally {
      this.loading = false;
    }
  },
  created() {
    this.clearCache();
  },
};
</script>
