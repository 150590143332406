<template>
  <cl-pill
    :variant="'success'"
    size="sm"
    class="tw-my-1 tw-mr-2 tw-flex tw-bg-secondary-100 tw-text-secondary-800"
  >
    <div class="tw-flex">
      {{ label }} {{ value }}
      <button @click="handlerEmit()" v-if="withTablerX">
        <cl-icon-table-x
          width="20px"
          height="20px"
          class="tw-mx-1 tw-cursor-pointer"
        />
      </button>
    </div>
  </cl-pill>
</template>
<script>
export default {
  name: "TPill",
  props: {
    label: {
      type: String,
      required: true,
    },
    value: {
      type: String,
      required: true,
    },
    withTablerX: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  methods: {
    handlerEmit() {
      this.$emit("click");
    },
  },
};
</script>
