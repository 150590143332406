export default {
  methods: {
    restrictInput(obj, propName) {
      if (this.form.port === "0") {
        this.form.port = "";
      }
      if (obj[propName]) {
        obj[propName] = obj[propName].replace(/[^0-9]/g, "");
      }
    },
  },
};
