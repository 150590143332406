<template>
  <div class="tw-mt-4 tw-rounded-sm tw-bg-white tw-p-4 tw-shadow">
    <div class="tw-mb-6">
      <div class="tw-float-left">
        <cl-heading :size="'h6'">{{ $t("Processed Messages") }} </cl-heading>
      </div>
      <div>
        <cl-form-group class="tw-flex tw-items-end">
          <cl-form-radio-group
            :selectedValue="selectedPeriod"
            @update:selected="(selected) => (selectedPeriod = selected)"
            button
            :options="periodOptions"
            :disabled="loading"
          />
        </cl-form-group>
      </div>
    </div>
    <div class="tw-relative tw-h-80 tw-w-full">
      <div
        class="tw-absolute tw-left-0 tw-top-0 tw-h-full tw-w-full tw-animate-pulse tw-rounded-sm tw-bg-dorian-gray-50/40"
        v-if="loading"
      ></div>
      <canvas
        id="processed-messages-chart"
        width="100"
        height="auto"
        role="image"
        :class="{ 'tw-hidden': loading }"
      ></canvas>
    </div>
  </div>
</template>

<script>
import Chart from "chart.js/auto";
import { DATE_FORMAT, PERIOD } from "@/constants";
import { mapActions, mapGetters } from "vuex";
import dayjs from "dayjs";
import { shallowRef } from "vue";

export default {
  name: "ProcessedMessagesChart",
  props: {
    id: {
      type: [String, Number],
      default: null,
    },
    tier: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      selectedPeriod: PERIOD.week,
      periodOptions: [
        {
          value: PERIOD.month,
          label: this.$t("Last Month"),
        },
        {
          value: PERIOD.week,
          label: this.$t("Last Week"),
        },
      ],
      loading: true,
      chart: null,
      blue: {
        default: "rgba(100, 159, 232, 1)",
        half: "rgba(100, 159, 232, 0.5)",
        quarter: "rgba(100, 159, 232, 0.25)",
        zero: "rgba(100, 159, 232, 0)",
      },
      green: {
        default: "rgba(101, 192, 145, 1)",
        half: "rgba(101, 192, 145, 0.5)",
        quarter: "rgba(101, 192, 145, 0.25)",
        zero: "rgba(101, 192, 145, 0)",
      },
      red: {
        default: "rgba(205, 69, 42, 1)",
        half: "rgba(205, 69, 42, 0.5)",
        quarter: "rgba(205, 69, 42, 0.25)",
        zero: "rgba(205, 69, 42, 0)",
      },
      orange: {
        default: "rgba(239, 160, 41, 1)",
        half: "rgba(239, 160, 41, 0.5)",
        quarter: "rgba(239, 160, 41, 0.25)",
        zero: "rgba(239, 160, 41, 0)",
      },
      labels: [],
      clean_messages: [],
      spam_messages: [],
      virus_messages: [],
      other_messages: [],
      options: {
        responsive: true,
        maintainAspectRatio: false,
        barPercentage: 0.4,
        scales: {
          x: {
            grid: {
              display: false,
              drawTicks: false,
            },
            border: {
              display: false,
            },
            ticks: {
              padding: 12,
            },
            offset: false,
          },
          y: {
            border: {
              display: false,
            },
            grid: {
              drawTicks: false,
              color: "#eaecef",
              lineWidth: 2,
            },
            ticks: {
              padding: 12,
              precision: 0,
            },
          },
        },
        plugins: {
          legend: {
            position: "bottom",
            labels: {
              usePointStyle: true,
              padding: 24,
            },
          },
        },
      },
    };
  },
  computed: {
    ...mapGetters("scanSummary", ["graphMessages"]),
  },
  methods: {
    ...mapActions("scanSummary", ["fetchScanGraphSummary"]),
    initChart() {
      const ctx = document
        .getElementById("processed-messages-chart")
        .getContext("2d");

      const gradientBlue = ctx.createLinearGradient(0, 0, 0, 250);
      gradientBlue.addColorStop(0, this.blue.half);
      gradientBlue.addColorStop(0.35, this.blue.quarter);
      gradientBlue.addColorStop(1, this.blue.zero);

      const gradientGreen = ctx.createLinearGradient(0, 0, 0, 250);
      gradientGreen.addColorStop(0, this.green.half);
      gradientGreen.addColorStop(0.35, this.green.quarter);
      gradientGreen.addColorStop(1, this.green.zero);

      const gradientRed = ctx.createLinearGradient(0, 0, 0, 250);
      gradientRed.addColorStop(0, this.red.half);
      gradientRed.addColorStop(0.35, this.red.quarter);
      gradientRed.addColorStop(1, this.red.zero);

      const gradientOrange = ctx.createLinearGradient(0, 0, 0, 250);
      gradientOrange.addColorStop(0, this.orange.half);
      gradientOrange.addColorStop(0.35, this.orange.quarter);
      gradientOrange.addColorStop(1, this.orange.zero);

      this.chart = shallowRef(
        new Chart(ctx, {
          type: "bar",
          grouped: false,
          data: {
            labels: this.labels,
            datasets: [
              {
                label: this.$t("Clean Messages"),
                data: this.clean_messages,
                backgroundColor: gradientGreen,
                pointBackgroundColor: this.green.default,
                borderColor: this.green.default,
                borderWidth: 2,
                pointRadius: 3,
                tension: 0.4,
                fill: true,
                type: "line",
                order: 1,
              },
              {
                label: this.$t("Spam Messages"),
                data: this.spam_messages,
                backgroundColor: gradientOrange,
                pointBackgroundColor: this.orange.default,
                borderColor: this.orange.default,
                borderWidth: 2,
                pointRadius: 3,
                tension: 0.4,
                fill: true,
                type: "line",
                order: 2,
              },
              {
                label: this.$t("Virus Messages"),
                data: this.virus_messages,
                backgroundColor: gradientRed,
                pointBackgroundColor: this.red.default,
                borderColor: this.red.default,
                borderWidth: 2,
                pointRadius: 3,
                tension: 0.4,
                fill: true,
                type: "line",
                order: 3,
              },
              {
                label: this.$t("Other Messages"),
                data: this.other_messages,
                backgroundColor: gradientBlue,
                pointBackgroundColor: this.blue.default,
                borderColor: this.blue.default,
                borderWidth: 2,
                pointRadius: 3,
                tension: 0.4,
                fill: true,
                type: "line",
                order: 4,
              },
            ],
          },
          options: this.options,
        })
      );
    },
  },
  watch: {
    async selectedPeriod() {
      if (this.chart === null) {
        return;
      }

      try {
        this.loading = true;

        await this.fetchScanGraphSummary({
          tier: this.tier,
          id: this.id,
          days: this.selectedPeriod === PERIOD.month ? 29 : 6,
        });
      } finally {
        this.loading = false;

        this.chart.data.labels = this.graphMessages.map(
          (item) => `${dayjs(item.date).format(DATE_FORMAT.SHORT_DATE)}`
        );
        this.chart.data.datasets[0].data = [...this.clean_messages];
        this.chart.data.datasets[1].data = [...this.spam_messages];
        this.chart.data.datasets[2].data = [...this.virus_messages];
        this.chart.data.datasets[3].data = [...this.other_messages];

        this.chart.update();
      }
    },
    graphMessages() {
      this.labels = this.graphMessages.map(
        (item) => `${dayjs(item.date).format(DATE_FORMAT.SHORT_DATE)}`
      );
      this.clean_messages = this.graphMessages.map(
        (item) => item.clean_messages
      );
      this.spam_messages = this.graphMessages.map((item) => item.spam_messages);
      this.virus_messages = this.graphMessages.map(
        (item) => item.virus_messages
      );
      this.other_messages = this.graphMessages.map((item) => item.other);
    },
  },
  async mounted() {
    try {
      await this.fetchScanGraphSummary({
        tier: this.tier,
        id: this.id,
        days: 6,
      });

      this.initChart();
    } finally {
      this.loading = false;
    }
  },
};
</script>
