<template>
  <edit-attachment-filter
    v-if="selectedUser.id"
    :tier="tierType"
    :id="selectedUser.id"
    :filterId="this.$route.params.id"
  />
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import EditAttachmentFilter from "@/views/Common/AttachmentFilters/Edit/index.vue";

import { TIER } from "@/constants";

export default {
  name: "EditAttachmentFilterWrapper",
  components: {
    EditAttachmentFilter,
  },
  data() {
    return {
      tierType: TIER.users,
    };
  },
  computed: {
    ...mapGetters("authentication", ["selectedUser"]),
  },
  methods: {
    ...mapActions("pageMeta", ["setPageTitle", "setPageSubtitle"]),
  },
  created() {
    this.setPageTitle(
      this.$t("Edit Attachment: X", { id: this.$route.params.id })
    );
    this.setPageSubtitle([]);
  },
};
</script>
