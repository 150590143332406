<template>
  <Teleport to="body">
    <cl-modal
      :visible="showModal"
      @on-close="onCloseModal"
      :headerLabel="$t('Add a Domain')"
    >
      <form @submit.prevent="submit" autocomplete="off">
        <div class="tw-grid sm:tw-grid-cols-12 sm:tw-gap-4">
          <div class="sm:tw-col-span-4">
            <cl-form-group>
              <cl-form-label label-for="domain">
                {{ $t("Domain") }}
              </cl-form-label>
              <cl-form-input
                data-lpignore="true"
                id="domain"
                v-model:value="form.domain"
                :placeholder="$t('e.g. example.ue')"
                :state="setInputState(v$.form.domain)"
                @on-blur="v$.form.domain.$touch"
              />

              <div
                class="tw-block tw-text-sm tw-text-danger-500"
                v-if="v$.form.domain.$error"
              >
                {{ $t("You should provide valid domain address") }}
              </div>
            </cl-form-group>
          </div>
          <div class="sm:tw-col-span-5">
            <cl-form-group>
              <cl-form-label label-for="destination">
                {{ $t("Destination Server") }}
              </cl-form-label>
              <cl-form-input
                data-lpignore="true"
                id="destination"
                v-model:value="form.destination"
                :placeholder="$t('Email server hostname / IP')"
                :state="setInputState(v$.form.destination)"
                @on-blur="v$.form.destination.$touch"
              />
              <div
                class="tw-block tw-text-sm tw-text-danger-500"
                v-if="v$.form.destination.$error"
              >
                {{ $t("You should provide valid domain or IP address") }}
              </div>
            </cl-form-group>
          </div>
          <div class="sm:tw-col-span-3">
            <cl-form-group>
              <cl-form-label label-for="port">
                {{ $t("Port") }}
              </cl-form-label>
              <cl-form-input
                data-lpignore="true"
                id="port"
                v-model:value="form.port"
                placeholder="25"
                :state="setInputState(v$.form.port)"
                @on-blur="v$.form.port.$touch"
                @keyup="restrictInput(form, 'port')"
              />
              <div
                v-if="v$.form.port.$error"
                class="tw-block tw-text-sm tw-text-danger-500"
              >
                {{ setCurrentErrorMessage(v$.form.port.$errors) }}
              </div>
            </cl-form-group>
          </div>
        </div>
        <div class="tw-border-t tw-border-solid tw-border-dorian-gray-100">
          <div class="tw-flex tw-items-center tw-justify-between">
            <span>{{ $t("MX Lookup") }}</span>
            <cl-form-radio-group
              id="MX-switcher"
              name="MX-switcher"
              :selectedValue="form.use_mx"
              @update:selected="(selected) => (form.use_mx = selected)"
              button
              class="tw-my-2"
              :options="options"
            />
          </div>
        </div>

        <div class="tw-border-t tw-border-solid tw-border-dorian-gray-100">
          <div class="tw-flex tw-items-center tw-justify-between">
            <span>{{ $t("RBL Checks") }}</span>
            <cl-form-radio-group
              id="RBL-switcher"
              name="RBL-switcher"
              :selectedValue="form.rbl_checks"
              @update:selected="(selected) => (form.rbl_checks = selected)"
              :options="options"
              button
              class="tw-my-2"
            />
          </div>
        </div>
        <div class="tw-border-t tw-border-solid tw-border-dorian-gray-100">
          <div class="tw-flex tw-items-center tw-justify-between">
            <span>{{ $t("SPF Checks") }}</span>
            <cl-form-radio-group
              id="SPF-switcher"
              name="SPF-switcher"
              :selectedValue="form.spf_checks"
              @update:selected="(selected) => (form.spf_checks = selected)"
              :options="options"
              button
              class="tw-my-2"
            />
          </div>
        </div>
        <div
          class="tw-border-b tw-border-t tw-border-solid tw-border-dorian-gray-100"
        >
          <div class="tw-flex tw-items-center tw-justify-between">
            <span>{{ $t("Grey Listing Checks") }}</span>
            <cl-form-radio-group
              id="Greylisting-switcher"
              name="Greylisting-switcher"
              :selectedValue="form.grey_checks"
              @update:selected="(selected) => (form.grey_checks = selected)"
              :options="options"
              button
              class="tw-my-2"
            />
          </div>
        </div>
        <div class="tw-mt-4">
          <cl-form-group>
            <cl-form-label label-for="recipient-verification-type">
              {{ $t("Recipient Verification") }}
            </cl-form-label>
            <cl-form-select
              id="recipient-verification-type"
              name="recipient-verification-type"
              v-model:value="form.rv_type"
              :options="recipientVerificationTypeOptions"
            ></cl-form-select>
          </cl-form-group>
        </div>
        <!-- RV Type LDAP -->
        <template v-if="form.rv_type === RV_VALUE.ldap">
          <div class="tw-grid tw-grid-cols-3 tw-gap-4">
            <div class="tw-col-span-2">
              <cl-form-group>
                <cl-form-label label-for="ldap-server">
                  {{ $t("Server") }}
                </cl-form-label>
                <cl-form-input
                  id="ldap-server"
                  name="ldap-server"
                  v-model:value="ldap.server"
                  :state="setInputState(v$.ldap.server)"
                  @on-blur="v$.ldap.server.$touch"
                />
                <div
                  class="tw-block tw-text-sm tw-text-danger-500"
                  v-if="v$.ldap.server.$error"
                >
                  {{ validationErrors.required }}
                </div>
              </cl-form-group>
            </div>
            <div>
              <cl-form-group>
                <cl-form-label label-for="ldap-port">
                  {{ $t("Port") }}
                </cl-form-label>
                <cl-form-input
                  id="ldap-port"
                  name="ldap-port"
                  placeholder="389"
                  v-model:value="ldap.port"
                  :state="setInputState(v$.ldap.server)"
                  @on-blur="v$.ldap.server.$touch"
                  @keyup="restrictInput(ldap, 'port')"
                />
                <div
                  v-if="v$.ldap.port.$error"
                  class="tw-block tw-text-sm tw-text-danger-500"
                >
                  {{ validationErrors.max_value }}
                </div>
              </cl-form-group>
            </div>
          </div>
          <cl-hr />
          <div class="tw-mb-4">
            <div class="tw-flex tw-items-center tw-justify-between">
              <span>{{ $t("Anonymous") }}</span>
              <cl-form-radio-group
                id="Anonymous-switcher"
                name="Anonymous-switcher"
                :selectedValue="ldap.search.anonymous"
                @update:selected="
                  (selected) => (ldap.search.anonymous = selected)
                "
                :options="options"
                button
                class="tw-my-2"
              />
            </div>
          </div>
          <div
            v-if="!ldap.search.anonymous"
            class="tw-grid sm:tw-grid-cols-2 sm:tw-gap-4"
          >
            <div>
              <cl-form-group>
                <cl-form-label label-for="user-dn">
                  {{ $t("User DN") }}
                </cl-form-label>
                <cl-form-input
                  id="user-dn"
                  name="user-dn"
                  v-model:value="ldap.search.user_dn"
                />
              </cl-form-group>
            </div>
            <div>
              <cl-form-group>
                <cl-form-label label-for="user-password">
                  {{ $t("User Password") }}
                </cl-form-label>
                <cl-form-input
                  id="user-password"
                  name="user-password"
                  type="password"
                  v-model:value="ldap.search.user_password"
                />
              </cl-form-group>
            </div>
          </div>
          <div>
            <cl-form-group>
              <cl-form-label label-for="search-base">
                {{ $t("Search Base") }}
              </cl-form-label>
              <cl-form-input
                id="search-base"
                name="search-base"
                v-model:value="ldap.search.base"
                :state="setInputState(v$.ldap.search.base)"
                @on-blur="v$.ldap.search.base.$touch"
              />
              <div
                class="tw-block tw-text-sm tw-text-danger-500"
                v-if="v$.ldap.search.base.$error"
              >
                {{ validationErrors.required }}
              </div>
            </cl-form-group>
          </div>
          <div>
            <cl-form-group>
              <cl-form-label label-for="query-filter">
                {{ $t("Query Filter") }}
              </cl-form-label>
              <cl-form-input
                id="query-filter"
                name="query-filter"
                v-model:value="ldap.query_filter"
              />
            </cl-form-group>
          </div>
          <div>
            <cl-form-group>
              <cl-form-label label-for="result-attribute">
                {{ $t("Result Attribute") }}
              </cl-form-label>
              <cl-form-input
                id="result-attribute"
                name="result-attribute"
                :placeholder="$t('Default attribute - \'mail\'')"
                v-model:value="ldap.result_attribute"
              />
            </cl-form-group>
          </div>
        </template>
        <!-- RV Type Dynamic -->
        <template v-if="form.rv_type === RV_VALUE.dynamic">
          <div class="tw-grid sm:tw-grid-cols-3 sm:tw-gap-4">
            <div class="sm:tw-col-span-2">
              <cl-form-group>
                <cl-form-label label-for="dynamic-server">
                  {{ $t("Server") }}
                </cl-form-label>
                <cl-form-input
                  id="dynamic-server"
                  name="dynamic-server"
                  v-model:value="dynamic.server"
                  :state="setInputState(v$.dynamic.server)"
                  @on-blur="v$.dynamic.server.$touch"
                />
                <div
                  v-if="v$.dynamic.server.$error"
                  class="tw-block tw-text-sm tw-text-danger-500"
                >
                  {{ validationErrors.required }}
                </div>
              </cl-form-group>
            </div>
            <div>
              <cl-form-group>
                <cl-form-label label-for="dynamic-port">
                  {{ $t("Port") }}
                </cl-form-label>
                <cl-form-input
                  id="dynamic-port"
                  name="dynamic-port"
                  v-model:value="dynamic.port"
                  :state="setInputState(v$.dynamic.port)"
                  @on-blur="v$.dynamic.port.$touch"
                  @keyup="restrictInput(dynamic, 'port')"
                />
                <div
                  v-if="v$.dynamic.port.$error"
                  class="tw-block tw-text-sm tw-text-danger-500"
                >
                  {{ validationErrors.max_value }}
                </div>
              </cl-form-group>
            </div>
          </div>
        </template>
        <!-- RV Type List -->
        <template v-if="form.rv_type === RV_VALUE.list">
          <div>
            <cl-form-group>
              <cl-form-label label-for="list">
                {{ $t("List") }}
              </cl-form-label>
              <cl-form-textarea
                id="list"
                name="list"
                rows="3"
                max-rows="6"
                v-model:value="list"
              />
            </cl-form-group>
          </div>
        </template>
        <!-- RV Type Regexp -->
        <template v-if="form.rv_type === RV_VALUE.regexp">
          <div>
            <cl-form-group>
              <cl-form-label label-for="regexp">
                {{ $t("Regexp") }}
              </cl-form-label>
              <cl-form-textarea
                id="regexp"
                name="regexp"
                rows="3"
                max-rows="6"
                v-model:value="regexp"
              />
            </cl-form-group>
          </div>
        </template>
      </form>
      <template v-slot:[`footer.decline`]>
        <cl-button
          :disabled="fetching"
          @on-click="closeModal"
          variant="link-secondary"
        >
          <span>{{ $t("Cancel") }}</span>
        </cl-button>
      </template>
      <template v-slot:[`footer.accept`]>
        <cl-button
          :variant="'secondary'"
          @on-click="submit"
          data-test-id="modal-domain-add-button"
        >
          <span v-if="!fetching">{{ this.$t("Add Domain") }}</span>
          <cl-spinner v-else :size="'small'" />
        </cl-button>
      </template>
    </cl-modal>
  </Teleport>
</template>
<script>
import { mapActions, mapGetters } from "vuex";

import { REGEX, RV_VALUE } from "@/constants";
import InputMixin from "@/mixins/InputMixin.js";
import { useVuelidate } from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import port from "@/constants/port.js";
import { useFocusElement } from "@/composables/useFocusElement";

const DEFAULT_FORM = Object.freeze({
  domain: null,
  action: null,
  status: null,
  destination: null,
  use_mx: false,
  port: 25,
  rbl_checks: true,
  spf_checks: true,
  grey_checks: true,
  rv_type: "none",
});

const DEFAULT_DYNAMIC = Object.freeze({
  server: null,
  port: 25,
});

const DEFAULT_LDAP = Object.freeze({
  server: null,
  port: 389,
  search: Object.freeze({
    anonymous: true,
    user_dn: "",
    user_password: "",
    base: "",
  }),
  query_filter:
    "(|(proxyaddresses=smtp$%s)(proxyaddresses=smtp:%s)(mail=%s)(userPrincipalName=%s))",
  result_attribute: "mail",
});

export default {
  setup() {
    const { focusElementById } = useFocusElement();
    return { v$: useVuelidate(), focusElementById };
  },
  validations() {
    return {
      form: {
        domain: {
          required,
          matchRegex: (value) => REGEX.DOMAIN.test(value),
        },
        destination: {
          required,
          matchRegex: (value) => {
            const destinations = value.split(/[ ,]+/);

            if (destinations.length <= 0) {
              return false;
            }

            return destinations.every((destination) =>
              REGEX.IPORFQDN.test(destination)
            );
          },
        },
        port: port.validation,
      },
      ldap: {
        server: {
          required,
        },
        port: port.validation,
        search: {
          base: {
            required,
          },
        },
      },
      dynamic: {
        server: {
          required,
        },
        port: port.validation,
      },
    };
  },
  mixins: [InputMixin], // call this.restrictInput() on template
  props: {
    id: {
      type: [String, Number],
      required: true,
    },
    tier: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      fetching: false,
      RV_VALUE,
      defaultPolicy: true,
      DOMAIN: REGEX.DOMAIN,
      PORT: REGEX.PORT,
      form: {
        ...DEFAULT_FORM,
      },
      dynamic: {
        ...DEFAULT_DYNAMIC,
      },
      regexp: "",
      list: "",
      ldap: {
        ...DEFAULT_LDAP,
        search: {
          ...DEFAULT_LDAP.search,
        },
      },
      recipientVerificationTypeOptions: [
        { text: this.$t("None"), value: RV_VALUE.none },
        { text: this.$t("LDAP"), value: RV_VALUE.ldap },
        { text: this.$t("Dynamic"), value: RV_VALUE.dynamic },
        { text: this.$t("List"), value: RV_VALUE.list },
        { text: this.$t("Regexp"), value: RV_VALUE.regexp },
      ],
      validationErrors: port.errorMessages,
      options: [
        { label: this.$t("Yes"), value: true },
        { label: this.$t("No"), value: false },
      ],
    };
  },
  computed: {
    ...mapGetters("modal", ["showModal"]),
    domain() {
      if (this.form.rv_type === RV_VALUE.ldap) {
        return { ...this.form, ldap: this.ldap };
      }

      if (this.form.rv_type === RV_VALUE.dynamic) {
        return { ...this.form, dynamic: this.dynamic };
      }

      if (this.form.rv_type === RV_VALUE.list) {
        const list = this.list.replace(/\r\n/g, "\n").split("\n");

        return { ...this.form, list };
      }

      if (this.form.rv_type === RV_VALUE.regexp) {
        const regexp = this.regexp.replace(/\r\n/g, "\n").split("\n");

        return { ...this.form, regexp };
      }

      // Default
      return { ...this.form };
    },
  },
  watch: {
    showModal: {
      handler() {
        this.focusElementById("domain");
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    ...mapActions("toast", ["displayToast"]),
    ...mapActions("domains", ["createDomain"]),
    ...mapActions("modal", ["closeModal"]),
    setInputState(input) {
      return input.$dirty ? !input.$error : null;
    },
    setCurrentErrorMessage(errors) {
      if (!errors.length) {
        return;
      }
      const validator = errors[0].$validator;
      return port.errorMessages[validator];
    },
    onCloseModal() {
      this.closeModal();
      this.form = { ...DEFAULT_FORM };
      this.dynamic = { ...DEFAULT_DYNAMIC };
      this.ldap = {
        ...DEFAULT_LDAP,
        search: {
          ...DEFAULT_LDAP.search,
        },
      };
      this.v$.$reset();
    },
    async submit() {
      const isValid = await this.v$.form.$validate();
      if (!isValid) return;

      if (this.form.rv_type === RV_VALUE.ldap) {
        const isLdapValid = await this.v$.ldap.$validate();
        if (!isLdapValid) return;
      }

      if (this.form.rv_type === RV_VALUE.dynamic) {
        const isLdapValid = await this.v$.dynamic.$validate();
        if (!isLdapValid) return;
      }

      try {
        this.fetching = true;

        await this.createDomain({
          tier: this.$props.tier,
          id: this.$props.id,
          formData: this.domain,
        });

        this.displayToast({
          title: this.$t("Success"),
          message: this.$t("Domain has been created"),
          duration: 2000,
          variant: "success",
        });

        this.onCloseModal();
      } catch (_err) {
        // stub
      } finally {
        this.fetching = false;
      }
    },
  },
};
</script>
