import { i18n } from "@/i18n";

export default {
  install(app) {
    app.prototype.$reportTypes = [
      {
        value: null,
        text: i18n.global.t("Please select a report type"),
        disabled: true,
      },
      {
        label: i18n.global.t("Behaviour Reports"),
        options: [
          {
            value: "r_requests_user",
            text: i18n.global.t("Requests by Users"),
          },
          {
            value: "r_requests_group",
            text: i18n.global.t("Requests by Group"),
          },
          {
            value: "r_requests_category",
            text: i18n.global.t("Requests by Category"),
          },
          {
            value: "r_requests_location",
            text: i18n.global.t("Requests by Location"),
          },
          {
            value: "r_requests_domain",
            text: i18n.global.t("Requests by Domain"),
          },
          {
            value: "r_requests_policy",
            text: i18n.global.t("Requests by Policy"),
          },
          {
            value: "r_requests_sourceip",
            text: i18n.global.t("Requests by Source IP"),
          },
        ],
      },
      {
        label: i18n.global.t("Blocked Reports"),
        options: [
          {
            value: "r_blockedrequests_user",
            text: i18n.global.t("Blocked by Users"),
          },
          {
            value: "r_blockedrequests_group",
            text: i18n.global.t("Blocked by Group"),
          },
          {
            value: "r_blockedrequests_url",
            text: i18n.global.t("Blocked Requests by URL"),
          },
          {
            value: "r_blockedrequests_category",
            text: i18n.global.t("Blocked by Category"),
          },
          {
            value: "r_blockedrequests_location",
            text: i18n.global.t("Blocked by Location"),
          },
          {
            value: "r_blockedrequests_domain",
            text: i18n.global.t("Blocked by Domain"),
          },
          {
            value: "r_blockedrequests_policy",
            text: i18n.global.t("Blocked by Policy"),
          },
          {
            value: "r_blockedrequests_sourceip",
            text: i18n.global.t("Blocked by Source IP"),
          },
        ],
      },
      {
        label: i18n.global.t("Trend Reports"),
        options: [
          {
            value: "r_trend_requests_hour",
            text: i18n.global.t("Requests by Hour of Day"),
          },
          {
            value: "r_trend_requests_day",
            text: i18n.global.t("Requests by Date"),
          },
        ],
      },
      {
        label: i18n.global.t("Security Reports"),
        options: [
          {
            value: "r_malware_sourceip",
            text: i18n.global.t("Malware Infected Clients"),
          },
          {
            value: "r_malware_domain",
            text: i18n.global.t("Malware Infected Domains"),
          },
          {
            value: "r_malware_user",
            text: i18n.global.t("Malware Infected Users"),
          },
          {
            value: "r_phishing_sourceip",
            text: i18n.global.t("Blocked Phishing Sites by Source IP"),
          },
          {
            value: "r_phishing_domain",
            text: i18n.global.t("Blocked Phishing Sites by Domain"),
          },
          {
            value: "r_phishing_user",
            text: i18n.global.t("Blocked Phishing Sites by User"),
          },
        ],
      },
    ];

    app.prototype.$reportTypeLookup = {
      r_requests_user: i18n.global.t("Requests by Users"),
      r_requests_group: i18n.global.t("Requests by Group"),
      r_requests_category: i18n.global.t("Requests by Category"),
      r_requests_location: i18n.global.t("Requests by Location"),
      r_requests_domain: i18n.global.t("Requests by Domain"),
      r_requests_policy: i18n.global.t("Requests by Policy"),
      r_requests_sourceip: i18n.global.t("Requests by Source IP"),
      r_blockedrequests_user: i18n.global.t("Blocked by Users"),
      r_blockedrequests_group: i18n.global.t("Blocked by Group"),
      r_blockedrequests_url: i18n.global.t("Blocked Requests by URL"),
      r_blockedrequests_category: i18n.global.t("Blocked by Category"),
      r_blockedrequests_location: i18n.global.t("Blocked by Location"),
      r_blockedrequests_domain: i18n.global.t("Blocked by Domain"),
      r_blockedrequests_policy: i18n.global.t("Blocked by Policy"),
      r_blockedrequests_sourceip: i18n.global.t("Blocked by Source IP"),
      r_trend_requests_hour: i18n.global.t("Requests by Hour of Day"),
      r_trend_requests_day: i18n.global.t("Requests by Date"),
      r_malware_sourceip: i18n.global.t("Malware Infected Clients"),
      r_malware_domain: i18n.global.t("Malware Infected Domains"),
      r_malware_user: i18n.global.t("Malware Infected Users"),
      r_phishing_sourceip: i18n.global.t("Blocked Phishing Sites by Source IP"),
      r_phishing_domain: i18n.global.t("Blocked Phishing Sites by Domain"),
      r_phishing_user: i18n.global.t("Blocked Phishing Sites by User"),
    };
  },
};
