<template>
  <div class="filetype-page">
    <section class="quarantined-messages">
      <cl-data-table
        :data-test-id="'archive-reports-data-table'"
        :fetching="fetching"
        :headers="tableHeaders"
        :items="items"
        :total="serverTotal"
        :default-sort-by="sortByCookie"
        :default-sort-desc="sortDirCookie"
        :default-items-on-page="itemsPerPageCookie"
        with-search
        with-delete
        with-select
        with-set-columns
        with-server-side-pagination
        :searchPlaceholder="$t('Search')"
        @change-page="changePage"
        @search="debouncedSearch"
        @on-delete-items="onDelete"
        @change-items-on-page="onChangeItemsOnPageClick"
        @sort-items="onSortItemsClick"
        @change-selected-columns="setSelectedColumnsCookie"
      >
        <template v-slot:[`body.cell.actions`]="props">
          <cl-data-table-edit-action
            v-if="!props.trItem.metadata.inherited"
            data-test-id="archive-reports-data-table-edit-btn"
            @on-click="onEdit(props.trItem)"
          />
        </template>
        <template v-slot:[`body.cell.inbound`]="props">
          <cl-pill
            :variant="
              props.tdValue.toLowerCase() === 'allow' ? 'success' : 'danger'
            "
            size="sm"
          >
            {{ props.tdValue }}
          </cl-pill>
        </template>
        <template v-slot:[`body.cell.scan_archive`]="props">
          <cl-pill
            :variant="
              props.tdValue.toLowerCase() === 'yes' ? 'success' : 'danger'
            "
            size="sm"
          >
            {{ props.tdValue }}
          </cl-pill>
        </template>
        <template v-slot:actions>
          <cl-button
            :variant="'secondary'"
            type="button"
            @on-click="onAdd"
            data-test-id="t-add-attachment-filter"
          >
            {{ $t("Create") }}
          </cl-button>
        </template>
      </cl-data-table>
    </section>
    <t-add-attachment-filter :id="id" :tier="tier" :type="modalType" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { useDataTable } from "@/composables/useDataTable";
import { useDataTableCookies } from "@/composables/useDataTableCookies";
import { ATTACHMENT_FILTER_TYPE, TIER, DEFAULT_OPTIONS } from "@/constants";
import TAddAttachmentFilter from "@/modals/TAddAttachmentFilter";

export default {
  name: "Filetype",
  components: {
    "t-add-attachment-filter": TAddAttachmentFilter,
  },
  props: {
    id: {
      type: [String, Number],
      default: null,
    },
    tier: {
      type: String,
      default: null,
    },
  },
  setup(props) {
    const cookieKey = `attachments-file-type-${props.tier || "customer"}`;
    const {
      sortByCookie,
      sortDirCookie,
      itemsPerPageCookie,
      selectedColumnsCookie,
      setSortItemsCookie,
      setItemsPerPageCookie,
      setSelectedColumnsCookie,
    } = useDataTableCookies(
      {
        sortByKey: `${cookieKey}-sort-by`,
        sortDirKey: `${cookieKey}-sort-dir`,
        itemsPerPageKey: `${cookieKey}-items-per-page`,
        selectedColumnsKey: `${cookieKey}-selected-columns`,
      },
      {
        ...DEFAULT_OPTIONS,
        sortBy: "name",
      }
    );

    const optionsBasedOnCookies = {
      sortBy: sortByCookie,
      sortDir: sortDirCookie,
      results: itemsPerPageCookie,
      page: 1,
    };

    const {
      dataOptions,
      sortItems,
      changePage,
      changeItemsPerPage,
      debouncedSearch,
    } = useDataTable(optionsBasedOnCookies);

    return {
      dataOptions,
      debouncedSearch,
      sortItems,
      changePage,
      changeItemsPerPage,
      selectedColumnsCookie,
      setSortItemsCookie,
      setItemsPerPageCookie,
      setSelectedColumnsCookie,
      sortByCookie,
      sortDirCookie,
      itemsPerPageCookie,
    };
  },
  data() {
    return {
      loading: false,
      fetching: false,
      currentHeaders: null,
      items: [],
    };
  },
  computed: {
    ...mapGetters("attachmentFilters", ["filtersList", "serverTotal"]),
    tableHeaders() {
      const headers = [
        { key: "name", label: this.$t("Extension"), sortable: true },
        {
          key: "inbound",
          label: this.$t("Inbound"),
          sortable: false,
        },
        {
          key: "scan_archive",
          label: this.$t("Scan Archive"),
          sortable: false,
        },
        {
          key: "comment",
          label: this.$t("Comment"),
          sortable: true,
        },
        { key: "actions", label: this.$t("Actions") },
      ];

      if (!this.selectedColumnsCookie?.length) {
        return headers;
      }

      return headers.map((header) => ({
        ...header,
        enabled: this.selectedColumnsCookie.includes(header.key),
      }));
    },
    routeName() {
      if (this.tier === TIER.msps) {
        return "msp-filtering-attachments-edit";
      }

      if (this.tier === TIER.domainGroups) {
        return "customer-filtering-attachments-edit";
      }

      if (this.tier === TIER.domains) {
        return "domain-filtering-attachments-edit";
      }

      if (this.tier === TIER.users) {
        return "user-filtering-attachments-edit";
      }

      return null;
    },
    inheritedLabel() {
      return this.$t("inherited");
    },
    modalType() {
      return ATTACHMENT_FILTER_TYPE.fileType;
    },
  },
  watch: {
    dataOptions: {
      handler() {
        this.fetchFileTypeFilters();
      },
      deep: true,
      immediate: true,
    },
    filtersList() {
      this.items = this.filtersList.map((filter) => {
        return {
          ...filter,
          grayout: true,
          scan_archive: filter.scan_archive ? this.$t("Yes") : this.$t("No"),
          name: filter.metadata.inherited
            ? `${filter.name} (${this.inheritedLabel})`
            : filter.name,
          isSelectable: !filter.metadata.inherited,
        };
      });
    },
  },
  methods: {
    ...mapActions("modal", ["openModal"]),
    ...mapActions("toast", ["displayToast"]),
    ...mapActions("attachmentFilters", [
      "fetchFiltersList",
      "batchDeleteFilters",
      "clearCache",
    ]),
    onSortItemsClick(by, dir) {
      this.setSortItemsCookie(by, dir);
      this.sortItems(by, dir);
    },

    onChangeItemsOnPageClick(number) {
      this.setItemsPerPageCookie(number);
      this.changeItemsPerPage(number);
    },
    async onDelete(filters) {
      try {
        this.loading = true;

        await this.batchDeleteFilters({
          tier: this.tier,
          id: this.id,
          filters,
        });
        this.displayToast({
          title: this.$t("Success"),
          message: this.$t("Filter has been deleted"),
          duration: 2000,
          variant: "success",
        });
        if (this.filtersList.length === 0 && this.dataOptions.page > 0) {
          this.dataOptions.page = this.dataOptions.page - 1;
        }
      } catch (_err) {
        // stub
      } finally {
        this.loading = false;
      }
    },
    onAdd() {
      this.openModal();
    },
    onEdit(filter) {
      if (filter.metadata.inherited) {
        return;
      }

      this.$router.push({
        name: this.routeName,
        params: {
          id: filter.id,
        },
      });
    },
    async fetchFileTypeFilters() {
      try {
        this.fetching = true;

        await this.fetchFiltersList({
          tier: this.tier,
          id: this.id,
          type: ATTACHMENT_FILTER_TYPE.fileType,
          ...this.dataOptions,
        });
      } catch (_err) {
        // stub
      } finally {
        this.fetching = false;
      }
    },
  },
  created() {
    this.clearCache();
  },
};
</script>
