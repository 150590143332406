<template>
  <section class="tw-mb-6">
    <div class="tw-mb-4">
      <h2>{{ this.$t("Edit URL Exemption") }}</h2>
    </div>

    <form @submit.prevent="submit" autocomplete="off">
      <cl-form-group>
        <cl-form-label label-for="url-input">
          {{ $t("Allowed URL") }}
        </cl-form-label>
        <cl-form-input
          id="url-input"
          name="url-input"
          v-model:value="form.url"
          size="medium"
          :state="setInputState(v$.form.url)"
          @on-blur="v$.form.url.$touch"
        />
        <span
          class="tw-block tw-pt-1 tw-text-sm tw-text-danger-500"
          v-if="v$.form.url.$error"
          >{{ $t("This field is a required field") }}</span
        >
      </cl-form-group>
      <cl-form-group>
        <cl-form-label label-for="comment">
          {{ $t("Comments (optional)") }}
        </cl-form-label>
        <cl-form-textarea
          id="textarea"
          v-model:value="form.comment"
          rows="3"
        ></cl-form-textarea>
      </cl-form-group>
      <!-- Submit -->
      <div>
        <div class="tw-flex tw-justify-end">
          <cl-button type="submit" :disabled="!hasChanged" variant="secondary">
            <span v-if="!saving"> {{ $t("Save Changes") }} </span>
            <span v-else><cl-spinner size="small" /></span>
          </cl-button>
        </div>
      </div>
    </form>
  </section>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { useVuelidate } from "@vuelidate/core";
import { required } from "@vuelidate/validators";

export default {
  name: "EditLinkLockExemption",
  props: {
    id: {
      type: [String, Number],
      default: null,
    },
    tier: {
      type: String,
      default: null,
    },
    exemptionId: {
      type: [String, Number],
      required: true,
    },
  },
  setup() {
    return { v$: useVuelidate() };
  },
  validations() {
    return {
      form: {
        url: {
          required,
        },
      },
    };
  },
  data() {
    return {
      saving: false,
      selectedExemption: null,
      form: {
        url: "",
        comment: "",
      },
    };
  },
  computed: {
    ...mapGetters("linkLock", ["exemptions"]),
    hasChanged() {
      return Object.keys(this.form).some(
        (field) => this.form[field] !== this.selectedExemption[field]
      );
    },
  },
  methods: {
    ...mapActions("toast", ["displayToast"]),
    ...mapActions("linkLock", ["updateLinkLockExemption"]),
    hidrateFormData() {
      this.selectedExemption = this.exemptions.find(
        (exemption) => Number(exemption.id) === Number(this.exemptionId)
      );

      this.form = {
        url: this.selectedExemption.url,
        comment: this.selectedExemption.comment,
      };
    },
    setInputState(input) {
      return input.$dirty ? !input.$error : null;
    },
    async submit() {
      const isValid = await this.v$.form.$validate();

      if (!isValid) {
        return;
      }

      try {
        await this.updateLinkLockExemption({
          tier: this.tier,
          id: this.id,
          exemptionId: this.exemptionId,
          formData: this.form,
        });

        this.hidrateFormData();

        this.displayToast({
          title: this.$t("Success"),
          message: this.$t("The URL exemption has been updated"),
          duration: 2000,
          variant: "success",
        });
      } catch (_err) {
        // stub
      } finally {
        this.saving = false;
      }
    },
  },
  created() {
    this.hidrateFormData();
  },
};
</script>
