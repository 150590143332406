<template>
  <section v-if="selectedMsp">
    <t-messages-stats :tier="tierType" :id="selectedMsp.id" />
    <t-quick-actions :actions="quickActions" @action-clicked="actionClicked" />
    <processed-messages-chart :tier="tierType" :id="selectedMsp.id" />
    <customer-table @add="activateModal" />
    <t-add-allow-block-email
      v-if="isAllowBlockModalActive"
      :isAllow="isAllow"
      :tier="tierType"
      :id="selectedMsp.id"
    />
    <t-add-msp-customer v-if="isAddCustomerModalActive" />
  </section>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import TMessagesStats from "@/components/TMessagesStats";
import CustomerTable from "@/views/Msp/Overview/Customers";
import ProcessedMessagesChart from "@/views/Common/ProcessedMessages";
import TQuickActions from "@/components/TQuickActions";
import { TIER } from "@/constants";
import TAddAllowBlockEmail from "@/modals/TAddAllowBlockEmail.vue";
import TAddMspCustomer from "@/modals/TAddMspCustomer.vue";

const ADD_CUSTOMER_ACTION = "add-customer";
const ALLOW_SENDER_ACTION = "allow-sender";
const BLOCK_SENDER_ACTION = "block-sender";
const ADD_CUSTOMER_MODAL = "t-add-msp-customer";
const ADD_ALLOW_BLOCK_EMAIL_MODAL = "t-add-allow-block-email";

export default {
  components: {
    ProcessedMessagesChart,
    CustomerTable,
    TMessagesStats,
    TQuickActions,
    TAddAllowBlockEmail,
    TAddMspCustomer,
  },
  data() {
    return {
      tierType: TIER.msps,
      activeModal: null,
      isAllow: true,
    };
  },
  computed: {
    ...mapGetters("authentication", ["selectedMsp"]),
    ...mapGetters("userInterface", ["uiTitle"]),
    quickActions() {
      return [
        {
          id: ADD_CUSTOMER_ACTION,
          icon: "cl-icon-tabler-user-plus",
          label: this.$t("Add Customer"),
        },
        {
          id: ALLOW_SENDER_ACTION,
          icon: "cl-icon-tabler-mail-check",
          label: this.$t("Allow Sender"),
        },
        {
          id: BLOCK_SENDER_ACTION,
          icon: "cl-icon-tabler-forbid",
          label: this.$t("Block Sender"),
        },
      ];
    },
    isAddCustomerModalActive() {
      return this.activeModal === ADD_CUSTOMER_MODAL;
    },
    isAllowBlockModalActive() {
      return this.activeModal === ADD_ALLOW_BLOCK_EMAIL_MODAL;
    },
  },
  watch: {
    uiTitle: {
      handler() {
        this.setPageTitle(this.$t("Overview"));
        this.setPageSubtitle([]);
      },
      immediate: true,
    },
  },
  methods: {
    ...mapActions("pageMeta", ["setPageTitle", "setPageSubtitle", "setMSP"]),
    ...mapActions("modal", ["openModal"]),
    activateModal(modal = ADD_CUSTOMER_MODAL) {
      this.activeModal = modal;
      this.openModal();
    },
    actionClicked(id) {
      if (id === ADD_CUSTOMER_ACTION) {
        this.activateModal(ADD_CUSTOMER_MODAL);
      } else if (id === ALLOW_SENDER_ACTION) {
        this.isAllow = true;
        this.activateModal(ADD_ALLOW_BLOCK_EMAIL_MODAL);
      } else if (id === BLOCK_SENDER_ACTION) {
        this.isAllow = false;
        this.activateModal(ADD_ALLOW_BLOCK_EMAIL_MODAL);
      } else {
        return;
      }
    },
  },

  mounted() {
    if (this.selectedMsp) {
      this.setMSP(this.selectedMsp.name);
    } else {
      this.$router.push("/logout");
    }
  },
};
</script>
