<template>
  <section class="all-msps">
    <div class="tw-mb-2 tw-p-2">
      <cl-heading :size="'h3'">
        {{ $t("All MSPs") }}
      </cl-heading>
    </div>
    <cl-data-table
      :data-test-id="'msp-data-table'"
      :fetching="fetching"
      :headers="tableHeaders"
      :items="items"
      :total="serverTotal"
      :default-sort-by="sortByCookie"
      :default-sort-desc="sortDirCookie"
      :default-items-on-page="itemsPerPageCookie"
      with-search
      with-server-side-pagination
      with-set-columns
      :searchBy="'name'"
      :searchPlaceholder="$t('Search MSPs')"
      @change-page="changePage"
      @search="debouncedSearch"
      @change-items-on-page="onChangeItemsOnPageClick"
      @sort-items="onSortItemsClick"
      @change-selected-columns="setSelectedColumnsCookie"
    >
      <template v-slot:actions>
        <cl-button
          :variant="'secondary'"
          type="button"
          @on-click="onAdd"
          data-test-id="msp-data-table-add"
        >
          {{ $t("Add MSP") }}
        </cl-button>
      </template>
      <template v-slot:[`body.cell.actions`]="props">
        <cl-data-table-view-action
          data-test-id="msps-data-table-view-btn"
          @on-click="onView(props.trItem)"
        />
        <cl-data-table-edit-action
          data-test-id="msps-data-table-edit-btn"
          @on-click="onEdit(props.trItem)"
        />
      </template>
    </cl-data-table>
  </section>
  <t-add-msps />
</template>

<script>
import dayjs from "dayjs";
import { mapActions, mapGetters, mapMutations } from "vuex";

import { DATE_FORMAT, TIER, DEFAULT_OPTIONS } from "@/constants";
import TAddMsps from "@/modals/TAddMsps.vue";
import { useDataTable } from "@/composables/useDataTable";
import { useDataTableCookies } from "@/composables/useDataTableCookies";

export default {
  components: {
    "t-add-msps": TAddMsps,
  },
  setup(props) {
    const cookieKey = `overview-${props.tier || "system"}`;

    const {
      sortByCookie,
      sortDirCookie,
      itemsPerPageCookie,
      selectedColumnsCookie,
      setSortItemsCookie,
      setItemsPerPageCookie,
      setSelectedColumnsCookie,
    } = useDataTableCookies(
      {
        sortByKey: `${cookieKey}-sort-by`,
        sortDirKey: `${cookieKey}-sort-dir`,
        itemsPerPageKey: `${cookieKey}-items-per-page`,
        selectedColumnsKey: `${cookieKey}-selected-columns`,
      },
      {
        ...DEFAULT_OPTIONS,
        sortBy: "name",
      }
    );

    const optionsBasedOnCookies = {
      sortBy: sortByCookie,
      sortDir: sortDirCookie,
      results: itemsPerPageCookie,
      page: 1,
    };

    const {
      dataOptions,
      sortItems,
      changePage,
      changeItemsPerPage,
      debouncedSearch,
    } = useDataTable(optionsBasedOnCookies);

    return {
      dataOptions,
      debouncedSearch,
      sortItems,
      changePage,
      changeItemsPerPage,
      selectedColumnsCookie,
      setSortItemsCookie,
      setItemsPerPageCookie,
      setSelectedColumnsCookie,
      sortByCookie,
      sortDirCookie,
      itemsPerPageCookie,
    };
  },
  data() {
    return {
      fetching: false,
      items: [],
      serverItemsLength: 0,
    };
  },
  watch: {
    dataOptions: {
      handler() {
        this.fetchNewMsps();
      },
      deep: true,
      immediate: true,
    },
    msps: {
      handler() {
        this.items = this.msps.map((msp) => ({
          ...msp,
          created_at: `${dayjs(msp.created_at).format(DATE_FORMAT.SHORT)}`,
          name: msp.name,
        }));
      },
      immediate: true,
    },
    uiTitle: {
      handler() {
        this.setPageTitle(this.$t("Overview"));
        this.setPageSubtitle([]);
      },
      immediate: true,
    },
  },
  computed: {
    ...mapGetters("msps", ["msps", "serverTotal"]),
    ...mapGetters("userInterface", ["uiTitle"]),
    ...mapGetters("modal", ["showModal"]),
    tableHeaders() {
      const headers = [
        { key: "name", label: this.$t("Name"), sortable: true },
        {
          key: "description",
          label: this.$t("Description"),
          sortable: true,
        },
        { key: "id", label: this.$t("MSP ID"), sortable: true },
        { key: "created_at", label: this.$t("Created At"), sortable: true },
        { key: "actions", label: this.$t("Actions"), sticky: true },
      ];

      if (!this.selectedColumnsCookie?.length) {
        return headers;
      }

      return headers.map((header) => ({
        ...header,
        enabled: this.selectedColumnsCookie.includes(header.key),
      }));
    },
  },
  methods: {
    ...mapActions("pageMeta", ["setPageTitle", "setPageSubtitle", "setMSP"]),
    ...mapActions("msps", ["fetchMsps", "clearList"]),
    ...mapActions("modal", ["openModal", "closeModal"]),
    ...mapActions("authentication", ["setMspUser"]),
    ...mapActions("userInterface", ["fetchInterface"]),
    ...mapMutations("userInterface", ["setInterfaceLogo"]),

    onSortItemsClick(by, dir) {
      this.setSortItemsCookie(by, dir);
      this.sortItems(by, dir);
    },

    onChangeItemsOnPageClick(number) {
      this.setItemsPerPageCookie(number);
      this.changeItemsPerPage(number);
    },

    onAdd() {
      this.openModal();
    },
    onEdit(rowObj) {
      this.$router.push(`/system/overview/${rowObj.id}`);
    },
    async onView(msp) {
      this.setMspUser(msp);
      this.$router.push({ name: "mspOverview" });
      await this.fetchInterfaceLogo(msp);
    },
    async fetchInterfaceLogo(msp) {
      this.loading = true;
      try {
        await this.fetchInterface({
          type_id: msp.id,
          type: TIER.msps,
        });
      } finally {
        this.loading = false;
      }
    },
    async fetchNewMsps() {
      try {
        this.fetching = true;
        this.clearList();

        await this.fetchMsps({
          ...this.dataOptions,
        });
      } catch (_err) {
        // stub
      } finally {
        this.fetching = false;
      }
    },
  },
  beforeUnmount() {
    this.closeModal();
  },
};
</script>
