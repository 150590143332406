<template>
  <div v-if="messageDetails" class="message-details">
    <div class="tw-mb-2 tw-flex tw-flex-row">
      <cl-icon-tabler-mail class="tw-mr-1" />
      <span class="tw-text-lg tw-font-bold">
        {{ $t("Message Details") }}
      </span>
    </div>
    <div class="tw-mb-2 tw-grid tw-grid-cols-4 tw-gap-4">
      <div>
        <span class="tw-font-bold tw-uppercase">{{
          $t("Message received")
        }}</span>
        <p class="tw-text-sm" data-test-id="message_received">
          {{ messageDetails.message_received }}
        </p>
      </div>
      <div class="tw-flex tw-flex-col">
        <span class="tw-font-bold tw-uppercase">{{ $t("Recipient") }} </span>
        <div class="tw-relative tw-inline-flex tw-text-sm">
          <cl-tooltip
            :text="messageDetails.recipient"
            :id="messageDetails.recipient"
            position="bottom"
          >
            <span v-truncate>{{ messageDetails.recipient }}</span>
          </cl-tooltip>
        </div>
      </div>
      <div class="tw-flex tw-flex-col">
        <span class="tw-font-bold tw-uppercase">
          {{ $t("From address") }}
        </span>
        <div class="tw-relative tw-inline-flex tw-text-sm">
          <cl-tooltip
            :text="messageDetails.sender"
            :id="messageDetails.sender"
            position="bottom"
          >
            <span v-truncate>{{ messageDetails.sender }}</span>
          </cl-tooltip>
        </div>
      </div>
      <div>
        <span class="tw-font-bold tw-uppercase">{{ $t("Host") }}</span>
        <p class="tw-text-sm" data-test-id="host">
          {{ messageDetails.host }}
        </p>
      </div>
    </div>
    <div class="tw-mb-2 tw-grid tw-grid-cols-4 tw-gap-4">
      <div>
        <span class="tw-font-bold tw-uppercase">{{
          $t("Country of origin")
        }}</span>
        <p class="tw-text-sm" data-test-id="country_of_origin">
          {{ messageDetails.country_of_origin }}
          <country-flag :country="countryFlag" />
        </p>
      </div>
      <div>
        <span class="tw-font-bold tw-uppercase">{{ $t("Mail flow") }}</span>
        <p class="tw-text-sm" data-test-id="flow_direction">
          {{ messageDetails.flow_direction }}
        </p>
      </div>
      <div>
        <span class="tw-font-bold tw-uppercase">{{
          $t("Delivery Status")
        }}</span>
        <p class="tw-text-sm" data-test-id="delivery_status">
          {{ messageDetails.delivery_status }}
        </p>
      </div>
      <div>
        <span class="tw-font-bold tw-uppercase">{{ $t("Subject") }}</span>
        <p class="tw-text-sm" data-test-id="subject">
          {{ messageDetails.subject }}
        </p>
      </div>
    </div>
    <div class="tw-mb-2 tw-mt-6 tw-flex tw-flex-row">
      <cl-icon-tabler-settings class="tw-mr-1" />
      <span class="tw-text-lg tw-font-bold">{{
        $t("Status Classification")
      }}</span>
    </div>
    <div class="tw-mb-2 tw-grid tw-grid-cols-4 tw-gap-4">
      <div>
        <span class="tw-font-bold tw-uppercase">{{ $t("SpamTitan ID") }}</span>
        <p class="tw-text-sm" data-test-id="id">
          {{ messageDetails.id }}
        </p>
      </div>
      <div>
        <span class="tw-font-bold tw-uppercase"
          >{{ $t("Content classification") }}
        </span>
        <t-message-type :messageType="messageDetails.message_type">
          <template v-slot:messageType>
            <div class="tw-text-sm">
              {{ messageDetails.message_type }}
            </div>
          </template>
        </t-message-type>
      </div>
      <div>
        <span class="tw-font-bold tw-uppercase">{{ $t("Score") }} </span>
        <p class="tw-text-sm" data-test-id="spam_level">
          {{ messageDetails.spam_level }}
        </p>
      </div>
      <div>
        <span class="tw-font-bold tw-uppercase">{{
          $t("Virus Infection")
        }}</span>
        <p
          class="tw-text-sm"
          v-if="messageDetails.virus === null || messageDetails.virus === ''"
        >
          {{ $t("No") }}
        </p>
        <p v-else class="tw-text-sm" data-test-id="virus">
          {{ messageDetails.virus }}
        </p>
      </div>
    </div>
    <div class="tw-grid tw-grid-cols-4 tw-gap-4">
      <div>
        <span class="tw-font-bold tw-uppercase">{{ $t("Quarantined") }}</span>
        <p v-if="messageDetails.quarantined" class="tw-text-sm">
          {{ $t("Yes") }}
        </p>
        <p v-else class="tw-text-sm" data-test-id="quarantined">
          {{ $t("No") }}
        </p>
      </div>
      <div>
        <span class="tw-font-bold tw-uppercase">{{
          $t("Last status update")
        }}</span>
        <p class="tw-text-sm" data-test-id="last_status_update">
          {{ messageDetails.last_status_update }}
        </p>
      </div>
      <div>
        <span class="tw-font-bold tw-uppercase">{{
          $t("Client address")
        }}</span>
        <p class="tw-text-sm" data-test-id="client_address">
          {{ messageDetails.client_address }}
        </p>
      </div>
      <div>
        <span class="tw-font-bold tw-uppercase">{{ $t("SMTP response") }}</span>
        <p class="tw-text-sm" data-test-id="smtp_response">
          {{ messageDetails.smtp_response }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import CountryFlag from "vue-country-flag-next";
import TMessageType from "@/components/TMessageType";

export default {
  name: "MessageDetails",
  components: {
    CountryFlag,
    TMessageType,
  },
  props: {
    messageDetails: {
      type: Object,
      required: true,
    },
  },
  computed: {
    countryFlag() {
      return this.messageDetails.country_of_origin_iso || "";
    },
  },
};
</script>
