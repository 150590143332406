<template>
  <div class="tw-flex tw-h-screen tw-overflow-hidden">
    <t-side-nav :navItems="navItems" :isClosed="isClosed">
      <template v-slot:dropdown="{ isClosed }" v-if="mspAccountList">
        <t-nav-dropdown
          data-test-id="navbar-customer-select"
          @selected="mspSelected"
          :isClosed="isClosed"
          :loading="loading"
          :options="mspAccountList"
          @clicked="clickedEvent"
        />
      </template>
    </t-side-nav>
    <div
      class="tw-ml-14 tw-flex tw-flex-1 tw-flex-col tw-overflow-y-auto tw-bg-light lg:tw-ml-0"
    >
      <main>
        <div class="container-fluid">
          <t-page-header :user="user" :user-links="userLinks" />
          <router-view />
        </div>
      </main>
      <cl-footer
        class="tw-mt-auto"
        v-if="logo"
        :version="appVersion"
        :logo="logo"
      />
      <div v-else role="status" class="tw-animate-pulse">
        <div
          class="tw-bg-gray-20 tw-h-20 tw-w-full tw-bg-dorian-gray-100"
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { version } from "../../../package";
import { PRODUCTS, USER_LINKS } from "@/constants";
import TSideNav from "@/components/TSideNav";
import TPageHeader from "@/components/TPageHeader";
import TNavDropdown from "@/components/TNavDropdown";

export default {
  name: "SystemLayout",
  components: {
    TSideNav,
    TPageHeader,
    TNavDropdown,
  },
  data() {
    return {
      version: null,
      mspAccountList: null,
      loading: true,
      navItems: this.$getPrimaryNavItems("system"),
      availableOptions: null,
      open: false,
      appVersion: version,
      isDropdownOpen: false,
      options: [
        { name: "All Customers", short: "AC", id: 0, allCustomers: true },
        { name: "All MSPs", short: "AM", allMsps: true, allCustomers: false },
        {
          object: "user",
          id: 1,
          created: "2022-04-14 09:24:29",
          accountname: "Customer with a huge name just for testing",
          description: "Customer",
          timezone: "UTC",
          email: "customer@titanhq.com",
          license: "customer01",
          lastlogin: "2022-04-20 14:45:32",
          install_key: "22b53996-cbc6-483a-bcbd-484cf37a0c02",
          "2fa_enabled": false,
          suspended: false,
          name: "Customer with a huge name just for testing",
          testID:
            "navbar-customer-select-customer-with-a-huge-name-just-for-testing",
          allCustomers: false,
          short: "Cw",
        },
      ],
      checkFetchFullList: false,
    };
  },
  computed: {
    ...mapGetters("msps", [
      "msps",
      "currentSortedBy",
      "currentSortedDir",
      "serverTotal",
    ]),
    ...mapGetters("support", ["support", "userLinks"]),
    ...mapGetters("userInterface", ["interface", "uiLogo"]),
    ...mapGetters("authentication", ["selectedUser", "authUser", "token"]),
    user() {
      const email = this.authUser.email ?? null;
      const accountname = this.authUser.accountname ?? null;

      return {
        email,
        accountname,
      };
    },
    logo() {
      return this.uiLogo;
    },
  },
  watch: {
    msps: {
      handler() {
        // ones we fetch full list after we do only sorting
        if (!this.checkFetchFullList) {
          this.mspAccountList = this.cleanMspAccounts(this.msps);
        } else {
          const sortedArray = this.applySorting(this.mspAccountList);
          this.mspAccountList = sortedArray;
        }
      },
      immediate: true,
      deep: true,
    },
    serverTotal() {
      this.isDropdownOpen = false;
      this.checkFetchFullList = false;
    },
  },
  methods: {
    ...mapActions("pageMeta", ["setParentPath"]),
    ...mapActions("msps", ["fetchMsps", "fetchAllMsps"]),
    ...mapActions("support", ["setUserLinks"]),
    ...mapActions("authentication", ["setUser", "setMspUser"]),
    ...mapActions("userInterface", ["fetchInterfaceBatch", "fetchInterface"]),
    async clickedEvent() {
      try {
        if (!this.isDropdownOpen && !this.checkFetchFullList) {
          const list = await this.fetchAllMsps();
          this.mspAccountList = this.cleanMspAccounts(list.data);
          this.isDropdownOpen = true;
        }
      } catch (_err) {
        // stub
      } finally {
        if (this.serverTotal === this.mspAccountList.length - 1) {
          this.checkFetchFullList = true;
        }
      }
    },
    openSelect() {
      this.open = !this.open;
    },
    setSelected(selectedOption) {
      if (selectedOption.allCustomers && !selectedOption.allMsps) {
        this.$router.push({ name: "customerOver", params: { id: 1 } });
      }
      if (!selectedOption.allCustomers && selectedOption.id) {
        this.$router.push({
          name: "mspOverview",
          params: { id: selectedOption.id },
        });
      }
      this.availableOptions = filterOptions(this.options, selectedOption);
      this.openSelect();
    },

    async mspSelected(msp) {
      this.setMspUser(msp);

      if (msp.AllMsps) {
        this.$router.push("/system/overview").catch(() => {});
      } else if (this.$route.path !== "/msp/overview") {
        this.$router.push("/msp/overview").catch(() => {});
      }
    },
    cleanMspAccounts(accounts) {
      const allAccounts = this.applySorting(accounts);
      return [
        { name: "All MSPs", short: "AM", id: 0, AllMsps: true },
        ...allAccounts,
      ];
    },
    applySorting(sortArray) {
      const sortByProperty = this.currentSortedBy;
      const sortDir = this.currentSortedDir;
      const result = sortArray.sort((a, b) => {
        const aValue = a[sortByProperty];
        const bValue = b[sortByProperty];

        if (sortDir === "desc") {
          return bValue > aValue ? 1 : aValue > bValue ? -1 : 0;
        } else {
          return aValue > bValue ? 1 : bValue > aValue ? -1 : 0;
        }
      });
      return result;
    },
  },
  async created() {
    const userLinksValue = [
      {
        ...USER_LINKS,
        title: this.$t(PRODUCTS.userLinksTitle),
      },
    ];
    this.setUserLinks(userLinksValue);

    await this.fetchInterface({
      type_id: null,
      type: null,
    });

    this.loading = false;

    this.version = String(this.interface.system.version);
    this.license = String(this.interface.system.license_number);
  },
  beforeMount() {
    this.setParentPath("");
  },
};
function filterOptions(options, selectedOption) {
  return options.filter((option) => option.id !== selectedOption.id);
}
</script>
