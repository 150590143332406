<template>
  <div class="geoblocking-rules-list">
    <cl-data-table
      :data-test-id="'dashboard-data-table'"
      :fetching="fetching"
      :headers="tableHeaders"
      :items="items"
      :total="serverTotal"
      :default-sort-by="sortByCookie"
      :default-sort-desc="sortDirCookie"
      :default-items-on-page="itemsPerPageCookie"
      with-delete
      with-select
      with-set-columns
      with-search
      with-server-side-pagination
      :searchPlaceholder="$t('Search Countries')"
      @change-page="changePage"
      @search="debouncedSearch"
      @on-delete-items="onDelete"
      @change-items-on-page="onChangeItemsOnPageClick"
      @sort-items="onSortItemsClick"
      @change-selected-columns="setSelectedColumnsCookie"
    >
      <template v-slot:[`body.cell.status`]="props">
        <cl-pill
          :variant="
            props.tdValue.toLowerCase() === 'allowed' ? 'success' : 'danger'
          "
          size="sm"
        >
          {{ props.tdValue }}
        </cl-pill>
      </template>
      <template v-slot:actions>
        <cl-button
          :variant="'secondary'"
          type="button"
          @on-click="onAdd"
          data-test-id="dashboard-data-table-add"
        >
          {{ $t("Add") }}
        </cl-button>
      </template>
      <template v-slot:[`body.cell.actions`]="props">
        <cl-data-table-edit-action
          data-test-id="dashboard-data-table-edit-btn"
          @on-click="onEdit(props.trItem)"
        />
      </template>
    </cl-data-table>

    <t-add-geoblocking-rule :id="id" :tier="tier" />
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";

import { DATE_FORMAT, TIER, DEFAULT_OPTIONS } from "@/constants";
import dayjs from "dayjs";
import TAddGeoblockingRule from "@/modals/TAddGeoblockingRule.vue";
import { useDataTable } from "@/composables/useDataTable";
import { useDataTableCookies } from "@/composables/useDataTableCookies";

export default {
  name: "GeoblockingRulesList",
  setup(props) {
    const cookieKey = `geoblocking-rules-${props.tier || "msp"}`;
    const {
      sortByCookie,
      sortDirCookie,
      itemsPerPageCookie,
      selectedColumnsCookie,
      setSortItemsCookie,
      setItemsPerPageCookie,
      setSelectedColumnsCookie,
    } = useDataTableCookies(
      {
        sortByKey: `${cookieKey}-sort-by`,
        sortDirKey: `${cookieKey}-sort-dir`,
        itemsPerPageKey: `${cookieKey}-items-per-page`,
        selectedColumnsKey: `${cookieKey}-selected-columns`,
      },
      {
        ...DEFAULT_OPTIONS,
        sortBy: "country",
      }
    );

    const optionsBasedOnCookies = {
      sortBy: sortByCookie,
      sortDir: sortDirCookie,
      results: itemsPerPageCookie,
      page: 1,
    };

    const {
      dataOptions,
      sortItems,
      changePage,
      changeItemsPerPage,
      debouncedSearch,
    } = useDataTable(optionsBasedOnCookies);

    return {
      dataOptions,
      debouncedSearch,
      sortItems,
      changePage,
      changeItemsPerPage,
      selectedColumnsCookie,
      setSortItemsCookie,
      setItemsPerPageCookie,
      setSelectedColumnsCookie,
      sortByCookie,
      sortDirCookie,
      itemsPerPageCookie,
    };
  },

  components: {
    "t-add-geoblocking-rule": TAddGeoblockingRule,
  },
  props: {
    id: {
      type: [String, Number],
      default: null,
    },
    tier: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      fetching: false,
      items: [],
    };
  },
  computed: {
    ...mapGetters("geoBlockingRules", ["geoBlockingRules", "serverTotal"]),
    tableHeaders() {
      const headers = [
        { key: "country", label: this.$t("Country"), sortable: true },
        { key: "iso", label: this.$t("ISO"), sortable: true },
        { key: "status", label: this.$t("Status"), sortable: false },
        { key: "updated_at", label: this.$t("Modified"), sortable: true },
        { key: "comment", label: this.$t("Comments"), sortable: true },
        { key: "actions", label: this.$t("Actions"), sortable: false },
      ];

      if (!this.selectedColumnsCookie?.length) {
        return headers;
      }

      return headers.map((header) => ({
        ...header,
        enabled: this.selectedColumnsCookie.includes(header.key),
      }));
    },
  },
  watch: {
    dataOptions: {
      handler() {
        this.fetchNewRules();
      },
      deep: true,
      immediate: true,
    },
    geoBlockingRules: {
      handler() {
        this.items = this.geoBlockingRules.map((rule) => ({
          ...rule,
          status:
            rule.geoblock === true ? this.$t("Blocked") : this.$t("Allowed"),
          updated_at: `${dayjs(rule.updated_at).format(DATE_FORMAT.SHORT)}`,
        }));
      },
      immediate: true,
    },
  },
  methods: {
    ...mapActions("modal", ["openModal", "closeModal"]),
    ...mapActions("toast", ["displayToast"]),
    ...mapActions("geoBlockingRules", [
      "getGeoblockingRules",
      "batchDeleteRules",
      "clearList",
    ]),
    onSortItemsClick(by, dir) {
      this.setSortItemsCookie(by, dir);
      this.sortItems(by, dir);
    },

    onChangeItemsOnPageClick(number) {
      this.setItemsPerPageCookie(number);
      this.changeItemsPerPage(number);
    },
    onAdd() {
      this.openModal();
    },
    async onDelete(rules) {
      try {
        this.fetching = true;

        await this.batchDeleteRules({
          tier: this.tier,
          id: this.id,
          rules,
        });
        this.displayToast({
          title: this.$t("Success"),
          message: this.$t("Geoblocking rule has been deleted"),
          duration: 2000,
          variant: "success",
        });
        if (this.geoBlockingRules.length === 0 && this.dataOptions.page > 0) {
          this.dataOptions.page = this.dataOptions.page - 1;
        }
      } catch (_err) {
        // stub
      } finally {
        this.fetching = false;
      }
    },
    onEdit(rule) {
      if (this.tier === TIER.msps) {
        this.$router.push({
          name: "msp-geoblocking-rule-edit",
          params: {
            iso: rule.iso,
          },
        });
      }

      if (this.tier === TIER.domainGroups) {
        this.$router.push({
          name: "customer-geoblocking-rule-edit",
          params: {
            iso: rule.iso,
          },
        });
      }

      if (this.tier === TIER.users) {
        this.$router.push({
          name: "user-geoblocking-rule-edit",
          params: {
            iso: rule.iso,
          },
        });
      }

      if (this.tier === TIER.domains) {
        this.$router.push({
          name: "domain-geoblocking-rule-edit",
          params: {
            iso: rule.iso,
          },
        });
      }
    },
    async fetchNewRules() {
      try {
        this.fetching = true;
        this.clearList();
        await this.getGeoblockingRules({
          tier: this.tier,
          id: this.id,
          ...this.dataOptions,
        });
      } catch (_err) {
        // stub
      } finally {
        this.fetching = false;
      }
    },
  },
};
</script>
