<template>
  <div class="domains-list-page">
    <section class="domains-list-page-section">
      <cl-data-table
        :data-test-id="'domains-list-data-table'"
        :fetching="fetching"
        :headers="tableHeaders"
        :items="items"
        :total="serverTotal"
        :default-sort-by="sortByCookie"
        :default-sort-desc="sortDirCookie"
        :default-items-on-page="itemsPerPageCookie"
        with-search
        with-delete
        with-select
        with-set-columns
        with-server-side-pagination
        :searchBy="'sender'"
        :searchPlaceholder="$t('Search')"
        @change-page="changePage"
        @search="debouncedSearch"
        @on-delete-items="onDelete"
        @change-items-on-page="onChangeItemsOnPageClick"
        @sort-items="onSortItemsClick"
        @change-selected-columns="setSelectedColumnsCookie"
      >
        <template v-slot:actions>
          <cl-dropdown
            :title="'Add'"
            data-test-id="domains-list-data-table-add"
          >
            <cl-dropdown-item
              @on-click="onAdd('allow')"
              data-test-id="domains-list-data-table-allow-a-domain"
            >
              {{ $t("Allow domain") }}
            </cl-dropdown-item>
            <cl-dropdown-item
              @on-click="onAdd('block')"
              data-test-id="domains-list-data-table-block-a-domain"
            >
              {{ $t("Block domain") }}
            </cl-dropdown-item>
          </cl-dropdown>
        </template>
        <template v-slot:[`body.cell.actions`]="props">
          <cl-data-table-edit-action
            data-test-id="domains-list-data-table-edit-btn"
            @on-click="onEdit(props.trItem)"
          />
        </template>
        <template v-slot:[`body.cell.status`]="props">
          <cl-pill
            :variant="
              props.tdValue.toLowerCase() === 'allow' ? 'success' : 'danger'
            "
            size="sm"
          >
            {{ props.tdValue }}
          </cl-pill>
        </template>
      </cl-data-table>
    </section>
    <t-add-allow-block-domain
      :isAllow="isAllow"
      :id="id"
      :tier="tier"
      :title="modalTitle"
    />
  </div>
</template>

<script>
import dayjs from "dayjs";

import { mapActions, mapGetters } from "vuex";

import { DATE_FORMAT, TIER, DEFAULT_OPTIONS } from "@/constants";
import TAddAllowBlockDomain from "@/modals/TAddAllowBlockDomain.vue";
import { useDataTable } from "@/composables/useDataTable";
import { useDataTableCookies } from "@/composables/useDataTableCookies";

export default {
  name: "BlockAllowDomains",
  setup(props) {
    const cookieKey = `allow-block-domain-${props.tier || "msp"}`;

    const {
      sortByCookie,
      sortDirCookie,
      itemsPerPageCookie,
      selectedColumnsCookie,
      setSortItemsCookie,
      setItemsPerPageCookie,
      setSelectedColumnsCookie,
    } = useDataTableCookies(
      {
        sortByKey: `${cookieKey}-sort-by`,
        sortDirKey: `${cookieKey}-sort-dir`,
        itemsPerPageKey: `${cookieKey}-items-per-page`,
        selectedColumnsKey: `${cookieKey}-selected-columns`,
      },
      {
        ...DEFAULT_OPTIONS,
        sortBy: "sender",
      }
    );

    const optionsBasedOnCookies = {
      sortBy: sortByCookie,
      sortDir: sortDirCookie,
      results: itemsPerPageCookie,
      page: 1,
    };

    const {
      dataOptions,
      sortItems,
      changePage,
      changeItemsPerPage,
      debouncedSearch,
    } = useDataTable(optionsBasedOnCookies);

    return {
      dataOptions,
      debouncedSearch,
      sortItems,
      changePage,
      changeItemsPerPage,
      selectedColumnsCookie,
      setSortItemsCookie,
      setItemsPerPageCookie,
      setSelectedColumnsCookie,
      sortByCookie,
      sortDirCookie,
      itemsPerPageCookie,
    };
  },
  components: {
    "t-add-allow-block-domain": TAddAllowBlockDomain,
  },
  props: {
    id: {
      type: [String, Number],
      default: null,
    },
    tier: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      fetching: false,
      currentHeaders: null,
      isAllow: true,
      modalTitle: "",
      items: [],
    };
  },
  computed: {
    ...mapGetters("blockAllowDomainsList", ["domains", "serverTotal"]),
    routeName() {
      if (this.tier === TIER.msps) {
        return "msp-block-allow-list-domains-edit";
      }

      if (this.tier === TIER.domainGroups) {
        return "customer-block-allow-list-domains-edit";
      }

      if (this.tier === TIER.domains) {
        return "domain-block-allow-list-domains-edit";
      }

      if (this.tier === TIER.users) {
        return "user-block-allow-list-domains-edit";
      }

      return null;
    },
    tableHeaders() {
      const headers = [
        { key: "sender", label: this.$t("Sender Domain"), sortable: true },
        { key: "status", label: this.$t("Status"), sortable: false },
        {
          key: "subdomains",
          label: this.$t("Include Subdomains"),
          sortable: false,
        },
        {
          key: "lastmodified",
          label: this.$t("Last Modified"),
          sortable: true,
        },
        { key: "comment", label: this.$t("Comments"), sortable: true },
        { key: "actions", label: this.$t("Actions"), sortable: false },
      ];

      if (!this.selectedColumnsCookie?.length) {
        return headers;
      }

      return headers.map((header) => ({
        ...header,
        enabled: this.selectedColumnsCookie.includes(header.key),
      }));
    },
  },
  watch: {
    dataOptions: {
      handler() {
        this.fetchNewDomains();
      },
      deep: true,
      immediate: true,
    },
    domains: {
      handler(newDomains) {
        this.items = newDomains.map((domain) => ({
          ...domain,
          sender: domain.sender.replace("@", ""),
          lastmodified: `${dayjs(domain.updated_at).format(DATE_FORMAT.SHORT)}`,
          subdomains: domain.include_subdomains
            ? this.$t("Yes")
            : this.$t("No"),
          status:
            domain.type === "Block List" ? this.$t("Block") : this.$t("Allow"),
        }));
      },
      immediate: true,
    },
  },
  methods: {
    ...mapActions("modal", ["openModal", "closeModal"]),
    ...mapActions("toast", ["displayToast"]),
    ...mapActions("blockAllowDomainsList", [
      "fetchBlockAllowDomains",
      "batchDeleteDomains",
      "clearDomainsCache",
    ]),
    onSortItemsClick(by, dir) {
      this.setSortItemsCookie(by, dir);
      this.sortItems(by, dir);
    },

    onChangeItemsOnPageClick(number) {
      this.setItemsPerPageCookie(number);
      this.changeItemsPerPage(number);
    },
    async fetchNewDomains() {
      try {
        this.fetching = true;

        await this.fetchBlockAllowDomains({
          id: this.id,
          tier: this.tier,
          ...this.dataOptions,
        });
      } catch (_err) {
        // stub
      } finally {
        this.fetching = false;
      }
    },
    async onDelete(items) {
      try {
        this.fetching = true;

        await this.batchDeleteDomains({
          domains: items,
          tier: this.tier,
          id: this.id,
        });
        this.displayToast({
          title: this.$t("Success"),
          message: this.$t("Domain has been deleted"),
          duration: 2000,
          variant: "success",
        });
        if (this.domains.length === 0 && this.dataOptions.page > 0) {
          this.dataOptions.page = this.dataOptions.page - 1;
        }
      } catch (_err) {
        // stub
      } finally {
        this.fetching = false;
      }
    },
    onEdit(domain) {
      this.$router.push({
        name: this.routeName,
        params: {
          id: domain.id,
        },
      });
    },
    onAdd(domainType) {
      this.isAllow = Boolean(domainType === "allow");
      this.openModal();
      if (domainType === "allow") {
        this.modalTitle = "Allow Domain";
      }
      if (domainType === "block") {
        this.modalTitle = "Block Domain";
      }
    },
  },
  created() {
    this.clearDomainsCache();
  },
};
</script>
