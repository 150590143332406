<template>
  <Teleport to="body">
    <cl-modal
      :visible="showModal"
      @on-close="onCloseModal"
      headerLabel="Add Sender Email"
    >
      <form @submit.prevent="submit" autocomplete="off">
        <div>
          <cl-form-group>
            <cl-form-label label-for="email">
              {{ $t("Sender Email") }}
            </cl-form-label>
            <cl-form-input
              data-lpignore="true"
              id="email"
              name="email"
              v-model:value="form.email"
              :placeholder="$t('Enter Email')"
              :state="setInputState(v$.form.email)"
              @on-blur="v$.form.email.$touch"
            />
            <span
              class="tw-mt-1 tw-block tw-text-sm tw-text-danger-500"
              v-if="v$.form.email.$error"
              data-test-id="validation-email-field"
            >
              {{ $t("Please enter a valid email") }}
            </span>
          </cl-form-group>
        </div>
        <cl-form-group>
          <cl-form-label label-for="comment">
            {{ $t("Comments (optional)") }}
          </cl-form-label>
          <cl-form-textarea
            id="textarea"
            v-model:value="form.comment"
            rows="3"
            max-rows="6"
          ></cl-form-textarea>
        </cl-form-group>
      </form>

      <template v-slot:[`footer.decline`]>
        <cl-button
          :disabled="fetching"
          @on-click="closeModal"
          variant="link-secondary"
        >
          <span>{{ $t("Cancel") }}</span>
        </cl-button>
      </template>
      <template v-slot:[`footer.accept`]>
        <cl-button
          :variant="'secondary'"
          @on-click="submit"
          data-test-id="modal-sender-email-add-button"
        >
          <span v-if="!fetching">{{ this.$t("Add") }}</span>
          <cl-spinner v-else :size="'small'" />
        </cl-button>
      </template>
    </cl-modal>
  </Teleport>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import { useFocusElement } from "@/composables/useFocusElement";
import { useVuelidate } from "@vuelidate/core";
import { required, email } from "@vuelidate/validators";

const DEFAULT_FORM = Object.freeze({
  email: null,
  comment: null,
});

export default {
  setup() {
    const { focusElementById } = useFocusElement();
    return { v$: useVuelidate(), focusElementById };
  },
  validations() {
    return {
      form: {
        email: {
          required,
          email,
        },
      },
    };
  },
  props: {
    id: {
      type: [String, Number],
      required: true,
    },
    tier: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      form: {
        ...DEFAULT_FORM,
      },
    };
  },
  computed: {
    ...mapGetters("modal", ["showModal"]),
  },
  watch: {
    showModal: {
      handler() {
        this.focusElementById("email");
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    ...mapActions("geoBlockingExemptions", ["createGeoBlockingExemption"]),
    ...mapActions("toast", ["displayToast"]),
    ...mapActions("modal", ["closeModal"]),
    setInputState(input) {
      return input.$dirty ? !input.$error : null;
    },
    onCloseModal() {
      this.closeModal();
      this.form = { ...DEFAULT_FORM };
      this.v$.$reset();
    },
    async submit() {
      const isValid = await this.v$.form.$validate();

      if (!isValid) {
        return;
      }

      try {
        await this.createGeoBlockingExemption({
          tier: this.$props.tier,
          id: this.$props.id,
          exemption: this.form,
        });
        this.displayToast({
          title: this.$t("Success"),
          message: this.$t("Email has been added"),
          duration: 2000,
          variant: "success",
        });
        this.onCloseModal();
      } catch (_err) {
        // stub
      }
    },
  },
};
</script>
