<template>
  <div class="content">
    <toggle-geo-blocking
      :geoblockingStatus="geoblockingStatus"
      :tier="tierType"
      :id="selectedMsp.id"
      class="tw-mb-6"
    />
    <transition name="fade" mode="out-in">
      <div v-if="geoblockingStatus">
        <t-tabs :items="tabNavItems" />
        <router-view> </router-view>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import ToggleGeoBlocking from "@/views/Common/GeoBlocking/ToggleGeoblocking";
import { TIER } from "@/constants";
import TTabs from "@/components/TTabs";

export default {
  components: {
    ToggleGeoBlocking,
    TTabs,
  },
  data() {
    return {
      tierType: TIER.msps,
      tabNavItems: this.$secondaryNav.toolsNavItems.geoBlockingMsp(),
      content: {
        pb_geoblocking: this.$t("Geoblocking"),
        setPageSubtitle: this.$t(
          "Geoblocking restricts email based on a sender's geographic location. Once enabled, it can be managed at all levels. Each level can apply its own rules, with rules being inherited from a higher level by default. Exemptions exclude mail from geoblocking rules."
        ),
      },
    };
  },
  computed: {
    ...mapGetters("geoBlocking", ["geoblockingStatus"]),
    ...mapGetters("authentication", ["selectedMsp"]),
  },
  methods: {
    ...mapActions("pageMeta", ["setPageTitle", "setPageSubtitle"]),
    ...mapActions("modal", ["openModal", "closeModal"]),
    ...mapActions("geoBlocking", ["getGeoBlockingStatus"]),
  },
  async mounted() {
    try {
      await this.getGeoBlockingStatus({
        tier: this.tierType,
        id: this.selectedMsp.id,
      });
    } catch (_err) {
      // stub
    }
  },
  created() {
    this.setPageTitle(this.$t("Geoblocking"));
    this.setPageSubtitle([
      this.content.setPageSubtitle,
      this.content.setPageSubtitle_two,
    ]);
  },
};
</script>
