<template>
  <div class="tw-flex tw-flex-col tw-gap-2">
    <div class="tw-mb-1">{{ $t("Frequency") }}</div>
    <label
      :for="item.id"
      v-for="item in items"
      :key="item.id"
      class="tw-align-center tw-flex tw-cursor-pointer tw-gap-2 tw-rounded-sm tw-p-4 tw-ring-1 tw-ring-dorian-gray-100"
      :class="{
        'tw-bg-secondary-100 tw-ring-2 tw-ring-secondary-400':
          item.value === frequencyModel,
      }"
    >
      <cl-icon-tabler-calendar-event height="24px" width="24px" />
      <input
        type="radio"
        :data-test-id="item.testID"
        :id="item.id"
        :name="item.id"
        :value="item.value"
        v-model="frequencyModel"
        @input="$emit('update:frequency', $event.target.value)"
        class="tw-peer tw-hidden"
      />
      <div class="tw-text-lg">
        {{ item.text }}
      </div>
    </label>
  </div>
</template>
<script>
import { ref, watch } from "vue";

export default {
  name: "TFrequency",
  props: {
    items: {
      type: Array,
      required: true,
    },
    frequency: {
      type: String,
    },
  },

  setup(props) {
    const frequencyModel = ref(props.frequency);

    watch(
      () => props.frequency,
      () => {
        frequencyModel.value = props.frequency;
      }
    );

    return { frequencyModel };
  },
};
</script>
