<template>
  <div class="tw-mb-4">
    <form class="form" @submit.prevent autocomplete="off">
      <!-- row domain to block domain -->
      <div>
        <cl-form-group>
          <cl-form-label label-for="domain-input">
            {{ $t("Domain") }}
          </cl-form-label>
          <cl-form-input
            autofocus
            id="domain-input"
            name="domain-input"
            v-model:value="form.domain"
            size="medium"
            :state="setInputState(v$.form.domain)"
            @on-blur="v$.form.domain.$touch"
          />
          <span
            class="tw-block tw-pt-1 tw-text-sm tw-text-danger-500"
            v-if="v$.form.domain.$error"
            >{{ $t("Please provide a valid domain name") }}</span
          >
        </cl-form-group>
      </div>
      <cl-hr />
      <!-- row include subdomains archive -->
      <div>
        <cl-checkbox
          id="apply-body-checkbox"
          v-model:model-value="form.include_subdomains"
        >
          {{ $t("Include Subdomains") }}
        </cl-checkbox>
      </div>
      <cl-hr />
      <!-- row comment -->
      <div>
        <cl-form-group>
          <cl-form-label label-for="comment-textarea">
            {{ $t("Comments (optional)") }}
          </cl-form-label>

          <cl-form-textarea
            id="comment-textarea"
            v-model:value="form.comment"
            rows="3"
          ></cl-form-textarea>
        </cl-form-group>
      </div>
      <!-- Submit -->
      <div>
        <div class="tw-flex tw-justify-end">
          <cl-button
            variant="link-secondary"
            class="tw-mr-2"
            @on-click="onCancel"
            >{{ $t("Cancel") }}</cl-button
          >
          <cl-button type="submit" @on-click="submit" variant="secondary">
            <span v-if="!saving"> {{ $t("Save") }} </span>
            <span class="px-2" v-else><cl-spinner size="small" /></span>
          </cl-button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { useVuelidate } from "@vuelidate/core";
import { required } from "@vuelidate/validators";

import { REGEX, TIER } from "@/constants";

const ALLOW_LIST = "Allow List";

export default {
  name: "EditDomain",
  props: {
    id: {
      type: [String, Number],
      default: null,
    },
    tier: {
      type: String,
      default: null,
    },
    domainId: {
      type: [String, Number],
      required: true,
    },
  },
  setup() {
    return { v$: useVuelidate() };
  },
  validations() {
    return {
      form: {
        domain: {
          required,
          matchRegex: (value) => REGEX.DOMAIN.test(value),
        },
      },
    };
  },
  data() {
    return {
      domainRegex: REGEX.DOMAIN,
      saving: false,
      form: {
        domain: "",
        include_subdomains: false,
        comment: "",
      },
    };
  },
  computed: {
    ...mapGetters("blockAllowDomainsList", ["domains"]),
    routeName() {
      if (this.tier === TIER.msps) {
        return "msp-block-allow-list-domains";
      }

      if (this.tier === TIER.domainGroups) {
        return "customer-block-allow-list-domains";
      }

      if (this.tier === TIER.domains) {
        return "domain-block-allow-list-domains";
      }

      if (this.tier === TIER.users) {
        return "user-block-allow-list-domains";
      }

      return null;
    },
  },
  methods: {
    ...mapActions("blockAllowDomainsList", [
      "updateAllowedDomain",
      "updateBlockedDomain",
    ]),
    ...mapActions("toast", ["displayToast"]),
    setInputState(input) {
      return input.$dirty ? !input.$error : null;
    },
    async submit() {
      const isValid = await this.v$.form.$validate();

      if (!isValid) {
        return;
      }

      try {
        this.saving = true;

        if (this.allowedList) {
          await this.updateAllowedDomain({
            formData: this.form,
            domainId: this.domainId,
            tier: this.tier,
            id: this.id,
          });
        } else {
          await this.updateBlockedDomain({
            formData: this.form,
            domainId: this.domainId,
            tier: this.tier,
            id: this.id,
          });
        }

        this.displayToast({
          title: this.$t("Success"),
          message: this.$t("Domain has been updated"),
          duration: 2000,
          variant: "success",
        });
      } catch (_err) {
        // stub
      } finally {
        this.saving = false;
      }
    },
    onCancel() {
      this.$router.push({
        name: this.routeName,
      });
    },
  },
  created() {
    const id = Number(this.domainId);
    const domain = this.domains.find((domain) => domain.id === id);

    this.form = {
      domain: domain.sender.replace("@", ""),
      include_subdomains: domain.include_subdomains,
      comment: domain.comment || "",
    };

    this.allowedList = domain.type === ALLOW_LIST;
  },
};
</script>
