import { ref, watch } from "vue";

export const useFocusElement = () => {
  const elementRef = ref(null);

  const focusElementById = (elementId) => {
    if (elementRef.value !== null) {
      elementRef.value = "";
    }
    setTimeout(() => {
      const element = document.getElementById(elementId);
      if (element) {
        elementRef.value = element;
      }
    }, 100);
  };

  watch(elementRef, (newVal) => {
    if (newVal) {
      newVal.focus();
    }
  });

  return {
    focusElementById,
    elementRef,
  };
};

export default { useFocusElement };
