<template>
  <div class="page-wrap-domain-policy">
    <domain-policy
      class="tw-mb-6"
      :id="this.$route.params.id"
      :domainPolicy="domainPolicy"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import DomainPolicy from "@/views/Common/Policies/DomainPolicy";

export default {
  components: {
    DomainPolicy,
  },
  methods: {
    ...mapActions("pageMeta", ["setPageTitle", "setPageSubtitle"]),
  },
  computed: {
    ...mapGetters("domainPolicy", ["domainPolicy"]),
  },

  async mounted() {
    this.setPageTitle(this.$route.query.name);
    this.setPageSubtitle([]);
  },
};
</script>
