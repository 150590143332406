<template>
  <section class="tw-mb-5">
    <div class="tw-mb-4">
      <h2>{{ this.$t("Edit Rule Details") }}</h2>
    </div>
    <form @submit.prevent="onSubmit" class="tw-mb-5">
      <div class="tw-grid tw-grid-cols-3 tw-gap-4">
        <div class="tw-col-span-2">
          <cl-form-group :label="$t('Country')">
            <cl-form-label label-for="email">
              {{ $t("Country") }}
            </cl-form-label>
            <country-select
              v-model="iso"
              :country="iso"
              :countryName="false"
              :usei18n="false"
              topCountry="US"
              disabled
            />
          </cl-form-group>
        </div>
        <cl-form-group>
          <cl-form-label label-for="geoblock">
            {{ $t("Action") }}
          </cl-form-label>
          <cl-form-select
            testId="modal-add-georule-action"
            name="geoblock"
            id="geoblock"
            v-model:value="form.geoblock"
            :options="geoblockOptions"
          />
        </cl-form-group>
      </div>

      <div>
        <cl-form-group>
          <cl-form-label label-for="comment">
            {{ $t("Comment") }}
          </cl-form-label>
          <cl-form-textarea
            id="comment"
            name="comment"
            :placeholder="$t('Comment')"
            v-model:value="form.comment"
            rows="3"
            max-rows="6"
          />
        </cl-form-group>
      </div>
      <div class="tw-flex tw-justify-end">
        <cl-button variant="secondary" type="submit">
          <span v-if="!fetching"> Save Changes </span>
          <span v-else><cl-spinner size="small" /></span>
        </cl-button>
      </div>
    </form>
  </section>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "EditRule",
  props: {
    id: {
      type: [String, Number],
      default: null,
    },
    tier: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      fetching: false,
      iso: null,
      geoblockOptions: [
        {
          text: this.$t("Block"),
          value: true,
        },
        {
          text: this.$t("Allow"),
          value: false,
        },
      ],
      form: {
        geoblock: false,
        comment: null,
      },
    };
  },
  computed: {
    ...mapGetters("geoBlockingRules", ["geoBlockingRules"]),
  },
  methods: {
    ...mapActions("geoBlockingRules", ["updateGeoBlockingRule"]),
    async onSubmit() {
      try {
        this.fetching = true;

        await this.updateGeoBlockingRule({
          tier: this.tier,
          id: this.id,
          iso: this.$route.params.iso,
          form: this.form,
        });

        this.$router.back();
      } catch (_err) {
        // stub
      } finally {
        this.fetching = false;
      }
    },
  },
  created() {
    const iso = this.$route.params.iso;
    const selectedRule = this.geoBlockingRules.find(
      (rules) => rules.iso === iso
    );

    this.iso = selectedRule.iso;
    this.form.geoblock = selectedRule.geoblock;
    this.form.comment = selectedRule.comment;
  },
};
</script>
