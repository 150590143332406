<template>
  <form
    v-if="selectedPolicy !== null && !loading"
    class="user-policy-wrapper"
    @keydown="checkChanges"
    @change="checkChanges"
    @submit.prevent="submit"
  >
    <!-- User Details -->
    <div class="tw-mb-4 tw-bg-white tw-p-4">
      <div class="tw-mb-4">
        <span class="tw-text-lg tw-font-bold">
          {{ $t("User Details") }}
        </span>
      </div>
      <section>
        <div class="tw-grid tw-gap-4 md:tw-grid-cols-2 lg:tw-grid-cols-3">
          <cl-form-group>
            <cl-form-label label-for="first_name">
              {{ $t("First Name") }}
            </cl-form-label>
            <cl-form-input
              id="first_name"
              name="first_name"
              v-model:value="selectedUser.first_name"
              size="medium"
              :state="setInputState(v$.selectedUser.first_name)"
              @on-blur="v$.selectedUser.first_name.$touch"
            />
            <span
              class="tw-block tw-pt-1 tw-text-sm tw-text-danger-500"
              v-if="v$.selectedUser.first_name.$error"
              >{{ error }}</span
            >
          </cl-form-group>

          <cl-form-group>
            <cl-form-label label-for="last_name">
              {{ $t("Last Name") }}
            </cl-form-label>
            <cl-form-input
              id="last_name"
              name="last_name"
              v-model:value="selectedUser.last_name"
              size="medium"
              :state="setInputState(v$.selectedUser.last_name)"
              @on-blur="v$.selectedUser.last_name.$touch"
            />
            <span
              class="tw-block tw-pt-1 tw-text-sm tw-text-danger-500"
              v-if="v$.selectedUser.last_name.$error"
              >{{ error }}</span
            >
          </cl-form-group>
          <cl-form-group>
            <cl-form-label label-for="user_role">
              {{ $t("User Role") }}
            </cl-form-label>
            <cl-form-input
              id="user_role"
              name="user_role"
              :value="roles"
              size="medium"
              disabled
            />
          </cl-form-group>
        </div>
      </section>
    </div>
    <!-- Spam Filtering -->
    <div
      v-bind:class="{
        'tw-bg-white': selectedPolicy.spam.enabled,
        'tw-border-b tw-border-dorian-gray-100': !selectedPolicy.spam.enabled,
      }"
    >
      <div class="tw-flex tw-items-center tw-justify-between tw-p-4">
        <span class="tw-text-lg tw-font-bold">
          {{ $t("Spam Filtering") }}
        </span>
        <cl-form-radio-group
          id="spam-switcher"
          name="spam-switcher"
          :selectedValue="selectedPolicy.spam.enabled"
          @update:selected="
            (selected) => (selectedPolicy.spam.enabled = selected)
          "
          button
          :options="radioOptions"
        />
      </div>
      <hr v-if="selectedPolicy.spam.enabled" />
      <div
        v-if="selectedPolicy.spam.enabled"
        data-test-id="spam_block"
        class="tw-mb-4"
      >
        <section>
          <div class="tw-flex tw-flex-col tw-p-4">
            <div class="tw-grid tw-gap-4 md:tw-grid-cols-2 lg:tw-grid-cols-3">
              <cl-form-group>
                <cl-form-label label-for="score">
                  {{ $t("Mark as Spam when score is greater than") }}
                </cl-form-label>
                <cl-form-input
                  id="score"
                  name="score"
                  type="number"
                  v-model:value="selectedPolicy.spam.score"
                  size="medium"
                  :state="setInputState(v$.selectedPolicy.spam.score)"
                  @on-blur="v$.selectedPolicy.spam.score.$touch"
                  step="0.01"
                />
                <span
                  class="tw-block tw-pt-1 tw-text-sm tw-text-danger-500"
                  v-if="v$.selectedPolicy.spam.score.$error"
                  >{{ error }}</span
                >
              </cl-form-group>

              <cl-form-group>
                <cl-form-label label-for="action">
                  {{ $t("Spam should be") }}
                </cl-form-label>
                <cl-form-select
                  id="action"
                  v-model:value="selectedPolicy.spam.action"
                  :options="spam.actionObjList"
                />
              </cl-form-group>
              <cl-form-group>
                <cl-form-label label-for="discard_score">
                  {{ $t("Discard Spam scoring above") }}
                </cl-form-label>
                <cl-form-input
                  type="number"
                  id="discard_score"
                  name="discard_score"
                  v-model:value="selectedPolicy.spam.discard_score"
                  size="medium"
                  :state="setInputState(v$.selectedPolicy.spam.discard_score)"
                  @on-blur="v$.selectedPolicy.spam.discard_score.$touch"
                />
                <span
                  class="tw-block tw-pt-1 tw-text-sm tw-text-danger-500"
                  v-if="v$.selectedPolicy.spam.discard_score.$error"
                  >{{ error }}</span
                >
              </cl-form-group>
            </div>
            <div class="tw-mt-4 tw-grid tw-gap-4 md:tw-grid-cols-2">
              <div>
                <cl-form-group>
                  <cl-checkbox
                    id="spam-send-ndr-checkbox"
                    v-model:model-value="selectedPolicy.spam.send_ndr"
                  >
                    {{ $t("Send NDR") }}
                  </cl-checkbox>
                </cl-form-group>
              </div>
              <div>
                <cl-form-group>
                  <cl-checkbox
                    id="spam-add-headers-checkbox"
                    v-model:model-value="selectedPolicy.spam.add_headers"
                  >
                    {{ $t("Add X-Spam headers to non-spam mails") }}
                  </cl-checkbox>
                </cl-form-group>
              </div>
            </div>
            <div class="tw-mt-4 tw-grid tw-gap-4 md:tw-grid-cols-2">
              <div>
                <cl-form-group>
                  <cl-checkbox
                    id="spam-block-marketing-checkbox"
                    v-model:model-value="selectedPolicy.spam.block_marketing"
                  >
                    {{ $t("Classify marketing emails as spam") }}
                  </cl-checkbox>
                </cl-form-group>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
    <!-- Virus Filtering -->
    <div
      class="virus-filtering"
      v-bind:class="{
        'tw-bg-white': selectedPolicy.virus.enabled,
        'tw-border-b tw-border-dorian-gray-100': !selectedPolicy.virus.enabled,
      }"
    >
      <div class="tw-flex tw-items-center tw-justify-between tw-p-4">
        <span class="tw-text-lg tw-font-bold">
          {{ $t("Virus Filtering") }}
        </span>
        <cl-form-radio-group
          id="virus-switcher"
          name="virus-switcher"
          :selectedValue="selectedPolicy.virus.enabled"
          @update:selected="
            (selected) => (selectedPolicy.virus.enabled = selected)
          "
          switcher
          button
          :options="radioOptions"
        />
      </div>
      <hr v-if="selectedPolicy.virus.enabled" />
      <div
        v-if="selectedPolicy.virus.enabled"
        data-test-id="virus_block"
        class="tw-mb-4 tw-p-4"
      >
        <section>
          <cl-form-group>
            <cl-form-label label-for="virus_action">
              {{ $t("Virus should be") }}
            </cl-form-label>
            <cl-form-select
              id="virus_action"
              v-model:value="selectedPolicy.virus.action"
              :options="virus.actionObjList"
            />
          </cl-form-group>
          <cl-form-group class="tw-mt-4 tw-flex tw-justify-center">
            <cl-checkbox
              id="spam-send-add-checkbox"
              v-model:model-value="selectedPolicy.virus.sandbox"
            >
              {{ $t("Sandbox") }}
            </cl-checkbox>
          </cl-form-group>
        </section>
      </div>
    </div>
    <!-- Attachment Type Filtering -->
    <div
      class="banned-filtering"
      v-bind:class="{
        'tw-bg-white': selectedPolicy.banned.enabled,
        'tw-border-b tw-border-dorian-gray-100': !selectedPolicy.banned.enabled,
      }"
    >
      <div
        class="tw-flex tw-flex-row tw-items-center tw-justify-between tw-p-4"
      >
        <span class="tw-text-lg tw-font-bold">
          {{ $t("Attachment Type Filtering") }}
        </span>
        <cl-form-radio-group
          id="banned_switcher"
          name="banned_switcher"
          :selectedValue="selectedPolicy.banned.enabled"
          @update:selected="
            (selected) => (selectedPolicy.banned.enabled = selected)
          "
          button
          :options="radioOptions"
        />
      </div>
      <hr v-if="selectedPolicy.banned.enabled" />
      <div v-if="selectedPolicy.banned.enabled" data-test-id="banned_block">
        <section>
          <div class="tw-grid tw-gap-4 tw-p-4 md:tw-grid-cols-2">
            <cl-form-group>
              <cl-form-label label-for="banned_action">
                {{ $t("Banned Attachments should be") }}
              </cl-form-label>
              <cl-form-select
                id="banned_action"
                v-model:value="selectedPolicy.banned.action"
                :options="banned.actionObjList"
              />
            </cl-form-group>
          </div>
        </section>
      </div>
    </div>
    <!-- Quarantine report -->
    <div
      class="qreport-filtering"
      v-bind:class="{
        'tw-bg-white': selectedPolicy.qreport.enabled,
        'tw-border-b tw-border-dorian-gray-100':
          !selectedPolicy.qreport.enabled,
      }"
    >
      <div
        class="tw-flex tw-flex-row tw-items-center tw-justify-between tw-p-4"
      >
        <span class="tw-text-lg tw-font-bold">
          {{ $t("Quarantine Report") }}
        </span>
        <cl-form-radio-group
          id="qreport_switcher"
          name="qreport_switcher"
          :selectedValue="selectedPolicy.qreport.enabled"
          @update:selected="
            (selected) => (selectedPolicy.qreport.enabled = selected)
          "
          button
          :options="radioOptions"
        />
      </div>
      <hr v-if="selectedPolicy.qreport.enabled" />
      <div
        v-if="selectedPolicy.qreport.enabled"
        class="qreport-filtering-content p-3"
        data-test-id="qreport_block"
      >
        <section>
          <div class="d-flex flex-column">
            <div class="tw-grid tw-gap-4 tw-p-4 md:tw-grid-cols-2">
              <cl-form-group>
                <cl-form-label label-for="qreport_language">
                  {{ $t("Language") }}
                </cl-form-label>
                <cl-form-select
                  id="qreport_language"
                  v-model:value="selectedPolicy.qreport.language"
                  :options="qreport.languageObjList"
                />
              </cl-form-group>
              <cl-form-group>
                <cl-form-label label-for="email_report">
                  {{ $t("Email report") }}
                </cl-form-label>
                <cl-form-select
                  id="email_report"
                  v-model:value="selectedPolicy.qreport.frequency"
                  :options="qreport.frequencyObjList"
                />
              </cl-form-group>
            </div>
            <div class="tw-grid tw-gap-4 tw-p-4 md:tw-grid-cols-2">
              <cl-form-group>
                <cl-form-label label-for="qreport_contains">
                  {{ $t("Report contains") }}
                </cl-form-label>
                <cl-form-select
                  id="qreport_contains"
                  v-model:value="selectedPolicy.qreport.type"
                  :options="qreport.containsObjList"
                />
              </cl-form-group>
              <cl-form-group>
                <cl-form-label label-for="qreport_score">
                  {{ $t("Exclude spam mails scoring above") }}
                </cl-form-label>
                <cl-form-input
                  type="number"
                  id="qreport_score"
                  name="qreport_score"
                  v-model:value="selectedPolicy.qreport.exclude_score"
                  size="medium"
                  :state="
                    setInputState(v$.selectedPolicy.qreport.exclude_score)
                  "
                  @on-blur="v$.selectedPolicy.qreport.exclude_score.$touch"
                />
                <span
                  class="tw-block tw-pt-1 tw-text-sm tw-text-danger-500"
                  v-if="v$.selectedPolicy.qreport.exclude_score.$error"
                  >{{ error }}</span
                >
              </cl-form-group>
            </div>
          </div>
        </section>
      </div>
    </div>
    <!-- Archive mail -->
    <div
      class="tw-flex tw-flex-row tw-items-center tw-justify-between tw-border-b tw-border-dorian-gray-100 tw-p-4"
    >
      <span class="tw-text-lg tw-font-bold">
        {{ $t("Archive Mail") }}
      </span>
      <cl-form-radio-group
        id="archive_clean_mail-switcher"
        name="archive_clean_mail-switcher"
        :selectedValue="selectedPolicy.archive_clean_mail"
        @update:selected="
          (selected) => (selectedPolicy.archive_clean_mail = selected)
        "
        button
        :options="radioOptions"
      />
    </div>
    <!-- button group -->
    <div class="tw-mt-4 tw-flex tw-justify-end">
      <cl-button type="submit" variant="secondary" :disabled="!isChange">
        <span v-if="!loading"> {{ $t("Save Changes") }} </span>
        <span class="px-2" v-else><cl-spinner size="small" /></span>
      </cl-button>
    </div>
  </form>
  <t-skeleton v-else />
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { useVuelidate } from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import TSkeleton from "@/components/TSkeleton";

import {
  SPAM_ACTION,
  VIRUS_ACTION,
  BANNED_ACTION,
  QREPORT_LANGUAGE,
  QREPORT_FREQUENCY,
  QREPORT_CONTAINS,
} from "@/constants";

const DEFAULT_SCORE = 999;
const DEFAULT_SPAM_SCORE = 5;

export default {
  name: "UserPolicyDetails",
  components: {
    TSkeleton,
  },
  setup() {
    return { v$: useVuelidate() };
  },
  validations() {
    return {
      selectedUser: {
        first_name: {
          required,
        },
        last_name: {
          required,
        },
      },
      selectedPolicy: {
        spam: {
          score: required,
          discard_score: required,
        },
        qreport: {
          exclude_score: required,
        },
      },
    };
  },
  data() {
    return {
      isChange: false,
      loading: false,
      error: this.$t("This field is a required field"),
      selectedPolicy: null,
      radioOptions: [
        { label: this.$t("On"), value: true },
        { label: this.$t("Off"), value: false },
      ],
      spam: {
        actionObjList: Object.entries(SPAM_ACTION).map(([value, text]) => ({
          value,
          text: this.$t(text),
        })),
      },
      virus: {
        actionObjList: Object.entries(VIRUS_ACTION).map(([value, text]) => ({
          value,
          text: this.$t(text),
        })),
      },
      banned: {
        actionObjList: Object.entries(BANNED_ACTION).map(([value, text]) => ({
          value,
          text: this.$t(text),
        })),
      },
      qreport: {
        languageObjList: Object.entries(QREPORT_LANGUAGE).map(
          ([value, text]) => ({
            value,
            text: this.$t(text),
          })
        ),
        frequencyObjList: Object.entries(QREPORT_FREQUENCY).map(
          ([value, text]) => ({
            value,
            text: this.$t(text),
          })
        ),
        containsObjList: Object.entries(QREPORT_CONTAINS).map(
          ([value, text]) => ({
            value,
            text: this.$t(text),
          })
        ),
      },
    };
  },
  computed: {
    ...mapGetters("userPolicies", ["selectedUser"]),
    ...mapGetters("authentication", ["selectedDomain"]),
    roles() {
      return this.selectedUser.roles
        .map((role) => role.type)
        .join(", ")
        .replace("realm", "Customer Administrator");
    },
  },
  watch: {
    selectedUser: {
      handler(selectedUser) {
        this.selectedPolicy = JSON.parse(JSON.stringify(selectedUser.policy));
      },
      immediate: true,
      deep: true,
    },
    selectedPolicy: {
      handler(selectedPolicy) {
        if (selectedPolicy !== null && typeof selectedPolicy === "object") {
          const setDefaultsIfEnabled = (policyKey, setDefaultsFn) => {
            if (
              selectedPolicy[policyKey]?.enabled &&
              Object.keys(selectedPolicy[policyKey]).length <= 1
            ) {
              setDefaultsFn();
            }
          };

          setDefaultsIfEnabled("spam", this.setSpamDefaults);
          setDefaultsIfEnabled("virus", this.setVirusDefaults);
          setDefaultsIfEnabled("banned", this.setBannedDefaults);
          setDefaultsIfEnabled("qreport", this.setQReportDefaults);
        }
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    ...mapActions("pageMeta", ["setPageTitle"]),
    ...mapActions("userPolicies", [
      "fetchUser",
      "updateUser",
      "updateUserPolicy",
      "clearList",
    ]),
    ...mapActions("toast", ["displayToast"]),
    checkChanges() {
      this.isChange = true;
    },
    setSpamDefaults() {
      this.selectedPolicy.spam.score = DEFAULT_SPAM_SCORE;
      this.selectedPolicy.spam.discard_score = DEFAULT_SCORE;
      this.selectedPolicy.spam.add_headers = false;
      this.selectedPolicy.spam.send_ndr = true;
      this.selectedPolicy.spam.action = this.spam.actionObjList[0].value;
    },
    setVirusDefaults() {
      this.selectedPolicy.virus.action = this.virus.actionObjList[0].value;
      this.selectedPolicy.virus.sandbox = true;
    },
    setBannedDefaults() {
      this.selectedPolicy.banned.action = this.banned.actionObjList[0].value;
    },
    setQReportDefaults() {
      this.selectedPolicy.qreport.language =
        this.qreport.languageObjList[0].value;
      this.selectedPolicy.qreport.frequency =
        this.qreport.frequencyObjList[0].value;
      this.selectedPolicy.qreport.type = this.qreport.containsObjList[1].value;
      this.selectedPolicy.qreport.exclude_score = DEFAULT_SCORE;
    },
    async updateUserCredentials() {
      try {
        this.loading = true;

        await this.updateUser({
          id: this.$route.params.id,
          value: {
            first_name: this.selectedUser.first_name,
            last_name: this.selectedUser.last_name,
          },
        });
      } catch (_err) {
        // stub
      } finally {
        this.loading = false;
      }
    },
    async updateUserPoliciesValue() {
      try {
        this.loading = true;

        await this.updateUserPolicy({
          id: this.$route.params.id,
          value: {
            ...this.selectedPolicy,
          },
        });

        this.displayToast({
          title: this.$t("Success"),
          message: this.$t("User Policy updated"),
          duration: 2000,
          variant: "success",
        });

        this.$router.push({ name: "domain-policies-user-policies" });
      } catch (_err) {
        // stub
      } finally {
        this.loading = false;
      }
    },
    setInputState(input) {
      return input.$dirty ? !input.$error : null;
    },
    async submit() {
      const isValid = await this.v$.$validate();

      if (!isValid) {
        return;
      }

      this.updateUserCredentials();
      this.updateUserPoliciesValue();
    },
  },
  async created() {
    try {
      this.loading = true;

      await this.fetchUser({
        userId: this.$route.params.id,
        domainId: this.selectedDomain.id,
      });
    } catch (_err) {
      // stub
    } finally {
      this.loading = false;
    }
  },
  mounted() {
    this.setPageTitle(this.selectedUser.email);
  },
  beforeUnmount() {
    this.clearList();
  },
};
</script>
