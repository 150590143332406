<template>
  <t-block-allow-email
    v-if="selectedUser.id"
    :id="selectedUser.id"
    :tier="TIER.users"
  ></t-block-allow-email>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import TBlockAllowEmail from "@/views/Common/TBlockAllowEmail";

import { TIER } from "@/constants/tier";

export default {
  name: "Emails",
  components: {
    TBlockAllowEmail,
  },
  data() {
    return {
      TIER,
    };
  },
  computed: {
    ...mapGetters("authentication", ["selectedUser"]),
  },
  methods: {
    ...mapActions("pageMeta", ["setPageTitle", "setPageSubtitle"]),
  },
  mounted() {
    this.setPageTitle(this.$t("Allow & Block List"));
    this.setPageSubtitle([]);
  },
};
</script>
