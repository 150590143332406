<template>
  <edit-pattern-filter
    v-if="selectedMsp.id"
    :id="selectedMsp.id"
    :tier="TIER.msps"
  />
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import EditPatternFilter from "@/views/Common/PatternFilters/Edit/Multi.vue";

import { TIER } from "@/constants";

export default {
  name: "EditAttachmentFilterWrapper",
  components: {
    EditPatternFilter,
  },
  data() {
    return {
      TIER,
    };
  },
  computed: {
    ...mapGetters("authentication", ["selectedMsp"]),
  },
  methods: {
    ...mapActions("pageMeta", ["setPageTitle", "setPageSubtitle"]),
  },
  created() {
    this.setPageTitle(
      this.$t("Edit Pattern: X", { id: this.$route.params.id })
    );
    this.setPageSubtitle([]);
  },
};
</script>
