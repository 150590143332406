<template>
  <Teleport to="body">
    <cl-modal
      :visible="showModal"
      @on-close="onCloseModal"
      headerLabel="Add Sender IP"
    >
      <form @submit.prevent="submit" autocomplete="off">
        <div class="tw-grid sm:tw-grid-cols-2 sm:tw-gap-4">
          <div>
            <cl-form-group>
              <cl-form-label label-for="ipNetwork">
                {{ $t("Sender IP/Network") }}
              </cl-form-label>
              <cl-form-input
                data-lpignore="true"
                id="ip"
                name="ip"
                v-model:value="form.ip"
                :placeholder="$t('Enter IP')"
                :state="setInputState(v$.form.ip)"
                @on-blur="v$.form.ip.$touch"
              />
              <span
                class="tw-mt-1 tw-block tw-text-sm tw-text-danger-500"
                v-if="v$.form.ip.$error"
                data-test-id="validation-ip-field"
              >
                {{ $t("You should provide valid IP address") }}
              </span>
            </cl-form-group>
          </div>
          <div>
            <cl-form-group>
              <cl-form-label label-for="netmask">
                {{ $t("Netmask") }}
              </cl-form-label>
              <cl-form-input
                type="number"
                data-lpignore="true"
                id="mask"
                name="mask"
                v-model:value="form.mask"
                :placeholder="$t('Netmask')"
                :state="setInputState(v$.form.mask)"
                @on-blur="v$.form.mask.$touch"
              />
              <span
                class="tw-mt-1 tw-block tw-text-sm tw-text-danger-500"
                v-if="v$.form.mask.$error"
                data-test-id="validation-netmask-field"
              >
                {{ $t("Netmask must be between the number range 8 and 32.") }}
              </span>
            </cl-form-group>
          </div>
          <div class="sm:tw-col-span-2">
            <cl-form-group>
              <cl-form-label label-for="comment">
                {{ $t("Comments (optional)") }}
              </cl-form-label>
              <cl-form-textarea
                id="textarea"
                v-model:value="form.comment"
                rows="3"
                max-rows="6"
              ></cl-form-textarea>
            </cl-form-group>
          </div>
        </div>
      </form>

      <template v-slot:[`footer.decline`]>
        <cl-button
          :disabled="fetching"
          @on-click="closeModal"
          variant="link-secondary"
        >
          <span>{{ $t("Cancel") }}</span>
        </cl-button>
      </template>
      <template v-slot:[`footer.accept`]>
        <cl-button
          :variant="'secondary'"
          @on-click="submit"
          data-test-id="modal-sender-ip-add-button"
        >
          <span v-if="!fetching">{{ this.$t("Add Exemption") }}</span>
          <cl-spinner v-else :size="'small'" />
        </cl-button>
      </template>
    </cl-modal>
  </Teleport>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import { useFocusElement } from "@/composables/useFocusElement";
import { REGEX } from "@/constants";
import { useVuelidate } from "@vuelidate/core";
import { required } from "@vuelidate/validators";

const DEFAULT_FORM = Object.freeze({
  ip: null,
  comment: null,
  mask: null,
});

export default {
  setup() {
    const { focusElementById } = useFocusElement();
    return { v$: useVuelidate(), focusElementById };
  },
  validations() {
    return {
      form: {
        ip: {
          required,
          matchRegex: (value) => REGEX.IP.test(value),
        },
        mask: {
          required,
          minValue: (value) => REGEX.NETMASK.test(value),
        },
      },
    };
  },
  props: {
    id: {
      type: [String, Number],
      required: true,
    },
    tier: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      fetching: false,
      form: {
        ...DEFAULT_FORM,
      },
    };
  },
  computed: {
    ...mapGetters("modal", ["showModal"]),
  },
  watch: {
    showModal: {
      handler() {
        this.focusElementById("ip");
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    ...mapActions("toast", ["displayToast"]),
    ...mapActions("geoBlockingExemptions", ["createGeoBlockingExemption"]),
    ...mapActions("modal", ["closeModal"]),
    setInputState(input) {
      return input.$dirty ? !input.$error : null;
    },
    onCloseModal() {
      this.closeModal();
      this.form = { ...DEFAULT_FORM };
      this.v$.$reset();
    },
    async submit() {
      const isValid = await this.v$.form.$validate();

      if (!isValid) {
        return;
      }

      try {
        await this.createGeoBlockingExemption({
          tier: this.$props.tier,
          id: this.$props.id,
          exemption: this.form,
        });
        this.displayToast({
          title: this.$t("Success"),
          message: this.$t("IP has been added"),
          duration: 2000,
          variant: "success",
        });
        this.onCloseModal();
      } catch (_err) {
        // stub
      }
    },
  },
};
</script>
