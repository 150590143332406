<template>
  <edit-rule
    v-if="selectedCustomer.id"
    :tier="tierType"
    :id="selectedCustomer.id"
  />
</template>
<script>
import { mapActions, mapGetters } from "vuex";

import EditRule from "@/views/Common/GeoBlocking/Rules/Edit/index.vue";

import { TIER } from "@/constants";

export default {
  name: "GeoblockingRuleEditWrap",
  components: {
    EditRule,
  },
  data() {
    return {
      tierType: TIER.domainGroups,
    };
  },
  methods: {
    ...mapActions("pageMeta", ["setPageTitle", "setPageSubtitle"]),
  },
  computed: {
    ...mapGetters("authentication", ["selectedCustomer"]),
  },
  created() {
    this.setPageTitle(this.$t("Geoblocking"));
    this.setPageSubtitle([]);
  },
};
</script>
