export const MESSAGE_DELIVERY_STATUS = Object.freeze({
  null: "All",
  sent: "Sent",
  deferred: "Deferred",
  bounced: "Bounced",
  expired: "Expired",
  clean: "Clean",
  blocked_spam: "Blocked Spam",
  blocked_banned: "Blocked Banned",
  blocked_infected: "Blocked Infected",
  reject: "Reject",
});

export default {};
