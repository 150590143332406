<template>
  <Teleport to="body">
    <cl-modal
      :visible="showModal"
      @on-close="onCloseModal"
      :headerLabel="title"
    >
      <form class="modal-form" @submit.prevent="submit" autocomplete="off">
        <div
          v-if="isAllow"
          class="tw-mb-4 tw-inline-flex tw-w-full tw-items-center tw-rounded-md tw-bg-warning-100 tw-p-4 tw-text-base tw-text-warning-800"
          role="alert"
        >
          {{
            $t(
              "Allowed IPs can bypass settings put in place for your security. Ensure this is a trusted IP and details are correct."
            )
          }}
        </div>
        <div>
          <cl-form-group v-if="form.address_type === 4">
            <cl-form-label label-for="ip">
              {{ $t("IP/Network") }}
            </cl-form-label>
            <cl-form-input
              autofocus
              id="ip"
              name="ip"
              v-model:value="v$.ip_v4.$model"
              :state="setInputState(v$.ip_v4.$model)"
              @on-blur="v$.ip_v4.$touch"
            />
            <div
              class="tw-block tw-text-sm tw-text-danger-500"
              v-if="v$.ip_v4.$error"
            >
              {{ $t("Please enter a valid IP/Network address") }}
            </div>
          </cl-form-group>
          <cl-form-group v-else>
            <cl-form-label label-for="ip">
              {{ $t("IP/Network") }}
            </cl-form-label>
            <cl-form-input
              id="ip"
              name="ip"
              v-model:value="v$.ip_v6.$model"
              :state="setInputState(v$.ip_v6.$model)"
              @on-blur="v$.ip_v6.$touch"
            />
            <div
              class="tw-block tw-text-sm tw-text-danger-500"
              v-if="v$.ip_v6.$error"
            >
              {{ $t("Please enter a valid IP/Network address") }}
            </div>
          </cl-form-group>
        </div>
        <!-- row comment -->
        <div class="tw-grid sm:tw-grid-cols-2 sm:tw-gap-4">
          <cl-form-group v-if="form.address_type === 4">
            <cl-form-label label-for="mask">
              {{ $t("Netmask") }}
            </cl-form-label>
            <cl-form-select
              name="mask"
              id="mask"
              v-model:value="v$.mask_v4.$model"
              :options="maskOptions"
              @on-blur="v$.mask_v4.$touch"
            />
            <div
              class="tw-block tw-text-sm tw-text-danger-500"
              v-if="v$.mask_v4.$error"
            >
              {{ $t("Please enter a valid netmask") }}
            </div>
          </cl-form-group>
          <cl-form-group v-else>
            <cl-form-label label-for="mask">
              {{ $t("Netmask") }}
            </cl-form-label>
            <cl-form-input
              name="mask"
              id="mask"
              v-model:value="v$.mask_v6.$model"
              :min="IPV6_RANGE.FROM"
              :max="IPV6_RANGE.TO"
              :step="1"
              type="number"
              :state="setInputState(v$.mask_v6.$model)"
              @on-blur="v$.mask_v6.$touch"
            />
            <div
              class="tw-block tw-text-sm tw-text-danger-500"
              v-if="v$.mask_v6.$error"
            >
              {{ $t("Please enter a valid netmask") }}
            </div>
          </cl-form-group>
          <cl-form-group>
            <cl-form-label label-for="address_type">
              {{ $t("Address Type") }}
            </cl-form-label>
            <cl-form-radio-group
              name="address_type"
              id="address_type"
              :selectedValue="form.address_type"
              @update:selected="onAddressTypeChange($event)"
              switcher
              :options="addressTypeOptions"
            />
          </cl-form-group>
        </div>
        <div>
          <cl-form-group>
            <cl-form-label label-for="comment-textarea">
              {{ $t("Comments (optional)") }}
            </cl-form-label>
            <cl-form-textarea
              id="comment-textarea"
              name="comment-textarea"
              v-model:value="form.comment"
              rows="3"
              max-rows="6"
            ></cl-form-textarea>
            <div
              class="tw-block tw-text-sm tw-text-danger-500"
              v-if="v$.form.comment.$error"
            >
              {{ $t("The comment must not be greater than 250 characters.") }}
            </div>
          </cl-form-group>
        </div>
      </form>

      <template v-slot:[`footer.decline`]>
        <cl-button
          :disabled="fetching"
          @on-click="closeModal"
          variant="link-secondary"
        >
          <span>{{ $t("Cancel") }}</span>
        </cl-button>
      </template>
      <template v-slot:[`footer.accept`]>
        <cl-button v-if="fetching" :variant="'secondary'">
          <cl-spinner :size="'small'" />
        </cl-button>
        <cl-button
          v-else
          :variant="'secondary'"
          @on-click="submit"
          data-test-id="modal-allow-block-add-ip-button"
        >
          <span v-if="isAllow">{{ this.$t("Allow") }}</span>
          <span v-else>{{ this.$t("Block") }}</span>
        </cl-button>
      </template>
    </cl-modal>
  </Teleport>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { useFocusElement } from "@/composables/useFocusElement";
import { useVuelidate } from "@vuelidate/core";
import { IPV4_MASK_OPTIONS, IPV6_RANGE, REGEX } from "@/constants";
import { maxLength } from "@vuelidate/validators";

const DEFAULT_FORM = Object.freeze({
  ip: "",
  mask: "32",
  address_type: 4,
  comment: "",
});

export default {
  setup() {
    const { focusElementById } = useFocusElement();
    return { v$: useVuelidate(), focusElementById };
  },
  validations() {
    return {
      form: {
        address_type: {},
        comment: {
          maxLength: maxLength(250),
        },
      },
      mask_v4: {
        matchMaskV4: (value) => REGEX.NETMASK.test(value),
      },
      mask_v6: {
        matchMaskV6: (value) => REGEX.NETMASK_V6.test(value),
      },
      ip_v4: {
        matchIpV4: (value) => REGEX.IPV4.test(value),
      },
      ip_v6: {
        matchIpV6: (value) => REGEX.IPV6.test(value),
      },
    };
  },
  props: {
    isAllow: {
      type: Boolean,
      required: true,
    },
    id: {
      type: [String, Number],
      required: true,
    },
    tier: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      fetching: false,
      form: {
        ...DEFAULT_FORM,
      },
      ip_v4: "",
      ip_v6: "",
      mask_v4: IPV4_MASK_OPTIONS[0].value,
      mask_v6: IPV6_RANGE.TO,
      addressTypeOptions: [
        { label: this.$t("IPv4"), value: 4 },
        { label: this.$t("IPv6"), value: 6 },
      ],
      maskOptions: IPV4_MASK_OPTIONS,
    };
  },
  computed: {
    IPV6_RANGE() {
      return IPV6_RANGE;
    },
    ...mapGetters("modal", ["showModal"]),
    inputLabel() {
      return this.$props.isAllow
        ? this.$t("Email to Allow")
        : this.$t("Email to Block");
    },
  },
  watch: {
    showModal: {
      handler() {
        this.focusElementById("ip");
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    ...mapActions("blockAllowIPsList", ["allowIP", "blockIP"]),
    ...mapActions("toast", ["displayToast"]),
    ...mapActions("modal", ["closeModal"]),
    setInputState(input) {
      return input.$dirty ? !input.$error : null;
    },
    onAddressTypeChange(address_type) {
      if (address_type === 4) {
        this.mask_v4 = IPV4_MASK_OPTIONS[0].value;
      } else {
        this.mask_v6 = IPV6_RANGE.TO;
      }
      this.form.address_type = address_type;
    },
    onCloseModal() {
      this.closeModal();
      this.form = { ...DEFAULT_FORM };
      this.v$.$reset();
    },
    async submit() {
      const isValid = await this.v$.form.$validate();
      const isIpValid =
        this.form.address_type === 4
          ? await this.v$.ip_v4.$validate()
          : await this.v$.ip_v6.$validate();
      const isMaskValid =
        this.form.address_type === 4
          ? await this.v$.mask_v4.$validate()
          : await this.v$.mask_v6.$validate();

      if (!isValid || !isIpValid || !isMaskValid) {
        return;
      }

      const entry = {
        ...this.form,
        ip: this.form.address_type === 4 ? this.ip_v4 : this.ip_v6,
        mask: this.form.address_type === 4 ? this.mask_v4 : this.mask_v6,
      };

      if (this.$props.isAllow) {
        await this.submitAllowIP(entry);
      } else {
        await this.submitBlockIP(entry);
      }
    },
    async submitAllowIP(entry) {
      try {
        this.fetching = true;

        await this.allowIP({
          entry,
          tier: this.$props.tier,
          id: this.$props.id,
        });

        this.displayToast({
          title: this.$t("Success"),
          message: this.$t("IP has been allowed", {
            domain: this.form.domain,
          }),
          duration: 2000,
          variant: "success",
        });
        this.onCloseModal();
      } catch (_err) {
        // stub
      } finally {
        this.fetching = false;
      }
    },
    async submitBlockIP(entry) {
      try {
        this.fetching = true;

        await this.blockIP({
          entry,
          tier: this.$props.tier,
          id: this.$props.id,
        });

        this.displayToast({
          title: this.$t("Success"),
          message: this.$t("IP has been blocked", {
            name: this.form.sender,
          }),
          duration: 2000,
          variant: "success",
        });
        this.onCloseModal();
      } catch (_err) {
        // stub
      } finally {
        this.fetching = false;
      }
    },
  },
};
</script>
