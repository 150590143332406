<template>
  <cl-card class="tw-mt-4">
    <template #text>
      <div class="md:tw-flex md:tw-items-center md:tw-justify-between">
        <div class="tw-font-medium">Quick Actions</div>
        <ul class="md:tw-flex md:tw-items-center md:tw-gap-6">
          <li v-for="action in actions" :key="action.id">
            <cl-button
              size="sm"
              class="tw-flex tw-items-center tw-gap-2"
              @on-click="$emit('action-clicked', action.id)"
              :data-test-id="`quick-actions-${action.id}`"
            >
              <component :is="action.icon" class="tw-text-secondary-500" />
              {{ action.label }}
            </cl-button>
          </li>
        </ul>
      </div>
    </template>
  </cl-card>
</template>

<script>
export default {
  props: {
    actions: Array,
    dataTestId: String,
  },
};
</script>
