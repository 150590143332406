<template>
  <div class="block-allow-list-wrap">
    <t-tabs :items="tabNavItems" />
    <router-view> </router-view>
  </div>
</template>

<script>
import TTabs from "@/components/TTabs";
import { mapActions } from "vuex";

export default {
  name: "BlockAllowList",
  components: {
    TTabs,
  },
  methods: {
    ...mapActions("pageMeta", ["setPageTitle", "setPageSubtitle"]),
  },
  data() {
    return {
      tabNavItems: this.$secondaryNav.systemAdminBlockAllowList(),
    };
  },
  mounted() {
    this.setPageTitle(this.$t("Allow & Block List"));
    this.setPageSubtitle([]);
  },
};
</script>
