import { $http } from "@/plugins/api.js";
import { CURRENT_DATE } from "@/constants";

export const initialFiltersState = {
  start_date: CURRENT_DATE,
  end_date: CURRENT_DATE,
  sender: "",
  recipient: "",
  envelope_from: "",
  subject: "",
  delivery_status: null,
  id: "",
  ip: "",
  type: [],
  score_compare: "",
  score: ["", ""],
  cluster: 1,
  date_range: null,
};

const state = {
  filters: {
    ...initialFiltersState,
  },
  emails: [],
  serverTotal: 0,
  selected: null,
  counter: 0,
};

const getters = {
  emails: (state) => state.emails,
  selected: (state) => state.selected,
  serverTotal: (state) => state.serverTotal,
  counter: (state) => state.counter,
  filters: (state) => state.filters,
};

const actions = {
  async setSelected({ commit }, email) {
    commit("setSelected", email);
  },

  async fetchEmailsHistory(
    { commit, state },
    {
      tier,
      id,
      results = 10,
      page = 1,
      sortBy = "message_received",
      sortDir = "desc",
    }
  ) {
    const searchParams = new URLSearchParams();
    // Clone without any observables
    const filtersState = JSON.parse(JSON.stringify(state.filters));
    let messageTypes = [];

    for (const [key, value] of Object.entries(filtersState)) {
      // Remove empty values
      if (!value) {
        delete filtersState[key];
      }

      // Convert message types to Array of string values or remove if Array is empty
      // message_type = [{ value, text }] -> message_type = [value]
      if (key === "type") {
        messageTypes = value.map((type) => type.value);

        // Message type will be processed later on separately
        // to be used as string `type[]=value1&type[]=value2`
        delete filtersState[key];
      }

      // score_compare & score comes together, so remove "score" if no "score_compare"
      if (key === "score" && !filtersState.score_compare) {
        delete filtersState[key];
      }
    }

    // if score_compare is *, it should be removed from the request
    if (filtersState.score_compare === "*") {
      delete filtersState["score"];
      delete filtersState["score_compare"];
    }

    // If score_compare is not between, the only one value should be past
    if (
      filtersState.score_compare &&
      filtersState.score_compare !== "between"
    ) {
      const score = filtersState.score.shift();
      filtersState.score = score;
    }

    // if recipient is empty (mandatory from API)
    if (filtersState.recipient === undefined) {
      filtersState.recipient = "*@*";
    }

    if (filtersState.delivery_status === "null") {
      delete filtersState["delivery_status"];
    }

    for (const [key, value] of Object.entries(filtersState)) {
      searchParams.append(key, value);
    }

    if (sortBy === "message_type") sortBy = "type";

    let requestURL =
      tier !== null && id !== null
        ? `/restapi/${tier}/${id}/history/trace?${searchParams.toString()}`
        : `/restapi/history/trace?${searchParams.toString()}`;

    if (messageTypes.length !== 0) {
      const typesSearch = new URLSearchParams(
        messageTypes.map((type) => ["type[]", type])
      );
      requestURL += `&${typesSearch.toString()}`;
    }
    if (results) requestURL += `&results=${results}`;
    if (page) requestURL += `&page=${page}`;
    if (sortBy) requestURL += `&sortkey=${sortBy}`;
    if (sortDir) requestURL += `&sortdir=${sortDir}`;
    const response = await $http.get(requestURL);

    commit("setEmailsHistory", response.data.data);
    commit("setServerTotal", response.data.total);
    commit("setCounter", response.data.count);
  },

  clearSearch({ commit, state }, isEndUser) {
    const resetFilters = {
      ...initialFiltersState,
      recipient: isEndUser
        ? state.filters.recipient
        : initialFiltersState.recipient,
    };
    commit("setFilters", resetFilters);
  },
  clearEmailsList({ commit }) {
    commit("setEmailsHistory", []);
  },
};

const mutations = {
  setSelected: (state, data) => (state.selected = data),
  setEmailsHistory: (state, data) => (state.emails = data),
  setServerTotal: (state, data) => (state.serverTotal = data),
  setCounter: (state, data) => (state.counter = data),
  setDateRange: (state, data) => (state.filters.date_range = data),
  setStartDate: (state, data) => (state.filters.start_date = data),
  setEndDate: (state, data) => (state.filters.end_date = data),
  setSender: (state, data) => (state.filters.sender = data),
  setRecipient: (state, data) => (state.filters.recipient = data),
  setEnvelopeFrom: (state, data) => (state.filters.envelope_from = data),
  setSubject: (state, data) => (state.filters.subject = data),
  setDeliveryStatus: (state, data) => (state.filters.delivery_status = data),
  setId: (state, data) => (state.filters.id = data),
  setIp: (state, data) => (state.filters.ip = data),
  setMessageFlow: (state, data) => (state.filters.message_flow = data),
  setMessageType: (state, data) => (state.filters.type = data),
  setScoreCompare: (state, data) => (state.filters.score_compare = data),
  setScore: (state, data) => (state.filters.score = data),
  setFilters: (state, data) => (state.filters = data),
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
