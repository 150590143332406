<template>
  <div
    v-if="inheritedStatus"
    class="tw-my-4 tw-rounded tw-border-4 tw-border-warning-500 tw-p-2"
  >
    <span>
      {{
        $t(
          "Link Lock has been configured at a higher level. To customize Link Lock configuration for this organization, change any setting on this page"
        )
      }}
    </span>
  </div>
  <div class="tw-flex tw-items-center tw-justify-between">
    <p v-if="hasPermission" class="tw-w-40 tw-font-bold">
      {{ $t("Link-Lock") }}:
    </p>
    <cl-form-group v-if="hasPermission">
      <cl-spinner v-if="loading" variant="secondary" />
      <cl-form-radio-group
        v-else
        :selectedValue="status"
        @update:selected="setStatus"
        button
        :options="statusDataList"
      />
    </cl-form-group>
  </div>
  <div class="tw-p-4 tw-font-bold" v-if="!hasPermission">
    {{
      $t(
        "Your license doesn't contain link lock access. Please contact sales team to upgrade"
      )
    }}
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import checkPermission from "@/utils/check-permission";

export default {
  props: {
    id: {
      type: [String, Number],
      default: null,
    },
    tier: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      loading: true,
      status: null,
      hasPermission: false,
      statusDataList: [
        { label: this.$t("Enabled"), value: true },
        { label: this.$t("Disabled"), value: false },
      ],
    };
  },
  computed: {
    ...mapGetters("linkLock", ["linkLockStatus", "policy", "inheritedStatus"]),
    ...mapGetters("authentication", ["permissions"]),
  },
  watch: {
    linkLockStatus() {
      this.status = !!this.linkLockStatus; // transform possible null to boolean
    },
  },
  methods: {
    ...mapActions("linkLock", [
      "createLinkLockPolicy",
      "updateLinkLockPolicy",
      "fetchLinkLockPolicy",
      "updateInheritedLinkLockPolicy",
    ]),
    setStatus(emit) {
      this.status = emit;
      this.inheritedStatus ? this.createPolicy() : this.updatePolicy();
    },
    async createPolicy() {
      try {
        this.loading = true;

        await this.createLinkLockPolicy({
          tier: this.tier,
          id: this.id,
          formData: {
            enabled: this.status,
          },
        });
      } catch (_err) {
        // stub
      } finally {
        this.loading = false;
      }
    },
    async updatePolicy() {
      const formData = new FormData();
      formData.append("_method", "PATCH");
      formData.append("enabled", this.status);
      try {
        this.loading = true;

        await this.updateLinkLockPolicy({
          tier: this.tier,
          id: this.id,
          formData,
        });
      } catch (_err) {
        // stub
      } finally {
        this.loading = false;
      }
    },
    async updateInheritedPolicy() {
      try {
        this.loading = true;

        await this.updateInheritedLinkLockPolicy({
          tier: this.tier,
          id: this.id,
          formData: {
            enabled: this.status,
          },
        });
      } catch (_err) {
        // stub
      } finally {
        this.loading = false;
      }
    },
  },
  async mounted() {
    try {
      this.loading = true;
      if (this.hasPermission) {
        await this.fetchLinkLockPolicy({
          tier: this.tier,
          id: this.id,
        });
      }
    } catch (_err) {
      // stub
    } finally {
      this.loading = false;
    }
  },
  beforeMount() {
    this.hasPermission = checkPermission(this.permissions, "link-lock");
  },
  created() {
    this.status = !!this.linkLockStatus; // transform possible null to boolean
  },
};
</script>
