<template>
  <edit-Exemption v-if="selectedMsp.id" :tier="tierType" :id="selectedMsp.id" />
</template>
<script>
import { mapActions, mapGetters } from "vuex";

import EditExemption from "@/views/Common/GeoBlocking/Exemptions/Edit";

import { TIER } from "@/constants";

export default {
  name: "mspGeoblockingExemptionEdit",
  components: {
    EditExemption,
  },
  data() {
    return {
      tierType: TIER.msps,
      geoblockingId: null,
    };
  },
  methods: {
    ...mapActions("pageMeta", ["setPageTitle", "setPageSubtitle"]),
  },
  computed: {
    ...mapGetters("authentication", ["selectedMsp"]),
  },
  created() {
    this.geoblockingId = this.$route.params.id;
  },
  mounted() {
    this.setPageTitle(this.$t("Id:") + this.geoblockingId);
    this.setPageSubtitle("");
  },
};
</script>
