export const MESSAGE_TYPE_QUARANTINE = Object.freeze({
  C: "Clean",
  // M: "Clean, but Invalid Header",
  // H: "Clean, but Invalid Header",
  S: "Spam",
  V: "Virus",
  B: "Banned",
  K: "Passed with Unchecked Content",
  N: "False Negative",
  n: "False Negative",
  G: "Greylisted",
  A: "Relay Denied",
  U: "Invalid Recipient",
  D: "Sender Address Rejected: Domain Not Found",
  Q: "Sender Address Rejected: Need Fully-Qualified Address",
  O: "Blocked Invalid HELO",
  L: "Blocked using RBL",
  Z: "Blocked IP",
  X: "Blocked TLD",
  P: "DMARC Reject",
  "?": "DMARC Quarantine",
  R: "Rate Controlled: Rejected",
  r: "Rate Controlled: Deferred",
  W: "Geoblocked",
});

export default {};
