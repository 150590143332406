<template>
  <div class="tw-flex tw-items-center tw-gap-2" data-test-id="message_type">
    <div class="tw-bg tw-h-3 tw-w-3 tw-rounded-full" :class="variantClass" />
    <div class="tw-flex tw-flex-col">
      <div><slot name="messageType" /></div>
      <div><slot name="details" /></div>
    </div>
  </div>
</template>
<script setup>
import { computed } from "vue";
import { MESSAGE_TYPE } from "@/constants";

const props = defineProps({
  messageType: String,
});

const variantClass = computed(() => {
  switch (props.messageType) {
    case MESSAGE_TYPE.spam:
      return "tw-bg-info-500";
    case MESSAGE_TYPE.virus:
      return "tw-bg-danger-500";
    case MESSAGE_TYPE.clean:
      return "tw-bg-success-500";
    default:
      return "tw-bg-dorian-gray-300";
  }
});
</script>
