<template>
  <div class="patterns-page">
    <section class="patterns-list-section">
      <cl-data-table
        :data-test-id="'patterns-list-data-table'"
        :fetching="fetching"
        :headers="tableHeaders"
        :items="items"
        :total="serverTotal"
        :default-sort-by="sortByCookie"
        :default-sort-desc="sortDirCookie"
        :default-items-on-page="itemsPerPageCookie"
        with-search
        with-delete
        with-select
        with-set-columns
        with-server-side-pagination
        :searchBy="'pattern'"
        :searchPlaceholder="$t('Search')"
        @change-page="changePage"
        @search="debouncedSearch"
        @on-delete-items="onDelete"
        @sort-items="onSortItemsClick"
        @change-items-on-page="onChangeItemsOnPageClick"
        @change-selected-columns="setSelectedColumnsCookie"
      >
        <template v-slot:actions>
          <cl-dropdown
            :title="'Add'"
            data-test-id="patterns-list-data-table-add"
          >
            <cl-dropdown-item
              @on-click="onAdd('single')"
              data-test-id="patterns-list-data-table-single-pattern"
            >
              {{ $t("Single pattern") }}
            </cl-dropdown-item>
            <cl-dropdown-item
              @on-click="onAdd('multi')"
              data-test-id="patterns-list-data-table-multi-pattern"
            >
              {{ $t("Multi pattern") }}
            </cl-dropdown-item>
          </cl-dropdown>
        </template>
        <template v-slot:[`body.cell.actions`]="props">
          <span
            class="tw-cursor-pointer hover:tw-text-secondary-500"
            @click="onEdit(props.trItem)"
          >
            <cl-icon-tabler-icon-edit />
          </span>
        </template>
      </cl-data-table>
    </section>
    <component :is="activeModal" :id="id" :tier="tier" />
  </div>
</template>

<script>
import { mapActions, mapMutations, mapGetters } from "vuex";

import { TIER, DEFAULT_OPTIONS } from "@/constants";
import TAddSinglePatternFilter from "@/modals/TAddSinglePatternFilter.vue";
import TAddMultiPatternFilter from "@/modals/TAddMultiPatternFilter.vue";

import {
  getLabelByScore,
  getLableByPatternType,
  getLabelByStatus,
  getLableForMetaPattern,
} from "@/utils/pattern-filtering";
import { useDataTable } from "@/composables/useDataTable";
import { useDataTableCookies } from "@/composables/useDataTableCookies";

export default {
  name: "PatternsList",
  components: {
    "t-add-single-pattern-filter": TAddSinglePatternFilter,
    "t-add-multi-pattern-filter": TAddMultiPatternFilter,
  },
  props: {
    id: {
      type: [String, Number],
      default: null,
    },
    tier: {
      type: String,
      default: null,
    },
  },
  setup(props) {
    const cookieKey = `patterns-${props.tier || "system"}`;

    const {
      sortByCookie,
      sortDirCookie,
      itemsPerPageCookie,
      selectedColumnsCookie,
      setSortItemsCookie,
      setItemsPerPageCookie,
      setSelectedColumnsCookie,
    } = useDataTableCookies(
      {
        sortByKey: `${cookieKey}-sort-by`,
        sortDirKey: `${cookieKey}-sort-dir`,
        itemsPerPageKey: `${cookieKey}-items-per-page`,
        selectedColumnsKey: `${cookieKey}-selected-columns`,
      },
      {
        ...DEFAULT_OPTIONS,
        sortBy: "pattern",
      }
    );

    const optionsBasedOnCookies = {
      sortBy: sortByCookie,
      sortDir: sortDirCookie,
      results: itemsPerPageCookie,
      page: 1,
    };

    const {
      dataOptions,
      sortItems,
      changePage,
      changeItemsPerPage,
      debouncedSearch,
    } = useDataTable(optionsBasedOnCookies);

    return {
      dataOptions,
      debouncedSearch,
      sortItems,
      changePage,
      changeItemsPerPage,
      selectedColumnsCookie,
      setSortItemsCookie,
      setItemsPerPageCookie,
      setSelectedColumnsCookie,
      sortByCookie,
      sortDirCookie,
      itemsPerPageCookie,
    };
  },
  data() {
    return {
      fetching: false,
      currentHeaders: null,
      activeModal: "t-add-single-pattern-filter",
      items: [],
    };
  },
  computed: {
    ...mapGetters("patternFilters", ["patterns", "serverTotal"]),
    routeName() {
      const routeMap = {
        [TIER.msps]: "msp-filtering-patterns-edit",
        [TIER.domainGroups]: "customer-filtering-patterns-edit",
        [TIER.domains]: "domain-filtering-patterns-edit",
        [TIER.users]: "user-filtering-patterns-edit",
      };

      return routeMap[this.tier] || "system-filtering-patterns-edit";
    },
    tableHeaders() {
      const headers = [
        { key: "id", label: "ID", sortable: true },
        { key: "name", label: this.$t("Pattern Name"), sortable: false },
        {
          key: "pattern",
          label: this.$t("Condition & Value"),
          sortable: true,
        },
        {
          key: "patternType",
          label: this.$t("Pattern Type"),
          sortable: false,
        },
        { key: "filterType", label: this.$t("Rule Type"), sortable: false },
        { key: "active", label: this.$t("Status"), sortable: false },
        { key: "comment", label: this.$t("Description"), sortable: true },
        { key: "actions", label: this.$t("Actions"), sticky: true },
      ];

      if (!this.selectedColumnsCookie?.length) {
        return headers;
      }

      return headers.map((header) => ({
        ...header,
        enabled: this.selectedColumnsCookie.includes(header.key),
      }));
    },
  },
  watch: {
    dataOptions: {
      handler() {
        this.fetchNewPatterns();
      },
      deep: true,
      immediate: true,
    },
    patterns(patterns) {
      this.items = patterns.map(this.mapPattern);
    },
  },
  methods: {
    ...mapActions("toast", ["displayToast"]),
    ...mapActions("patternFilters", ["fetchPatterns", "batchDeletePatterns"]),
    ...mapActions("modal", ["openModal", "closeModal"]),
    ...mapMutations("patternFilters", ["deletePatterns"]),
    onSortItemsClick(by, dir) {
      this.setSortItemsCookie(by, dir);
      this.sortItems(by, dir);
    },

    onChangeItemsOnPageClick(number) {
      this.setItemsPerPageCookie(number);
      this.changeItemsPerPage(number);
    },
    async onDelete(patterns) {
      const ids = patterns.map((pattern) => Number(pattern.id));

      try {
        this.fetching = true;

        await this.batchDeletePatterns({
          tier: this.tier,
          id: this.id,
          ids,
        });

        this.deletePatterns(ids);

        this.displayToast({
          title: this.$t("Success"),
          message: this.$t("Patterns has been deleted"),
          duration: 2000,
          variant: "success",
        });
        if (this.patterns.length === 0 && this.dataOptions.page > 0) {
          this.dataOptions.page = this.dataOptions.page - 1;
        }
      } finally {
        this.fetching = false;
      }
    },
    onAdd(patternType) {
      const activeModalName =
        patternType === "single"
          ? "t-add-single-pattern-filter"
          : "t-add-multi-pattern-filter";
      this.activeModal = activeModalName;

      this.openModal();
    },
    mapPattern(patternItem) {
      const pattern = `${getLableByPatternType(patternItem.type)}: ${
        patternItem.type === "meta"
          ? getLableForMetaPattern(patternItem.pattern)
          : patternItem.pattern
      }`;
      const filterType = getLabelByScore(patternItem.score);
      const active = getLabelByStatus(patternItem.active);
      const patternType =
        patternItem.type === "meta"
          ? this.$t("Multi Pattern")
          : this.$t("Single Pattern");

      return {
        ...patternItem,
        name: patternItem.name || "---",
        pattern,
        filterType,
        patternType,
        active,
      };
    },
    async onEdit(pattern) {
      if (!this.routeName) {
        return;
      }

      this.$router.push({
        name: `${this.routeName}-${
          pattern.type === "meta" ? "multi" : "single"
        }`,
        params: {
          id: pattern.id,
        },
      });
    },
    async fetchNewPatterns() {
      try {
        this.fetching = true;

        await this.fetchPatterns({
          tier: this.tier,
          id: this.id,
          ...this.dataOptions,
        });
      } catch (_err) {
        // stub
      } finally {
        this.fetching = false;
      }
    },
  },
};
</script>
