export const PATTERN_SCORE_LABEL = Object.freeze({
  HARD_BLOCK: "Hard Block",
  HARD_ALLOW: "Hard Allow",
  SOFT_BLOCK: "Soft Block",
  SOFT_ALLOW: "Soft Allow",
  TEST_MODE: "Test Mode",
});

export const PATTERN_STATUS_LABEL = Object.freeze({
  ACTIVE: "Active",
  INACTIVE: "Inactive",
});

export const SINGLE_PATTERN_RULE_OPTIONS = Object.freeze({
  startswith: "Starts with",
  endswith: "Ends with",
  regex: "Matches regular expression",
  anyword: "Matches any word in",
  contains: "Contains",
  equals: "Equals",
});

export const MULTI_PATTERN_RULE_OPTIONS = Object.freeze({
  other: "Built-in Rule",
});

export const PATTERN_TYPES = Object.freeze({
  startswith: "Starts with",
  endswith: "Ends with",
  regex: "Matches regular expression",
  anyword: "Matches any word in",
  meta: "Matches multiple rules",
  contains: "Contains",
  equals: "Equals",
  other: "Built-in Rule",
});

export const PATTERN_CONDITION_OPTIONS = Object.freeze({
  AND: "and",
  OR: "or",
  NULL: null,
});

export default {};
