<template>
  <Teleport to="body">
    <cl-modal
      :visible="showModal"
      @on-close="closeModal"
      headerLabel="Add On Demand Report"
    >
      <form @submit.prevent="submit" autocomplete="off">
        <!-- row Timeframe -->
        <div>
          <cl-form-group class="tw-mb-0">
            <cl-form-label>
              {{ $t("Timeframe of the report to be generated") }}
            </cl-form-label>
            <cl-form-select
              autofocus
              v-model:value="form.period"
              :options="periodOptions"
            ></cl-form-select>
          </cl-form-group>
        </div>
      </form>
      <template v-slot:[`footer.decline`]>
        <cl-button
          :disabled="fetching"
          @on-click="closeModal"
          variant="link-secondary"
        >
          <span>{{ $t("Cancel") }}</span>
        </cl-button>
      </template>
      <template v-slot:[`footer.accept`]>
        <cl-button
          :variant="'secondary'"
          @on-click="submit"
          data-test-id="modal-on-demand-report-add-button"
        >
          <span v-if="!fetching">{{ this.$t("Add") }}</span>
          <cl-spinner v-else :size="'small'" />
        </cl-button>
      </template>
    </cl-modal>
  </Teleport>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import { TIER } from "@/constants";

const DEFAULT_FORM = Object.freeze({
  period: "today",
  format: "html",
});

const DOMAIN_FORM = {
  ...DEFAULT_FORM,
  type: "domsummary",
};

const CUSTOMER_FORM = {
  ...DEFAULT_FORM,
  type: "realmsummary",
};

export default {
  props: {
    id: {
      type: [String, Number],
      required: true,
    },
    tier: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      fetching: false,
      // Note. Periods reference - https://gitlab.titanhq.com/titanhq/dev/st/spamtitan-api/-/issues/397
      periodOptions: [
        { text: this.$t("All"), value: "all" },
        { text: this.$t("Today"), value: "today" },
        { text: this.$t("Yesterday"), value: "yesterday" },
        { text: this.$t("Last 7 days"), value: "last_7_days" },
      ],
      form:
        this.$props.tier === TIER.domainGroups ? CUSTOMER_FORM : DOMAIN_FORM,
    };
  },
  computed: {
    ...mapGetters("modal", ["showModal"]),
  },
  methods: {
    ...mapActions("toast", ["displayToast"]),
    ...mapActions("onDemandReports", ["createReport"]),
    ...mapActions("modal", ["closeModal"]),

    async submit() {
      try {
        this.fetching = true;

        await this.createReport({
          tier: this.$props.tier,
          id: this.$props.id,
          formData: this.form,
        });

        this.displayToast({
          title: this.$t("Success"),
          message: this.$t(
            "The report can take a few moments to show on the list."
          ),
          duration: 8000,
          variant: "success",
        });

        this.closeModal();
      } catch (_err) {
        // stub
      } finally {
        this.fetching = false;
      }
    },
  },
};
</script>
