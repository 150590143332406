import { $http } from "@/plugins/api.js";

const getDefaultState = () => {
  return {
    reports: [],
    selectedReport: null,
    serverTotal: 0,
  };
};

// initial state
const state = getDefaultState();

const getters = {
  reports: (state) => state.reports,
  selectedReport: (state) => state.selectedReport,
  serverTotal: (state) => state.serverTotal,
};

const actions = {
  clearCache({ commit }) {
    commit("resetState");
  },
  async fetchReports(
    { commit },
    {
      tier,
      id,
      results = 10,
      page = 1,
      sortBy = "created_at",
      sortDir = "desc",
      filter = null,
    }
  ) {
    let requestURL =
      tier !== null && id !== null
        ? `/restapi/${tier}/${id}/reports/archive`
        : `/restapi/reports/archive`;

    if (results) requestURL += `?results=${results}`;
    if (page) requestURL += `&page=${page}`;
    if (sortBy) requestURL += `&sortkey=${sortBy}`;
    if (sortDir) requestURL += `&sortdir=${sortDir}`;
    if (filter) requestURL += `&filter=${filter}`;

    const res = await $http.get(requestURL);

    commit("setReports", res.data.data);
    commit("setServerTotal", res.data.total);
  },

  async fetchReportHTML({ commit }, { tier, id, reportId }) {
    const url =
      tier !== null && id !== null
        ? `/restapi/${tier}/${id}/reports/archive/${reportId}`
        : `/restapi/reports/archive/${reportId}`;
    const res = await $http.get(url, {
      headers: {
        Accept: "text/html",
      },
    });

    commit("setSelectedReport", res.data);
  },

  // eslint-disable-next-line no-unused-vars
  async fetchReportCSV({ _ }, { tier, id, reportId, title }) {
    const url =
      tier !== null && id !== null
        ? `/restapi/${tier}/${id}/reports/archive/${reportId}`
        : `/restapi/reports/archive/${reportId}`;
    await $http
      .get(url, {
        headers: {
          Accept: "text/csv",
        },
        responseType: "text",
      })
      .then((response) => {
        const url = window.URL.createObjectURL(
          new Blob([response.data], { type: "text/csv" })
        );
        var a = document.createElement("a");
        a.href = url;
        a.setAttribute("id", title);
        a.download = `${title}.csv`;
        a.click();
        a.remove();
        setTimeout(() => window.URL.revokeObjectURL(url), 100);
      });
  },

  // eslint-disable-next-line no-unused-vars
  async fetchReportTXT({ _ }, { tier, id, reportId, title }) {
    const url =
      tier !== null && id !== null
        ? `/restapi/${tier}/${id}/reports/archive/${reportId}`
        : `/restapi/reports/archive/${reportId}`;
    await $http
      .get(url, {
        headers: {
          Accept: "text/plain",
        },
        responseType: "text",
      })
      .then((response) => {
        const url = window.URL.createObjectURL(
          new Blob([response.data], { type: "text/plain" })
        );
        var a = document.createElement("a");
        a.href = url;
        a.setAttribute("id", title);
        a.download = `${title}.txt`;
        a.click();
        a.remove();
        setTimeout(() => window.URL.revokeObjectURL(url), 100);
      });
  },

  // eslint-disable-next-line no-unused-vars
  async fetchReportPDF({ _ }, { tier, id, reportId, title }) {
    const url =
      tier !== null && id !== null
        ? `/restapi/${tier}/${id}/reports/archive/${reportId}`
        : `/restapi/reports/archive/${reportId}`;
    await $http
      .get(url, {
        headers: {
          Accept: "application/pdf",
        },
        responseType: "arraybuffer",
      })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        var a = document.createElement("a");
        a.href = url;
        a.setAttribute("id", title);
        a.download = `${title}.pdf`;
        a.click();
        a.remove();
        setTimeout(() => window.URL.revokeObjectURL(url), 100);
      });
  },

  async deleteReport({ commit }, { tier, id, reportId }) {
    const url =
      tier !== null && id !== null
        ? `/restapi/${tier}/${id}/reports/archive/${reportId}`
        : `/restapi/reports/archive/${reportId}`;

    await $http.delete(url);

    commit("setSelectedReport", null);
  },

  async batchDeleteReports({ commit }, { tier, id, reports }) {
    const entries = [];

    for (const report of reports) {
      const key = `${report.id}`;
      const value = {
        method: "delete",
        path:
          tier !== null && id !== null
            ? `${tier}/${id}/reports/archive/${report.id}`
            : `/reports/archive/${report.id}`,
      };

      entries.push([key, value]);
    }

    const res = await $http.post("/restapi/batch", Object.fromEntries(entries));
    const ids = Object.keys(res.data).map((item) => item);

    commit("deleteReports", ids);
  },
  clearList({ commit }) {
    commit("setReports", []);
  },
};

const mutations = {
  resetState(state) {
    Object.assign(state, getDefaultState());
  },
  setReports: (state, data) => (state.reports = data),
  setSelectedReport: (state, data) => (state.selectedReport = data),
  addReport: (state, data) => {
    state.reports.push(data);
    state.serverTotal = state.serverTotal + 1;
  },
  deleteReports(state, ids) {
    for (const id of ids) {
      state.reports = state.reports.filter(
        (report) => report.id !== Number(id)
      );
      state.serverTotal = state.serverTotal - 1;
    }
  },
  setServerTotal: (state, payload) => (state.serverTotal = payload),
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
