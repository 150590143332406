<template>
  <t-skeleton v-if="loading" />
  <cl-card class="tw-mb-4" v-else>
    <template #text>
      <div>
        <div v-html="sanitizedReport"></div>
      </div>
    </template>
  </cl-card>
  <div class="tw-flex tw-justify-end" v-if="selectedReport !== null">
    <cl-button
      @on-click="onDelete"
      type="submit"
      variant="danger"
      class="tw-mb-4"
    >
      <span v-if="!deleting"> {{ $t("Delete") }} </span>
      <span v-else><cl-spinner size="small" /></span>
    </cl-button>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import DOMPurify from "dompurify";

import { TIER } from "@/constants";
import TSkeleton from "@/components/TSkeleton";

export default {
  name: "ArchiveReportPreview",
  components: { TSkeleton },
  props: {
    id: {
      type: [String, Number],
      default: null,
    },
    tier: {
      type: String,
      default: null,
    },
    reportId: {
      type: [String, Number],
      default: null,
    },
  },
  data() {
    return {
      loading: true,
      deleting: false,
    };
  },
  computed: {
    ...mapGetters("archiveReports", ["selectedReport"]),
    sanitizedReport() {
      return DOMPurify.sanitize(this.selectedReport, {
        FORBID_TAGS: ["script"],
      });
    },
  },
  methods: {
    ...mapActions("modal", ["openModal", "closeModal"]),
    ...mapActions("pageMeta", ["setPageTitle", "setPageSubtitle"]),
    ...mapActions("toast", ["displayToast"]),
    ...mapActions("archiveReports", [
      "fetchReportHTML",
      "fetchReportPDF",
      "deleteReport",
      "clearCache",
    ]),
    redirect() {
      if (this.tier === TIER.domainGroups) {
        this.$router.push({
          name: "customer-reports-archive",
        });
      }

      if (this.tier === TIER.domains) {
        this.$router.push({
          name: "domain-reports-archive",
        });
      }
    },
    async onDelete() {
      try {
        this.deleting = true;

        await this.deleteReport({
          tier: this.tier,
          id: this.id,
          reportId: this.reportId,
        });

        this.displayToast({
          title: this.$t("Success"),
          message: this.$t("Report has been deleted", {
            reportId: this.reportId,
          }),
          duration: 2000,
          variant: "success",
        });

        this.redirect();
      } catch (_err) {
        // stub
      } finally {
        this.deleting = false;
      }
    },
  },
  async mounted() {
    try {
      await this.fetchReportHTML({
        tier: this.tier,
        id: this.id,
        reportId: this.reportId,
      });
    } catch (_err) {
      // stub
    } finally {
      this.loading = false;
    }
  },
  created() {
    this.clearCache();
    this.setPageTitle(this.$t("Preview Report: X", { id: this.reportId }));
    this.setPageSubtitle([]);
  },
};
</script>
