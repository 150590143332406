<template>
  <GeoblockingExemptionsList
    v-if="selectedUser.id"
    :tier="tierType"
    :id="selectedUser.id"
  />
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import GeoblockingExemptionsList from "@/views/Common/GeoBlocking/Exemptions/index.vue";

import { TIER } from "@/constants";

export default {
  name: "GeoblockingExemptions",
  components: {
    GeoblockingExemptionsList,
  },
  data() {
    return {
      tierType: TIER.users,
      content: {
        setPageSubtitle: this.$t(
          "Geoblocking restricts email based on a sender's geographic location. Once enabled, it can be managed at all levels. Each level can apply its own rules, with rules being inherited from a higher level by default. Exemptions exclude mail from geoblocking rules."
        ),
      },
    };
  },
  computed: {
    ...mapGetters("authentication", ["selectedUser"]),
  },
  methods: {
    ...mapActions("pageMeta", ["setPageTitle", "setPageSubtitle"]),
  },
  created() {
    this.setPageTitle(this.$t("Geoblocking"));
    this.setPageSubtitle([
      this.content.setPageSubtitle,
      this.content.setPageSubtitle_two,
    ]);
  },
};
</script>
