<template>
  <div class="emails-list-page">
    <section class="emails-list-page-section">
      <cl-data-table
        :data-test-id="'emails-list-data-table'"
        :fetching="fetching"
        :headers="tableHeaders"
        :items="items"
        :total="serverTotal"
        :default-sort-by="sortByCookie"
        :default-sort-desc="sortDirCookie"
        :default-items-on-page="itemsPerPageCookie"
        with-search
        with-delete
        with-select
        with-set-columns
        with-server-side-pagination
        :searchBy="'sender'"
        :searchPlaceholder="$t('Search')"
        @change-page="changePage"
        @search="debouncedSearch"
        @change-items-on-page="onChangeItemsOnPageClick"
        @sort-items="onSortItemsClick"
        @change-selected-columns="setSelectedColumnsCookie"
        @on-delete-items="onDelete"
      >
        <template v-slot:actions>
          <cl-dropdown :title="'Add'" data-test-id="emails-list-data-table-add">
            <cl-dropdown-item
              @on-click="onAdd('allow')"
              data-test-id="emails-list-data-table-allow-email"
            >
              {{ $t("Allow email") }}
            </cl-dropdown-item>
            <cl-dropdown-item
              @on-click="onAdd('block')"
              data-test-id="emails-list-data-table-block-email"
            >
              {{ $t("Block email") }}
            </cl-dropdown-item>
          </cl-dropdown>
        </template>
        <template v-slot:[`body.cell.actions`]="props">
          <cl-data-table-edit-action
            data-test-id="emails-list-data-table-edit-btn"
            @on-click="onEdit(props.trItem)"
          />
        </template>
        <template v-slot:[`body.cell.status`]="props">
          <cl-pill
            :variant="
              props.tdValue.toLowerCase() === 'allow' ? 'success' : 'danger'
            "
            size="sm"
          >
            {{ props.tdValue }}
          </cl-pill>
        </template>
      </cl-data-table>
    </section>
    <t-add-allow-block-email :isAllow="isAllow" :id="id" :tier="tier" />
  </div>
</template>

<script>
import dayjs from "dayjs";
import { mapActions, mapGetters } from "vuex";

import { DATE_FORMAT, TIER, DEFAULT_OPTIONS } from "@/constants";
import TAddAllowBlockEmail from "@/modals/TAddAllowBlockEmail.vue";
import { useDataTable } from "@/composables/useDataTable";
import { useDataTableCookies } from "@/composables/useDataTableCookies";

export default {
  name: "BlockAllowEmails",
  components: {
    "t-add-allow-block-email": TAddAllowBlockEmail,
  },
  setup(props) {
    const cookieKey = `allow-block-email-${props.tier || "msp"}`;
    const {
      sortByCookie,
      sortDirCookie,
      itemsPerPageCookie,
      selectedColumnsCookie,
      setSortItemsCookie,
      setItemsPerPageCookie,
      setSelectedColumnsCookie,
    } = useDataTableCookies(
      {
        sortByKey: `${cookieKey}-sort-by`,
        sortDirKey: `${cookieKey}-sort-dir`,
        itemsPerPageKey: `${cookieKey}-items-per-page`,
        selectedColumnsKey: `${cookieKey}-selected-columns`,
      },
      {
        ...DEFAULT_OPTIONS,
        sortBy: "sender",
      }
    );

    const optionsBasedOnCookies = {
      sortBy: sortByCookie,
      sortDir: sortDirCookie,
      results: itemsPerPageCookie,
      page: 1,
    };

    const {
      dataOptions,
      sortItems,
      changePage,
      changeItemsPerPage,
      debouncedSearch,
    } = useDataTable(optionsBasedOnCookies);

    return {
      dataOptions,
      debouncedSearch,
      sortItems,
      changePage,
      changeItemsPerPage,
      selectedColumnsCookie,
      setSortItemsCookie,
      setItemsPerPageCookie,
      setSelectedColumnsCookie,
      sortByCookie,
      sortDirCookie,
      itemsPerPageCookie,
    };
  },
  props: {
    id: {
      type: [String, Number],
      default: null,
    },
    tier: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      fetching: false,
      currentHeaders: null,
      isAllow: true,
      items: [],
    };
  },
  computed: {
    ...mapGetters("blockAllowEmailsList", ["emails", "serverTotal"]),
    routeName() {
      if (this.tier === TIER.msps) {
        return "msp-block-allow-list-emails-edit";
      }

      if (this.tier === TIER.domainGroups) {
        return "customer-block-allow-list-emails-edit";
      }

      if (this.tier === TIER.domains) {
        return "domain-block-allow-list-emails-edit";
      }

      if (this.tier === TIER.users) {
        return "user-block-allow-list-emails-edit";
      }

      return null;
    },
    tableHeaders() {
      const headers = [
        { key: "sender", label: this.$t("Sender Email"), sortable: true },
        { key: "status", label: this.$t("Status") },
        {
          key: "lastmodified",
          label: this.$t("Last Modified"),
          sortable: true,
        },
        { key: "comment", label: this.$t("Comments"), sortable: true },
        { key: "actions", label: this.$t("Actions"), sortable: false },
      ];

      if (!this.selectedColumnsCookie?.length) {
        return headers;
      }

      return headers.map((header) => ({
        ...header,
        enabled: this.selectedColumnsCookie.includes(header.key),
      }));
    },
  },
  watch: {
    dataOptions: {
      handler() {
        this.fetchNewEmails();
      },
      deep: true,
      immediate: true,
    },
    emails: {
      handler(newEmails) {
        this.items = newEmails.map((message) => ({
          ...message,
          lastmodified: `${dayjs(message.updated_at).format(
            DATE_FORMAT.SHORT
          )}`,
          status:
            message.type === "Block List" ? this.$t("Block") : this.$t("Allow"),
        }));
      },
      immediate: true,
    },
  },
  methods: {
    ...mapActions("blockAllowEmailsList", [
      "fetchBlockAllowEmails",
      "batchDeleteEmails",
      "clearEmailsCache",
    ]),
    ...mapActions("toast", ["displayToast"]),
    ...mapActions("modal", ["openModal", "closeModal"]),
    onSortItemsClick(by, dir) {
      this.setSortItemsCookie(by, dir);
      this.sortItems(by, dir);
    },

    onChangeItemsOnPageClick(number) {
      this.setItemsPerPageCookie(number);
      this.changeItemsPerPage(number);
    },
    async onDelete(items) {
      try {
        this.fetching = true;

        await this.batchDeleteEmails({
          emails: items,
          tier: this.tier,
          id: this.id,
        });
        this.displayToast({
          title: this.$t("Success"),
          message: this.$t("Email has been deleted"),
          duration: 2000,
          variant: "success",
        });
        if (this.emails.length === 0 && this.dataOptions.page > 0) {
          this.dataOptions.page = this.dataOptions.page - 1;
        }
      } catch (_err) {
        // stub
      } finally {
        this.fetching = false;
      }
    },
    onAdd(emailType) {
      this.isAllow = Boolean(emailType === "allow");
      this.openModal();
    },
    onEdit(email) {
      this.$router.push({
        name: this.routeName,
        params: {
          id: email.id,
        },
      });
    },
    async fetchNewEmails() {
      try {
        this.fetching = true;

        await this.fetchBlockAllowEmails({
          tier: this.tier,
          id: this.id,
          ...this.dataOptions,
        });
      } catch (_err) {
        // stub
      } finally {
        this.fetching = false;
      }
    },
  },
  created() {
    this.clearEmailsCache();
  },
};
</script>
