export const IPV6_RANGE = Object.freeze({
  FROM: 8,
  TO: 128,
});

export const IPV4_MASK_OPTIONS = [
  { text: "/32 (255.255.255.255)", value: "32" },
  { text: "/31 (255.255.255.254)", value: "31" },
  { text: "/30 (255.255.255.252)", value: "30" },
  { text: "/29 (255.255.255.248)", value: "29" },
  { text: "/28 (255.255.255.240)", value: "28" },
  { text: "/27 (255.255.255.224)", value: "27" },
  { text: "/26 (255.255.255.192)", value: "26" },
  { text: "/25 (255.255.255.128)", value: "25" },
  { text: "/24 (255.255.255.0)", value: "24" },
  { text: "/23 (255.255.254.0)", value: "23" },
  { text: "/22 (255.255.252.0)", value: "22" },
  { text: "/21 (255.255.248.0)", value: "21" },
  { text: "/20 (255.255.240.0)", value: "20" },
  { text: "/19 (255.255.224.0)", value: "19" },
  { text: "/18 (255.255.192.0)", value: "18" },
  { text: "/17 (255.255.128.0)", value: "17" },
  { text: "/16 (255.255.0.0)", value: "16" },
  { text: "/15 (255.254.0.0)", value: "15" },
  { text: "/14 (255.252.0.0)", value: "14" },
  { text: "/13 (255.248.0.0)", value: "13" },
  { text: "/12 (255.240.0.0)", value: "12" },
  { text: "/11 (255.224.0.0)", value: "11" },
  { text: "/10 (255.192.0.0)", value: "10" },
  { text: "/9 (255.128.0.0)", value: "9" },
  { text: "/8 (255.0.0.0)", value: "8" },
];

export default {};
