// Note. Types reference - https://gitlab.titanhq.com/titanhq/dev/st/spamtitan-api/-/issues/401

export const REPORT_DOMAIN_TYPE = Object.freeze({
  domsummary: "Domain Summary Report",
  spamrecipients: "Top Spam Recipients",
  virusrecipients: "Top Virus Recipients",
  mailrecipients: "Top Email Recipients (mails)",
  mailrcptsize: "Top Email Recipients (MB)",
  mailsenders: "Top Email Senders (mails)",
  mailsendersize: "Top Email Senders (MB)",
  licensereport: "License Usage Report",
  geoblocking: "Geoblocking",
  geoblocking_clients: "Geoblocking Clients",
  linklockrecip: "Top Malicious Link Recipients",
  linklocksender: "Top Malicious Link Senders",
  linklock: "Malicious Links Summary Report",
});

export const REPORT_CUSTOMER_TYPE = Object.freeze({
  realmsummary: "Customer Summary Report",
  spamrecipients: "Top Spam Recipients",
  virusrecipients: "Top Virus Recipients",
  mailrecipients: "Top Email Recipients (mails)",
  mailrcptsize: "Top Email Recipients (MB)",
  mailsenders: "Top Email Senders (mails)",
  mailsendersize: "Top Email Senders (MB)",
  domainreport: "Domain Report",
  licensereport: "License Usage Report",
  geoblocking: "Geoblocking",
  geoblocking_clients: "Geoblocking Clients",
  linklockrecip: "Top Malicious Link Recipients",
  linklocksender: "Top Malicious Link Senders",
  linklock: "Malicious Links Summary Report",
});

export default {};
