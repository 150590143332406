<template>
  <edit-Exemption
    v-if="selectedUser.id"
    :tier="tierType"
    :id="selectedUser.id"
  />
</template>
<script>
import { mapActions, mapGetters } from "vuex";

import EditExemption from "@/views/Common/GeoBlocking/Exemptions/Edit";

import { TIER } from "@/constants";

export default {
  name: "customerGeoblockingExemptionEdit",
  components: {
    EditExemption,
  },
  data() {
    return {
      tierType: TIER.users,
      exemptionId: this.$route.params.id,
    };
  },
  methods: {
    ...mapActions("pageMeta", ["setPageTitle", "setPageSubtitle"]),
  },
  computed: {
    ...mapGetters("authentication", ["selectedUser"]),
  },
  created() {
    this.setPageTitle(this.$t("Id:" + this.exemptionId));
    this.setPageSubtitle("");
  },
};
</script>
