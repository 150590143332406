<template>
  <Teleport to="body">
    <cl-modal
      :visible="showModal"
      @on-close="onCloseModal"
      headerLabel="Create A Multi Pattern Filter"
    >
      <div class="modal-wrap">
        <div class="tw-mb-3">
          <cl-heading size="h4">
            {{ $t("Pattern Details") }}
          </cl-heading>
        </div>
        <form class="modal-form" @submit.prevent="submit" autocomplete="off">
          <!-- row pattern name & description (comment) -->
          <div class="tw-mb-3 tw-grid tw-gap-4 sm:tw-grid-cols-2 sm:tw-gap-6">
            <div>
              <cl-form-group>
                <cl-form-label label-for="pattern_name">
                  {{ $t("Pattern Name") }}
                </cl-form-label>
                <cl-form-input
                  id="pattern_name"
                  name="pattern_name"
                  v-model:value="form.name"
                  :state="setInputState(v$.form.name)"
                  @on-blur="v$.form.name.$touch"
                />
                <div
                  class="tw-block tw-text-sm tw-text-danger-500"
                  v-if="v$.form.name.$error"
                >
                  {{ $t("This field is a required field") }}
                </div>
              </cl-form-group>
            </div>
            <div>
              <cl-form-group>
                <cl-form-label label-for="pattern-description-input">
                  {{ $t("Pattern Description") }}
                </cl-form-label>
                <cl-form-input
                  id="pattern-description-input"
                  name="pattern-description-input"
                  v-model:value="form.comment"
                />
              </cl-form-group>
            </div>
          </div>
          <cl-hr />
          <div class="tw-grid tw-gap-4 sm:tw-grid-cols-2 sm:tw-gap-6">
            <div>
              <cl-form-group>
                <cl-form-label label-for="filter-type">
                  {{ $t("Rule Type") }}
                </cl-form-label>
                <cl-form-select
                  id="filter-type"
                  v-model:value="form.score"
                  @change="onScoreChanged($event)"
                  :options="filterScoreOptions"
                ></cl-form-select>
              </cl-form-group>
            </div>
            <div>
              <cl-form-group>
                <cl-form-label label-for="filter-type">
                  {{ $t("Score") }}
                </cl-form-label>
                <cl-form-input
                  id="filter-soft-score"
                  type="number"
                  v-model:value="softScore"
                  step="0.001"
                  :min="softScoreMin"
                  :max="softScoreMax"
                  :state="setInputState(v$.softScore)"
                  @on-blur="v$.softScore.$touch"
                  :disabled="isSoftScoreDisabled"
                ></cl-form-input>
                <div
                  class="tw-block tw-text-sm tw-text-danger-500"
                  v-if="v$.softScore.$error && form.score === 5"
                >
                  {{
                    $t("The score should be in range between 0.001 and 99.999")
                  }}
                </div>
                <div
                  class="tw-block tw-text-sm tw-text-danger-500"
                  v-if="v$.softScore.$error && form.score === -5"
                >
                  {{
                    $t(
                      "The score should be in range between -99.999 and -0.001"
                    )
                  }}
                </div>
              </cl-form-group>
            </div>
          </div>
          <div v-if="!isSoftScoreDisabled">
            <div
              class="tw-rounded-md tw-border tw-border-info-500 tw-bg-info-100 tw-px-6 tw-py-5 tw-text-base tw-text-info-800"
              v-if="this.form.score === SOFT_ALLOW_DEFAULT"
            >
              {{
                $t(
                  "Soft Allow Subtracts a configurable negative score, between -0.001 and -99.999 (default: -5)."
                )
              }}
            </div>
            <div
              class="tw-rounded-md tw-border tw-border-info-500 tw-bg-info-100 tw-px-6 tw-py-5 tw-text-base tw-text-info-800"
              v-if="this.form.score === SOFT_BLOCK_DEFAULT"
            >
              {{
                $t(
                  "Soft Block Adds a configurable positive score, between 0.001 and 99.999 (default: 5)."
                )
              }}
            </div>
          </div>
          <cl-hr />
          <!-- Group Start -->
          <template v-for="(group, groupIndex) in groups" :key="group.id">
            <div class="tw-mb-6 tw-rounded-md tw-bg-light tw-p-3">
              <template
                v-for="(condition, conditionIndex) in group.conditions"
                :key="condition.id"
              >
                <div class="tw-mb-2 tw-grid tw-grid-cols-12 tw-gap-4">
                  <div class="tw-col-span-12 sm:tw-col-span-6 md:tw-col-span-3">
                    <cl-form-group>
                      <cl-form-label label-for="filter-status-select">
                        {{ $t("Pattern Filter") }}
                      </cl-form-label>
                      <cl-form-select
                        id="filter-status-select"
                        v-model:value="condition.type"
                        :options="ruleOptions"
                        @change="
                          onConditionChange($event, group.id, condition.id)
                        "
                        :disabled="saving"
                      ></cl-form-select>
                    </cl-form-group>
                  </div>
                  <div class="tw-col-span-12 sm:tw-col-span-6 md:tw-col-span-4">
                    <cl-form-group>
                      <cl-form-label label-for="filter-value-input">
                        {{ $t("Value") }}
                      </cl-form-label>
                      <cl-form-input
                        id="filter-value-input"
                        name="filter-value-input"
                        v-model:value="group.conditions[conditionIndex].pattern"
                        :disabled="
                          !isValueEditAllowed(condition.type) || saving
                        "
                      />
                      <div
                        class="tw-block tw-text-sm tw-text-danger-500"
                        v-if="
                          v$.groups.$each.$response.$errors[groupIndex]
                            .conditions.length
                        "
                      >
                        {{ $t("This field is a required field") }}
                      </div>
                    </cl-form-group>
                  </div>
                  <div
                    class="tw-col-span-12 sm:tw-col-span-12 md:tw-col-span-5 md:tw-mt-6"
                  >
                    <div class="tw-flex">
                      <template v-if="condition.nextSibling === null">
                        <cl-dropdown
                          :title="$t('Add Condition')"
                          :disabled="saving"
                          class="tw-flex-grow"
                        >
                          <cl-dropdown-item
                            @on-click="onAddCondition(group.id, 'and')"
                          >
                            {{ $t("and").toUpperCase() }}
                          </cl-dropdown-item>
                          <cl-dropdown-item
                            @on-click="onAddCondition(group.id, 'or')"
                          >
                            {{ $t("or").toUpperCase() }}
                          </cl-dropdown-item>
                        </cl-dropdown>
                      </template>
                      <template v-else>
                        <cl-dropdown
                          :title="$t(condition.nextSibling).toUpperCase()"
                          :disabled="saving"
                          class="tw-flex-grow"
                        >
                          <cl-dropdown-item
                            @on-click="onUpdateCondition(condition.id, 'and')"
                          >
                            {{ $t("and").toUpperCase() }}
                          </cl-dropdown-item>
                          <cl-dropdown-item
                            @on-click="onUpdateCondition(condition.id, 'or')"
                          >
                            {{ $t("or").toUpperCase() }}
                          </cl-dropdown-item>
                        </cl-dropdown>
                      </template>
                      <template v-if="isConditionDeleteAvailable">
                        <cl-button
                          @on-click="onRemoveCondition(group.id, condition.id)"
                          :disabled="saving"
                          size="small"
                        >
                          <cl-icon-tabler-trash />
                        </cl-button>
                      </template>
                    </div>
                  </div>
                </div>
              </template>
              <div class="tw-flex tw-justify-center">
                <div>
                  <template v-if="group.nextSibling === null">
                    <cl-dropdown :title="$t('Add Group')" :disabled="saving">
                      <cl-dropdown-item @on-click="onAddGroup('and')">{{
                        $t("and").toUpperCase()
                      }}</cl-dropdown-item>
                      <cl-dropdown-item @on-click="onAddGroup('or')">{{
                        $t("or").toUpperCase()
                      }}</cl-dropdown-item>
                    </cl-dropdown>
                  </template>
                  <template v-else>
                    <cl-dropdown
                      :title="$t(group.nextSibling).toUpperCase()"
                      :disabled="saving"
                    >
                      <cl-dropdown-item
                        @on-click="onUpdateGroup(group.id, 'and')"
                        >{{ $t("and").toUpperCase() }}</cl-dropdown-item
                      >
                      <cl-dropdown-item
                        @on-click="onUpdateGroup(group.id, 'or')"
                        >{{ $t("or").toUpperCase() }}</cl-dropdown-item
                      >
                    </cl-dropdown>
                  </template>
                </div>
              </div>
            </div>
          </template>
          <!-- Group Ends -->
        </form>
      </div>
      <template v-slot:[`footer.decline`]>
        <cl-button
          :disabled="fetching"
          @on-click="closeModal"
          variant="link-secondary"
        >
          <span>{{ $t("Cancel") }}</span>
        </cl-button>
      </template>
      <template v-slot:[`footer.accept`]>
        <cl-button
          :variant="'secondary'"
          @on-click="submit"
          data-test-id="modal-multi-pattern-create-button"
        >
          <span v-if="!fetching">{{ this.$t("Create") }}</span>
          <cl-spinner v-else :size="'small'" />
        </cl-button>
      </template>
    </cl-modal>
  </Teleport>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import {
  generateCondition,
  generateGroup,
  stringifyGroupsToPattern,
} from "@/utils/pattern-filtering";
import { MULTI_PATTERN_RULE_OPTIONS, PATTERN_SCORE_LABEL } from "@/constants";
import { useVuelidate } from "@vuelidate/core";
import { required, minLength, helpers } from "@vuelidate/validators";
import { useFocusElement } from "@/composables/useFocusElement";

const DEFAULT_FORM = Object.freeze({
  name: "",
  comment: "",
  score: 100,
  active: true,
});

export default {
  setup() {
    const { focusElementById } = useFocusElement();
    return { v$: useVuelidate(), focusElementById };
  },
  validations() {
    return {
      groups: {
        $each: helpers.forEach({
          conditions: {
            $each: helpers.forEach({
              pattern: {
                required,
              },
            }),
          },
        }),
      },
      form: {
        name: {
          minLengthValue: minLength(2),
          required,
        },
      },
      softScore: {
        required,
        matchSoftScore: (value) => {
          if (this.form.score === 5) {
            return value >= 0.0001 && value <= 99.999;
          }
          if (this.form.score === -5) {
            return value >= -99.999 && value <= -0.001;
          }
          return true;
        },
      },
    };
  },
  props: {
    id: {
      type: [String, Number],
      required: true,
    },
    tier: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      fetching: false,
      SOFT_BLOCK_DEFAULT: 5,
      SOFT_ALLOW_DEFAULT: -5,
      TYPES: {
        OTHER: "other",
        META: "meta",
      },
      softScore: 100,
      groups: [],
      saving: false,
      form: {
        ...DEFAULT_FORM,
      },
      ruleOptions: [
        {
          id: "regular-patterns",
          label: this.$t("CREATE NEW PATTERN"),
          options: Object.entries(MULTI_PATTERN_RULE_OPTIONS).map(
            ([value, text]) => ({
              value,
              text: this.$t(text),
            })
          ),
        },
      ],
      filterScoreOptions: [
        { value: 100, text: this.$t(PATTERN_SCORE_LABEL.HARD_BLOCK) },
        { value: -100, text: this.$t(PATTERN_SCORE_LABEL.HARD_ALLOW) },
        { value: 5, text: this.$t(PATTERN_SCORE_LABEL.SOFT_BLOCK) },
        { value: -5, text: this.$t(PATTERN_SCORE_LABEL.SOFT_ALLOW) },
        { value: 0, text: this.$t(PATTERN_SCORE_LABEL.TEST_MODE) },
      ],
    };
  },
  computed: {
    ...mapGetters("modal", ["showModal"]),
    ...mapGetters("patternFilters", ["patterns"]),
    softScoreMin() {
      if (this.form.score === 5) {
        return 0.001;
      }

      if (this.form.score === -5) {
        return -99.999;
      }

      return null;
    },
    softScoreMax() {
      if (this.form.score === 5) {
        return 99.999;
      }

      if (this.form.score === -5) {
        return -0.001;
      }

      return null;
    },
    isSoftScoreDisabled() {
      if (this.form.score === 5 || this.form.score === -5) {
        return false;
      }

      return true;
    },
    isConditionDeleteAvailable() {
      if (this.groups.length <= 1 && this.groups[0].conditions.length <= 1) {
        return false;
      }

      return true;
    },
  },
  watch: {
    patterns: {
      handler(patterns) {
        const patternsOptions = [];

        for (const pattern of patterns) {
          if (Number(pattern.id) === Number(this.$route.params.id)) {
            continue;
          }

          if (!pattern.active) {
            continue;
          }

          if (pattern.type === this.TYPES.META) {
            continue;
          }

          patternsOptions.push({
            value: pattern.id,
            text: pattern.name || `RULE_${pattern.id}`,
          });
        }

        this.ruleOptions[1] = {
          id: "existing-patterns",
          label: this.$t("EXISTING PATTERNS"),
          options: patternsOptions,
        };
      },
      immediate: true,
    },
    showModal: {
      handler() {
        this.focusElementById("pattern_name");
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    ...mapActions("toast", ["displayToast"]),
    ...mapActions("patternFilters", ["addPattern"]),
    ...mapActions("modal", ["closeModal"]),
    isValueEditAllowed(type) {
      return Object.keys(MULTI_PATTERN_RULE_OPTIONS).some(
        (key) => key === type
      );
    },
    onScoreChanged(score) {
      this.softScore = score;
    },
    onConditionChange(selectedValue, groupId, conditionId) {
      const groupIndex = this.groups.findIndex(({ id }) => id === groupId);
      if (groupIndex === -1) {
        return;
      }

      const conditionIndex = this.groups[groupIndex].conditions.findIndex(
        ({ id }) => id === conditionId
      );
      if (conditionIndex === -1) {
        return;
      }

      this.groups[groupIndex].conditions[conditionIndex].pattern =
        selectedValue === this.TYPES.OTHER ? "" : `RULE_${selectedValue}`;
    },
    onRemoveCondition(groupID, conditionID) {
      const group = this.groups.find((group) => group.id === groupID);

      if (!group) {
        return;
      }

      // Means that it needs to remove the entire group,
      // because there is only one condition in the group
      // otherwise just remove the condition
      if (group.conditions.length <= 1) {
        const groupIndex = this.groups.findIndex(
          (group) => group.id === groupID
        );

        this.groups.splice(groupIndex, 1);
        this.groups[this.groups.length - 1].nextSibling = null;
      } else {
        const conditionIndex = group.conditions.findIndex(
          (condition) => condition.id === conditionID
        );
        group.conditions.splice(conditionIndex, 1);

        const prevSibling = group.conditions[group.conditions.length - 1];
        if (prevSibling) {
          group.conditions[group.conditions.length - 1].nextSibling = null;
        }
      }
    },
    onAddCondition(groupID, siblingType) {
      const group = this.groups.find((group) => group.id === groupID);

      if (!group) {
        return;
      }

      const prevSibling = group.conditions[group.conditions.length - 1];

      if (prevSibling) {
        group.conditions[group.conditions.length - 1].nextSibling = siblingType;
      }

      group.conditions.push(generateCondition());
    },
    onUpdateCondition(conditionID, siblingType) {
      for (const group of this.groups) {
        const condition = group.conditions.find(
          (condition) => condition.id === conditionID
        );

        if (condition) {
          condition.nextSibling = siblingType;
        }
      }
    },
    onAddGroup(siblingType) {
      const prevSibling = this.groups[this.groups.length - 1];

      if (prevSibling) {
        this.groups[this.groups.length - 1].nextSibling = siblingType;
      }

      this.groups.push(generateGroup());
    },
    onUpdateGroup(groupID, siblingType) {
      const group = this.groups.find((group) => group.id === groupID);

      if (group) {
        group.nextSibling = siblingType;
      }
    },
    setInputState(input) {
      return input.$dirty ? !input.$error : null;
    },
    onCloseModal() {
      this.closeModal();
      this.form = { ...DEFAULT_FORM };
      this.v$.$reset();
    },
    async submit() {
      const isValid = await this.v$.form.$validate();
      const isGroupsValid = await this.v$.groups.$validate();

      if (!isValid || !isGroupsValid) {
        return;
      }

      try {
        this.fetching = true;
        this.saving = true;

        const formData = {
          ...this.form,
          score: this.softScore,
          type: this.TYPES.META,
          pattern: stringifyGroupsToPattern(this.groups),
          headers: [],
          apply_headers: false,
          rawbody: false,
          apply_body: false,
        };

        await this.addPattern({
          tier: this.$props.tier,
          id: this.$props.id,
          formData,
        });

        this.displayToast({
          title: this.$t("Success"),
          message: this.$t("Pattern has been created"),
          duration: 2000,
          variant: "success",
        });

        this.onCloseModal();
      } catch (_err) {
        // stub
      } finally {
        this.saving = false;
        this.fetching = false;
      }
    },
  },
  created() {
    this.groups.push(generateGroup());
  },
};
</script>
