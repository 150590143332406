<template>
  <t-skeleton v-if="loading" />
  <div class="tw-mb-4" v-else>
    <form
      class="modal-form"
      @submit.prevent
      autocomplete="off"
      @change="onChange"
      @keydown="onChange"
    >
      <!-- row name -->
      <div>
        <cl-form-group>
          <cl-form-label label-for="name-input">
            {{ nameLabel }}
          </cl-form-label>
          <cl-form-input
            id="name-input"
            name="name-input"
            v-model:value="form.name"
            size="medium"
            :state="setInputState(v$.form.name)"
            @on-blur="v$.form.name.$touch"
          />
          <span
            class="tw-block tw-pt-1 tw-text-sm tw-text-danger-500"
            v-if="v$.form.name.$error"
            >{{ $t("This field is a required field") }}</span
          >
        </cl-form-group>
      </div>
      <cl-hr />
      <!-- row inbound btn-group -->
      <div class="tw-flex tw-flex-row tw-items-center tw-justify-between">
        <span>
          {{ $t("Inbound action:") }}
        </span>
        <cl-form-radio-group
          :selectedValue="form.inbound"
          @update:selected="(selected) => (form.inbound = selected)"
          switcher
          button
          :options="options"
        />
      </div>
      <cl-hr />
      <div
        v-if="isSystemAdminLayer"
        class="tw-flex tw-flex-row tw-items-center tw-justify-between"
      >
        <span>
          {{ $t("Outbound action:") }}
        </span>
        <cl-form-radio-group
          id="outbound-switcher"
          name="outbound-switcher"
          :selectedValue="form.outbound"
          @update:selected="(selected) => (form.outbound = selected)"
          switcher
          button
          :options="options"
        />
      </div>
      <ClHr v-if="isSystemAdminLayer" />
      <!-- row scan archive & double scan -->
      <div
        v-if="!isFilterMimeType"
        class="tw-mb-3 tw-mt-2 tw-grid tw-gap-4 md:tw-grid-cols-2"
      >
        <div>
          <div class="d-flex h-100 w-100 align-items-center bg-light p-2">
            <cl-form-group class="tw-mb-0 tw-pl-2">
              <cl-checkbox
                id="scan-archive-checkbox"
                v-model:model-value="form.scan_archive"
              >
                {{ $t("Scan Archives") }}
              </cl-checkbox>
            </cl-form-group>
          </div>
        </div>
        <div v-if="!isFilterFileType && !isFilterFileName">
          <div class="d-flex h-100 w-100 align-items-center bg-light p-2">
            <cl-form-group class="tw-mb-0 tw-pl-2">
              <cl-checkbox
                id="scan-double-checkbox"
                v-model:model-value="form.scan_double"
              >
                {{ $t("Scan Double Extensions") }}
              </cl-checkbox>
            </cl-form-group>
          </div>
        </div>
      </div>
      <!-- row commit -->
      <div>
        <cl-form-group :label="$t('Comments (optional)')" label-for="comment">
          <cl-form-textarea
            v-model:value="form.comment"
            id="comment-textarea"
            rows="3"
          ></cl-form-textarea>
        </cl-form-group>
      </div>
      <!-- row submit -->
      <div class="tw-flex tw-justify-end">
        <cl-button
          type="submit"
          :disabled="!isChanged"
          @on-click="submit"
          variant="secondary"
        >
          <span v-if="!saving"> {{ $t("Save changes") }} </span>
          <span class="px-2" v-else><cl-spinner size="small" /></span>
        </cl-button>
      </div>
    </form>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { useVuelidate } from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import TSkeleton from "@/components/TSkeleton";

import { ATTACHMENT_FILTER_TYPE, TIER } from "@/constants";

export default {
  components: {
    TSkeleton,
  },
  props: {
    id: {
      type: [String, Number],
      default: null,
    },
    tier: {
      type: String,
      default: null,
    },
    filterId: {
      type: [String, Number],
      default: null,
    },
  },
  setup() {
    return { v$: useVuelidate() };
  },
  validations() {
    return {
      form: {
        name: {
          required,
        },
      },
    };
  },
  data() {
    return {
      loading: true,
      saving: false,
      isChanged: false,
      options: [
        { label: this.$t("Allow"), value: "allow" },
        { label: this.$t("Block"), value: "block" },
      ],
      form: {
        name: "",
        inbound: "block",
        outbound: "block",
        scan_archive: true,
        scan_double: true,
        comment: "",
      },
    };
  },
  computed: {
    ...mapGetters("attachmentFilters", ["currentFilter"]),
    nameLabel() {
      const { type = "" } = this.currentFilter;

      switch (type) {
        case ATTACHMENT_FILTER_TYPE.extension:
          return this.$t("Extension");
        case ATTACHMENT_FILTER_TYPE.fileName:
          return this.$t("Filename Pattern");
        case ATTACHMENT_FILTER_TYPE.fileType:
          return this.$t("File Type");
        case ATTACHMENT_FILTER_TYPE.mimeType:
          return this.$t("Mime Type");
        default:
          return "Name";
      }
    },
    isSystemAdminLayer() {
      return this.tier === null && this.id;
    },
    isFilterMimeType() {
      return this.currentFilter.type === "mimetype";
    },
    isFilterFileType() {
      return this.currentFilter.type === "filetype";
    },
    isFilterFileName() {
      return this.currentFilter.type === "filename";
    },
  },
  watch: {
    currentFilter: {
      handler(newFilter) {
        this.hidrateFormData(newFilter);
      },
      immediate: true,
    },
  },
  methods: {
    ...mapActions("toast", ["displayToast"]),
    ...mapActions("attachmentFilters", ["fetchFilter", "updateFilter"]),
    onChange() {
      this.isChanged = true;
    },
    setInputState(input) {
      return input.$dirty ? !input.$error : null;
    },
    async submit() {
      const isValid = await this.v$.form.$validate();

      if (!isValid) {
        return;
      }

      try {
        this.saving = true;

        const entries = [];

        for (const [key, value] of Object.entries(this.form)) {
          if (key === "outbound" && !this.isSystemAdminLayer) {
            continue;
          }

          if (
            this.isFilterMimeType &&
            (key === "scan_archive" || key === "scan_double")
          ) {
            continue;
          }

          if (
            (this.isFilterFileType || this.isFilterFileName) &&
            key === "scan_double"
          ) {
            continue; // Skip adding the "scan_double" keys to entries[]
          }

          entries.push([`${key}`, value]);
        }

        await this.updateFilter({
          tier: this.tier,
          id: this.id,
          filterId: this.filterId,
          formData: Object.fromEntries(entries),
        });

        this.displayToast({
          title: this.$t("Success"),
          message: this.$t("The filter has been updated"),
          duration: 2000,
          variant: "success",
        });
        if (this.tier === TIER.domainGroups) {
          this.$router.push({
            name: `customer-filtering-attachments-${this.currentFilter.type}`,
          });
        }
        if (this.tier === TIER.domains) {
          this.$router.push({
            name: `domain-filtering-attachments-${this.currentFilter.type}`,
          });
        }
      } catch (_err) {
        // stub
      } finally {
        this.saving = false;
      }
    },
    hidrateFormData(selectedFilter) {
      const {
        name = "",
        inbound = "block",
        outbound = "block",
        scan_archive = true,
        scan_double = true,
        comment = "",
      } = selectedFilter;

      this.form = {
        name,
        inbound,
        outbound,
        scan_archive,
        scan_double,
        comment,
      };
    },
  },
  async mounted() {
    try {
      this.loading = true;

      await this.fetchFilter({
        tier: this.tier,
        id: this.id,
        filterId: this.filterId,
      });
    } catch (_err) {
      // stub
    } finally {
      this.loading = false;
    }
  },
};
</script>
