import { CURRENT_DATE } from "@/constants";
import { store } from "@/store";

export const checkDateRange = (value, storeModule) => {
  const currentDate = new Date(CURRENT_DATE);
  let daysToSubtract;

  if (value === "yesterday") {
    daysToSubtract = 1;
  } else if (value === "last7days") {
    daysToSubtract = 7;
  } else if (value === "last30days") {
    daysToSubtract = 30;
  } else if (value === "null") {
    store.commit(`${storeModule}/setStartDate`, CURRENT_DATE);
    store.commit(`${storeModule}/setEndDate`, CURRENT_DATE);
  }

  if (daysToSubtract !== undefined) {
    currentDate.setDate(currentDate.getDate() - daysToSubtract);
    const newDate = currentDate.toISOString().split("T")[0];
    store.commit(`${storeModule}/setStartDate`, newDate);
    daysToSubtract === 1
      ? store.commit(`${storeModule}/setEndDate`, newDate)
      : store.commit(`${storeModule}/setEndDate`, CURRENT_DATE);
  }

  return {
    checkDateRange,
  };
};

export default { checkDateRange };
