<template>
  <div class="tw-relative">
    <quarantine-common
      v-if="selectedMsp"
      :tier="tierType"
      :tier_id="selectedMsp.id"
      class="tw-mb-6"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import QuarantineCommon from "@/views/Common/Quarantine";

import { TIER } from "@/constants";

export default {
  components: {
    QuarantineCommon,
  },
  data() {
    return {
      tierType: TIER.msps,
    };
  },
  methods: {
    ...mapActions("pageMeta", ["setPageTitle"]),
  },
  computed: {
    ...mapGetters("authentication", ["selectedMsp"]),
  },

  async mounted() {
    this.setPageTitle(this.$t("Quarantine"));
    if (!this.selectedMsp) {
      this.$router.push("/logout");
    }
  },
};
</script>
