<template>
  <archives-list
    v-if="selectedDomain.id"
    :tier="tierType"
    :id="selectedDomain.id"
  />
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import ArchivesList from "@/views/Common/AttachmentFilters/PasswordProtectedArchive.vue";

import { TIER } from "@/constants";

export default {
  name: "PasswordProtectedArchives",
  components: {
    ArchivesList,
  },
  data() {
    return {
      tierType: TIER.domains,
    };
  },
  computed: {
    ...mapGetters("authentication", ["selectedDomain"]),
  },
  methods: {
    ...mapActions("pageMeta", ["setPageTitle", "setPageSubtitle"]),
  },
  created() {
    this.setPageTitle(this.$t("Attachments"));
    this.setPageSubtitle([]);
  },
};
</script>
