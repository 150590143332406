<template>
  <span v-if="messageSource" data-test-id="source">
    <pre class="tw-font-Primary">{{ messageSource }}</pre>
  </span>
</template>

<script>
export default {
  name: "MessageSource",
  props: {
    messageSource: {
      type: [Object, String],
      required: true,
    },
  },
};
</script>
