<template>
  <div class="content">
    <toggle-link-lock :tier="tierType" :id="selectedUser.id" class="tw-mb-6" />
    <transition name="fade" mode="out-in">
      <div v-if="linkLockStatus">
        <t-tabs :items="tabNavItems" />
        <router-view> </router-view>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import ToggleLinkLock from "@/views/Common/LinkLock/ToggleLinkLock/index.vue";
import { TIER } from "@/constants";
import TTabs from "@/components/TTabs";

export default {
  name: "LinkLockIndex",
  components: {
    ToggleLinkLock,
    TTabs,
  },
  data() {
    return {
      tierType: TIER.users,
      tabNavItems: this.$secondaryNav.toolsNavItems.linkLockUser(),
      content: {
        title: this.$t("Link Lock"),
        firstSubTitle: this.$t(
          "Link Lock removes the possibility of a user clicking on a malicious link by rewriting all links in every inbound email. If a link is malicious, the user is sees a block page indicating that there is a security risk."
        ),
      },
    };
  },
  computed: {
    ...mapGetters("authentication", ["selectedUser"]),
    ...mapGetters("linkLock", ["linkLockStatus"]),
  },
  watch: {
    linkLockStatus() {
      this.redirectToConfiguration();
    },
  },
  methods: {
    ...mapActions("pageMeta", ["setPageTitle", "setPageSubtitle"]),
    ...mapActions("linkLock", ["clearCache"]),
    redirectToConfiguration() {
      const redirectUrl = "user-link-lock-policy-configuration";
      const linkLockRoute = "user-link-lock";

      if (this.linkLockStatus && this.$route.name === linkLockRoute) {
        this.$router.push({ name: redirectUrl });
      }
    },
  },
  mounted() {
    this.redirectToConfiguration();
  },
  created() {
    this.clearCache();

    this.setPageTitle(this.content.title);
    this.setPageSubtitle([this.content.firstSubTitle]);
  },
};
</script>
