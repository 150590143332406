<template>
  <t-skeleton v-if="shouldShowSkeleton" />
  <div v-else>
    <cl-hr />
    <div
      @change="setAntispoof"
      class="tw-flex tw-flex-row tw-items-center tw-justify-between"
    >
      <span>
        {{ $t("Anti-Spoofing") }}
      </span>
      <cl-form-radio-group
        id="anti_spoofing-switcher"
        name="anti_spoofing-switcher"
        :selectedValue="anti_spoofing"
        @update:selected="(selected) => (anti_spoofing = selected)"
        switcher
        button
        :options="radioOptions"
        :class="anti_spoofing === null ? 'tw-opacity-50' : ''"
      />
    </div>
    <cl-hr />
    <div
      v-if="anti_spoofing"
      @change="setAntispoof"
      class="tw-flex tw-flex-row tw-items-center tw-justify-between"
    >
      <span>
        {{ $t("Use SPF Record for IP Validation") }}
      </span>
      <div
        v-if="items.length === 0 && !use_spf"
        class="tw-my-4 tw-rounded tw-border-4 tw-border-warning-500 tw-p-2"
      >
        <span>
          {{ $t("Please define IP or Hostnames") }}
        </span>
      </div>
      <cl-form-radio-group
        id="use_spf-switcher"
        name="use_spf-switcher"
        :selectedValue="use_spf"
        @update:selected="(selected) => (use_spf = selected)"
        switcher
        button
        :options="radioOptions"
      />
    </div>
    <cl-hr v-if="anti_spoofing" />
    <div v-if="anti_spoofing" class="IP-data-table pt-6">
      <section class="IP">
        <div class="tw-mb-2 tw-p-2">
          <cl-heading :size="'h3'">
            {{ $t("IP & Hostnames") }}
          </cl-heading>
        </div>
        <cl-data-table
          :data-test-id="'IP-data-table'"
          :fetching="fetching"
          :headers="tableHeaders"
          :items="items"
          :total="serverTotal"
          :default-sort-by="sortByCookie"
          :default-sort-desc="sortDirCookie"
          :default-items-on-page="itemsPerPageCookie"
          with-search
          with-delete
          with-select
          with-set-columns
          with-server-side-pagination
          :searchBy="'ip'"
          :searchPlaceholder="$t('Search IP & Hostnames')"
          @change-page="changePage"
          @search="debouncedSearch"
          @on-delete-items="onDelete"
          @change-items-on-page="onChangeItemsOnPageClick"
          @sort-items="onSortItemsClick"
          @change-selected-columns="setSelectedColumnsCookie"
        >
          <template v-slot:actions>
            <cl-dropdown :title="'Add'" data-test-id="IP-data-table-add">
              <cl-dropdown-item
                @on-click="onAdd('ip')"
                data-test-id="IP-data-table-ip"
              >
                {{ $t("IP") }}
              </cl-dropdown-item>
              <cl-dropdown-item
                @on-click="onAdd('hostname')"
                data-test-id="IP-data-table-hostname"
              >
                {{ $t("Hostname") }}
              </cl-dropdown-item>
            </cl-dropdown>
          </template>
          <template v-slot:[`body.cell.actions`]="props">
            <cl-data-table-edit-action
              data-test-id="IP-data-table-edit-btn"
              @on-click="onEdit(props.trItem)"
            />
          </template>
        </cl-data-table>
      </section>
    </div>
    <component :is="activeModal" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { DEFAULT_OPTIONS } from "@/constants";
import TAddIp from "@/modals/TAddIp.vue";
import TAddHostname from "@/modals/TAddHostname.vue";
import { useDataTable } from "@/composables/useDataTable";
import { useDataTableCookies } from "@/composables/useDataTableCookies";
import TSkeleton from "@/components/TSkeleton";

export default {
  name: "AntiSpoofing",
  components: {
    TAddIp,
    TAddHostname,
    TSkeleton,
  },
  setup() {
    const cookieKey = `anti-spoofing-domain`;
    const {
      sortByCookie,
      sortDirCookie,
      itemsPerPageCookie,
      selectedColumnsCookie,
      setSortItemsCookie,
      setItemsPerPageCookie,
      setSelectedColumnsCookie,
    } = useDataTableCookies(
      {
        sortByKey: `${cookieKey}-sort-by`,
        sortDirKey: `${cookieKey}-sort-dir`,
        itemsPerPageKey: `${cookieKey}-items-per-page`,
        selectedColumnsKey: `${cookieKey}-selected-columns`,
      },
      {
        ...DEFAULT_OPTIONS,
        sortBy: "id",
      }
    );

    const optionsBasedOnCookies = {
      sortBy: sortByCookie,
      sortDir: sortDirCookie,
      results: itemsPerPageCookie,
      page: 1,
    };

    const {
      dataOptions,
      sortItems,
      changePage,
      changeItemsPerPage,
      debouncedSearch,
    } = useDataTable(optionsBasedOnCookies);

    return {
      dataOptions,
      debouncedSearch,
      sortItems,
      changePage,
      changeItemsPerPage,
      selectedColumnsCookie,
      setSortItemsCookie,
      setItemsPerPageCookie,
      setSelectedColumnsCookie,
      sortByCookie,
      sortDirCookie,
      itemsPerPageCookie,
    };
  },
  data() {
    return {
      fetching: false,
      anti_spoofing: null,
      use_spf: null,
      radioOptions: [
        { label: this.$t("On"), value: true },
        { label: this.$t("Off"), value: false },
      ],
      activeModal: "t-add-ip",
      items: [],
    };
  },
  computed: {
    ...mapGetters("authentication", ["selectedDomain"]),
    ...mapGetters("antiSpoofing", ["senders", "serverTotal"]),
    tableHeaders() {
      const headers = [
        { key: "id", label: this.$t("ID"), sortable: true },
        { key: "ip", label: this.$t("IP"), sortable: false },
        { key: "hostname", label: this.$t("Hostname"), sortable: false },
        { key: "comment", label: this.$t("comments"), sortable: true },
        { key: "actions", label: this.$t("Actions"), sortable: false },
      ];

      if (!this.selectedColumnsCookie?.length) {
        return headers;
      }

      return headers.map((header) => ({
        ...header,
        enabled: this.selectedColumnsCookie.includes(header.key),
      }));
    },
    shouldShowSkeleton() {
      return this.anti_spoofing === null && this.use_spf === null;
    },
  },
  watch: {
    dataOptions: {
      handler() {
        this.fetchNewSenders();
      },
      deep: true,
      immediate: true,
    },
    senders: {
      handler() {
        this.items = this.senders;
      },
      immediate: true,
    },
  },
  methods: {
    ...mapActions("pageMeta", ["setPageTitle", "setPageSubtitle"]),
    ...mapActions("pageMeta", { setBreadcrumbDomain: "setDomain" }),
    ...mapActions("domains", ["fetchDomainDetails"]),
    ...mapActions("antiSpoofing", [
      "fetchSenders",
      "batchDeleteSenders",
      "updateSettings",
      "createSettings",
      "clearList",
    ]),
    ...mapActions("authentication", ["setDomain"]),
    ...mapActions("modal", ["openModal", "closeModal"]),
    onSortItemsClick(by, dir) {
      this.setSortItemsCookie(by, dir);
      this.sortItems(by, dir);
    },

    onChangeItemsOnPageClick(number) {
      this.setItemsPerPageCookie(number);
      this.changeItemsPerPage(number);
    },
    onAdd(sender) {
      const activeModalName = sender === "ip" ? "t-add-ip" : "t-add-hostname";
      this.activeModal = activeModalName;

      this.openModal();
    },
    async onDelete(items) {
      try {
        this.fetching = true;

        await this.batchDeleteSenders({
          senders: items,
          domainId: this.selectedDomain.id,
        });
        if (this.senders.length === 0 && this.dataOptions.page > 0) {
          this.dataOptions.page = this.dataOptions.page - 1;
        }
      } catch (_err) {
        // stub
      } finally {
        this.fetching = false;
      }
    },
    onEdit(item) {
      this.$router.push({
        name: "anti-spoofing-edit",
        params: {
          domainId: this.selectedDomain.id,
          id: item.id,
        },
      });
    },
    async setAntispoof() {
      if (
        this.selectedDomain.antispoof?.enabled === null &&
        this.selectedDomain.antispoof?.use_spf === null
      ) {
        this.createAntispoofSettings();

        return;
      }

      this.updateAntispoofSettings();
    },
    async createAntispoofSettings() {
      try {
        this.loading = true;

        const res = await this.createSettings({
          enabled: this.anti_spoofing,
          use_spf: this.use_spf,
          domainId: this.selectedDomain.id,
        });

        this.setDomain({
          ...this.selectedDomain,
          antispoof: {
            enabled: res.data.antispoof.enabled,
            use_spf: res.data.antispoof.use_spf,
          },
        });
      } catch (_err) {
        // stub
      } finally {
        this.loading = false;
      }
    },
    async updateAntispoofSettings() {
      try {
        this.loading = true;

        const res = await this.updateSettings({
          enabled: this.anti_spoofing,
          use_spf: this.use_spf,
          domainId: this.selectedDomain.id,
        });

        this.setDomain({
          ...this.selectedDomain,
          antispoof: {
            enabled: res.data.antispoof.enabled,
            use_spf: res.data.antispoof.use_spf,
          },
        });
      } catch (_err) {
        // stub
      } finally {
        this.loading = false;
      }
    },
    async getDomainDetails() {
      const domainDetails = await this.fetchDomainDetails({
        id: null,
        tier: null,
        domainId: this.selectedDomain.id,
      });

      this.setDomain(domainDetails);
      this.setBreadcrumbDomain(domainDetails.domain);

      this.anti_spoofing = domainDetails.antispoof?.enabled ?? false;
      this.use_spf = domainDetails.antispoof?.use_spf ?? false;
    },

    async fetchNewSenders() {
      try {
        this.fetching = true;
        this.clearList();
        await this.fetchSenders({
          domainId: this.selectedDomain.id,
          ...this.dataOptions,
        });
      } catch (_err) {
        // stub
      } finally {
        this.fetching = false;
      }
    },
  },
  created() {
    this.setPageTitle("");
    this.setPageTitle(this.selectedDomain.domain || this.selectedDomain.name);

    this.setPageSubtitle([]);
  },
  async mounted() {
    try {
      await this.getDomainDetails();
    } catch (_err) {
      // stub
    } finally {
      // stub
    }
  },
};
</script>
