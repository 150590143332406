<template>
  <Teleport to="body">
    <cl-modal
      :visible="showModal"
      @on-close="onCloseModal"
      headerLabel="Add Hostname"
    >
      <form @submit.prevent="submit" autocomplete="off">
        <div>
          <cl-form-group>
            <cl-form-label label-for="hostname">
              {{ $t("Hostname") }}
            </cl-form-label>
            <cl-form-input
              data-lpignore="true"
              id="hostname"
              v-model:value="form.hostname"
              :placeholder="$t('Hostname')"
              :state="setInputState(v$.form.hostname)"
              @on-blur="v$.form.hostname.$touch"
            />
            <div
              class="tw-block tw-text-sm tw-text-danger-500"
              v-if="v$.form.hostname.$error"
            >
              {{ $t("This field is a required field") }}
            </div>
          </cl-form-group>
        </div>
        <div>
          <cl-form-group>
            <cl-form-label label-for="comment">
              {{ $t("Comments (optional)") }}
            </cl-form-label>
            <cl-form-textarea
              id="comment"
              v-model:value="form.comment"
              placeholder="Enter comment here..."
              rows="3"
              max-rows="6"
            ></cl-form-textarea>
          </cl-form-group>
        </div>
      </form>

      <template v-slot:[`footer.decline`]>
        <cl-button
          :disabled="fetching"
          @on-click="closeModal"
          variant="link-secondary"
        >
          <span>{{ $t("Cancel") }}</span>
        </cl-button>
      </template>
      <template v-slot:[`footer.accept`]>
        <cl-button
          :variant="'secondary'"
          @on-click="submit"
          data-test-id="modal-hostname-add-button"
        >
          <span v-if="!fetching">{{ this.$t("Add") }}</span>
          <cl-spinner v-else :size="'small'" />
        </cl-button>
      </template>
    </cl-modal>
  </Teleport>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { useFocusElement } from "@/composables/useFocusElement";
import { useVuelidate } from "@vuelidate/core";
import { required } from "@vuelidate/validators";

const DEFAULT_FORM = Object.freeze({
  hostname: null,
  comment: null,
});

export default {
  setup() {
    const { focusElementById } = useFocusElement();
    return { v$: useVuelidate(), focusElementById };
  },
  validations() {
    return {
      form: {
        hostname: {
          required,
        },
      },
    };
  },
  data() {
    return {
      fetching: false,
      form: {
        ...DEFAULT_FORM,
      },
    };
  },
  computed: {
    ...mapGetters("authentication", ["selectedDomain"]),
    ...mapGetters("modal", ["showModal"]),
  },
  watch: {
    showModal: {
      handler() {
        this.focusElementById("hostname");
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    ...mapActions("antiSpoofing", ["createSender"]),
    ...mapActions("toast", ["displayToast"]),
    ...mapActions("modal", ["closeModal"]),
    setInputState(input) {
      return input.$dirty ? !input.$error : null;
    },
    onCloseModal() {
      this.closeModal();
      this.form = { ...DEFAULT_FORM };
      this.v$.$reset();
    },
    async submit() {
      const isValid = await this.v$.form.$validate();

      if (!isValid) {
        return;
      }

      try {
        this.fetching = true;

        await this.createSender({
          domainId: this.selectedDomain.id,
          formData: this.form,
        });

        this.displayToast({
          title: this.$t("Success"),
          message: this.$t("Created"),
          duration: 2000,
          variant: "success",
        });

        this.onCloseModal();
      } catch (_err) {
        // stub
      } finally {
        this.fetching = false;
      }
    },
  },
};
</script>
