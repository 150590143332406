<template>
  <Teleport to="body">
    <cl-modal
      :visible="showModal"
      @on-close="onCloseModal"
      headerLabel="Send Test Email"
    >
      <div class="tw-grid tw-grid-cols-3 tw-gap-2">
        <div>{{ $t("Domain:") }}</div>
        <div class="tw-col-span-2">{{ this.$props.domain }}</div>
        <div>{{ $t("Destination Server:") }}</div>
        <div class="tw-col-span-2">{{ this.$props.destination }}</div>
      </div>

      <cl-hr />

      <form @submit.prevent="submit" autocomplete="off">
        <cl-form-group>
          <cl-form-label label-for="email">
            {{ $t("Email Address") }}
          </cl-form-label>
          <cl-form-input
            data-lpignore="true"
            id="email"
            name="email"
            v-model:value="form.email"
            :placeholder="$t('Test Email')"
            :state="setInputState(v$.form.email)"
            @on-blur="v$.form.email.$touch"
          />
          <div
            class="tw-block tw-text-sm tw-text-danger-500"
            v-if="v$.form.email.$error"
          >
            {{ $t("Please enter a valid email") }}
          </div>
        </cl-form-group>
      </form>

      <template v-slot:[`footer.decline`]>
        <cl-button
          :disabled="fetching"
          @on-click="closeModal"
          variant="link-secondary"
        >
          <span>{{ $t("Cancel") }}</span>
        </cl-button>
      </template>
      <template v-slot:[`footer.accept`]>
        <cl-button
          :variant="'secondary'"
          @on-click="submit"
          data-test-id="modal-test-email-send-button"
        >
          <span v-if="!fetching">{{ this.$t("Send") }}</span>
          <cl-spinner v-else :size="'small'" />
        </cl-button>
      </template>
    </cl-modal>
  </Teleport>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { useFocusElement } from "@/composables/useFocusElement";
import { useVuelidate } from "@vuelidate/core";
import { required, email } from "@vuelidate/validators";

const DEFAULT_FORM = Object.freeze({
  email: "",
});

export default {
  setup() {
    const { focusElementById } = useFocusElement();
    return { v$: useVuelidate(), focusElementById };
  },
  validations() {
    return {
      form: {
        email: {
          email,
          required,
        },
      },
    };
  },
  props: {
    domain: {
      type: String,
      required: true,
    },
    destination: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      fetching: false,
      form: {
        email: "",
      },
    };
  },
  computed: {
    ...mapGetters("modal", ["showModal"]),
  },
  watch: {
    showModal: {
      handler() {
        this.focusElementById("email");
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    ...mapActions("toast", ["displayToast"]),
    ...mapActions("domains", ["sendTestEmail"]),
    ...mapActions("modal", ["closeModal"]),
    setInputState(input) {
      return input.$dirty ? !input.$error : null;
    },
    onCloseModal() {
      this.closeModal();
      this.form = { ...DEFAULT_FORM };
      this.v$.$reset();
    },
    async submit() {
      const isValid = await this.v$.form.$validate();

      if (!isValid) {
        return;
      }

      try {
        this.fetching = true;
        const dataValue = {
          email: this.form.email,
          domain: this.$props.domain,
        };

        await this.sendTestEmail({
          dataValue: dataValue,
        });

        this.displayToast({
          title: this.$t("Success"),
          message: this.$t("Test Email has been send"),
          duration: 2000,
          variant: "success",
        });

        this.onCloseModal();
      } catch (_err) {
        // stub
      } finally {
        this.fetching = false;
      }
    },
  },
};
</script>
