<template>
  <t-message-details v-if="messageDetails" :messageDetails="messageDetails" />
  <div v-else><cl-spinner size="small" /></div>
</template>

<script>
import { mapGetters } from "vuex";

import TMessageDetails from "@/views/Common/MessageOverview/Details";

export default {
  name: "DetailsTab",
  components: {
    TMessageDetails,
  },
  computed: {
    ...mapGetters("message", ["messageDetails"]),
  },
};
</script>
