<template>
  <Teleport to="body">
    <cl-modal
      :visible="showModal"
      @on-close="onCloseModal"
      headerLabel="Forward To:"
    >
      <form @submit.prevent="submit" autocomplete="off">
        <div>
          <cl-form-group>
            <cl-form-label label-for="forward-to">
              {{ $t("Email") }}
            </cl-form-label>
            <cl-form-input
              autofocus
              data-lpignore="true"
              id="forward-to"
              v-model:value="form.forward_to"
              :placeholder="$t('Email')"
              :state="setInputState(v$.form.forward_to)"
              @on-blur="v$.form.forward_to.$touch"
            />
            <div
              class="tw-block tw-text-sm tw-text-danger-500"
              v-if="v$.form.forward_to.$error"
            >
              {{ $t("Please enter a valid email") }}
            </div>
          </cl-form-group>
        </div>
      </form>

      <template v-slot:[`footer.decline`]>
        <cl-button
          :disabled="fetching"
          @on-click="closeModal"
          variant="link-secondary"
        >
          <span>{{ $t("Cancel") }}</span>
        </cl-button>
      </template>
      <template v-slot:[`footer.accept`]>
        <cl-button
          :variant="'secondary'"
          @on-click="submit"
          data-test-id="modal-forward-to-button"
        >
          <span v-if="!fetching">{{ this.$t("Forward") }}</span>
          <cl-spinner v-else :size="'small'" />
        </cl-button>
      </template>
    </cl-modal>
  </Teleport>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import { useVuelidate } from "@vuelidate/core";
import { required, email } from "@vuelidate/validators";

const DEFAULT_FORM = Object.freeze({
  forward_to: "",
});

export default {
  setup() {
    return { v$: useVuelidate() };
  },
  validations() {
    return {
      form: {
        forward_to: {
          required,
          email,
        },
      },
    };
  },
  props: {
    routeRedirectName: {
      type: String,
      required: true,
    },
    id: {
      type: [String, Number],
      required: true,
    },
    tier: {
      type: String,
      required: true,
    },
    secret_id: {
      type: [String, Number],
      required: true,
    },
    messageId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      fetching: false,
      form: {
        ...DEFAULT_FORM,
      },
    };
  },
  computed: {
    ...mapGetters("modal", ["showModal"]),
    ...mapGetters("message", ["host"]),
  },
  methods: {
    ...mapActions("toast", ["displayToast"]),
    ...mapActions("quarantinedMessages", [
      "releaseQuarantinedMessage",
      "clearSearch",
    ]),
    ...mapActions("modal", ["closeModal"]),
    setInputState(input) {
      return input.$dirty ? !input.$error : null;
    },
    onCloseModal() {
      this.closeModal();
      this.form = { ...DEFAULT_FORM };
      this.v$.$reset();
    },
    async submit() {
      const isValid = await this.v$.form.$validate();

      if (!isValid) {
        return;
      }
      try {
        this.fetching = true;

        await this.releaseQuarantinedMessage({
          id: this.$props.messageId,
          tier: this.$props.tier,
          tier_id: this.$props.id,
          formData: {
            secret_id: this.$props.secret_id,
            forward_to: this.form.forward_to,
          },
          host: this.host,
        });

        this.clearSearch();
        this.displayToast({
          title: this.$t("Success"),
          message: this.$t("Message released"),
          duration: 2000,
          variant: "success",
        });

        this.$router.push({ name: this.$props.routeRedirectName });

        this.onCloseModal();
      } catch (_err) {
        // stub
      } finally {
        this.fetching = false;
      }
    },
  },
};
</script>
