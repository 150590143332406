<template>
  <history-common
    v-if="selectedUser"
    :tier="tierType"
    :tier_id="selectedUser.id"
    class="tw-mb-6"
  />
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import HistoryCommon from "@/views/Common/History";

import { TIER } from "@/constants";

export default {
  components: {
    HistoryCommon,
  },
  data() {
    return {
      loading: false,
      tierType: TIER.users,
    };
  },
  methods: {
    ...mapActions("pageMeta", ["setPageTitle", "setPageSubtitle", "setUser"]),
  },
  computed: {
    ...mapGetters("authentication", ["selectedUser"]),
  },
  beforeMount() {
    this.setUser(this.selectedUser.email);
  },
  async mounted() {
    this.setPageTitle(this.$t("History"));
    this.$store.commit("emailsHistory/setRecipient", this.selectedUser.email);
    this.setPageSubtitle([
      this.$t(
        "Search and view recent messages for your domains to troubleshoot in realtime"
      ),
    ]);
    if (!this.selectedUser) {
      this.$router.push("/logout");
    }
  },
  beforeUnmount() {
    this.$store.commit("emailsHistory/setRecipient", "");
  },
};
</script>
