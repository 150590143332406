<template>
  <div class="mb-4">
    <form class="modal-form" @submit.prevent autocomplete="off">
      <div
        v-if="this.allowedList"
        class="tw-mb-4 tw-inline-flex tw-w-full tw-items-center tw-rounded-md tw-bg-warning-100 tw-p-4 tw-text-base tw-text-warning-800"
        role="alert"
      >
        {{
          $t(
            "Allowed IPs can bypass settings put in place for your security. Ensure this is a trusted IP and details are correct."
          )
        }}
      </div>
      <div>
        <cl-form-group v-if="form.address_type === 4">
          <cl-form-label label-for="ip">
            {{ $t("IP/Network") }}
          </cl-form-label>
          <cl-form-input
            autofocus
            id="ip"
            name="ip"
            v-model:value="v$.ip_v4.$model"
            :state="setInputState(v$.ip_v4.$model)"
            @on-blur="v$.ip_v4.$touch"
          />
          <div
            class="tw-block tw-text-sm tw-text-danger-500"
            v-if="v$.ip_v4.$error"
          >
            {{ $t("Please enter a valid IP/Network address") }}
          </div>
        </cl-form-group>
        <cl-form-group v-else>
          <cl-form-label label-for="ip">
            {{ $t("IP/Network") }}
          </cl-form-label>
          <cl-form-input
            autofocus
            id="ip"
            name="ip"
            v-model:value="v$.ip_v6.$model"
            :state="setInputState(v$.ip_v6.$model)"
            @on-blur="v$.ip_v6.$touch"
          />
          <div
            class="tw-block tw-text-sm tw-text-danger-500"
            v-if="v$.ip_v6.$error"
          >
            {{ $t("Please enter a valid IP/Network address") }}
          </div>
        </cl-form-group>
      </div>
      <!-- row comment -->
      <div class="tw-grid sm:tw-grid-cols-2 sm:tw-gap-4">
        <cl-form-group v-if="form.address_type === 4">
          <cl-form-label label-for="mask">
            {{ $t("Netmask") }}
          </cl-form-label>
          <cl-form-select
            name="mask"
            id="mask"
            v-model:value="v$.mask_v4.$model"
            :options="maskOptions"
            @on-blur="v$.mask_v4.$touch"
          />
          <div
            class="tw-block tw-text-sm tw-text-danger-500"
            v-if="v$.mask_v4.$error"
          >
            {{ $t("Please enter a valid netmask") }}
          </div>
        </cl-form-group>
        <cl-form-group v-else>
          <cl-form-label label-for="mask">
            {{ $t("Netmask") }}
          </cl-form-label>
          <cl-form-input
            name="mask"
            id="mask"
            v-model:value="v$.mask_v6.$model"
            :min="IPV6_RANGE.FROM"
            :max="IPV6_RANGE.TO"
            :step="1"
            type="number"
            :state="setInputState(v$.mask_v6.$model)"
            @on-blur="v$.mask_v6.$touch"
          />
          <div
            class="tw-block tw-text-sm tw-text-danger-500"
            v-if="v$.mask_v6.$error"
          >
            {{ $t("Please enter a valid netmask") }}
          </div>
        </cl-form-group>
        <cl-form-group>
          <cl-form-label label-for="address_type">
            {{ $t("Address Type") }}
          </cl-form-label>
          <cl-form-radio-group
            name="address_type"
            id="address_type"
            :selectedValue="v$.form.address_type.$model"
            @update:selected="onAddressTypeChange($event)"
            switcher
            :options="addressTypeOptions"
          />
        </cl-form-group>
      </div>
      <div>
        <cl-form-group>
          <cl-form-label label-for="comment-textarea">
            {{ $t("Comments (optional)") }}
          </cl-form-label>
          <cl-form-textarea
            id="comment-textarea"
            name="comment-textarea"
            v-model:value="v$.form.comment.$model"
            rows="3"
            max-rows="6"
          ></cl-form-textarea>
          <div
            class="tw-block tw-text-sm tw-text-danger-500"
            v-if="v$.form.comment.$error"
          >
            {{ $t("The comment must not be greater than 250 characters.") }}
          </div>
        </cl-form-group>
      </div>
      <!-- Submit -->
      <div>
        <div class="tw-flex tw-justify-end">
          <cl-button class="mr-2" @on-click="onCancel">{{
            $t("Cancel")
          }}</cl-button>
          <cl-button variant="secondary" type="submit" @on-click="submit">
            <span v-if="!saving"> {{ $t("Save") }} </span>
            <span class="px-2" v-else><cl-spinner size="small" /></span>
          </cl-button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { useVuelidate } from "@vuelidate/core";

import { IPV4_MASK_OPTIONS, IPV6_RANGE, REGEX, TIER } from "@/constants";
import { maxLength } from "@vuelidate/validators";

const ALLOW_LIST = "allowip";

export default {
  name: "EditIp",
  props: {
    id: {
      type: [String, Number],
      default: null,
    },
    tier: {
      type: String,
      default: null,
    },
    ipId: {
      type: [String, Number],
      required: true,
    },
  },
  setup() {
    return { v$: useVuelidate() };
  },
  validations() {
    return {
      form: {
        address_type: {},
        comment: {
          maxLength: maxLength(250),
        },
      },
      mask_v4: {
        matchMaskV4: (value) => REGEX.NETMASK.test(value),
      },
      mask_v6: {
        matchMaskV6: (value) => REGEX.NETMASK_V6.test(value),
      },
      ip_v4: {
        matchIpV4: (value) => REGEX.IPV4.test(value),
      },
      ip_v6: {
        matchIpV6: (value) => REGEX.IPV6.test(value),
      },
    };
  },
  data() {
    return {
      allowedList: true,
      saving: false,
      form: {
        ip: "",
        mask: "",
        address_type: "",
        comment: "",
      },
      ip_v4: "",
      ip_v6: "",
      mask_v4: "",
      mask_v6: "",
      addressTypeOptions: [
        { label: this.$t("IPv4"), value: 4 },
        { label: this.$t("IPv6"), value: 6 },
      ],
      maskOptions: IPV4_MASK_OPTIONS,
    };
  },
  computed: {
    IPV6_RANGE() {
      return IPV6_RANGE;
    },
    ...mapGetters("blockAllowIPsList", ["ips"]),
    routeName() {
      if (this.tier === TIER.msps) {
        return "msp-block-allow-list-ips";
      }

      return "system-block-allow-list-ips";
    },
  },
  methods: {
    ...mapActions("blockAllowIPsList", ["updateBlockedIP", "updateAllowedIP"]),
    ...mapActions("toast", ["displayToast"]),
    setInputState(input) {
      return input.$dirty ? !input.$error : null;
    },
    onAddressTypeChange(address_type) {
      if (address_type === 4) {
        this.mask_v4 = IPV4_MASK_OPTIONS[0].value;
      } else {
        this.mask_v6 = IPV6_RANGE.TO;
      }
      this.form.address_type = address_type;
    },
    async submit() {
      const isValid = await this.v$.form.$validate();
      const isIpValid =
        this.form.address_type === 4
          ? await this.v$.ip_v4.$validate()
          : await this.v$.ip_v6.$validate();
      const isMaskValid =
        this.form.address_type === 4
          ? await this.v$.mask_v4.$validate()
          : await this.v$.mask_v6.$validate();

      if (!isValid || !isIpValid || !isMaskValid) {
        return;
      }

      try {
        this.saving = true;

        const formData = {
          ...this.form,
          ip: this.form.address_type === 4 ? this.ip_v4 : this.ip_v6,
          mask: this.form.address_type === 4 ? this.mask_v4 : this.mask_v6,
        };

        if (this.allowedList) {
          await this.updateAllowedIP({
            formData,
            ipId: this.ipId,
            tier: this.tier,
            id: this.id,
          });
        } else {
          await this.updateBlockedIP({
            formData,
            ipId: this.ipId,
            tier: this.tier,
            id: this.id,
          });
        }

        this.displayToast({
          title: this.$t("Success"),
          message: this.$t("IP has been updated"),
          duration: 2000,
          variant: "success",
        });
      } catch (_err) {
        // stub
      } finally {
        this.saving = false;
      }
    },
    onCancel() {
      this.$router.push({
        name: this.routeName,
      });
    },
  },
  created() {
    const id = Number(this.ipId);
    const ip = this.ips.find((ip) => ip.id === id);
    const address_type = ip?.value.ipfamily;

    this.form = {
      address_type,
      comment: ip.comment || "",
    };

    this.ip_v4 = address_type === 4 ? ip.value.ip : "";
    this.ip_v6 = address_type === 6 ? ip.value.ip : "";
    this.mask_v4 = address_type === 4 ? ip.value.mask : "";
    this.mask_v6 = address_type === 6 ? ip.value.mask : "";
    this.allowedList = ip?.section === ALLOW_LIST;
  },
};
</script>
