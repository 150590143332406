<template>
  <div>
    <t-tabs :items="tabNavItems" class="tw-mt-5" />
    <div class="tw-mb-2 tw-bg-light tw-p-2">
      <router-view />
    </div>
    <actions-buttons
      :message_id="messageId"
      :tier_id="selectedCustomer.id"
      :tier="tierType"
      :selected="selected"
    ></actions-buttons>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import ActionsButtons from "@/views/Common/MessageOverview/ActionsButtons/index.vue";
import { TIER } from "@/constants";
import TTabs from "@/components/TTabs";

export default {
  name: "MessageOverviewWrap",
  components: {
    ActionsButtons,
    TTabs,
  },
  data() {
    return {
      tabNavItems: [],
      tierType: TIER.domainGroups,
      messageId: this.$route.params.id,
    };
  },
  computed: {
    ...mapGetters("emailsHistory", ["selected"]),
    ...mapGetters("authentication", ["selectedCustomer"]),
  },
  methods: {
    ...mapActions("pageMeta", ["setPageTitle", "setPageSubtitle"]),
  },
  mounted() {
    this.setPageTitle(`Message ID: ${this.messageId}`);
    this.setPageSubtitle([]);
    this.tabNavItems = this.$secondaryNav.customerHistoryMessageOverview(
      this.messageId,
      this.selected.quarantined
    );
  },
};
</script>
