<template>
  <div>
    <h1>{{ $t("Overview") }} (Msp layer)</h1>
    <router-link to="/auth/sign-in" class="nav-link">
      {{ $t("Logout") }}
    </router-link>
  </div>
</template>

<script>
export default {
  name: "mspApp",
};
</script>
