export default {
  install(app) {
    app.prototype.$timezones = [
      {
        label: "Default",
        options: [{ value: " ", text: "Use System Default" }],
      },
      {
        label: "America",
        options: [
          { value: "America/Adak", text: "Adak -10:00" },
          { value: "America/Anchorage", text: "Anchorage -9:00" },
          { value: "America/Juneau", text: "Juneau -9:00" },
          { value: "America/Nome", text: "Nome -9:00" },
          { value: "America/Yakutat", text: "Yakutat -9:00" },
          { value: "America/Dawson", text: "Dawson -8:00" },
          { value: "America/Los_Angeles", text: "Los Angeles -8:00" },
          { value: "America/Tijuana", text: "Tijuana -8:00" },
          { value: "America/Vancouver", text: "Vancouver -8:00" },
          { value: "America/Whitehorse", text: "Whitehorse -8:00" },
          { value: "America/Boise", text: "Boise -7:00" },
          { value: "America/Cambridge_Bay", text: "Cambridge Bay -7:00" },
          { value: "America/Chihuahua", text: "Chihuahua -7:00" },
          { value: "America/Dawson_Creek", text: "Dawson Creek -7:00" },
          { value: "America/Denver", text: "Denver -7:00" },
          { value: "America/Edmonton", text: "Edmonton -7:00" },
          { value: "America/Hermosillo", text: "Hermosillo -7:00" },
          { value: "America/Inuvik", text: "Inuvik -7:00" },
          { value: "America/Mazatlan", text: "Mazatlan -7:00" },
          { value: "America/Phoenix", text: "Phoenix -7:00" },
          { value: "America/Yellowknife", text: "Yellowknife -7:00" },
          { value: "America/Cancun", text: "Cancun -6:00" },
          { value: "America/Chicago", text: "Chicago -6:00" },
          { value: "America/Costa_Rica", text: "Costa Rica -6:00" },
          { value: "America/El_Salvador", text: "El Salvador -6:00" },
          { value: "America/Guatemala", text: "Guatemala -6:00" },
          { value: "America/Managua", text: "Managua -6:00" },
          { value: "America/Menominee", text: "Menominee -6:00" },
          { value: "America/Merida", text: "Merida -6:00" },
          { value: "America/Mexico_City", text: "Mexico City -6:00" },
          { value: "America/Monterrey", text: "Monterrey -6:00" },
          { value: "America/Rainy_River", text: "Rainy River -6:00" },
          { value: "America/Rankin_Inlet", text: "Rankin Inlet -6:00" },
          { value: "America/Regina", text: "Regina -6:00" },
          { value: "America/Swift_Current", text: "Swift Current -6:00" },
          { value: "America/Tegucigalpa", text: "Tegucigalpa -6:00" },
          { value: "America/Winnipeg", text: "Winnipeg -6:00" },
          { value: "America/Atikokan", text: "Atikokan -5:00" },
          { value: "America/Bogota", text: "Bogota -5:00" },
          { value: "America/Cayman", text: "Cayman -5:00" },
          { value: "America/Detroit", text: "Detroit -5:00" },
          { value: "America/Grand_Turk", text: "Grand Turk -5:00" },
          { value: "America/Guayaquil", text: "Guayaquil -5:00" },
          { value: "America/Havana", text: "Havana -5:00" },
          { value: "America/Iqaluit", text: "Iqaluit -5:00" },
          { value: "America/Jamaica", text: "Jamaica -5:00" },
          { value: "America/Lima", text: "Lima -5:00" },
          { value: "America/Nassau", text: "Nassau -5:00" },
          { value: "America/New_York", text: "New York -5:00" },
          { value: "America/Nipigon", text: "Nipigon -5:00" },
          { value: "America/Panama", text: "Panama -5:00" },
          { value: "America/Pangnirtung", text: "Pangnirtung -5:00" },
          { value: "America/Port-au-Prince", text: "Port-au-Prince -5:00" },
          { value: "America/Resolute", text: "Resolute -5:00" },
          { value: "America/Thunder_Bay", text: "Thunder Bay -5:00" },
          { value: "America/Toronto", text: "Toronto -5:00" },
          { value: "America/Caracas", text: "Caracas -4:-30" },
          { value: "America/Anguilla", text: "Anguilla -4:00" },
          { value: "America/Antigua", text: "Antigua -4:00" },
          { value: "America/Aruba", text: "Aruba -4:00" },
          { value: "America/Asuncion", text: "Asuncion -4:00" },
          { value: "America/Barbados", text: "Barbados -4:00" },
          { value: "America/Blanc-Sablon", text: "Blanc-Sablon -4:00" },
          { value: "America/Boa_Vista", text: "Boa Vista -4:00" },
          { value: "America/Campo_Grande", text: "Campo Grande -4:00" },
          { value: "America/Cuiaba", text: "Cuiaba -4:00" },
          { value: "America/Curacao", text: "Curacao -4:00" },
          { value: "America/Dominica", text: "Dominica -4:00" },
          { value: "America/Eirunepe", text: "Eirunepe -4:00" },
          { value: "America/Glace_Bay", text: "Glace Bay -4:00" },
          { value: "America/Goose_Bay", text: "Goose Bay -4:00" },
          { value: "America/Grenada", text: "Grenada -4:00" },
          { value: "America/Guadeloupe", text: "Guadeloupe -4:00" },
          { value: "America/Guyana", text: "Guyana -4:00" },
          { value: "America/Halifax", text: "Halifax -4:00" },
          { value: "America/La_Paz", text: "La Paz -4:00" },
          { value: "America/Manaus", text: "Manaus -4:00" },
          { value: "America/Marigot", text: "Marigot -4:00" },
          { value: "America/Martinique", text: "Martinique -4:00" },
          { value: "America/Moncton", text: "Moncton -4:00" },
          { value: "America/Montserrat", text: "Montserrat -4:00" },
          { value: "America/Port_of_Spain", text: "Port of Spain -4:00" },
          { value: "America/Porto_Velho", text: "Porto Velho -4:00" },
          { value: "America/Puerto_Rico", text: "Puerto Rico -4:00" },
          { value: "America/Rio_Branco", text: "Rio Branco -4:00" },
          { value: "America/Santiago", text: "Santiago -4:00" },
          { value: "America/Santo_Domingo", text: "Santo Domingo -4:00" },
          { value: "America/St_Barthelemy", text: "St Barthelemy -4:00" },
          { value: "America/St_Kitts", text: "St Kitts -4:00" },
          { value: "America/St_Lucia", text: "St Lucia -4:00" },
          { value: "America/St_Thomas", text: "St Thomas -4:00" },
          { value: "America/St_Vincent", text: "St Vincent -4:00" },
          { value: "America/Thule", text: "Thule -4:00" },
          { value: "America/Tortola", text: "Tortola -4:00" },
          { value: "America/St_Johns", text: "St Johns -3:-30" },
          { value: "America/Araguaina", text: "Araguaina -3:00" },
          { value: "America/Bahia", text: "Bahia -3:00" },
          { value: "America/Belem", text: "Belem -3:00" },
          { value: "America/Cayenne", text: "Cayenne -3:00" },
          { value: "America/Fortaleza", text: "Fortaleza -3:00" },
          { value: "America/Maceio", text: "Maceio -3:00" },
          { value: "America/Miquelon", text: "Miquelon -3:00" },
          { value: "America/Montevideo", text: "Montevideo -3:00" },
          { value: "America/Paramaribo", text: "Paramaribo -3:00" },
          { value: "America/Recife", text: "Recife -3:00" },
          { value: "America/Santarem", text: "Santarem -3:00" },
          { value: "America/Sao_Paulo", text: "Sao Paulo -3:00" },
          { value: "America/Noronha", text: "Noronha -2:00" },
          { value: "America/Scoresbysund", text: "Scoresbysund -1:00" },
          { value: "America/Danmarkshavn", text: "Danmarkshavn +0:00" },
        ],
      },
      {
        label: "Antarctica",
        options: [
          { value: "Antarctica/Palmer", text: "Palmer -4:00" },
          { value: "Antarctica/Rothera", text: "Rothera -3:00" },
          { value: "Antarctica/Syowa", text: "Syowa +3:00" },
          { value: "Antarctica/Mawson", text: "Mawson +6:00" },
          { value: "Antarctica/Vostok", text: "Vostok +6:00" },
          { value: "Antarctica/Davis", text: "Davis +7:00" },
          { value: "Antarctica/Casey", text: "Casey +8:00" },
          { value: "Antarctica/DumontDUrville", text: "DumontDUrville +10:00" },
          { value: "Antarctica/McMurdo", text: "McMurdo +12:00" },
        ],
      },
      {
        label: "Atlantic",
        options: [
          { value: "Atlantic/Bermuda", text: "Bermuda -4:00" },
          { value: "Atlantic/Stanley", text: "Stanley -4:00" },
          { value: "Atlantic/South_Georgia", text: "South Georgia -2:00" },
          { value: "Atlantic/Azores", text: "Azores -1:00" },
          { value: "Atlantic/Cape_Verde", text: "Cape Verde -1:00" },
          { value: "Atlantic/Canary", text: "Canary +0:00" },
          { value: "Atlantic/Faroe", text: "Faroe +0:00" },
          { value: "Atlantic/Madeira", text: "Madeira +0:00" },
          { value: "Atlantic/Reykjavik", text: "Reykjavik +0:00" },
          { value: "Atlantic/St_Helena", text: "St Helena +0:00" },
        ],
      },
      {
        label: "Africa",
        options: [
          { value: "Africa/Abidjan", text: "Abidjan +0:00" },
          { value: "Africa/Accra", text: "Accra +0:00" },
          { value: "Africa/Bamako", text: "Bamako +0:00" },
          { value: "Africa/Banjul", text: "Banjul +0:00" },
          { value: "Africa/Bissau", text: "Bissau +0:00" },
          { value: "Africa/Casablanca", text: "Casablanca +0:00" },
          { value: "Africa/Conakry", text: "Conakry +0:00" },
          { value: "Africa/Dakar", text: "Dakar +0:00" },
          { value: "Africa/El_Aaiun", text: "El Aaiun +0:00" },
          { value: "Africa/Freetown", text: "Freetown +0:00" },
          { value: "Africa/Lome", text: "Lome +0:00" },
          { value: "Africa/Monrovia", text: "Monrovia +0:00" },
          { value: "Africa/Nouakchott", text: "Nouakchott +0:00" },
          { value: "Africa/Ouagadougou", text: "Ouagadougou +0:00" },
          { value: "Africa/Sao_Tome", text: "Sao Tome +0:00" },
          { value: "Africa/Algiers", text: "Algiers +1:00" },
          { value: "Africa/Bangui", text: "Bangui +1:00" },
          { value: "Africa/Brazzaville", text: "Brazzaville +1:00" },
          { value: "Africa/Ceuta", text: "Ceuta +1:00" },
          { value: "Africa/Douala", text: "Douala +1:00" },
          { value: "Africa/Kinshasa", text: "Kinshasa +1:00" },
          { value: "Africa/Lagos", text: "Lagos +1:00" },
          { value: "Africa/Libreville", text: "Libreville +1:00" },
          { value: "Africa/Luanda", text: "Luanda +1:00" },
          { value: "Africa/Malabo", text: "Malabo +1:00" },
          { value: "Africa/Ndjamena", text: "Ndjamena +1:00" },
          { value: "Africa/Niamey", text: "Niamey +1:00" },
          { value: "Africa/Porto-Novo", text: "Porto-Novo +1:00" },
          { value: "Africa/Tunis", text: "Tunis +1:00" },
          { value: "Africa/Windhoek", text: "Windhoek +1:00" },
          { value: "Africa/Blantyre", text: "Blantyre +2:00" },
          { value: "Africa/Bujumbura", text: "Bujumbura +2:00" },
          { value: "Africa/Cairo", text: "Cairo +2:00" },
          { value: "Africa/Gaborone", text: "Gaborone +2:00" },
          { value: "Africa/Harare", text: "Harare +2:00" },
          { value: "Africa/Johannesburg", text: "Johannesburg +2:00" },
          { value: "Africa/Kigali", text: "Kigali +2:00" },
          { value: "Africa/Lubumbashi", text: "Lubumbashi +2:00" },
          { value: "Africa/Lusaka", text: "Lusaka +2:00" },
          { value: "Africa/Maputo", text: "Maputo +2:00" },
          { value: "Africa/Maseru", text: "Maseru +2:00" },
          { value: "Africa/Mbabane", text: "Mbabane +2:00" },
          { value: "Africa/Tripoli", text: "Tripoli +2:00" },
          { value: "Africa/Addis_Ababa", text: "Addis Ababa +3:00" },
          { value: "Africa/Asmara", text: "Asmara +3:00" },
          { value: "Africa/Dar_es_Salaam", text: "Dar es Salaam +3:00" },
          { value: "Africa/Djibouti", text: "Djibouti +3:00" },
          { value: "Africa/Kampala", text: "Kampala +3:00" },
          { value: "Africa/Khartoum", text: "Khartoum +3:00" },
          { value: "Africa/Mogadishu", text: "Mogadishu +3:00" },
          { value: "Africa/Nairobi", text: "Nairobi +3:00" },
        ],
      },
      {
        label: "Europe",
        options: [
          { value: "Europe/Dublin", text: "Dublin +0:00" },
          { value: "Europe/Guernsey", text: "Guernsey +0:00" },
          { value: "Europe/Isle_of_Man", text: "Isle of Man +0:00" },
          { value: "Europe/Jersey", text: "Jersey +0:00" },
          { value: "Europe/Lisbon", text: "Lisbon +0:00" },
          { value: "Europe/London", text: "London +0:00" },
          { value: "Europe/Amsterdam", text: "Amsterdam +1:00" },
          { value: "Europe/Andorra", text: "Andorra +1:00" },
          { value: "Europe/Belgrade", text: "Belgrade +1:00" },
          { value: "Europe/Berlin", text: "Berlin +1:00" },
          { value: "Europe/Bratislava", text: "Bratislava +1:00" },
          { value: "Europe/Brussels", text: "Brussels +1:00" },
          { value: "Europe/Budapest", text: "Budapest +1:00" },
          { value: "Europe/Copenhagen", text: "Copenhagen +1:00" },
          { value: "Europe/Gibraltar", text: "Gibraltar +1:00" },
          { value: "Europe/Ljubljana", text: "Ljubljana +1:00" },
          { value: "Europe/Luxembourg", text: "Luxembourg +1:00" },
          { value: "Europe/Madrid", text: "Madrid +1:00" },
          { value: "Europe/Malta", text: "Malta +1:00" },
          { value: "Europe/Monaco", text: "Monaco +1:00" },
          { value: "Europe/Oslo", text: "Oslo +1:00" },
          { value: "Europe/Paris", text: "Paris +1:00" },
          { value: "Europe/Podgorica", text: "Podgorica +1:00" },
          { value: "Europe/Prague", text: "Prague +1:00" },
          { value: "Europe/Rome", text: "Rome +1:00" },
          { value: "Europe/San_Marino", text: "San Marino +1:00" },
          { value: "Europe/Sarajevo", text: "Sarajevo +1:00" },
          { value: "Europe/Skopje", text: "Skopje +1:00" },
          { value: "Europe/Stockholm", text: "Stockholm +1:00" },
          { value: "Europe/Tirane", text: "Tirane +1:00" },
          { value: "Europe/Vaduz", text: "Vaduz +1:00" },
          { value: "Europe/Vatican", text: "Vatican +1:00" },
          { value: "Europe/Vienna", text: "Vienna +1:00" },
          { value: "Europe/Warsaw", text: "Warsaw +1:00" },
          { value: "Europe/Zagreb", text: "Zagreb +1:00" },
          { value: "Europe/Zurich", text: "Zurich +1:00" },
          { value: "Europe/Athens", text: "Athens +2:00" },
          { value: "Europe/Bucharest", text: "Bucharest +2:00" },
          { value: "Europe/Chisinau", text: "Chisinau +2:00" },
          { value: "Europe/Helsinki", text: "Helsinki +2:00" },
          { value: "Europe/Kaliningrad", text: "Kaliningrad +2:00" },
          { value: "Europe/Kiev", text: "Kiev +2:00" },
          { value: "Europe/Mariehamn", text: "Mariehamn +2:00" },
          { value: "Europe/Minsk", text: "Minsk +2:00" },
          { value: "Europe/Riga", text: "Riga +2:00" },
          { value: "Europe/Simferopol", text: "Simferopol +2:00" },
          { value: "Europe/Sofia", text: "Sofia +2:00" },
          { value: "Europe/Tallinn", text: "Tallinn +2:00" },
          { value: "Europe/Uzhgorod", text: "Uzhgorod +2:00" },
          { value: "Europe/Vilnius", text: "Vilnius +2:00" },
          { value: "Europe/Zaporozhye", text: "Zaporozhye +2:00" },
          { value: "Europe/Moscow", text: "Moscow +3:00" },
          { value: "Europe/Volgograd", text: "Volgograd +3:00" },
          { value: "Europe/Samara", text: "Samara +4:00" },
        ],
      },
      {
        label: "Arctic",
        options: [{ value: "Arctic/Longyearbyen", text: "Longyearbyen +1:00" }],
      },
      {
        label: "Asia",
        options: [
          { value: "Asia/Amman", text: "Amman +2:00" },
          { value: "Asia/Beirut", text: "Beirut +2:00" },
          { value: "Asia/Damascus", text: "Damascus +2:00" },
          { value: "Asia/Gaza", text: "Gaza +2:00" },
          { value: "Asia/Jerusalem", text: "Jerusalem +2:00" },
          { value: "Asia/Nicosia", text: "Nicosia +2:00" },
          { value: "Asia/Aden", text: "Aden +3:00" },
          { value: "Asia/Baghdad", text: "Baghdad +3:00" },
          { value: "Asia/Bahrain", text: "Bahrain +3:00" },
          { value: "Asia/Kuwait", text: "Kuwait +3:00" },
          { value: "Asia/Qatar", text: "Qatar +3:00" },
          { value: "Asia/Tehran", text: "Tehran +3:30" },
          { value: "Asia/Baku", text: "Baku +4:00" },
          { value: "Asia/Dubai", text: "Dubai +4:00" },
          { value: "Asia/Muscat", text: "Muscat +4:00" },
          { value: "Asia/Tbilisi", text: "Tbilisi +4:00" },
          { value: "Asia/Yerevan", text: "Yerevan +4:00" },
          { value: "Asia/Kabul", text: "Kabul +4:30" },
          { value: "Asia/Aqtau", text: "Aqtau +5:00" },
          { value: "Asia/Aqtobe", text: "Aqtobe +5:00" },
          { value: "Asia/Ashgabat", text: "Ashgabat +5:00" },
          { value: "Asia/Dushanbe", text: "Dushanbe +5:00" },
          { value: "Asia/Karachi", text: "Karachi +5:00" },
          { value: "Asia/Oral", text: "Oral +5:00" },
          { value: "Asia/Samarkand", text: "Samarkand +5:00" },
          { value: "Asia/Tashkent", text: "Tashkent +5:00" },
          { value: "Asia/Yekaterinburg", text: "Yekaterinburg +5:00" },
          { value: "Asia/Colombo", text: "Colombo +5:30" },
          { value: "Asia/Kolkata", text: "Kolkata +5:30" },
          { value: "Asia/Almaty", text: "Almaty +6:00" },
          { value: "Asia/Bishkek", text: "Bishkek +6:00" },
          { value: "Asia/Dhaka", text: "Dhaka +6:00" },
          { value: "Asia/Novosibirsk", text: "Novosibirsk +6:00" },
          { value: "Asia/Omsk", text: "Omsk +6:00" },
          { value: "Asia/Qyzylorda", text: "Qyzylorda +6:00" },
          { value: "Asia/Thimphu", text: "Thimphu +6:00" },
          { value: "Asia/Bangkok", text: "Bangkok +7:00" },
          { value: "Asia/Ho_Chi_Minh", text: "Ho Chi Minh +7:00" },
          { value: "Asia/Hovd", text: "Hovd +7:00" },
          { value: "Asia/Jakarta", text: "Jakarta +7:00" },
          { value: "Asia/Krasnoyarsk", text: "Krasnoyarsk +7:00" },
          { value: "Asia/Phnom_Penh", text: "Phnom Penh +7:00" },
          { value: "Asia/Pontianak", text: "Pontianak +7:00" },
          { value: "Asia/Vientiane", text: "Vientiane +7:00" },
          { value: "Asia/Brunei", text: "Brunei +8:00" },
          { value: "Asia/Choibalsan", text: "Choibalsan +8:00" },
          { value: "Asia/Hong_Kong", text: "Hong Kong +8:00" },
          { value: "Asia/Irkutsk", text: "Irkutsk +8:00" },
          { value: "Asia/Kuala_Lumpur", text: "Kuala Lumpur +8:00" },
          { value: "Asia/Kuching", text: "Kuching +8:00" },
          { value: "Asia/Macau", text: "Macau +8:00" },
          { value: "Asia/Makassar", text: "Makassar +8:00" },
          { value: "Asia/Manila", text: "Manila +8:00" },
          { value: "Asia/Shanghai", text: "Shanghai +8:00" },
          { value: "Asia/Singapore", text: "Singapore +8:00" },
          { value: "Asia/Taipei", text: "Taipei +8:00" },
          { value: "Asia/Ulaanbaatar", text: "Ulaanbaatar +8:00" },
          { value: "Asia/Urumqi", text: "Urumqi +8:00" },
          { value: "Asia/Dili", text: "Dili +9:00" },
          { value: "Asia/Jayapura", text: "Jayapura +9:00" },
          { value: "Asia/Pyongyang", text: "Pyongyang +9:00" },
          { value: "Asia/Seoul", text: "Seoul +9:00" },
          { value: "Asia/Tokyo", text: "Tokyo +9:00" },
          { value: "Asia/Yakutsk", text: "Yakutsk +9:00" },
          { value: "Asia/Sakhalin", text: "Sakhalin +10:00" },
          { value: "Asia/Vladivostok", text: "Vladivostok +10:00" },
          { value: "Asia/Magadan", text: "Magadan +11:00" },
          { value: "Asia/Anadyr", text: "Anadyr +12:00" },
          { value: "Asia/Kamchatka", text: "Kamchatka +12:00" },
        ],
      },
      {
        label: "Indian",
        options: [
          { value: "Indian/Antananarivo", text: "Antananarivo +3:00" },
          { value: "Indian/Comoro", text: "Comoro +3:00" },
          { value: "Indian/Mayotte", text: "Mayotte +3:00" },
          { value: "Indian/Mahe", text: "Mahe +4:00" },
          { value: "Indian/Mauritius", text: "Mauritius +4:00" },
          { value: "Indian/Reunion", text: "Reunion +4:00" },
          { value: "Indian/Kerguelen", text: "Kerguelen +5:00" },
          { value: "Indian/Maldives", text: "Maldives +5:00" },
          { value: "Indian/Chagos", text: "Chagos +6:00" },
          { value: "Indian/Cocos", text: "Cocos +6:30" },
          { value: "Indian/Christmas", text: "Christmas +7:00" },
        ],
      },
      {
        label: "Australia",
        options: [
          { value: "Australia/Perth", text: "Perth +8:00" },
          { value: "Australia/Eucla", text: "Eucla +8:45" },
          { value: "Australia/Adelaide", text: "Adelaide +9:30" },
          { value: "Australia/Broken_Hill", text: "Broken Hill +9:30" },
          { value: "Australia/Darwin", text: "Darwin +9:30" },
          { value: "Australia/Brisbane", text: "Brisbane +10:00" },
          { value: "Australia/Hobart", text: "Hobart +10:00" },
          { value: "Australia/Lindeman", text: "Lindeman +10:00" },
          { value: "Australia/Melbourne", text: "Melbourne +10:00" },
          { value: "Australia/Sydney", text: "Sydney +10:00" },
          { value: "Australia/Lord_Howe", text: "Lord Howe +10:30" },
        ],
      },
      {
        label: "Other",
        options: [{ value: "UTC", text: "UTC" }],
      },
    ];
  },
};
