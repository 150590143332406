<template class="msp-edit-wrapper">
  <t-skeleton v-if="loading" />

  <form v-else autocomplete="off" @keyup="changed">
    <div class="tw-grid tw-gap-4 sm:tw-grid-cols-2">
      <cl-form-group>
        <cl-form-label label-for="name">
          {{ $t("Name") }}
        </cl-form-label>
        <cl-form-input
          autofocus
          id="name"
          name="name"
          :state="setInputState(v$.form.name)"
          @on-blur="v$.form.name.$touch"
          :placeholder="$t('MSP Name')"
          v-model:value="form.name"
        />
        <span
          class="tw-block tw-pt-1 tw-text-sm tw-text-danger-500"
          v-if="v$.form.name.$error"
          >{{ $t("This field is a required field") }}</span
        >
      </cl-form-group>
      <cl-form-group>
        <cl-form-label label-for="description">
          {{ $t("Description (optional)") }}
        </cl-form-label>
        <cl-form-input
          id="description"
          name="description"
          :placeholder="$t('Description')"
          v-model:value="form.description"
        />
      </cl-form-group>
    </div>
    <div class="tw-mt-2 tw-flex tw-justify-end">
      <cl-button v-if="saving" variant="secondary">
        <cl-spinner :size="'small'" />
        <span>Saving...</span>
      </cl-button>
      <cl-button
        v-else
        :variant="'secondary'"
        @on-click="submit"
        :disabled="!isChanged"
      >
        <span>{{ this.$t("Save changes") }}</span>
      </cl-button>
    </div>
  </form>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { useVuelidate } from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import TSkeleton from "@/components/TSkeleton";

export default {
  name: "EditMsp",
  components: {
    TSkeleton,
  },
  setup() {
    return { v$: useVuelidate() };
  },
  validations() {
    return {
      form: {
        name: {
          required,
        },
      },
    };
  },
  data() {
    return {
      loading: false,
      isChanged: false,
      saving: false,
      form: {
        name: "",
        description: "",
      },
    };
  },
  watch: {
    selected: {
      immediate: true,
      deep: true,
      handler(msp) {
        if (!msp) {
          return;
        }
        const { name, description } = msp;

        this.form = {
          name,
          description,
        };
      },
    },
  },
  computed: {
    ...mapGetters("msps", ["selected"]),
    mspId() {
      return this.$route.params.id || null;
    },
  },
  methods: {
    ...mapActions("msps", ["fetchMsp", "updateMsp"]),
    ...mapActions("pageMeta", ["setPageTitle", "setPageSubtitle"]),
    ...mapActions("toast", ["displayToast"]),
    changed() {
      this.isChanged = true;
    },
    setInputState(input) {
      return input.$dirty ? !input.$error : null;
    },
    async submit() {
      const isValid = await this.v$.form.$validate();

      if (!isValid) {
        return;
      }
      try {
        this.saving = true;

        await this.updateMsp({
          id: this.mspId,
          formData: this.form,
        });

        this.displayToast({
          title: this.$t("Success"),
          message: this.$t("MSP details updated successfully"),
          duration: 2000,
          variant: "success",
        });

        this.isChanged = false;
      } catch (_err) {
        // stub
      } finally {
        this.saving = false;
      }
    },
  },
  async mounted() {
    this.setPageTitle("MSP");
    this.setPageSubtitle([]);
    try {
      this.loading = true;
      await this.fetchMsp({
        id: this.mspId,
      });
      this.setPageTitle(this.selected.name);
    } catch (_err) {
      // stub
    } finally {
      this.loading = false;
    }
  },
};
</script>
