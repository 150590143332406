<template>
  <div class="selected-domain-wrap">
    <t-tabs :items="tabNavItems" class="tw-mt-5" />
    <router-view />
  </div>
</template>

<script>
import TTabs from "@/components/TTabs";

export default {
  name: "SelectedDomain",
  components: {
    TTabs,
  },
  data() {
    return {
      tabNavItems: this.$secondaryNav.domainsNavItems(),
    };
  },
};
</script>
