<template>
  <div
    class="bg-no-repeat tw-flex tw-h-full tw-flex-col tw-justify-center tw-overflow-hidden tw-bg-hero tw-bg-cover tw-bg-fixed tw-bg-center"
  >
    <t-auth-header />
    <!-- Login Form -->
    <section
      class="tw-fle tw-relative tw-left-1/2 tw-top-[15%] tw-max-w-lg tw--translate-x-1/2 tw--translate-y-1/2 tw-rounded-md tw-bg-light/90 tw-p-6 tw-shadow-md"
      v-if="showLogin"
    >
      <h1
        class="tw-mb-10 tw-text-center tw-text-3xl tw-font-bold tw-text-dorian-gray-900"
      >
        {{ $t("Sign in to your account") }}
      </h1>
      <form @submit.prevent="submit" class="tw-w-full">
        <cl-form-group class="tw-mb-6">
          <cl-form-input
            id="email"
            name="email"
            v-model:value="form.email"
            :placeholder="$t('Email Address')"
            :state="setInputState(v$.form.email)"
            @on-blur="v$.form.email.$touch"
            size="large"
            @on-keyup="cleanErrorMessage"
          />
          <!-- Temporary disabled email rule validation -->
          <!-- <span class="tw-block tw-pt-1 tw-text-sm tw-text-danger-500" v-if="v$.form.email.$error">{{
            $t("This field is a required field")
          }}</span>
          <span class="tw-block tw-pt-1 tw-text-sm tw-text-danger-500" v-if="v$.form.email.$touch">{{
            $t("Please enter a valid email")
          }}</span> -->
        </cl-form-group>
        <cl-form-group class="tw-mb-4">
          <cl-form-input
            id="password"
            type="password"
            name="password"
            v-model:value="form.password"
            :placeholder="$t('Password')"
            @on-blur="v$.form.password.$touch"
            :state="setInputState(v$.form.password)"
            size="large"
            @on-keyup="cleanErrorMessage"
          />
          <span
            class="tw-block tw-pt-1 tw-text-sm tw-text-danger-500"
            v-if="v$.form.password.$error"
            >{{ $t("This field is a required field") }}</span
          >
        </cl-form-group>
        <p
          v-if="invalidUser"
          id="auth-error-message"
          class="tw-mb-2 tw-text-sm tw-text-danger-500"
        >
          {{
            $t(
              "The email and password you entered did not match our records. Please try again."
            )
          }}
        </p>
        <div class="tw-flex tw-flex-col tw-items-center">
          <div class="tw-mb-10">
            <router-link
              class="tw-text-primary-700 tw-font-bold"
              :to="{ name: 'auth-password-reset' }"
              >{{ $t("Forgot your password?") }}</router-link
            >
          </div>

          <cl-button
            variant="secondary"
            data-test-id="submit-button"
            class="tw-w-3/5 tw-justify-center"
            size="large"
            type="submit"
          >
            <span v-if="!fetching">{{ this.$t("Continue") }}</span>
            <cl-spinner v-else :size="'small'" />
          </cl-button>
        </div>
      </form>
    </section>
    <!-- Login Form End -->
    <!-- Login 2fa Form -->
    <section
      v-if="show2FA"
      class="login-2fa tw-fle tw-relative tw-left-1/2 tw-top-[15%] tw-max-w-lg tw--translate-x-1/2 tw--translate-y-1/2 tw-rounded-md tw-bg-light/90 tw-p-6 tw-shadow-md"
      id="login-2fa"
    >
      <h1
        class="tw-mb-10 tw-text-center tw-text-3xl tw-font-bold tw-text-dorian-gray-900"
      >
        {{ $t("Two-factor authentication") }}
      </h1>
      <form @submit.prevent="submit" class="tw-w-full">
        <p class="tw-mb-4 tw-text-center">
          {{
            $t(
              "To make sure it's really you, please enter the verification code generated by the authenticator app on your mobile device."
            )
          }}
        </p>
        <cl-form-group v-if="!showBackupCode">
          <div class="tw-flex tw-justify-center tw-gap-2">
            <input
              v-for="(el, index) in twoFactorInputs"
              :autofocus="index === 0"
              :id="el"
              :tabindex="index + 1"
              :key="el"
              :ref="el"
              class="tw-w-10 tw-border-dorian-gray-500 tw-bg-white"
              :maxlength="1"
              v-model="twoFactorCode[`${el}`]"
              @keydown="on2faInput"
              @paste="on2faPaste"
            />
          </div>
        </cl-form-group>
        <cl-form-group
          v-if="showBackupCode"
          class="tw-mb-4"
          label-for="backup-code"
          id="backup-2fa-code"
        >
          <div class="backup-code">
            <div class="code-block">
              <cl-form-input
                id="backup-code"
                maxlength="8"
                tabindex="1"
                autocomplete="false"
                name="password"
                v-model:value="backupCode"
                :placeholder="$t('Backup Code')"
                @on-keyup="cleanErrorMessage"
              />
            </div>
          </div>
        </cl-form-group>
        <p
          v-if="invalidUser2fa"
          class="tw-mb-2 tw-block tw-text-center tw-text-sm tw-text-danger-500"
        >
          {{ $t("The code you entered is not valid. Please try again.") }}
        </p>
        <div class="tw-text-center">
          <p>
            {{ $t("Not working?") }}
            <a
              class="tw-underline hover:tw-text-secondary-600"
              href="#"
              @click="cancel2fa"
              >{{ $t("Try again") }}</a
            >
          </p>
          <p v-if="show2FA && !showBackupCode">
            {{ $t("Or") }},
            <a
              class="tw-underline hover:tw-text-secondary-600"
              href="#"
              @click="enableBackupCode"
              >{{ this.$t("try another way to log in.") }}</a
            >
          </p>
        </div>
        <div class="tw-mt-2 tw-flex tw-justify-center">
          <cl-button
            variant="secondary"
            data-test-id="submit-button"
            class="tw-mb-2 tw-justify-center"
            type="submit"
          >
            <span v-if="!fetching">{{
              this.$t("Sign in to your account")
            }}</span>
            <cl-spinner v-else :size="'small'" />
          </cl-button>
        </div>
      </form>
    </section>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { useVuelidate } from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import { useStore } from "vuex";
import TAuthHeader from "@/components/TAuthHeader";
import { ACCOUNT_TYPE, RESPONSE_ERRORS, TIER } from "@/constants";

export default {
  name: "SignIn",
  components: {
    TAuthHeader,
  },
  setup() {
    const store = useStore();

    return {
      v$: useVuelidate(),
      authentication: store.state.authentication,
      requestToken: (user) =>
        store.dispatch("authentication/requestToken", user),
    };
  },
  validations() {
    return {
      form: {
        email: {
          required,
        },
        password: {
          required,
        },
      },
    };
  },
  data() {
    return {
      fetching: false,
      invalidUser: false,
      invalidUser2fa: false,
      showLogin: true,
      show2FA: false,
      showBackupCode: false,
      form: {
        email: null,
        password: null,
      },
      twoFactorCode: {
        code1: null,
        code2: null,
        code3: null,
        code4: null,
        code5: null,
        code6: null,
      },
      backupCode: null,
      tier: null,
    };
  },
  computed: {
    ...mapGetters("authentication", ["token", "selectedAccount"]),
    isTokenValid() {
      if (!this.token) {
        return false;
      }
      let parsedToken;

      if (this.token.access_token) {
        const splitToken = atob(this.token.access_token.split(".")[1]);
        parsedToken = JSON.parse(splitToken);
      }
      return +parsedToken.exp * 1000 > Date.now();
    },
    twoFactorInputs() {
      return new Array(6).fill(null).map((_el, index) => `code${index + 1}`);
    },
    consolidated2faCode() {
      if (this.showBackupCode) {
        return this.backupCode || "";
      }

      const codeValues = Object.values(this.twoFactorCode);

      if (codeValues.some((value) => !value)) {
        return "";
      }

      return codeValues.reduce((acc, next) => acc + next, "");
    },
  },
  methods: {
    ...mapActions("userInterface", ["fetchInterface"]),
    ...mapActions("authentication", ["requestToken", "setToken"]),
    cleanErrorMessage() {
      this.invalidUser = false;
      this.invalidUser2fa = false;
    },
    routeBasedOnRole() {
      const type = this.selectedAccount.account_type;

      if (type === ACCOUNT_TYPE.SYSTEM) {
        this.$router.push("/system");
        return;
      }

      if (type === ACCOUNT_TYPE.SALES) {
        this.$router.push("/sales");
        return;
      }

      if (type === ACCOUNT_TYPE.MSP) {
        this.$router.push("/msp");
        return;
      }

      if (type === ACCOUNT_TYPE.CUSTOMER) {
        this.$router.push("/customer");
        return;
      }

      if (type === ACCOUNT_TYPE.DOMAIN) {
        this.$router.push("/domain");
        return;
      }

      this.$router.push("/user");
    },
    on2faInput(e) {
      this.cleanErrorMessage();
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        const code = e.keyCode || e.which;

        if (code === 9) {
          return;
        }

        if (code === 8 || code === 37) {
          if (e.target.id !== "code1") {
            e.target.previousSibling.focus();
          }
        } else {
          if (e.target.id !== "code6") {
            e.target.nextSibling.focus();
          }
        }
      }, 1);
    },
    on2faPaste(ev) {
      const clip = ev.clipboardData.getData("text").trim();

      if (!/\d{6}/.test(clip)) {
        return ev.preventDefault();
      }

      const symbols = [...clip];
      this.twoFactorCode.code1 = symbols[0];
      this.twoFactorCode.code2 = symbols[1];
      this.twoFactorCode.code3 = symbols[2];
      this.twoFactorCode.code4 = symbols[3];
      this.twoFactorCode.code5 = symbols[4];
      this.twoFactorCode.code6 = symbols[5];

      this.$refs.code6[0].select();
    },
    cancel2fa() {
      this.cleanErrorMessage();

      this.showBackupCode = false;
      this.show2FA = false;
      this.showLogin = true;

      this.backupCode = null;
      this.twoFactorCode.code1 = null;
      this.twoFactorCode.code2 = null;
      this.twoFactorCode.code3 = null;
      this.twoFactorCode.code4 = null;
      this.twoFactorCode.code5 = null;
      this.twoFactorCode.code6 = null;
    },
    enableBackupCode() {
      this.showBackupCode = true;
      this.invalidUser2fa = false;
    },
    setInputState(input) {
      return input.$dirty ? !input.$error : null;
    },
    async submit() {
      const isValid = await this.v$.form.$validate();

      if (!this.show2FA && !isValid) {
        return;
      }
      try {
        this.fetching = true;

        await this.requestToken({
          username: this.form.email,
          password: this.form.password,
          code: this.consolidated2faCode,
        });

        this.routeBasedOnRole();
      } catch (err) {
        if (!err.response) return;

        if (
          err.response.status === 401 &&
          err.response.data.error.includes(RESPONSE_ERRORS.TWO_FA_ENABLED)
        ) {
          if (this.show2FA) {
            this.invalidUser2fa = true;

            return;
          } else {
            this.show2FA = true;
            this.showLogin = false;

            return;
          }
        }

        if (err.response.status === 401) {
          this.invalidUser = true;

          return;
        }
      } finally {
        this.fetching = false;
        this.setAccountType();

        if (this.selectedAccount.account_type !== ACCOUNT_TYPE.SYSTEM) {
          this.fetchInterfaceUi(); // logo and title
        }
      }
    },
    routeTryAndBuy() {
      this.$router.push("#");
    },
    setAccountType() {
      const type = this.selectedAccount.account_type_name;
      if (type === "msp") {
        this.tier = TIER.msps;
      }
      if (type === "domain-group") {
        this.tier = TIER.domainGroups;
      }
      if (type === "user") {
        this.tier = TIER.users;
      }
      if (type === "domain") {
        this.tier = TIER.domains;
      }
    },

    async fetchInterfaceUi() {
      try {
        await this.fetchInterface({
          type_id: this.selectedAccount.account_id,
          type: this.tier,
        });
      } finally {
        this.loading = false;
      }
    },
  },
  beforeMount() {
    if (this.isTokenValid) {
      this.routeBasedOnRole();
    }
  },
  created() {
    this.setToken(null);
  },
};
</script>
