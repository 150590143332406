<template>
  <Teleport to="body">
    <cl-modal
      :visible="showModal"
      @on-close="onCloseModal"
      :headerLabel="modalHeaderTitle"
    >
      <div>
        <cl-heading size="h4">
          {{ $t("Filter Details") }}
        </cl-heading>
      </div>
      <form class="modal-form" @submit.prevent="submit" autocomplete="off">
        <!-- row name -->
        <div>
          <cl-form-group>
            <cl-form-label label-for="name-input">
              {{ nameLabel }}
            </cl-form-label>
            <cl-form-input
              autofocus
              id="name-input"
              v-model:value="form.name"
              :state="setInputState(v$.form.name)"
              @on-blur="v$.form.name.$touch"
            />
            <div
              class="tw-block tw-text-sm tw-text-danger-500"
              v-if="v$.form.name.$error"
            >
              {{ $t("This field is a required field") }}
            </div>
          </cl-form-group>
        </div>
        <cl-hr />
        <!-- row inbound btn-group -->
        <div class="tw-flex tw-flex-row tw-items-center tw-justify-between">
          <span>
            {{ $t("Inbound action:") }}
          </span>
          <cl-form-radio-group
            id="inbound-switcher"
            name="inbound-switcher"
            :selectedValue="form.inbound"
            @update:selected="(selected) => (form.inbound = selected)"
            switcher
            button
            :options="options"
          />
        </div>
        <cl-hr />
        <div
          v-if="!isOutboundDisabled"
          class="tw-flex tw-flex-row tw-items-center tw-justify-between"
        >
          <span>
            {{ $t("Outbound action:") }}
          </span>
          <cl-form-radio-group
            id="outbound-switcher"
            name="outbound-switcher"
            :selectedValue="form.outbound"
            @update:selected="(selected) => (form.outbound = selected)"
            switcher
            button
            :options="options"
          />
        </div>
        <cl-hr class="divider" v-if="!isOutboundDisabled" />
        <!-- row scan archive & double scan -->
        <div class="tw-grid tw-gap-4 sm:tw-grid-cols-2">
          <div v-if="!isScanArchivesDisabled">
            <div class="tw-mb-3 tw-rounded-md tw-bg-light tw-p-2">
              <cl-checkbox
                id="scan-archive-checkbox"
                v-model:model-value="form.scan_archive"
              >
                {{ $t("Scan Archives") }}
              </cl-checkbox>
            </div>
          </div>
          <div v-if="!isScanDoubleDisabled">
            <div class="tw-mb-3 tw-rounded-md tw-bg-light tw-p-2">
              <cl-checkbox
                id="scan-double-checkbox"
                v-model:model-value="form.scan_double"
              >
                {{ $t("Scan Double Extensions") }}
              </cl-checkbox>
            </div>
          </div>
        </div>
        <!-- row submit -->
        <div>
          <cl-form-group>
            <cl-form-label label-for="comment">
              {{ $t("Comments (optional)") }}
            </cl-form-label>
            <cl-form-textarea
              v-model:value="form.comment"
              id="comment-textarea"
              rows="3"
              max-rows="6"
            ></cl-form-textarea>
          </cl-form-group>
        </div>
      </form>

      <template v-slot:[`footer.decline`]>
        <cl-button
          :disabled="fetching"
          @on-click="closeModal"
          variant="link-secondary"
        >
          <span>{{ $t("Cancel") }}</span>
        </cl-button>
      </template>
      <template v-slot:[`footer.accept`]>
        <cl-button
          :variant="'secondary'"
          @on-click="submit"
          data-test-id="modal-filter-add-button"
        >
          <span v-if="!fetching">{{ this.$t("Create") }}</span>
          <cl-spinner v-else :size="'small'" />
        </cl-button>
      </template>
    </cl-modal>
  </Teleport>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import { ATTACHMENT_FILTER_TYPE } from "@/constants";
import { useVuelidate } from "@vuelidate/core";
import { required } from "@vuelidate/validators";

const DEFAULT_FORM = Object.freeze({
  name: "",
  inbound: "block",
  outbound: "block",
  scan_archive: true,
  scan_double: true,
  comment: "",
});

export default {
  setup() {
    return { v$: useVuelidate() };
  },
  validations() {
    return {
      form: {
        name: {
          required,
        },
      },
    };
  },
  props: {
    type: {
      // @TODO: VUE3-MIGRATION + TS
      // Give more specific type:
      type: String,
      required: true,
    },
    id: {
      type: [String, Number],
      required: true,
    },
    tier: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      fetching: false,
      options: [
        { label: this.$t("Allow"), value: "allow" },
        { label: this.$t("Block"), value: "block" },
      ],
      form: {
        ...DEFAULT_FORM,
      },
    };
  },
  computed: {
    ...mapGetters("modal", ["showModal"]),
    nameLabel() {
      if (this.$props.type === ATTACHMENT_FILTER_TYPE.extension) {
        return this.$t("Extension");
      }

      if (this.$props.type === ATTACHMENT_FILTER_TYPE.fileName) {
        return this.$t("Filename Pattern");
      }

      if (this.$props.type === ATTACHMENT_FILTER_TYPE.fileType) {
        return this.$t("File Type");
      }

      if (this.$props.type === ATTACHMENT_FILTER_TYPE.mimeType) {
        return this.$t("Mime Type");
      }

      if (this.$props.type === ATTACHMENT_FILTER_TYPE.ppa) {
        return this.$t("Name");
      }

      return "";
    },
    isOutboundDisabled() {
      return this.$props.tier !== null && this.$props.id !== null;
    },
    isScanArchivesDisabled() {
      return this.$props.type === ATTACHMENT_FILTER_TYPE.mimeType;
    },
    isScanDoubleDisabled() {
      return (
        this.$props.type === ATTACHMENT_FILTER_TYPE.fileName ||
        this.$props.type === ATTACHMENT_FILTER_TYPE.fileType ||
        this.$props.type === ATTACHMENT_FILTER_TYPE.mimeType
      );
    },
    modalHeaderTitle() {
      if (this.type === ATTACHMENT_FILTER_TYPE.extension) {
        return "Create An Extension Filter";
      }
      if (this.type === ATTACHMENT_FILTER_TYPE.fileName) {
        return "Create A Filename Pattern Filter";
      }
      if (this.type === ATTACHMENT_FILTER_TYPE.fileType) {
        return "Create A File Type Filter";
      }
      if (this.type === ATTACHMENT_FILTER_TYPE.mimeType) {
        return "Create A Mime Type Filter";
      }
      return "";
    },
  },
  methods: {
    ...mapActions("toast", ["displayToast"]),
    ...mapActions("attachmentFilters", ["createFilter", "fetchFiltersList"]),
    ...mapActions("modal", ["closeModal"]),
    setInputState(input) {
      return input.$dirty ? !input.$error : null;
    },
    onCloseModal() {
      this.closeModal();
      this.form = { ...DEFAULT_FORM };
      this.v$.$reset();
    },
    async submit() {
      const isValid = await this.v$.form.$validate();

      if (!isValid) {
        return;
      }

      try {
        this.fetching = true;

        const formData = {};

        for (const [key, value] of Object.entries(this.form)) {
          if (key === "outbound" && this.isOutboundDisabled) {
            continue;
          }

          if (key === "scan_archive" && this.isScanArchivesDisabled) {
            continue;
          }

          if (key === "scan_double" && this.isScanDoubleDisabled) {
            continue;
          }

          Object.assign(formData, { [`${key}`]: value });
        }

        Object.assign(formData, { type: this.$props.type });

        await this.createFilter({
          tier: this.$props.tier,
          id: this.$props.id,
          formData,
        });

        await this.fetchFiltersList({
          tier: this.$props.tier,
          id: this.$props.id,
          type: this.$props.type,
        });

        this.displayToast({
          title: this.$t("Success"),
          message: this.$t("The filter has been added"),
          duration: 2000,
          variant: "success",
        });

        this.onCloseModal();
      } catch (_err) {
        // stub
      } finally {
        this.fetching = false;
      }
    },
  },
};
</script>
