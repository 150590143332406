<template>
  <patterns-list />
</template>

<script>
import { mapActions } from "vuex";

import PatternsList from "@/views/Common/PatternFilters/List/index.vue";

export default {
  name: "PatternsListWrapper",
  components: {
    PatternsList,
  },
  methods: {
    ...mapActions("pageMeta", ["setPageTitle", "setPageSubtitle"]),
  },
  mounted() {
    this.setPageTitle(this.$t("Pattern Filtering"));
    this.setPageSubtitle([
      this.$t(
        "Pattern filtering allows you to block or accept emails based on filter rules that can be applied to an email subject, header or body."
      ),
    ]);
  },
};
</script>
