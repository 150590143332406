<template>
  <Teleport to="body">
    <cl-modal
      :visible="showModal"
      @on-close="closeModal"
      data-test-id="quarantine-confirmation-modal"
    >
      <template v-slot:[`header.title`]>
        <slot name="title">
          <cl-heading :size="'h4'">{{ this.$t("Attention") }}</cl-heading>
        </slot>
      </template>
      <div>
        <slot name="body">
          <div class="tw-flex tw-w-full tw-justify-center">
            <span>{{
              this.$t(
                "You must not Release or Allow virus emails. Virus emails should only be Deleted"
              )
            }}</span>
          </div>
        </slot>
      </div>
      <template v-slot:[`footer.decline`]>
        <slot name="decline">
          <cl-button v-show="false" />
        </slot>
      </template>
      <template v-slot:[`footer.accept`]>
        <cl-button
          :variant="'secondary'"
          @on-click="closeModal"
          data-test-id="quarantine-confirmation-button"
        >
          <span>{{ this.$t("Accept") }}</span>
        </cl-button>
      </template>
    </cl-modal>
  </Teleport>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  computed: {
    ...mapGetters("modal", ["showModal"]),
  },
  methods: {
    ...mapActions("modal", ["closeModal"]),
  },
};
</script>
