export const COMPARE_OPTION = Object.freeze({
  "*": "Is Any",
  "<": "Less than",
  ">": "Greater than",
  "=": "Equal",
  between: "Between",
});

export const DEFAULT_OPTIONS = Object.freeze({
  results: 10,
  page: 1,
  sortDir: "desc",
  filter: null,
  type: null,
});

export default {};
