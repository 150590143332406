<template>
  <div>
    <h1>Sales layer</h1>
    <p>Welcome to the world of tomorrow</p>
    <router-link to="/auth/sign-in" class="nav-link"> Logout </router-link>
  </div>
</template>

<script>
export default {
  name: "salesApp",
};
</script>
