<template>
  <div
    v-if="selected.quarantined && !loading"
    class="tw-mb-2 tw-flex tw-items-center tw-justify-end tw-gap-2"
  >
    <cl-button
      variant="danger"
      class="outline-warning"
      @on-click="onDelete"
      data-test-id="delete"
    >
      <span v-if="!deletingMessage">
        {{ $t("Delete") }}
      </span>
      <span class="px-2" v-else><cl-spinner size="small" /></span>
    </cl-button>
    <div
      class="tw-text-dorian-gray-200"
      v-if="!isShowForwardEmail || !isShowVirusEmail"
    >
      |
    </div>
    <cl-button
      v-if="!isShowForwardEmail"
      variant="secondary"
      @on-click="onForward"
      data-test-id="forward"
      >{{ $t("Forward") }}</cl-button
    >
    <cl-button
      v-if="!isShowVirusEmail"
      variant="secondary"
      @on-click="onRelease"
      data-test-id="release"
    >
      <span v-if="!releasingMessage">
        {{ $t("Release") }}
      </span>
      <span class="px-2" v-else><cl-spinner size="small" /></span>
    </cl-button>
    <cl-button
      v-if="!isShowVirusEmail"
      variant="secondary"
      @on-click="onAllow"
      data-test-id="allow"
    >
      <span v-if="!allowingMessage">
        {{ $t("Allow") }}
      </span>
      <span class="px-2" v-else><cl-spinner size="small" /></span>
    </cl-button>
    <t-add-message-forward-to
      :messageId="message_id"
      :secret_id="selected.secret_id"
      :routeRedirectName="routeRedirectName"
      :tier="tier"
      :id="tier_id"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import { TIER } from "@/constants";
import TAddMessageForwardTo from "@/modals/TAddMessageForwardTo.vue";

export default {
  components: {
    "t-add-message-forward-to": TAddMessageForwardTo,
  },
  props: {
    message_id: {
      type: String || Number,
      required: true,
    },
    tier_id: {
      type: [String, Number],
      default: null,
    },
    tier: {
      type: String,
      default: null,
    },
    selected: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      deletingMessage: false,
      releasingMessage: false,
      allowingMessage: false,
      historyFragment: null,
    };
  },
  computed: {
    ...mapGetters("authentication", ["selectedAccount"]),
    ...mapGetters("message", ["host", "loading"]),
    isShowVirusEmail() {
      return (
        this.selected.message_type === "Virus" &&
        this.selectedAccount.account_type_name === "user"
      );
    },

    isShowForwardEmail() {
      return this.selectedAccount.account_type_name === "user";
    },

    routeRedirectName() {
      let targetName;
      switch (this.tier) {
        case TIER.domainGroups:
          targetName =
            this.historyFragment === "history"
              ? "customer-history"
              : "customer-quarantine";
          break;
        case TIER.domains:
          targetName =
            this.historyFragment === "history"
              ? "domain-history"
              : "domain-quarantine";
          break;
        case TIER.msps:
          targetName =
            this.historyFragment === "history"
              ? "msp-history"
              : "msp-quarantine";
          break;
        case TIER.users:
          targetName =
            this.historyFragment === "history"
              ? "user-history"
              : "user-quarantine";
          break;
      }
      return targetName;
    },
  },
  methods: {
    ...mapActions("modal", ["openModal", "closeModal"]),
    ...mapActions("toast", ["displayToast"]),
    ...mapActions("blockAllowEmailsList", ["allowEmail"]),
    ...mapActions("quarantinedMessages", [
      "deleteQuarantinedMessage",
      "releaseQuarantinedMessage",
      "clearSearch",
      "allowQuarantinedMessage",
    ]),
    ...mapActions("message", ["clearHost"]),
    async onDelete() {
      try {
        this.deletingMessage = true;

        await this.deleteQuarantinedMessage({
          id: this.message_id,
          secret_id: this.selected.secret_id,
          tier: this.tier,
          tier_id: this.tier_id,
          host: this.host || null,
        });

        this.clearSearch();
        this.displayToast({
          title: this.$t("Success"),
          message: this.$t("Message deleted"),
          duration: 2000,
          variant: "success",
        });

        this.$router.push({ name: this.routeRedirectName });
      } catch (_err) {
        // stub
      } finally {
        this.deletingMessage = false;
      }
    },
    async onForward() {
      this.openModal();
    },
    async onRelease() {
      try {
        this.releasingMessage = true;

        await this.releaseQuarantinedMessage({
          id: this.message_id,
          tier: this.tier,
          tier_id: this.tier_id,
          formData: {
            secret_id: this.selected.secret_id,
          },
          host: this.host || null,
        });

        this.clearSearch();
        this.displayToast({
          title: this.$t("Success"),
          message: this.$t("Message released"),
          duration: 2000,
          variant: "success",
        });
        this.$router.push({ name: this.routeRedirectName });
      } catch (_err) {
        // stub
      } finally {
        this.releasingMessage = false;
      }
    },
    async onAllow() {
      try {
        this.allowingMessage = true;

        await this.allowQuarantinedMessage({
          id: this.message_id,
          tier: this.tier,
          tier_id: this.tier_id,
          formData: {
            secret_id: this.selected.secret_id,
          },
          host: this.host || null,
        });

        this.clearSearch();
        this.displayToast({
          title: this.$t("Success"),
          message: this.$t("Message allowed"),
          duration: 2000,
          variant: "success",
        });

        this.$router.push({ name: this.routeRedirectName });
      } catch (_err) {
        // stub
      } finally {
        this.allowingMessage = false;
      }
    },
  },
  mounted() {
    const fragmentsArray = this.$route.name.split("-");
    const historyIndex = fragmentsArray.indexOf("history");
    if (historyIndex !== -1) {
      this.historyFragment = fragmentsArray[historyIndex];
    }
  },
  beforeUnmount() {
    this.clearHost();
  },
};
</script>
