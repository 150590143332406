<template>
  <div class="geoblocking-toggle-wrap">
    <div class="tw-flex tw-justify-between">
      <div>
        <p v-if="geoblockingStatus" class="tw-font-bold">
          {{ $t("Geoblocking") }}:
        </p>
        <p v-else class="tw-font-bold">{{ $t("Geoblocking") }}:</p>
      </div>
      <div
        @change="setStatus"
        class="tw-flex tw-justify-center tw-text-right tw-align-middle"
      >
        <cl-spinner v-if="loading" />
        <cl-form-radio-group
          v-else
          :selectedValue="status"
          @update:selected="(newStatus) => (status = newStatus)"
          :options="statusDataList"
          button
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  props: {
    geoblockingStatus: {
      type: Boolean,
      required: true,
    },
    id: {
      type: [String, Number],
      default: null,
    },
    tier: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      status: null,
      loading: false,
      statusDataList: [
        { label: this.$t("Enabled"), value: true },
        { label: this.$t("Disabled"), value: false },
      ],
    };
  },
  methods: {
    ...mapActions("geoBlocking", ["setGeoBlockingStatus"]),
    async setStatus() {
      try {
        this.loading = true;

        await this.setGeoBlockingStatus({
          tier: this.tier,
          id: this.id,
          status: this.status,
        });
      } catch (_err) {
        // stub
      } finally {
        this.loading = false;
      }
    },
  },
  watch: {
    geoblockingStatus() {
      this.status = this.geoblockingStatus;
    },
  },
  created() {
    this.status = this.geoblockingStatus;
  },
};
</script>
