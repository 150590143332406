<template>
  <file-names-list
    v-if="selectedDomain.id"
    :tier="tierType"
    :id="selectedDomain.id"
  />
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import FileNamesList from "@/views/Common/AttachmentFilters/FileName.vue";

import { TIER } from "@/constants";

export default {
  name: "FileNames",
  components: {
    FileNamesList,
  },
  data() {
    return {
      tierType: TIER.domains,
    };
  },
  computed: {
    ...mapGetters("authentication", ["selectedDomain"]),
  },
  methods: {
    ...mapActions("pageMeta", ["setPageTitle", "setPageSubtitle"]),
  },
  created() {
    this.setPageTitle(this.$t("Attachments"));
    this.setPageSubtitle([]);
  },
};
</script>
