<template>
  <div class="linklock-exemptions-list">
    <cl-data-table
      :data-test-id="'dashboard-data-table'"
      :fetching="fetching"
      :headers="tableHeaders"
      :items="items"
      :total="serverTotal"
      :default-sort-by="sortByCookie"
      :default-sort-desc="sortDirCookie"
      :default-items-on-page="itemsPerPageCookie"
      with-search
      with-delete
      with-select
      with-set-columns
      with-server-side-pagination
      :searchPlaceholder="$t('Search')"
      @change-page="changePage"
      @search="debouncedSearch"
      @on-delete-items="onDelete"
      @change-items-on-page="onChangeItemsOnPageClick"
      @sort-items="onSortItemsClick"
      @change-selected-columns="setSelectedColumnsCookie"
    >
      <template v-slot:actions>
        <cl-button
          :variant="'secondary'"
          type="button"
          @on-click="onAdd"
          data-test-id="dashboard-data-table-add"
        >
          {{ $t("Add") }}
        </cl-button>
      </template>
      <template v-slot:[`body.cell.actions`]="props">
        <cl-data-table-edit-action
          v-if="!props.trItem.metadata.inherited"
          data-test-id="dashboard-data-table-edit-btn"
          @on-click="onEdit(props.trItem)"
        />
      </template>
      <template v-slot:[`body.cell.comment`]="props">
        <div class="tw-relative tw-inline-flex">
          <template v-if="props.tdValue.length > truncateMaxNumber">
            <cl-tooltip :text="props.tdValue" :id="props.tdValue">
              <span v-truncate> {{ props.tdValue }} </span>
            </cl-tooltip>
          </template>
          <template v-else>{{ props.tdValue }}</template>
        </div>
      </template>
    </cl-data-table>
    <t-add-allowed-url :id="id" :tier="tier" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import { TIER, DEFAULT_OPTIONS, TRUNCATE } from "@/constants";
import TAddAllowedUrl from "@/modals/TAddAllowedUrl.vue";
import { useDataTable } from "@/composables/useDataTable";
import { useDataTableCookies } from "@/composables/useDataTableCookies";

export default {
  name: "LinkLockExemptionsList",
  components: {
    "t-add-allowed-url": TAddAllowedUrl,
  },
  setup(props) {
    const cookieKey = `linklock-exemptions${props.tier || "msp"}`;
    const {
      sortByCookie,
      sortDirCookie,
      itemsPerPageCookie,
      selectedColumnsCookie,
      setSortItemsCookie,
      setItemsPerPageCookie,
      setSelectedColumnsCookie,
    } = useDataTableCookies(
      {
        sortByKey: `${cookieKey}-sort-by`,
        sortDirKey: `${cookieKey}-sort-dir`,
        itemsPerPageKey: `${cookieKey}-items-per-page`,
        selectedColumnsKey: `${cookieKey}-selected-columns`,
      },
      {
        ...DEFAULT_OPTIONS,
        sortBy: "url",
      }
    );

    const optionsBasedOnCookies = {
      sortBy: sortByCookie,
      sortDir: sortDirCookie,
      results: itemsPerPageCookie,
      page: 1,
    };

    const {
      dataOptions,
      sortItems,
      changePage,
      changeItemsPerPage,
      debouncedSearch,
    } = useDataTable(optionsBasedOnCookies);

    const truncateMaxNumber = TRUNCATE.max;

    return {
      dataOptions,
      debouncedSearch,
      sortItems,
      changePage,
      changeItemsPerPage,
      selectedColumnsCookie,
      setSortItemsCookie,
      setItemsPerPageCookie,
      setSelectedColumnsCookie,
      sortByCookie,
      sortDirCookie,
      itemsPerPageCookie,
      truncateMaxNumber,
    };
  },

  props: {
    id: {
      type: [String, Number],
      default: null,
    },
    tier: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      fetching: false,
      searchExports: [],
      items: [],
    };
  },
  computed: {
    ...mapGetters("linkLock", ["exemptions", "serverTotal"]),
    inheritedLabel() {
      return this.$t("inherited");
    },
    tableHeaders() {
      const headers = [
        {
          key: "url",
          label: this.$t("Allowed URL"),
          sortable: true,
        },
        {
          key: "comment",
          label: this.$t("Comment"),
          sortable: true,
        },
        {
          key: "updated_at",
          label: this.$t("Last Modified"),
          sortable: true,
        },
        { key: "actions", label: this.$t("Actions") },
      ];

      if (!this.selectedColumnsCookie?.length) {
        return headers;
      }

      return headers.map((header) => ({
        ...header,
        enabled: this.selectedColumnsCookie.includes(header.key),
      }));
    },
  },
  watch: {
    dataOptions: {
      handler() {
        this.fetchNewExemptions();
      },
      deep: true,
      immediate: true,
    },

    exemptions: {
      handler(newExemptions) {
        this.items = newExemptions.map((exemption) => ({
          ...exemption,
          isSelectable: !exemption.metadata.inherited,
          url: exemption.metadata.inherited
            ? `${exemption.url} (${this.inheritedLabel})`
            : exemption.url,
        }));
      },
      immediate: true,
    },
  },
  methods: {
    ...mapActions("modal", ["openModal", "closeModal"]),
    ...mapActions("toast", ["displayToast"]),
    ...mapActions("linkLock", [
      "fetchLinkLockExemptions",
      "batchDeleteLinkLockExemptions",
    ]),
    onSortItemsClick(by, dir) {
      this.setSortItemsCookie(by, dir);
      this.sortItems(by, dir);
    },

    onChangeItemsOnPageClick(number) {
      this.setItemsPerPageCookie(number);
      this.changeItemsPerPage(number);
    },
    onAdd() {
      this.openModal();
    },
    async onDelete(exemptions) {
      try {
        this.fetching = true;

        await this.batchDeleteLinkLockExemptions({
          tier: this.tier,
          id: this.id,
          exemptions,
        });
        this.displayToast({
          title: this.$t("Success"),
          message: this.$t("URL has been deleted"),
          duration: 2000,
          variant: "success",
        });
        if (this.exemptions.length === 0 && this.dataOptions.page > 0) {
          this.dataOptions.page = this.dataOptions.page - 1;
        }
      } catch (_err) {
        // stub
      } finally {
        this.fetching = false;
      }
    },
    onEdit(exemption) {
      if (exemption.metadata.inherited) {
        return;
      }

      const tierRoutes = {
        [TIER.msps]: "msp-link-lock-exemptions-edit",
        [TIER.domainGroups]: "customer-link-lock-exemptions-edit",
        [TIER.users]: "user-link-lock-exemptions-edit",
        [TIER.domains]: "domain-link-lock-exemptions-edit",
      };

      if (this.tier in tierRoutes) {
        this.$router.push({
          name: tierRoutes[this.tier],
          params: {
            id: exemption.id,
          },
        });
      }
    },
    async fetchNewExemptions() {
      try {
        this.fetching = true;
        await this.fetchLinkLockExemptions({
          tier: this.tier,
          id: this.id,
          ...this.dataOptions,
        });
      } catch (_err) {
        // stub
      } finally {
        this.fetching = false;
      }
    },
  },
};
</script>
