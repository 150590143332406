<template>
  <div>
    <t-skeleton v-if="loading" />
    <form
      v-else
      @submit.prevent="submit"
      autocomplete="off"
      @keyup="changed"
      @change="changed"
    >
      <div class="tw-grid tw-gap-4 sm:tw-grid-cols-2">
        <cl-form-group>
          <cl-form-label label-for="first_name">
            {{ $t("First Name") }}
          </cl-form-label>
          <cl-form-input
            autofocus
            id="first_name"
            name="first_name"
            type="text"
            :placeholder="$t('First Name')"
            v-model:value="form.first_name"
            size="medium"
          />
        </cl-form-group>
        <cl-form-group>
          <cl-form-label label-for="last_name">
            {{ $t("Last Name") }}
          </cl-form-label>
          <cl-form-input
            id="last_name"
            name="last_name"
            type="text"
            :placeholder="$t('Last Name')"
            v-model:value="form.last_name"
            size="medium"
          />
        </cl-form-group>
      </div>
      <div class="tw-grid tw-gap-4 sm:tw-grid-cols-2">
        <cl-form-group>
          <cl-form-label label-for="email">
            {{ $t("Email") }}
          </cl-form-label>
          <cl-form-input
            id="email"
            name="email"
            :placeholder="$t('name@company.com')"
            v-model:value="form.email"
            size="medium"
            :disabled="true"
          />
        </cl-form-group>
        <div
          class="tw-flex tw-items-center tw-justify-between"
          v-if="hasLinkLockPermission"
        >
          <span>{{ $t("Link Lock Admin") }}</span>
          <cl-form-radio-group
            :selectedValue="form.linklock"
            @update:selected="(selected) => (form.linklock = selected)"
            :options="radioOptions"
            button
          />
        </div>
      </div>
      <div class="tw-flex tw-w-full tw-justify-end">
        <cl-button
          type="submit"
          :disabled="!isChanged"
          class="tw-my-3"
          variant="secondary"
        >
          <span v-if="!saving"> {{ $t("Save") }} </span>
          <span v-else class="px-2">
            <cl-spinner size="small" />
          </span>
        </cl-button>
      </div>
    </form>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import checkPermission from "@/utils/check-permission";
import TSkeleton from "@/components/TSkeleton";

export default {
  name: "EditCustomer",
  components: {
    TSkeleton,
  },
  data() {
    return {
      loading: false,
      isChanged: false,
      saving: false,
      form: {
        first_name: "",
        last_name: "",
        email: "",
        linklock: false,
      },
      radioOptions: [
        { label: this.$t("Yes"), value: true },
        { label: this.$t("No"), value: false },
      ],
    };
  },
  watch: {
    selectedAdministrator: {
      immediate: true,
      deep: true,
      handler(admin) {
        if (!admin) {
          return;
        }

        const { first_name, last_name, email, roles } = admin;

        this.form = {
          first_name,
          last_name,
          email,
          linklock: roles ? roles.includes("link-lock.admin") : false,
        };
      },
    },
  },
  computed: {
    ...mapGetters("administrators", [
      "selectedAdministrator",
      "selectedTier",
      "selectedTierId",
    ]),
    ...mapGetters("authentication", ["permissions"]),
    administratorId() {
      return this.$route.params.id || null;
    },
    fullname() {
      return (
        this.selectedAdministrator.first_name +
        " " +
        this.selectedAdministrator.last_name
      );
    },
  },
  methods: {
    ...mapActions("administrators", [
      "getAdministrator",
      "updateAdministrator",
    ]),
    ...mapActions("pageMeta", ["setPageTitle", "setPageSubtitle"]),
    ...mapActions("toast", ["displayToast"]),
    changed() {
      this.isChanged = true;
    },
    async submit() {
      try {
        this.saving = true;

        // eslint-disable-next-line no-unused-vars
        const { email, ...restFormValue } = this.form;

        await this.updateAdministrator({
          id: this.administratorId,
          tier: this.selectedTier,
          tier_id: this.selectedTierId,
          formData: restFormValue,
        });

        this.displayToast({
          title: this.$t("Success"),
          message: this.$t("Administrator details updated successfully"),
          duration: 2000,
          variant: "success",
        });

        this.isChanged = false;
      } catch (_err) {
        // stub
      } finally {
        this.saving = false;
      }
    },
  },
  created() {
    this.hasLinkLockPermission = checkPermission(this.permissions, "link-lock");
  },
  async mounted() {
    try {
      this.loading = true;
      await this.getAdministrator({
        id: this.administratorId,
        tier: this.selectedTier,
        tier_id: this.selectedTierId,
      });
      this.setPageTitle(this.fullname);
      this.setPageSubtitle([]);
    } catch (_err) {
      // stub
    } finally {
      this.loading = false;
    }
  },
  beforeMount() {
    this.setPageTitle("");
    this.setPageSubtitle([]);
  },
};
</script>
