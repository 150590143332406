import { API_TYPE } from "@/constants";

export function getLoggedInType(type) {
  switch (type) {
    case API_TYPE.DG:
      return "dg";
    case API_TYPE.DOMAINS:
      return "domains";
    case API_TYPE.USERS:
      return "users";
    case API_TYPE.MSPS:
      return "msps";
    default:
  }
}

export default {};
