<template>
  <div>
    <section class="all-customers">
      <div class="tw-mb-2 tw-p-2">
        <cl-heading :size="'h3'">
          {{ $t("All Customers") }}
        </cl-heading>
      </div>
      <cl-data-table
        :data-test-id="'dashboard-data-table'"
        :fetching="fetchingCustomers"
        :headers="tableHeaders"
        :items="items"
        :total="serverTotal"
        :default-sort-by="sortByCookie"
        :default-sort-desc="sortDirCookie"
        :default-items-on-page="itemsPerPageCookie"
        with-search
        with-delete
        with-select
        with-set-columns
        with-server-side-pagination
        :searchBy="'accountname'"
        :searchPlaceholder="$t('Search Customers')"
        @change-page="changePage"
        @search="debouncedSearch"
        @on-delete-items="onDelete"
        @change-items-on-page="onChangeItemsOnPageClick"
        @sort-items="onSortItemsClick"
        @change-selected-columns="setSelectedColumnsCookie"
      >
        <template v-slot:actions>
          <cl-button
            :variant="'secondary'"
            type="button"
            @on-click="$emit('add')"
            data-test-id="dashboard-data-table-add-customer"
          >
            {{ $t("Add") }}
          </cl-button>
        </template>
        <template v-slot:[`body.cell.actions`]="props">
          <cl-data-table-view-action
            data-test-id="dashboard-data-table-view-btn"
            @on-click="onView(props.trItem)"
          />
          <cl-data-table-edit-action
            data-test-id="dashboard-data-table-edit-btn"
            @on-click="onEdit(props.trItem)"
          />
        </template>
      </cl-data-table>
    </section>
  </div>
</template>

<script>
import dayjs from "dayjs";
import { mapActions, mapGetters } from "vuex";

import { DATE_FORMAT, DEFAULT_OPTIONS, TIER } from "@/constants";
import { useDataTable } from "@/composables/useDataTable";
import { useDataTableCookies } from "@/composables/useDataTableCookies";
import { usePills } from "@/composables/usePills";

export default {
  setup(props) {
    const cookieKey = `overview-${props.tier || "msp"}`;
    const {
      sortByCookie,
      sortDirCookie,
      itemsPerPageCookie,
      selectedColumnsCookie,
      setSortItemsCookie,
      setItemsPerPageCookie,
      setSelectedColumnsCookie,
    } = useDataTableCookies(
      {
        sortByKey: `${cookieKey}-sort-by`,
        sortDirKey: `${cookieKey}-sort-dir`,
        itemsPerPageKey: `${cookieKey}-items-per-page`,
        selectedColumnsKey: `${cookieKey}-selected-columns`,
      },
      {
        ...DEFAULT_OPTIONS,
        sortBy: "name",
      }
    );

    const optionsBasedOnCookies = {
      sortBy: sortByCookie,
      sortDir: sortDirCookie,
      results: itemsPerPageCookie,
      page: 1,
    };

    const {
      dataOptions,
      sortItems,
      changePage,
      changeItemsPerPage,
      debouncedSearch,
    } = useDataTable(optionsBasedOnCookies);

    const { deletePill } = usePills({});

    return {
      dataOptions,
      debouncedSearch,
      sortItems,
      changePage,
      changeItemsPerPage,
      selectedColumnsCookie,
      setSortItemsCookie,
      setItemsPerPageCookie,
      setSelectedColumnsCookie,
      sortByCookie,
      sortDirCookie,
      itemsPerPageCookie,
      deletePill,
    };
  },
  data() {
    return {
      currentHeaders: null,
      items: [],
    };
  },
  computed: {
    ...mapGetters("msps", ["mspCustomers", "serverTotal", "fetchingCustomers"]),
    ...mapGetters("authentication", ["selectedMsp"]),
    tableHeaders() {
      const headers = [
        {
          key: "name",
          label: this.$t("Customer Name"),
          sortable: true,
        },
        {
          key: "description",
          label: this.$t("Description"),
          sortable: true,
        },
        { key: "id", label: this.$t("Customer ID"), sortable: true },
        { key: "created_at", label: this.$t("Created At"), sortable: true },
        { key: "actions", label: this.$t("Actions"), sticky: true },
      ];

      if (!this.selectedColumnsCookie?.length) {
        return headers;
      }

      return headers.map((header) => ({
        ...header,
        enabled: this.selectedColumnsCookie.includes(header.key),
      }));
    },
  },
  watch: {
    dataOptions: {
      handler() {
        this.fetchNewCustomers();
      },
      deep: true,
    },
    mspCustomers: {
      handler() {
        this.items = this.mspCustomers.map((customer) => ({
          ...customer,
          created_at: `${dayjs(customer.created_at).format(DATE_FORMAT.SHORT)}`,
          accountname: customer.name,
        }));
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    ...mapActions("msps", [
      "fetchMspCustomers",
      "batchDeleteMspCustomers",
      "clearList",
    ]),
    ...mapActions("authentication", ["setCustomer"]),
    ...mapActions("toast", ["displayToast"]),
    onSortItemsClick(by, dir) {
      this.setSortItemsCookie(by, dir);
      this.sortItems(by, dir);
    },

    onChangeItemsOnPageClick(number) {
      this.setItemsPerPageCookie(number);
      this.changeItemsPerPage(number);
    },
    onEdit(rowObj) {
      this.$router.push(`/msp/overview/${rowObj.id}`);
    },
    async onDelete(customers) {
      try {
        await this.batchDeleteMspCustomers({
          customers,
        });
        this.displayToast({
          title: this.$t("Success"),
          message: this.$t("Customer has been deleted"),
          duration: 2000,
          variant: "success",
        });
      } catch (_err) {
        // stub
      } finally {
        if (this.mspCustomers.length === 0 && this.dataOptions.page > 0) {
          this.dataOptions.page = this.dataOptions.page - 1;
        }
        this.deletePill(`q-${TIER.msps}-${this.selectedMsp.id}-domain_group`);
      }
    },
    onView(customer) {
      this.setCustomer(customer);
      this.$router.push({ name: "customerOver" });
    },
    async fetchNewCustomers() {
      try {
        this.clearList();

        await this.fetchMspCustomers({
          mspId: this.selectedMsp.id,
          ...this.dataOptions,
        });
      } catch (_err) {
        // stub
      } finally {
        // stub
      }
    },
  },
};
</script>
