import { ACCOUNT_TYPE } from "@/constants";
import Settings from "@/views/Msp/Settings";

const mspRoutes = [
  {
    path: "",
    redirect: "/msp/overview",
    component: () => import("@/views/Msp/App.vue"),
    exact: false,
    meta: {
      authorize: [ACCOUNT_TYPE.SYSTEM, ACCOUNT_TYPE.SALES, ACCOUNT_TYPE.MSP],
    },
  },
  {
    path: "overview",
    exact: false,
    name: "mspOverview",
    component: () => import("@/views/Msp/Overview"),
  },
  {
    path: "overview/:id",
    exact: false,
    name: "msp-customer-edit",
    component: () => import("@/views/Msp/Customer"),
  },
  {
    path: "filtering",
    redirect: {
      name: "msp-block-allow-list",
    },
    component: () => import("@/views/Msp/Filtering"),
    children: [
      {
        path: "attachments",
        name: "msp-filtering-attachments",
        component: () => import("@/views/Msp/Filtering/Attachments"),
        redirect: {
          name: "msp-filtering-attachments-extension",
        },
        children: [
          {
            path: "extension_filters",
            name: "msp-filtering-attachments-extension",
            component: () =>
              import("@/views/Msp/Filtering/Attachments/Filters/Extension.vue"),
          },
          {
            path: "file_name_filters",
            name: "msp-filtering-attachments-filename",
            component: () =>
              import("@/views/Msp/Filtering/Attachments/Filters/FileName.vue"),
          },
          {
            path: "file_type_filters",
            name: "msp-filtering-attachments-filetype",
            component: () =>
              import("@/views/Msp/Filtering/Attachments/Filters/FileType.vue"),
          },
          {
            path: "mime_type_filters",
            name: "msp-filtering-attachments-mimetype",
            component: () =>
              import("@/views/Msp/Filtering/Attachments/Filters/MimeType.vue"),
          },
          {
            path: "password_protected_archive",
            name: "msp-filtering-attachments-ppa",
            component: () =>
              import(
                "@/views/Msp/Filtering/Attachments/Filters/PasswordProtectedArchive.vue"
              ),
          },
        ],
      },
      {
        path: "attachments/edit/:id",
        name: "msp-filtering-attachments-edit",
        component: () =>
          import("@/views/Msp/Filtering/Attachments/Filters/Edit/index.vue"),
      },
      {
        path: "allow_block_list",
        name: "msp-block-allow-list",
        component: () =>
          import("@/views/Msp/Filtering/BlockAllowList/index.vue"),
        redirect: {
          name: "msp-block-allow-list-emails",
        },
        children: [
          {
            path: "emails",
            name: "msp-block-allow-list-emails",
            component: () =>
              import("@/views/Msp/Filtering/BlockAllowList/Emails/index.vue"),
          },
          {
            path: "domains",
            name: "msp-block-allow-list-domains",
            component: () =>
              import("@/views/Msp/Filtering/BlockAllowList/Domains/index.vue"),
          },
          {
            path: "IPs",
            name: "msp-block-allow-list-ips",
            component: () =>
              import("@/views/Msp/Filtering/BlockAllowList/Ips/index.vue"),
          },
        ],
      },
      {
        path: "allow_block_list/emails/:id",
        name: "msp-block-allow-list-emails-edit",
        component: () =>
          import("@/views/Msp/Filtering/BlockAllowList/Emails/Edit"),
      },
      {
        path: "allow_block_list/domains/:id",
        name: "msp-block-allow-list-domains-edit",
        component: () =>
          import("@/views/Msp/Filtering/BlockAllowList/Domains/Edit"),
      },
      {
        path: "allow_block_list/IPs/:id",
        name: "msp-block-allow-list-ips-edit",
        component: () =>
          import("@/views/Msp/Filtering/BlockAllowList/Ips/Edit"),
      },
      {
        path: "geoblocking",
        name: "geoblocking",
        component: () => import("@/views/Msp/Filtering/Geoblocking"),
        redirect: {
          name: "msp-geoblocking-rule",
        },
        children: [
          {
            path: "rules",
            name: "msp-geoblocking-rule",
            component: () =>
              import("@/views/Msp/Filtering/Geoblocking/Tabs/Rules"),
          },
          {
            path: "exemptions",
            name: "msp-geoblocking-exemptions",
            component: () =>
              import("@/views/Msp/Filtering/Geoblocking/Tabs/Exemptions"),
          },
        ],
      },
      {
        path: "geoblocking/rules/:iso",
        name: "msp-geoblocking-rule-edit",
        component: () =>
          import("@/views/Msp/Filtering/Geoblocking/Tabs/Rules/Edit"),
      },
      {
        path: "geoblocking/exemptions/:id",
        name: "msp-geoblocking-exemptions-edit",
        component: () =>
          import("@/views/Msp/Filtering/Geoblocking/Tabs/Exemptions/Edit"),
      },
      {
        path: "link_lock",
        name: "msp-link-lock",
        component: () => import("@/views/Msp/Filtering/LinkLock"),
        children: [
          {
            path: "policy_configuration",
            name: "msp-link-lock-policy-configuration",
            component: () =>
              import(
                "@/views/Msp/Filtering/LinkLock/Tabs/PolicyConfiguration/index.vue"
              ),
          },
          {
            path: "exemptions",
            name: "msp-link-lock-exemptions",
            component: () =>
              import(
                "@/views/Msp/Filtering/LinkLock/Tabs/Exemptions/index.vue"
              ),
          },
        ],
      },
      {
        path: "link_lock/exemptions/:id",
        name: "msp-link-lock-exemptions-edit",
        component: () =>
          import(
            "@/views/Msp/Filtering/LinkLock/Tabs/Exemptions/Edit/index.vue"
          ),
      },
      {
        path: "pattern_filtering",
        name: "msp-filtering-patterns-list",
        component: () => import("@/views/Msp/Filtering/Patterns/index.vue"),
      },
      {
        path: "pattern_filtering/edit_single_pattern",
        redirect: {
          name: "msp-filtering-patterns-list",
        },
      },
      {
        path: "pattern_filtering/edit_multi_pattern",
        redirect: {
          name: "msp-filtering-patterns-list",
        },
      },
      {
        path: "pattern_filtering/edit_single_pattern/:id",
        name: "msp-filtering-patterns-edit-single",
        component: () =>
          import("@/views/Msp/Filtering/Patterns/Edit/Single.vue"),
      },
      {
        path: "pattern_filtering/edit_multi_pattern/:id",
        name: "msp-filtering-patterns-edit-multi",
        component: () =>
          import("@/views/Msp/Filtering/Patterns/Edit/Multi.vue"),
      },
    ],
  },
  {
    path: "history",
    name: "msp-history",
    component: () => import("@/views/Msp/History/index.vue"),
  },
  {
    path: "history/message_overview",
    name: "msp-history-message",
    redirect: {
      name: "msp-history",
    },
  },
  {
    path: "history/message_overview/:id",
    component: () => import("@/views/Msp/History/MessageOverview/index.vue"),
    name: "msp-history-message-overview",
    redirect: {
      name: "msp-history-message-overview-details",
    },
    children: [
      {
        path: "details",
        name: "msp-history-message-overview-details",
        component: () =>
          import("@/views/Msp/History/MessageOverview/Tabs/Details.vue"),
      },
      {
        path: "message",
        name: "msp-history-message-overview-message",
        component: () =>
          import("@/views/Msp/History/MessageOverview/Tabs/Message.vue"),
      },
      {
        path: "source",
        name: "msp-history-message-overview-source",
        component: () =>
          import("@/views/Msp/History/MessageOverview/Tabs/Source.vue"),
      },
    ],
  },
  {
    path: "quarantine",
    component: () => import("@/views/Msp/Quarantine"),
    name: "msp-quarantine",
  },
  {
    path: "quarantine/message_overview",
    name: "msp-quarantine-message",
    redirect: {
      name: "msp-quarantine",
    },
  },
  {
    path: "quarantine/message_overview/:id",
    component: () => import("@/views/Msp/Quarantine/MessageOverview"),
    name: "msp-quarantine-message-overview",
    redirect: {
      name: "msp-quarantine-message-overview-details",
    },
    children: [
      {
        path: "details",
        name: "msp-quarantine-message-overview-details",
        component: () =>
          import("@/views/Msp/Quarantine/MessageOverview/Tabs/Details.vue"),
      },
      {
        path: "message",
        name: "msp-quarantine-message-overview-message",
        component: () =>
          import("@/views/Msp/Quarantine/MessageOverview/Tabs/Message.vue"),
      },
      {
        path: "source",
        name: "msp-quarantine-message-overview-source",
        component: () =>
          import("@/views/Msp/Quarantine/MessageOverview/Tabs/Source.vue"),
      },
    ],
  },
  {
    path: "settings",
    name: "msp-settings",
    component: Settings,
    redirect: {
      name: "msp-settings-administrators",
    },
    children: [
      {
        path: "administrators",
        name: "msp-settings-administrators",
        component: () =>
          import("@/views/Msp/Settings/Administrators/index.vue"),
      },
      {
        path: "administrators/:id",
        name: "msp-settings-administrators-edit",
        component: () => import("@/views/Common/Administrators/Edit"),
      },
      {
        path: "interface",
        name: "interface",
        component: () => import("@/views/Msp/Settings/Interface"),
      },
      {
        path: "support",
        name: "support",
        component: () => import("@/views/Msp/Settings/Support"),
      },
    ],
  },
  {
    path: "TitanHQ_Products",
    name: "TitanHQ-Products",
    component: () => import("@/views/Msp/OtherProducts"),
  },
  {
    path: "customer",
    redirect: {
      name: "customerOver",
    },
  },
  {
    path: "customer/filtering",
    redirect: {
      name: "customer-filtering-attachments",
    },
  },
  {
    path: "customer/filtering/attachments",
    redirect: {
      name: "customer-filtering-attachments",
    },
  },
  {
    path: "customer/filtering/allow_block_list",
    redirect: {
      name: "customer-block-allow-list",
    },
  },
  {
    path: "customer/filtering/allow_block_list/emails",
    redirect: {
      name: "customer-block-allow-list-emails",
    },
  },
  {
    path: "customer/filtering/allow_block_list/domains",
    redirect: {
      name: "customer-block-allow-list-domains",
    },
  },
  {
    path: "customer/policies",
    redirect: {
      name: "customer-policies-domain-policies",
    },
  },
  {
    path: "customer/policies/domain_policies",
    redirect: {
      name: "customer-policies-domain-policies",
    },
  },
  {
    path: "customer/domain",
    redirect: {
      name: "domain-overview",
    },
  },
  {
    path: "customer/domain/anti-spoofing",
    redirect: {
      name: "anti-spoofing",
    },
  },
  {
    path: "customer/history",
    redirect: {
      name: "customer-history",
    },
  },
  {
    path: "customer/history/message_overview",
    redirect: {
      name: "customer-history",
    },
  },
  {
    path: "customer/history/message_overview/:id",
    redirect: {
      name: "customer-history-message-overview",
    },
  },
  {
    path: "customer/quarantine",
    redirect: {
      name: "customer-quarantine",
    },
  },
  {
    path: "customer/quarantine/message_overview",
    redirect: {
      name: "customer-quarantine",
    },
  },
  {
    path: "customer/quarantine/message_overview/:id",
    redirect: {
      name: "customer-quarantine-message-overview",
    },
  },
  {
    path: "customer/filtering/pattern_filtering",
    redirect: {
      name: "customer-filtering-patterns-list",
    },
  },
  {
    path: "customer/filtering/pattern_filtering/edit_single_pattern",
    redirect: {
      name: "customer-filtering-patterns-list",
    },
  },
  {
    path: "customer/filtering/pattern_filtering/edit_multi_pattern",
    redirect: {
      name: "customer-filtering-patterns-list",
    },
  },
  {
    path: "customer/domain/filtering/pattern_filtering",
    redirect: {
      name: "domain-filtering-patterns-list",
    },
  },
  {
    path: "customer/domain/filtering/pattern_filtering/edit_multi_pattern",
    redirect: {
      name: "domain-filtering-patterns-list",
    },
  },
  {
    path: "customer/domain/filtering/pattern_filtering/edit_single_pattern",
    redirect: {
      name: "domain-filtering-patterns-list",
    },
  },
  {
    path: "customer/domain/history",
    redirect: {
      name: "domain-history",
    },
  },
  {
    path: "customer/domain/history/message_overview",
    redirect: {
      name: "domain-history",
    },
  },
  {
    path: "customer/domain/history/message_overview/:id",
    redirect: {
      name: "domain-history-message-overview",
    },
  },
  {
    path: "customer/domain/quarantine",
    redirect: {
      name: "domain-quarantine",
    },
  },
  {
    path: "customer/domain/quarantine/message_overview",
    redirect: {
      name: "domain-quarantine",
    },
  },
  {
    path: "customer/domain/quarantine/message_overview/:id",
    redirect: {
      name: "domain-quarantine-message-overview",
    },
  },
  {
    path: "customer/domain/filtering",
    redirect: {
      name: "domain-filtering-attachments",
    },
  },
  {
    path: "customer/domain/filtering/attachments",
    redirect: {
      name: "domain-filtering-attachments",
    },
  },
  {
    path: "customer/domain/filtering/allow_block_list",
    redirect: {
      name: "domain-block-allow-list",
    },
  },
  {
    path: "customer/domain/filtering/geoblocking",
    redirect: {
      name: "domain-geoblocking",
    },
  },
  {
    path: "customer/domain/filtering/link_lock",
    redirect: {
      name: "domain-link-lock",
    },
  },
  {
    path: "customer/domain/policies",
    redirect: {
      name: "domain-policies-user-policies",
    },
  },
  {
    path: "customer/domain/reporting",
    redirect: {
      name: "domain-reports-scheduled",
    },
  },
  {
    path: "customer/domain/settings",
    redirect: {
      name: "domain-settings-administrators",
    },
  },
  {
    path: "customer/domain/settings/administrators",
    redirect: {
      name: "domain-settings-administrators",
    },
  },
  {
    path: "customer/domain/user",
    redirect: {
      name: "user-quarantine",
    },
  },
  {
    path: "customer/domain/user/filtering",
    redirect: {
      name: "user-block-allow-list",
    },
  },
  {
    path: "customer/domain/user/filtering/allow_block_list",
    redirect: {
      name: "user-block-allow-list",
    },
  },
  {
    path: "customer/domain/user/filtering/geoblocking",
    redirect: {
      name: "user-geoblocking",
    },
  },
  {
    path: "customer/domain/user/history",
    redirect: {
      name: "user-history",
    },
  },
  {
    path: "customer/domain/user/history/message_overview",
    redirect: {
      name: "user-history",
    },
  },
  {
    path: "customer/domain/user/history/message_overview/:id",
    redirect: {
      name: "user-history-message-overview",
    },
  },
  {
    path: "customer/domain/user/quarantine",
    redirect: {
      name: "user-quarantine",
    },
  },
  {
    path: "customer/domain/user/quarantine/message_overview",
    redirect: {
      name: "user-quarantine",
    },
  },
  {
    path: "customer/domain/user/quarantine/message_overview/:id",
    redirect: {
      name: "user-quarantine-message-overview",
    },
  },
  {
    path: "profile",
    name: "profile",
    component: () => import("@/views/Profile/Security"),
  },
  {
    path: "customer/profile",
    component: () => import("@/views/Profile/Security"),
  },
];

export default mspRoutes;
