export const ID = Object.freeze({
  label: "Message ID",
  action: "setId",
  cookieName: "id",
});

export const RANGE = Object.freeze({
  label: "Date Range",
  action: "setDateRange",
  cookieName: "date_range",
});

export const RECIPIENT = Object.freeze({
  label: "Recipient",
  action: "setRecipient",
  cookieName: "recipient",
});

export const SENDER = Object.freeze({
  label: "Sender",
  action: "setSender",
  cookieName: "sender",
});

export const FROM = Object.freeze({
  label: "From Address",
  action: "setSender",
  cookieName: "sender",
});

export const SUBJECT = Object.freeze({
  label: "Subject",
  action: "setSubject",
  cookieName: "subject",
});

export const CUSTOMER = Object.freeze({
  label: "Customer",
  action: "setDomainGroup",
  cookieName: "domain_group",
});

export const DOMAIN = Object.freeze({
  label: "Domain",
  action: "setDomain",
  cookieName: "domain",
});

export const SCORE_COMPARE = Object.freeze({
  label: "Score",
  action: "setScore",
  cookieName: "score_compare",
});

export const SCORE = Object.freeze({
  label: "Score",
  action: "setScore",
  cookieName: "score",
});

export const TYPE = Object.freeze({
  label: "Message Type",
  action: "setMessageType",
  cookieName: "type",
});

export const ENVELOP_FROM = Object.freeze({
  label: "Envelope From",
  action: "setEnvelopeFrom",
  cookieName: "envelope_from",
});

export const DELIVER_STATUS = Object.freeze({
  label: "Delivery Status",
  action: "setDeliveryStatus",
  cookieName: "delivery_status",
});

export const IP = Object.freeze({
  label: "Source IP address",
  action: "setIp",
  cookieName: "ip",
});

export default {};
