<template>
  <edit-link-lock-exemption
    v-if="selectedDomain.id"
    :tier="tierType"
    :id="selectedDomain.id"
    :exemptionId="this.$route.params.id"
  />
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import EditLinkLockExemption from "@/views/Common/LinkLock/Exemptions/Edit/index.vue";

import { TIER } from "@/constants";

export default {
  name: "EditLinkLockExemptionWrapper",
  components: {
    EditLinkLockExemption,
  },
  data() {
    return {
      tierType: TIER.domains,
    };
  },
  computed: {
    ...mapGetters("authentication", ["selectedDomain"]),
  },
  methods: {
    ...mapActions("pageMeta", ["setPageTitle"]),
  },
  created() {
    this.setPageTitle(this.$t(`Edit Exemption: ${this.$route.params.id}`));
  },
};
</script>
