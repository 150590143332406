<template>
  <t-skeleton v-if="loading" />
  <form v-else @submit.prevent autocomplete="off" @keyup="changed">
    <div class="tw-grid tw-gap-x-4 md:tw-grid-cols-2">
      <cl-form-group v-if="isIp">
        <cl-form-label label-for="ip">
          {{ $t("IP") }}
        </cl-form-label>
        <cl-form-input
          autofocus
          id="ip"
          name="ip"
          data-test-id="ip"
          data-lpignore="true"
          v-model:value="form.ip"
          :placeholder="$t('ip')"
          :state="setInputState(v$.form.ip)"
          @on-blur="v$.form.ip.$touch"
        />
        <span
          class="tw-block tw-pt-1 tw-text-sm tw-text-danger-500"
          v-if="v$.form.ip.$error"
          >{{ $t("You should provide valid IP address") }}</span
        >
      </cl-form-group>

      <cl-form-group v-if="isHostname">
        <cl-form-label label-for="hostname">
          {{ $t("IP") }}
        </cl-form-label>
        <cl-form-input
          id="hostname"
          name="hostname"
          data-test-id="hostname"
          v-model:value="form.hostname"
          :placeholder="$t('Hostname')"
          :state="setInputState(v$.form.hostname)"
          @on-blur="v$.form.hostname.$touch"
        />
        <span
          class="tw-block tw-pt-1 tw-text-sm tw-text-danger-500"
          v-if="v$.form.hostname.$error"
          >{{ $t("This field is a required field") }}</span
        >
      </cl-form-group>
      <cl-form-group>
        <cl-form-label label-for="comment">
          {{ $t("Comment") }}
        </cl-form-label>
        <cl-form-input
          id="comment"
          name="comment"
          data-test-id="comment"
          v-model:value="form.comment"
        />
      </cl-form-group>
    </div>
    <div class="tw-flex tw-justify-end">
      <cl-button v-if="saving" :variant="'secondary'" disabled>
        <cl-spinner :size="'small'" />
        <span>Saving...</span>
      </cl-button>
      <cl-button
        v-else
        :variant="'secondary'"
        @on-click="submit"
        data-test-id="submit"
        :disabled="!isChanged"
      >
        <span>{{ this.$t("Save Changes") }}</span>
      </cl-button>
    </div>
  </form>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { useVuelidate } from "@vuelidate/core";
import { required, ipAddress } from "@vuelidate/validators";
import TSkeleton from "@/components/TSkeleton";

import { REGEX } from "@/constants";

export default {
  name: "EditCustomer",
  components: {
    TSkeleton,
  },
  setup() {
    return { v$: useVuelidate() };
  },
  validations() {
    return {
      form: {
        ip: {
          required,
          ipAddress,
        },
        hostname: {
          required,
        },
      },
    };
  },
  data() {
    return {
      IP: REGEX.IP,
      loading: false,
      isChanged: false,
      saving: false,
      form: {
        ip: null,
        hostname: null,
        comment: null,
      },
    };
  },

  computed: {
    ...mapGetters("antiSpoofing", ["selectedSender"]),
    isIp() {
      return "ip" in this.selectedSender;
    },
    isHostname() {
      return "hostname" in this.selectedSender;
    },
  },
  watch: {
    selectedSender: {
      immediate: true,
      deep: true,
      handler(sender) {
        if (!sender) {
          return;
        }

        const { ip, hostname, comment } = sender;

        this.form = {
          ip,
          hostname,
          comment,
        };
      },
    },
  },
  methods: {
    ...mapActions("pageMeta", ["setPageTitle", "setPageSubtitle"]),
    ...mapActions("toast", ["displayToast"]),
    ...mapActions("antiSpoofing", ["fetchSender", "updateSender"]),
    changed() {
      this.isChanged = true;
    },
    setInputState(input) {
      return input.$dirty ? !input.$error : null;
    },
    async submit() {
      if (this.isIp) {
        const isValid = await this.v$.form.ip.$validate();
        if (!isValid) {
          return;
        }
      }

      if (this.isHostname) {
        const isValid = await this.v$.form.hostname.$validate();
        if (!isValid) {
          return;
        }
      }

      try {
        this.saving = true;

        const sender = {
          ip: this.form.ip,
          hostname: this.form.hostname,
          comment: this.form.comment,
          netmask: this.selectedSender.netmask,
        };

        await this.updateSender({
          domainId: this.$route.params.domainId,
          id: this.$route.params.id,
          sender,
        });

        this.displayToast({
          title: this.$t("Success"),
          message: this.$t("Sender details updated successfully"),
          duration: 2000,
          variant: "success",
        });

        this.isChanged = false;
      } catch (_err) {
        // stub
      } finally {
        this.saving = false;
        this.$router.push({ name: "anti-spoofing" });
      }
    },
  },
  beforeMount() {
    this.setPageTitle("Sender id:");
    this.setPageSubtitle([this.$t("Edit Anti-Spoofing Sender")]);
  },
  async mounted() {
    this.loading = true;
    try {
      await this.fetchSender({
        domainId: this.$route.params.domainId,
        id: this.$route.params.id,
      });
    } catch (_err) {
      // stub
    } finally {
      this.loading = false;
      this.setPageTitle(this.$t("Sender id:") + this.$route.params.id);
    }
  },
};
</script>
