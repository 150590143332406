<template>
  <Teleport to="body">
    <cl-modal
      :visible="showModal"
      @on-close="onCloseModal"
      headerLabel="Add Scheduled Report"
    >
      <form @submit.prevent="submit" autocomplete="off">
        <div class="tw-grid sm:tw-grid-cols-2 sm:tw-gap-2">
          <cl-form-group>
            <cl-form-label label-for="Type">
              {{ this.$t("Type") }}
            </cl-form-label>
            <cl-form-select
              id="type"
              v-model:value="form.type"
              :options="typeOptions"
              @change="onTypeChange($event)"
            ></cl-form-select>
          </cl-form-group>
          <cl-form-group>
            <cl-form-label label-for="subject">
              {{ $t("Subject") }}
            </cl-form-label>
            <cl-form-input
              data-lpignore="true"
              id="subject"
              name="subject"
              v-model:value="form.subject"
              :placeholder="$t('Subject')"
              :state="setInputState(v$.form.subject)"
              @on-blur="v$.form.subject.$touch"
            />
            <div
              class="tw-block tw-text-sm tw-text-danger-500"
              v-if="v$.form.subject.$error"
            >
              {{ $t("This field is a required field") }}
            </div>
          </cl-form-group>

          <div class="sm:tw-col-span-2">
            <t-frequency v-model:frequency="form.frequency" :items="items" />
          </div>

          <div class="sm:tw-col-span-2">
            <div class="tw-flex tw-flex-col">
              <cl-form-label>
                {{ $t("Format") }}
              </cl-form-label>
              <cl-form-radio-group
                name="recepient"
                :selectedValue="form.format"
                :options="formatOptions"
                @update:selected="(selected) => (form.format = selected)"
                class="tw--ml-3"
              />
              <div
                v-if="v$.form.format.$error"
                class="tw-block tw-text-sm tw-text-danger-500"
              >
                {{ $t("This field is a required field") }}
              </div>
            </div>
          </div>
          <cl-hr class="sm:tw-col-span-2" />
          <div class="sm:tw-col-span-2">
            <div class="tw-flex tw-items-center tw-justify-between">
              <cl-form-label>
                {{ $t("Archive") }}
              </cl-form-label>
              <cl-form-radio-group
                name="switcher"
                :selectedValue="form.archive"
                @update:selected="(selected) => (form.archive = selected)"
                button
                :options="radioOptions"
              />
            </div>
          </div>
          <cl-hr class="sm:tw-col-span-2" />
          <div class="sm:tw-col-span-2">
            <cl-form-group>
              <cl-form-label label-for="recipients">
                {{ $t("Recipients") }}
              </cl-form-label>
              <cl-form-input
                data-lpignore="true"
                id="recipients"
                name="recipients"
                v-model:value="form.recipients"
                :placeholder="$t('Recipients separated by comma')"
                :state="setInputState(v$.form.recipients)"
                @on-blur="v$.form.recipients.$touch"
              />
              <span
                class="tw-block tw-text-sm tw-text-danger-500"
                v-if="v$.form.recipients.$error"
                >{{ $t("This field is a required field") }}</span
              >
            </cl-form-group>
          </div>
        </div>
      </form>

      <template v-slot:[`footer.decline`]>
        <cl-button
          :disabled="fetching"
          @on-click="closeModal"
          variant="link-secondary"
        >
          <span>{{ $t("Cancel") }}</span>
        </cl-button>
      </template>
      <template v-slot:[`footer.accept`]>
        <cl-button
          :variant="'secondary'"
          @on-click="submit"
          data-test-id="modal-scheduled-report-add-button"
        >
          <span v-if="!fetching">{{ this.$t("Add") }}</span>
          <cl-spinner v-else :size="'small'" />
        </cl-button>
      </template>
    </cl-modal>
  </Teleport>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { useVuelidate } from "@vuelidate/core";
import { required } from "@vuelidate/validators";

import {
  TIER,
  REPORT_CUSTOMER_TYPE,
  REPORT_DOMAIN_TYPE,
  EXTENSION,
} from "@/constants";

import TFrequency from "@/components/TFrequency";
const DEFAULT_FORM = Object.freeze({
  subject: "",
  frequency: "yesterday",
  format: "",
  recipients: "",
  archive: true,
});

const DOMAIN_FORM = {
  ...DEFAULT_FORM,
  type: "domsummary",
};

const CUSTOMER_FORM = {
  ...DEFAULT_FORM,
  type: "realmsummary",
};

export default {
  components: {
    TFrequency,
  },
  setup() {
    return { v$: useVuelidate() };
  },
  validations() {
    return {
      form: {
        format: {
          required,
        },
        subject: {
          required,
        },
        recipients: {
          required,
        },
      },
    };
  },
  props: {
    id: {
      type: [String, Number],
      required: true,
    },
    tier: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      fetching: false,
      formatOptions: [],
      // Note. Periods reference - https://gitlab.titanhq.com/titanhq/dev/st/spamtitan-api/-/issues/397
      frequencyOptions: [
        {
          text: this.$t("Daily"),
          id: "scheduled-report-frequency-daily",
          testID: "scheduled-report-frequency-daily",
          value: "yesterday",
        },
        {
          text: this.$t("Weekly"),
          id: "scheduled-report-frequency-weekly",
          testID: "scheduled-report-frequency-weekly",
          value: "prev_week",
        },
        {
          text: this.$t("Monthly"),
          id: "scheduled-report-frequency-monthly",
          testID: "scheduled-report-frequency-monthly",
          value: "prev_month",
        },
      ],
      frequencyOptionsLR: [
        {
          text: this.$t("Daily"),
          id: "scheduled-report-frequency-daily",
          testID: "scheduled-report-frequency-daily",
          value: "yesterday",
        },
      ],
      radioOptions: [
        { label: this.$t("Yes"), value: true },
        { label: this.$t("No"), value: false },
      ],
      form:
        this.$props.tier === TIER.domainGroups ? CUSTOMER_FORM : DOMAIN_FORM,
    };
  },
  computed: {
    ...mapGetters("modal", ["showModal"]),
    items() {
      return this.form.type === "licensereport"
        ? this.frequencyOptionsLR
        : this.frequencyOptions;
    },
    typeOptions() {
      let options = [];
      if (this.$props.tier === TIER.domainGroups) {
        options = Object.entries(REPORT_CUSTOMER_TYPE).map(([value, text]) => ({
          value,
          text: this.$t(text),
        }));
      }
      if (this.$props.tier === TIER.domains) {
        options = Object.entries(REPORT_DOMAIN_TYPE).map(([value, text]) => ({
          value,
          text: this.$t(text),
        }));
      }
      return options;
    },
  },
  methods: {
    ...mapActions("toast", ["displayToast"]),
    ...mapActions("scheduledReports", ["createReport"]),
    ...mapActions("modal", ["closeModal"]),
    onCloseModal() {
      this.closeModal();
      this.form = TIER.domainGroups ? { ...CUSTOMER_FORM } : { ...DOMAIN_FORM };
      this.v$.$reset();
    },
    onTypeChange(type) {
      // Note. Formats reference - https://gitlab.titanhq.com/titanhq/dev/st/spamtitan-api/-/issues/464
      if (type === "domaingroup" || type === "domainreport") {
        this.formatOptions = [
          { label: this.$t("CSV"), value: EXTENSION.csv },
          { label: this.$t("TXT"), value: EXTENSION.txt },
        ];
        this.form.format = EXTENSION.csv;

        return;
      }

      if (type === "licensereport") {
        this.formatOptions = [{ label: this.$t("CSV"), value: EXTENSION.csv }];
        this.form.format = EXTENSION.csv;

        this.form.frequency = "yesterday";

        return;
      }

      if (
        type === "linklock" ||
        type === "linklockrecip" ||
        type === "linklocksender"
      ) {
        this.formatOptions = [{ label: this.$t("PDF"), value: EXTENSION.pdf }];
        this.form.format = EXTENSION.pdf;

        return;
      }

      this.setDefaultFormatOptions();
    },
    setDefaultFormatOptions() {
      // Default
      this.formatOptions = [
        { label: this.$t("PDF"), value: EXTENSION.pdf },
        { label: this.$t("CSV"), value: EXTENSION.csv },
        { label: this.$t("TXT"), value: EXTENSION.txt },
      ];

      this.form.format = EXTENSION.pdf;
    },
    setInputState(input) {
      return input.$dirty ? !input.$error : null;
    },
    async submit() {
      const isValid = await this.v$.form.$validate();

      if (!isValid) {
        return;
      }

      try {
        this.fetching = true;

        await this.createReport({
          tier: this.$props.tier,
          id: this.$props.id,
          formData: {
            ...this.form,
            recipients: this.form.recipients.split(","),
          },
        });

        this.displayToast({
          title: this.$t("Success"),
          message: this.$t("The report has been created"),
          duration: 2000,
          variant: "success",
        });

        this.onCloseModal();
      } catch (_err) {
        // stub
      } finally {
        this.fetching = false;
      }
    },
  },
  created() {
    this.setDefaultFormatOptions();
  },
};
</script>
