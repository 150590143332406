<template>
  <section id="filter">
    <div class="tw-relative">
      <form @submit.prevent="submit" @change="isChange = true">
        <div
          class="tw-grid tw-gap-2 tw-rounded tw-bg-white tw-p-4 tw-pb-0 md:tw-grid-cols-4"
        >
          <cl-form-group>
            <cl-form-label label-for="message-type" position="border">
              {{ $t("Date Range") }}
            </cl-form-label>
            <cl-form-select
              id="date-range"
              v-model:value="date_range"
              :options="dateRangeOptions"
            ></cl-form-select>
          </cl-form-group>
          <cl-form-group>
            <cl-form-label label-for="recipient-email" position="border">
              {{ $t("Recipient (To)") }}
            </cl-form-label>
            <cl-form-input
              id="recipient-email"
              name="recipient-email"
              v-model:value="recipient"
              size="medium"
              :disabled="isRecipientStatic"
            />
          </cl-form-group>
          <cl-form-group>
            <cl-form-label label-for="sender-email" position="border">
              {{ $t("From Address") }}
            </cl-form-label>
            <cl-form-input
              id="sender-email"
              name="sender-email"
              v-model:value="sender"
              size="medium"
            />
          </cl-form-group>
          <cl-form-group>
            <cl-form-label label-for="subject" position="border">
              {{ $t("Subject") }}
            </cl-form-label>
            <cl-form-input
              id="subject"
              name="subject"
              v-model:value="subject"
              size="medium"
            />
          </cl-form-group>
        </div>
        <div
          v-if="isAdvancedFilter"
          class="tw-grid tw-gap-2 tw-rounded tw-bg-white tw-p-2 tw-pb-0 md:tw-grid-cols-4"
        >
          <cl-form-group v-if="!isDisabledDate">
            <cl-form-label label-for="date-from" position="border">
              <div>{{ $t("Date From") }}<sup>*</sup></div>
            </cl-form-label>
            <cl-form-input
              type="date"
              id="date-from"
              name="date-from"
              v-model:value="start_date"
              size="medium"
              @on-keydown.prevent
              :max="maxDate"
              :disabled="isDisabledDate"
            />
          </cl-form-group>
          <cl-form-group v-if="!isDisabledDate">
            <cl-form-label label-for="date-to" position="border">
              {{ $t("Date To") }}
            </cl-form-label>
            <cl-form-input
              type="date"
              id="date-to"
              name="date-to"
              v-model:value="end_date"
              size="medium"
              @on-keydown.prevent
              :max="maxDate"
              :min="start_date"
              :disabled="isDisabledDate"
            />
          </cl-form-group>
          <cl-form-group v-if="isShowCustomer">
            <cl-form-label label-for="domain_group" position="border">
              {{ $t("Customer") }}
            </cl-form-label>
            <cl-form-select
              id="domain_group"
              v-model:value="domain_group"
              type="select"
              ref="domain_group"
              :options="customerOptions"
              class="tw-border-dorian-gray-100"
            />
          </cl-form-group>
          <cl-form-group v-if="isShowDomain">
            <cl-form-label label-for="domain" position="border">
              {{ $t("Domain") }}
            </cl-form-label>

            <cl-form-select
              id="domain"
              v-model:value="domain"
              type="select"
              ref="domain"
              :options="domainOptions"
              :disabled="fetchingDomains"
            />
          </cl-form-group>
          <cl-form-group>
            <cl-form-label label-for="messageType" position="border">
              {{ $t("Message Type") }}
            </cl-form-label>
            <cl-form-select
              id="messageType"
              v-model:value="type"
              type="select"
              :options="messageTypeOptions"
              selected="selected"
            />
          </cl-form-group>
          <div class="md:tw-col-span-2">
            <cl-form-group>
              <cl-form-label label-for="score-key" position="border">
                {{ $t("Score") }}
              </cl-form-label>
              <div class="tw-flex">
                <cl-form-select
                  id="score-key"
                  v-model:value="score_compare"
                  :options="scoreCompareOptions"
                ></cl-form-select>
                <template v-if="score_compare !== '' && score_compare !== '*'">
                  <cl-form-input
                    id="score-first"
                    type="number"
                    name="score-first"
                    v-model:value="score[0]"
                    size="medium"
                    step="0.001"
                  />
                </template>
                <template v-if="score_compare === 'between'">
                  <cl-form-input
                    id="score-second"
                    type="number"
                    name="score-second"
                    v-model:value="score[1]"
                    size="medium"
                    step="0.001"
                  />
                </template>
              </div>
            </cl-form-group>
          </div>
        </div>
        <div class="tw-grid tw-rounded tw-bg-white tw-px-2 sm:tw-grid-cols-1">
          <!-- row actions -->
          <div class="tw-mb-1.5 tw-flex tw-justify-end">
            <cl-button
              variant="secondary"
              type="submit"
              data-test-id="submit-button"
            >
              <span v-if="!fetching" class="tw-text-white">
                {{ $t("Apply") }}
              </span>
              <span class="px-2" v-else><cl-spinner size="small" /></span>
            </cl-button>
          </div>
        </div>
      </form>
      <button
        variant="secondary"
        class="tw-absolute tw-bottom-4 tw-left-5"
        @click="isAdvancedFilter = !isAdvancedFilter"
      >
        <div class="tw-align-center tw-flex tw-gap-1">
          <span class="tw-whitespace-nowrap tw-font-bold tw-text-secondary-500">
            {{ $t("Advanced Filter") }}
          </span>
          <div
            class="tw-flex tw-items-center tw-pt-1 tw-text-xs tw-text-secondary-500"
          >
            <span v-if="isAdvancedFilter" class="tw-rotate-180 tw-pt-1"
              >&#9660;</span
            >
            <span v-else>&#9660;</span>
          </div>
        </div>
      </button>
      <cl-button
        variant="link-secondary"
        @on-click="clearLocalSearch"
        class="tw-absolute tw-bottom-1 tw-right-24 tw-mr-2"
        data-test-id="clear-search-button"
        >{{ $t("Clear Search") }}</cl-button
      >
    </div>
  </section>
  <!-- Pills -->
  <section>
    <div class="w-2/3 tw-mb-0 tw-mt-2 tw-flex tw-flex-wrap tw-items-center">
      <cl-active-filter
        :label="$t('Date From')"
        :value="start_date"
        :with-close="false"
        class="tw-my-2 tw-mr-2"
      />
      <cl-active-filter
        :label="$t('Date To')"
        :value="end_date"
        :with-close="false"
        class="tw-my-2 tw-mr-2"
      />
      <div v-for="(pill, index) in pills" :key="index">
        <cl-active-filter
          v-if="pill.condition"
          :label="$t(pill.label)"
          :value="pill.value"
          class="tw-my-2 tw-mr-2"
          @remove="actionsHandler(pill)"
          :with-close="!(pill.value === recipient && isRecipientStatic)"
        />
      </div>
    </div>
  </section>
  <section>
    <cl-data-table
      :data-test-id="'archive-reports-data-table'"
      :fetching="fetching"
      :headers="tableHeaders"
      :items="items"
      :default-sort-by="sortByCookie"
      :default-sort-desc="sortDirCookie"
      :default-items-on-page="maxItemsOnPage"
      with-delete
      with-release
      with-allow
      with-select
      with-set-columns
      @change-page="changePage"
      @sort-items="onSortItemsClick"
      @change-selected-columns="setSelectedColumnsCookie"
      @on-delete-items="onDelete"
      @on-release-items="onRelease"
      @on-allow-items="onAllow"
    >
      <template v-slot:actions>
        <cl-button variant="secondary" @on-click="refresh" :disabled="fetching">
          {{ $t("Refresh") }}
        </cl-button>
      </template>
      <template v-slot:[`body.cell.actions`]="props">
        <cl-data-table-view-action
          data-test-id="archive-reports-data-table-view-btn"
          @on-click="onView(props.trItem)"
        />
      </template>
      <template v-slot:[`body.cell.sender`]="props">
        <div class="tw-relative tw-inline-flex">
          <template v-if="props.tdValue.length > truncateMaxNumber">
            <cl-tooltip :text="props.tdValue" :id="props.tdValue">
              <span v-truncate> {{ props.tdValue }} </span>
            </cl-tooltip>
          </template>
          <template v-else>{{ props.tdValue }}</template>
        </div>
      </template>
      <template v-slot:[`body.cell.recipient`]="props">
        <div class="tw-relative tw-inline-flex">
          <template v-if="props.tdValue.length > truncateMaxNumber">
            <cl-tooltip :text="props.tdValue" :id="props.tdValue">
              <span v-truncate> {{ props.tdValue }} </span>
            </cl-tooltip>
          </template>
          <template v-else>{{ props.tdValue }}</template>
        </div>
      </template>
      <template v-slot:[`body.cell.smtp_reason`]="props">
        <div class="tw-relative tw-inline-flex">
          <template v-if="props.tdValue.length > truncateMaxNumber">
            <cl-tooltip :text="props.tdValue" :id="props.tdValue">
              <span v-truncate> {{ props.tdValue }} </span>
            </cl-tooltip>
          </template>
          <template v-else>{{ props.tdValue }}</template>
        </div>
      </template>
      <template v-slot:[`body.cell.subject`]="props">
        <div class="tw-relative tw-inline-flex">
          <template v-if="props.tdValue.length > truncateMaxNumber">
            <cl-tooltip :text="props.tdValue" :id="props.tdValue">
              <span v-truncate> {{ props.tdValue }} </span>
            </cl-tooltip>
          </template>
          <template v-else>{{ props.tdValue }}</template>
        </div>
      </template>
      <template v-slot:[`body.cell.client_address`]="props">
        <div class="tw-flex tw-items-center tw-gap-2">
          <div>
            <country-flag :country="props.trItem.country_of_origin_iso ?? ''" />
          </div>
          <div>{{ props.tdValue }}</div>
        </div>
      </template>
      <template v-slot:[`body.cell.message_type`]="props">
        <t-message-type :messageType="props.trItem.message_type">
          <template v-slot:messageType>
            <div class="tw-text-lg tw-leading-4">
              {{ props.trItem.message_type }}
            </div>
          </template>
          <template v-slot:details>
            <div class="tw-text-sm">
              {{ setMessageTypeDetails(props.trItem) }}
            </div>
          </template>
        </t-message-type>
      </template>
      <template v-slot:pagination>
        <div class="tw-grid tw-grid-cols-12 tw-gap-1">
          <div class="tw-col-span-12 md:tw-col-span-6">
            <div class="tw-relative">
              <div
                class="tw-mr-4 tw-inline-flex tw-h-10 tw-items-baseline tw-justify-start"
              >
                <label
                  class="tw-mr-4 tw-whitespace-nowrap tw-text-sm tw-text-dorian-gray-500"
                >
                  {{ $t("Rows per page:") }}
                </label>
                <select
                  v-model="maxItemsOnPage"
                  name="rows-per-page"
                  class="tw-focus:border-gray-200 peer tw-block tw-w-full tw-appearance-none tw-border-0 tw-border-b-2 tw-border-solid tw-border-dorian-gray-100/80 tw-bg-white tw-text-sm tw-text-dorian-gray-500 tw-outline-none focus:tw-border-secondary-500 focus:tw-ring-0"
                  @change="changeItemsOnPage"
                >
                  <option
                    v-for="value in itemsPerPage"
                    :key="value"
                    :value="value"
                    :selected="value === maxItemsOnPage"
                  >
                    {{ value }}
                  </option>
                </select>
              </div>
              <div
                v-if="items.length !== 0"
                class="tw-inline-flex tw-h-10 tw-items-baseline tw-justify-start"
              >
                <span class="tw-text-sm tw-text-dorian-gray-500"
                  >{{ indexPointer.start + 1 }}-{{
                    indexPointer.start + items.length
                  }}</span
                >
              </div>
            </div>
          </div>
          <div class="tw-col-span-12 md:tw-col-span-6">
            <div class="tw-relative tw-flex tw-justify-end">
              <button
                class="tw-bg-transparent tw-mr-[-1px] tw-rounded-l tw-border-2 tw-border-solid tw-border-secondary-500 tw-px-2 tw-text-sm tw-font-medium tw-text-dorian-gray-200 hover:tw-text-secondary-500 disabled:tw-cursor-default disabled:tw-border-r disabled:tw-border-dorian-gray-100/80 disabled:tw-text-dorian-gray-200"
                :disabled="currentPage === 1"
                @click="goPrev"
              >
                <span>&lt;</span>
              </button>
              <button
                class="tw-bg-transparent tw-ml-[-1px] tw-rounded-r tw-border-2 tw-border-solid tw-border-secondary-500 tw-px-2 tw-text-sm tw-font-medium tw-text-dorian-gray-200 hover:tw-text-secondary-500 disabled:tw-cursor-default disabled:tw-border-l disabled:tw-border-dorian-gray-100/80 disabled:tw-text-dorian-gray-200"
                :disabled="counter < dataOptions.results"
                @click="goNext"
              >
                <span>&gt;</span>
              </button>
            </div>
          </div>
        </div>
      </template>
    </cl-data-table>
  </section>
  <t-quarantine-confirm />
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import CountryFlag from "vue-country-flag-next";
import dayjs from "dayjs";

import { useDataTable } from "@/composables/useDataTable";
import { useDataTableCookies } from "@/composables/useDataTableCookies";
import { checkDateRange } from "@/composables/useDateRange";
import {
  COMPARE_OPTION,
  QUARANTINE_MESSAGE_TYPE,
  TIER,
  DATE_FORMAT,
  DEFAULT_OPTIONS,
  MESSAGE_TYPE,
  TRUNCATE,
  DATE_RANGE,
  ITEMS_PER_PAGE,
  RANGE,
  RECIPIENT,
  FROM,
  SUBJECT,
  CUSTOMER,
  DOMAIN,
  SCORE_COMPARE,
  SCORE,
  TYPE,
} from "@/constants";
import getMessageType from "@/utils/get-message-type-quarantine";
import TMessageType from "@/components/TMessageType";
import TQuarantineConfirm from "@/modals/TQuarantineConfirm.vue";
import { usePills } from "@/composables/usePills";
import { initialFiltersState } from "@/store/modules/quarantinedMessages.js";
import { ref, computed } from "vue";

const ALL_DOMAINS = { value: "@.", text: "All" };
const ALL_CUSTOMERS = { value: "@.", text: "All" };
const storeModule = "quarantinedMessages";

export default {
  name: "QuarantineCommon",
  components: {
    CountryFlag,
    TMessageType,
    "t-quarantine-confirm": TQuarantineConfirm,
  },
  props: {
    tier_id: {
      type: [String, Number],
      default: null,
    },
    tier: {
      type: String,
      default: null,
    },
  },
  setup(props) {
    const cookieKey = `data-table-quarantine-${props.tier}`;
    const pillsKey = `q-${props.tier}-${props.tier_id}`;
    const STARTING_PAGE = 1;

    const {
      sortByCookie,
      sortDirCookie,
      itemsPerPageCookie,
      selectedColumnsCookie,
      setSortItemsCookie,
      setItemsPerPageCookie,
      setSelectedColumnsCookie,
    } = useDataTableCookies(
      {
        sortByKey: `${cookieKey}-sort-by`,
        sortDirKey: `${cookieKey}-sort-dir`,
        itemsPerPageKey: `${cookieKey}-items-per-page`,
        selectedColumnsKey: `${cookieKey}-selected-columns`,
      },
      {
        ...DEFAULT_OPTIONS,
        sortBy: "message_received",
      }
    );

    const optionsBasedOnCookies = {
      sortBy: sortByCookie,
      sortDir: sortDirCookie,
      results: itemsPerPageCookie || DEFAULT_OPTIONS.results,
      page: 1,
    };

    const {
      dataOptions,
      sortItems,
      changePage,
      changeItemsPerPage,
      debouncedSearch,
    } = useDataTable(optionsBasedOnCookies);

    const truncateMaxNumber = TRUNCATE.max;

    const {
      scoreLS,
      scoreCompareLS,
      typeLS,
      senderLS,
      recipientLS,
      subjectLS,
      domainGroupLS,
      domainLS,
      setPills,
      deletePill,
      dateRangeLS,
      startDateLS,
      endDateLS,
      clearPills,
    } = usePills({
      scoreKey: `${pillsKey}-score`,
      scoreCompareKey: `${pillsKey}-score_compare`,
      typeKey: `${pillsKey}-type`,
      senderKey: `${pillsKey}-sender`,
      recipientKey: `${pillsKey}-recipient`,
      subjectKey: `${pillsKey}-subject`,
      domainKey: `${pillsKey}-domain`,
      domainGroupKey: `${pillsKey}-domain_group`,
      dateRangeKey: `${pillsKey}-date_range`,
      startDateKey: `${pillsKey}-start_date`,
      endDateKey: `${pillsKey}-end_date`,
    });

    const maxItemsOnPage = ref(dataOptions.value.results);
    const isAdvancedFilter = ref(false);

    const changeItemsOnPage = () => {
      currentPage.value = STARTING_PAGE;
      setItemsPerPageCookie(maxItemsOnPage.value);
      changeItemsPerPage(maxItemsOnPage.value, currentPage.value);
    };

    const indexPointer = computed(() => {
      const end = currentPage.value * maxItemsOnPage.value;
      const start = end - maxItemsOnPage.value;

      return { start, end };
    });

    const currentPage = ref(STARTING_PAGE);
    const goNext = () => {
      currentPage.value = currentPage.value + 1;
      changePage(currentPage.value);
    };

    const goPrev = () => {
      currentPage.value = currentPage.value - 1;
      changePage(currentPage.value);
    };

    return {
      debouncedSearch,
      dataOptions,
      sortItems,
      changePage,
      changeItemsPerPage,
      sortByCookie,
      sortDirCookie,
      itemsPerPageCookie,
      selectedColumnsCookie,
      setSortItemsCookie,
      setItemsPerPageCookie,
      setSelectedColumnsCookie,
      truncateMaxNumber,
      scoreLS,
      scoreCompareLS,
      typeLS,
      senderLS,
      recipientLS,
      subjectLS,
      setPills,
      deletePill,
      pillsKey,
      domainGroupLS,
      domainLS,
      dateRangeLS,
      startDateLS,
      endDateLS,
      clearPills,
      goNext,
      goPrev,
      currentPage,
      changeItemsOnPage,
      maxItemsOnPage,
      indexPointer,
      isAdvancedFilter,
    };
  },

  data() {
    return {
      loading: false,
      fetching: false,
      isAdvancedSearch: false,
      serverItemsLength: 0,
      items: [],
      domainOptions: [ALL_DOMAINS],
      customerOptions: [ALL_CUSTOMERS],
      scoreCompareOptions: Object.entries(COMPARE_OPTION).map(
        ([value, text]) => ({
          value,
          text: this.$t(text),
        })
      ),
      dateRangeOptions: Object.entries(DATE_RANGE).map(([value, text]) => ({
        value,
        text: this.$t(text),
      })),
      messageTypeOptions: Object.entries(QUARANTINE_MESSAGE_TYPE).map(
        ([value, text]) => ({
          value,
          text: this.$t(text),
        })
      ),
      defaultFilters: initialFiltersState,
      itemsPerPage: ITEMS_PER_PAGE.quarantine,
      isChange: false,
    };
  },
  computed: {
    ...mapGetters(storeModule, ["messages", "serverTotal", "counter"]),
    ...mapGetters("authentication", ["selectedMsp", "selectedAccount"]),
    ...mapGetters("domains", ["domains", "fetchingDomains"]),
    ...mapGetters("msps", ["mspCustomers", "fetchingCustomers"]),
    ...mapGetters("message", ["host"]),
    isDisabledDate() {
      return this.date_range !== "custom";
    },
    tableHeaders() {
      const headers = [
        { key: "message_received", label: this.$t("Date"), sortable: true },
        {
          key: "client_address",
          label: this.$t("Client Address"),
          sortable: false,
        },
        { key: "sender", label: this.$t("From"), sortable: true },
        { key: "recipient", label: this.$t("To"), sortable: true },
        {
          key: "message_type",
          label: this.$t("Message Type"),
          sortable: false,
        },
        { key: "subject", label: this.$t("Subject"), sortable: true },
        {
          key: "delivery_status",
          label: this.$t("Delivery Status"),
          sortable: false,
        },
        { key: "id", label: this.$t("SpamTitan ID"), sortable: true },
        { key: "actions", label: this.$t("Actions"), sticky: true },
      ];

      if (!this.selectedColumnsCookie?.length) {
        return headers;
      }

      return headers.map((header) => ({
        ...header,
        enabled: this.selectedColumnsCookie.includes(header.key),
      }));
    },
    date_range: {
      get() {
        return this.$store.state.quarantinedMessages.filters.date_range;
      },
      set(value) {
        this.$store.commit(`${storeModule}/setDateRange`, value);
      },
    },
    start_date: {
      get() {
        return this.$store.state.quarantinedMessages.filters.start_date;
      },
      set(value) {
        this.$store.commit(`${storeModule}/setStartDate`, value);
      },
    },
    end_date: {
      get() {
        return this.$store.state.quarantinedMessages.filters.end_date;
      },
      set(value) {
        this.$store.commit(`${storeModule}/setEndDate`, value);
      },
    },
    sender: {
      get() {
        return this.$store.state.quarantinedMessages.filters.sender;
      },
      set(value) {
        this.$store.commit(`${storeModule}/setSender`, value);
      },
    },
    domain: {
      get() {
        return this.$store.state.quarantinedMessages.filters.domain;
      },
      set(value) {
        this.$store.commit(`${storeModule}/setDomain`, value);
      },
    },
    domain_group: {
      get() {
        return this.$store.state.quarantinedMessages.filters.domain_group;
      },
      set(value) {
        this.$store.commit(`${storeModule}/setDomainGroup`, value);
      },
    },
    recipient: {
      get() {
        return this.$store.state.quarantinedMessages.filters.recipient;
      },
      set(value) {
        this.$store.commit(`${storeModule}/setRecipient`, value);
      },
    },
    type: {
      get() {
        return this.$store.state.quarantinedMessages.filters.type;
      },
      set(value) {
        this.$store.commit(`${storeModule}/setMessageType`, value);
      },
    },
    subject: {
      get() {
        return this.$store.state.quarantinedMessages.filters.subject;
      },
      set(value) {
        this.$store.commit(`${storeModule}/setSubject`, value);
      },
    },
    score_compare: {
      get() {
        return this.$store.state.quarantinedMessages.filters.score_compare;
      },
      set(value) {
        this.$store.commit(`${storeModule}/setScoreCompare`, value);
      },
    },
    score: {
      get() {
        return this.$store.state.quarantinedMessages.filters.score;
      },
      set(value) {
        this.$store.commit(`${storeModule}/setScore`, value);
      },
    },
    isShowDomain() {
      return this.tier === TIER.domainGroups;
    },
    isShowCustomer() {
      return this.tier === TIER.msps;
    },
    isRecipientStatic() {
      return this.tier === TIER.users;
    },
    maxDate() {
      const today = new Date().toISOString().split("T")[0];
      return today;
    },
    isAccountUser() {
      return this.selectedAccount.account_type_name === "user";
    },
    pills() {
      return [
        {
          ...RANGE,
          condition: !!this.date_range && this.date_range !== "null",
          value: DATE_RANGE[this.date_range],
        },
        {
          ...RECIPIENT,
          condition: !!this.recipient,
          value: this.recipient,
        },
        {
          ...FROM,
          condition: !!this.sender,
          value: this.sender,
        },
        {
          ...SUBJECT,
          condition: !!this.subject,
          value: this.subject,
        },
        {
          ...CUSTOMER,
          condition: !!this.domain_group && this.domain_group !== "@.",
          value: this.domain_group,
        },
        {
          ...DOMAIN,
          condition: !!this.domain && this.domain !== "@.",
          value: this.domain,
        },
        {
          ...SCORE_COMPARE,
          condition:
            !!this.score &&
            this.score_compare !== "*" &&
            this.score_compare !== "between",
          value: `${this.score_compare} ${this.score[0]}`,
        },
        {
          ...SCORE,
          condition:
            !!this.score &&
            this.score_compare !== "*" &&
            this.score_compare === "between",
          value: `${this.score_compare} ${this.score[0]} and ${this.score[1]}`,
        },
        {
          ...TYPE,
          condition:
            !!this.type && this.type !== "all" && this.type.length !== 0,
          value: this.type,
        },
      ];
    },
  },
  watch: {
    dataOptions: {
      handler() {
        this.submit();
      },
      deep: true,
      immediate: false,
    },

    messages: {
      handler(newMessages) {
        if (newMessages !== undefined) {
          const messages = newMessages.map((message) => ({
            ...message,
            virus: message.virus !== null ? message.virus.virus_name : "",
            sender:
              message.sender === "" ? this.$t("MAILER-DAEMON") : message.sender,
            type: this.replaceContentType(message.content_type),
            message_received: `${dayjs(message.message_received).format(
              DATE_FORMAT.SHORT
            )}`,
          }));
          this.items = messages;
        }
      },
      deep: true,
      immediate: true,
    },
    domains: {
      handler(domains) {
        const options = domains.map((domain) => ({
          value: domain.domain,
          text: domain.domain,
        }));

        this.domainOptions = [ALL_DOMAINS, ...options];
      },
      deep: true,
      immediate: true,
    },
    mspCustomers: {
      handler(mspCustomers) {
        const options = mspCustomers.map((customer) => ({
          value: customer.name,
          text: customer.name,
        }));

        this.customerOptions = [ALL_CUSTOMERS, ...options];
      },
      deep: true,
      immediate: true,
    },
    date_range: {
      handler() {
        checkDateRange(this.date_range, storeModule);
        if (!this.isDisabledDate) {
          this.isAdvancedFilter = true;
        }
      },
      deep: true,
      immediate: true,
    },
    isChange: {
      handler() {
        if (this.dataOptions.page > 1) {
          this.changePage(1);
          this.currentPage = 1;
        }
        this.onChangeAdvancedSearch();
        this.isChange = false;
      },
      deep: true,
      immediate: false,
    },
  },
  methods: {
    ...mapActions("toast", ["displayToast"]),
    ...mapActions("pageMeta", ["setPageTitle", "setPageSubtitle"]),
    ...mapActions(storeModule, [
      "fetchQuarantinedMessages",
      "clearSearch",
      "batchDeleteQuarantinedMessages",
      "clearEmailsList",
      "setSelectedQuarantine",
      "batchReleaseQuarantineMessage",
      "batchAllowQuarantineMessage",
    ]),
    ...mapActions("modal", ["openModal", "closeModal"]),
    ...mapActions("message", ["setHost"]),
    replaceContentType(key) {
      return this.$t(getMessageType(key));
    },
    setMessageTypeDetails(item) {
      if (item.message_type === MESSAGE_TYPE.virus) {
        return item.virus;
      }
      if (item.message_type === MESSAGE_TYPE.spam) {
        return `${this.$t("Score")}: ${item.spam_level}`;
      }
      return;
    },
    async onDelete(messages) {
      try {
        this.loading = true;
        await this.batchDeleteQuarantinedMessages({
          tier: this.tier,
          id: this.tier_id,
          messages,
        });
        if (this.messages.length === 0 && this.dataOptions.page > 0) {
          this.dataOptions.page = this.dataOptions.page - 1;
        }
        this.displayToast({
          title: this.$t("Success"),
          message: this.$t("Selected Messages Deleted"),
          duration: 2000,
          variant: "success",
        });
      } catch (_err) {
        // stub
      } finally {
        this.loading = false;
        this.submit();
      }
    },
    checkAccount(messages) {
      if (this.isAccountUser) {
        let showVirusMessage = false;
        messages.forEach((m) => {
          if (m.message_type === "Virus" && !showVirusMessage) {
            this.openModal();
            showVirusMessage = true;
          }
        });
      }
    },
    async onRelease(messages) {
      this.checkAccount(messages);
      try {
        await this.batchReleaseQuarantineMessage({
          tier: this.tier,
          id: this.tier_id,
          messages,
          isUser: this.isAccountUser,
        });
        if (this.messages.length === 0 && this.dataOptions.page > 0) {
          this.dataOptions.page = this.dataOptions.page - 1;
        }
        this.displayToast({
          title: this.$t("Success"),
          message: this.$t("Selected Messages Released"),
          duration: 2000,
          variant: "success",
        });
      } catch (_err) {
        // stub
      } finally {
        this.submit();
      }
    },
    async onAllow(messages) {
      this.checkAccount(messages);
      try {
        await this.batchAllowQuarantineMessage({
          tier: this.tier,
          id: this.tier_id,
          messages,
          isUser: this.isAccountUser,
        });
        if (this.messages.length === 0 && this.dataOptions.page > 0) {
          this.dataOptions.page = this.dataOptions.page - 1;
        }
        this.displayToast({
          title: this.$t("Success"),
          message: this.$t("Selected Messages Allowed"),
          duration: 2000,
          variant: "success",
        });
      } catch (_err) {
        // stub
      } finally {
        this.submit();
      }
    },
    async onView(message) {
      this.setSelectedQuarantine(message);
      await this.setHost(message.host);
      const messageDetails = this.messages.find((m) => m.id === message.id);
      this.$store.commit("message/setMessageDetails", messageDetails);
      let routerName = "";
      switch (this.tier) {
        case TIER.domains:
          routerName = "domain-quarantine-message-overview";
          break;
        case TIER.users:
          routerName = "user-quarantine-message-overview";
          break;
        case TIER.msps:
          routerName = "msp-quarantine-message-overview";
          break;
        default:
          routerName = "customer-quarantine-message-overview";
      }
      await this.$router.push({
        name: routerName,
        params: { id: message.id },
      });
    },
    onSortItemsClick(by, dir) {
      this.setSortItemsCookie(by, dir);
      this.sortItems(by, dir);
    },
    checkScoreValue() {
      const isScoreEmpty = this.score[0] === "";
      const isBetweenComparisonEmpty =
        this.score_compare === "between" &&
        (this.score[0] === "" || this.score[1] === "");

      if (this.score_compare && (isScoreEmpty || isBetweenComparisonEmpty)) {
        this.$store.commit(
          "quarantinedMessages/setScore",
          this.defaultFilters.score
        );
        this.$store.commit(
          "quarantinedMessages/setScoreCompare",
          this.defaultFilters.score_compare
        );
      }
    },
    clearLocalSearch() {
      this.clearSearch(this.isRecipientStatic);
      this.clearScore();
      this.clearPills(this.isRecipientStatic);
      this.submit();
    },
    refresh() {
      this.submit();
    },
    async submit() {
      this.checkScoreValue();
      try {
        this.fetching = true;

        this.clearEmailsList();
        await this.fetchQuarantinedMessages({
          tier: this.tier,
          id: this.tier_id,
          ...this.dataOptions,
        });
      } catch (_err) {
        // stub
      } finally {
        this.fetching = false;
        this.isChange = false;
      }
    },

    actionsHandler(pill) {
      const { action, cookieName } = pill;
      const { date_range, start_date, end_date } = this.defaultFilters;
      if (action === "setScore") {
        this.clearScore();
      } else if (pill.action === "setMessageType") {
        this.$store.commit(
          `quarantinedMessages/${pill.action}`,
          this.defaultFilters.type
        );
        this.deletePill(`${this.pillsKey}-${cookieName}`);
      } else if (action === "setDateRange") {
        this.$store.commit(`${storeModule}/${action}`, date_range);
        this.$store.commit(`${storeModule}/setStartDate`, start_date);
        this.$store.commit(`${storeModule}/setEndDate`, end_date);
        this.deletePill(`${this.pillsKey}-${cookieName}`);
        this.deletePill(`${this.pillsKey}-start_date`);
        this.deletePill(`${this.pillsKey}-end_date`);
      } else {
        this.$store.commit(`${storeModule}/${action}`, "");
        this.deletePill(`${this.pillsKey}-${cookieName}`);
      }
      this.submit();
    },
    clearScore() {
      this.$store.commit(`${storeModule}/setScore`, this.defaultFilters.score);
      this.$store.commit(
        `${storeModule}/setScoreCompare`,
        this.defaultFilters.score_compare
      );
      this.deletePill(`${this.pillsKey}-score_compare`);
      this.deletePill(`${this.pillsKey}-score`);
    },
    onChangeAdvancedSearch() {
      this.setPills({
        score: this.score,
        score_compare: this.score_compare,
        sender: this.sender,
        recipient: this.recipient,
        subject: this.subject,
        date_range: this.date_range,
        start_date: this.start_date,
        end_date: this.end_date,
        ...(this.type !== "all" && this.type && { type: this.type }),
        ...(this.domain_group !== "@." &&
          this.domain_group && { domain_group: this.domain_group }),
        ...(this.domain !== "@." && this.domain && { domain: this.domain }),
      });
    },
  },
  mounted() {
    this.setPageTitle(this.$t("Quarantine"));
    this.setPageSubtitle([]);
    const localStorageKeys = [
      {
        key: this.recipientLS,
        mutation: "quarantinedMessages/setRecipient",
      },
      {
        key: this.subjectLS,
        mutation: "quarantinedMessages/setSubject",
      },
      {
        key: this.senderLS,
        mutation: "quarantinedMessages/setSender",
      },
      {
        key: this.scoreLS,
        mutation: "quarantinedMessages/setScore",
      },
      {
        key: this.scoreCompareLS,
        mutation: "quarantinedMessages/setScoreCompare",
      },
      {
        key: this.typeLS,
        mutation: "quarantinedMessages/setMessageType",
      },
      {
        key: this.domainLS,
        mutation: "quarantinedMessages/setDomain",
      },
      {
        key: this.domainGroupLS,
        mutation: "quarantinedMessages/setDomainGroup",
      },
      {
        key: this.startDateLS || this.defaultFilters.start_date,
        mutation: "quarantinedMessages/setStartDate",
      },
      {
        key: this.endDateLS || this.defaultFilters.end_date,
        mutation: "quarantinedMessages/setEndDate",
      },
      {
        key: this.dateRangeLS,
        mutation: "quarantinedMessages/setDateRange",
      },
    ];
    localStorageKeys.forEach(({ key, mutation }) => {
      if (key) {
        this.$store.commit(mutation, key);
        checkDateRange(key, "quarantinedMessages");
      }
    });
    this.submit();
  },
  beforeUnmount() {
    this.clearSearch();
    this.clearEmailsList();
  },
};
</script>
