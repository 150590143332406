<template>
  <t-skeleton v-if="loading" />
  <div v-else>
    <form
      v-if="selectedPolicy !== null"
      class="domain-policy-wrapper"
      @keydown="checkChanges"
      @change="checkChanges"
      @submit.prevent="submit"
    >
      <!-- Spam Filtering -->
      <div
        v-bind:class="{
          'tw-bg-white': selectedPolicy.spam.enabled,
          'tw-border-b tw-border-dorian-gray-100': !selectedPolicy.spam.enabled,
        }"
      >
        <div class="tw-flex tw-items-center tw-justify-between tw-p-4">
          <span class="tw-text-lg tw-font-bold">
            {{ $t("Spam Filtering") }}
          </span>
          <cl-form-radio-group
            id="spam-switcher"
            name="spam-switcher"
            :selectedValue="selectedPolicy.spam.enabled"
            @update:selected="
              (selected) => (selectedPolicy.spam.enabled = selected)
            "
            button
            :options="options"
          />
        </div>
        <cl-hr v-if="selectedPolicy.spam.enabled" />
        <div
          v-if="selectedPolicy.spam.enabled"
          data-test-id="spam_block"
          class="tw-mb-4"
        >
          <section>
            <div class="tw-flex tw-flex-col tw-p-4">
              <div class="tw-grid tw-gap-4 md:tw-grid-cols-2 lg:tw-grid-cols-3">
                <cl-form-group>
                  <cl-form-label label-for="score">
                    {{ $t("Mark as Spam when score is greater than") }}
                  </cl-form-label>
                  <cl-form-input
                    id="score"
                    name="score"
                    type="number"
                    v-model:value="selectedPolicy.spam.score"
                    size="medium"
                    :state="setInputState(v$.selectedPolicy.spam.score)"
                    @on-blur="v$.selectedPolicy.spam.score.$touch"
                    step="0.01"
                  />
                  <span
                    class="tw-block tw-pt-1 tw-text-sm tw-text-danger-500"
                    v-if="v$.selectedPolicy.spam.score.$error"
                    >{{ error }}</span
                  >
                </cl-form-group>

                <cl-form-group>
                  <cl-form-label label-for="action">
                    {{ $t("Spam should be") }}
                  </cl-form-label>
                  <cl-form-select
                    id="action"
                    v-model:value="selectedPolicy.spam.action"
                    :options="spam.actionObjList"
                  />
                </cl-form-group>
                <cl-form-group>
                  <cl-form-label label-for="discard_score">
                    {{ $t("Discard Spam scoring above") }}
                  </cl-form-label>
                  <cl-form-input
                    type="number"
                    id="discard_score"
                    name="discard_score"
                    v-model:value="selectedPolicy.spam.discard_score"
                    size="medium"
                    :state="setInputState(v$.selectedPolicy.spam.discard_score)"
                    @on-blur="v$.selectedPolicy.spam.discard_score.$touch"
                  />
                  <span
                    class="tw-block tw-pt-1 tw-text-sm tw-text-danger-500"
                    v-if="v$.selectedPolicy.spam.discard_score.$error"
                    >{{ error }}</span
                  >
                </cl-form-group>
              </div>
              <div class="tw-mt-4 tw-grid tw-gap-4 md:tw-grid-cols-2">
                <div>
                  <cl-form-group>
                    <cl-checkbox
                      id="spam-send-ndr-checkbox"
                      v-model:model-value="selectedPolicy.spam.send_ndr"
                    >
                      {{ $t("Send NDR") }}
                    </cl-checkbox>
                  </cl-form-group>
                </div>
                <div>
                  <cl-form-group>
                    <cl-checkbox
                      id="spam-add-headers-checkbox"
                      v-model:model-value="selectedPolicy.spam.add_headers"
                    >
                      {{ $t("Add X-Spam headers to non-spam mails") }}
                    </cl-checkbox>
                  </cl-form-group>
                </div>
              </div>
              <div class="tw-mt-4 tw-grid tw-gap-4 md:tw-grid-cols-2">
                <div>
                  <cl-form-group>
                    <cl-checkbox
                      id="spam-block-marketing-checkbox"
                      v-model:model-value="selectedPolicy.spam.block_marketing"
                    >
                      {{ $t("Classify marketing emails as spam") }}
                    </cl-checkbox>
                  </cl-form-group>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
      <!-- Virus Filtering -->
      <div
        class="virus-filtering"
        v-bind:class="{
          'tw-bg-white': selectedPolicy.virus.enabled,
          'tw-border-b tw-border-dorian-gray-100':
            !selectedPolicy.virus.enabled,
        }"
      >
        <div class="tw-flex tw-items-center tw-justify-between tw-p-4">
          <span class="tw-text-lg tw-font-bold">
            {{ $t("Virus Filtering") }}
          </span>
          <cl-form-radio-group
            id="virus-switcher"
            name="virus-switcher"
            :selectedValue="selectedPolicy.virus.enabled"
            @update:selected="
              (selected) => (selectedPolicy.virus.enabled = selected)
            "
            switcher
            button
            :options="options"
          />
        </div>
        <cl-hr v-if="selectedPolicy.virus.enabled" />
        <div
          v-if="selectedPolicy.virus.enabled"
          data-test-id="virus_block"
          class="tw-mb-4 tw-p-4"
        >
          <section>
            <cl-form-group>
              <cl-form-label label-for="virus-action">
                {{ $t("Virus should be") }}
              </cl-form-label>
              <cl-form-select
                id="virus-action"
                v-model:value="selectedPolicy.virus.action"
                :options="virus.actionObjList"
              />
            </cl-form-group>
            <cl-form-group class="tw-mt-4 tw-flex tw-justify-center">
              <cl-checkbox
                id="spam-send-add-checkbox"
                v-model:model-value="selectedPolicy.virus.sandbox"
              >
                {{ $t("Sandbox") }}
              </cl-checkbox>
            </cl-form-group>
          </section>
        </div>
      </div>
      <!-- Attachment Type Filtering -->
      <div
        class="banned-filtering"
        v-bind:class="{
          'tw-bg-white': selectedPolicy.banned.enabled,
          'tw-border-b tw-border-dorian-gray-100':
            !selectedPolicy.banned.enabled,
        }"
      >
        <div
          class="tw-flex tw-flex-row tw-items-center tw-justify-between tw-p-4"
        >
          <span class="tw-text-lg tw-font-bold">
            {{ $t("Attachment Type Filtering") }}
          </span>
          <cl-form-radio-group
            id="banned-switcher"
            name="banned-switcher"
            :selectedValue="selectedPolicy.banned.enabled"
            @update:selected="
              (selected) => (selectedPolicy.banned.enabled = selected)
            "
            button
            :options="options"
          />
        </div>
        <cl-hr v-if="selectedPolicy.banned.enabled" />
        <div
          v-if="selectedPolicy.banned.enabled"
          data-test-id="banned_block"
          class="tw-mb-4"
        >
          <section>
            <div class="tw-grid tw-gap-4 tw-p-4 md:tw-grid-cols-2">
              <cl-form-group>
                <cl-form-label label-for="banned-action">
                  {{ $t("Banned Attachments should be") }}
                </cl-form-label>
                <cl-form-select
                  id="banned-action"
                  v-model:value="selectedPolicy.banned.action"
                  :options="banned.actionObjList"
                />
              </cl-form-group>
            </div>
          </section>
        </div>
      </div>
      <!-- Quarantine report -->
      <div
        class="qreport-filtering"
        v-bind:class="{
          'tw-bg-white': selectedPolicy.qreport.enabled,
          'tw-border-b tw-border-dorian-gray-100':
            !selectedPolicy.qreport.enabled,
        }"
      >
        <div
          class="tw-flex tw-flex-row tw-items-center tw-justify-between tw-p-4"
        >
          <span class="tw-text-lg tw-font-bold">
            {{ $t("Quarantine Report") }}
          </span>
          <cl-form-radio-group
            id="qreport-switcher"
            name="qreport-switcher"
            :selectedValue="selectedPolicy.qreport.enabled"
            @update:selected="
              (selected) => (selectedPolicy.qreport.enabled = selected)
            "
            switcher
            button
            :options="options"
          />
        </div>
        <cl-hr v-if="selectedPolicy.qreport.enabled" />
        <div
          v-if="selectedPolicy.qreport.enabled"
          class="qreport-filtering-content p-3"
          data-test-id="qreport_block"
        >
          <section>
            <div class="d-flex flex-column">
              <div class="tw-grid tw-gap-4 tw-p-4 md:tw-grid-cols-2">
                <cl-form-group>
                  <cl-form-label label-for="language">
                    {{ $t("Language") }}
                  </cl-form-label>
                  <cl-form-select
                    id="language"
                    v-model:value="selectedPolicy.qreport.language"
                    :options="qreport.languageObjList"
                  />
                </cl-form-group>
                <cl-form-group>
                  <cl-form-label label-for="email-report">
                    {{ $t("Email report") }}
                  </cl-form-label>
                  <cl-form-select
                    id="email-report"
                    v-model:value="selectedPolicy.qreport.frequency"
                    :options="qreport.frequencyObjList"
                  />
                </cl-form-group>
              </div>
              <div class="tw-grid tw-gap-4 tw-p-4 md:tw-grid-cols-2">
                <cl-form-group>
                  <cl-form-label label-for="qreport-contains">
                    {{ $t("Report contains") }}
                  </cl-form-label>
                  <cl-form-select
                    id="qreport-contains"
                    v-model:value="selectedPolicy.qreport.type"
                    :options="qreport.containsObjList"
                  />
                </cl-form-group>
                <cl-form-group>
                  <cl-form-label label-for="qreport-score">
                    {{ $t("Exclude spam mails scoring above") }}
                  </cl-form-label>
                  <cl-form-input
                    type="number"
                    id="qreport-score"
                    name="qreport-score"
                    v-model:value="selectedPolicy.qreport.exclude_score"
                    size="medium"
                    :state="
                      setInputState(v$.selectedPolicy.qreport.exclude_score)
                    "
                    @on-blur="v$.selectedPolicy.qreport.exclude_score.$touch"
                  />
                  <span
                    class="tw-block tw-pt-1 tw-text-sm tw-text-danger-500"
                    v-if="v$.selectedPolicy.qreport.exclude_score.$error"
                    >{{ error }}</span
                  >
                </cl-form-group>
              </div>
            </div>
          </section>
        </div>
      </div>
      <!-- Archive mail -->
      <div
        class="tw-flex tw-flex-row tw-items-center tw-justify-between tw-border-b tw-border-dorian-gray-100 tw-p-4"
      >
        <span class="tw-text-lg tw-font-bold">
          {{ $t("Archive Mail") }}
        </span>
        <cl-form-radio-group
          id="archive-switcher"
          name="archive-switcher"
          :selectedValue="selectedPolicy?.archive_clean_mail"
          @update:selected="
            (selected) => (selectedPolicy.archive_clean_mail = selected)
          "
          switcher
          button
          :options="options"
        />
      </div>
      <!-- button group -->
      <div class="tw-mt-4 tw-flex tw-justify-end">
        <cl-button type="submit" variant="secondary" :disabled="!isChange">
          <span v-if="!fetching"> {{ $t("Save Changes") }} </span>
          <cl-spinner v-else size="small" />
        </cl-button>
      </div>
    </form>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { useVuelidate } from "@vuelidate/core";
import { required } from "@vuelidate/validators";

import {
  SPAM_ACTION,
  VIRUS_ACTION,
  BANNED_ACTION,
  QREPORT_LANGUAGE,
  QREPORT_FREQUENCY,
  QREPORT_CONTAINS,
} from "@/constants";
import TSkeleton from "@/components/TSkeleton";

const DEFAULT_SCORE = 999;
const DEFAULT_SPAM_SCORE = 5;

export default {
  name: "DomainPolicyDetails",
  components: {
    TSkeleton,
  },
  props: {
    id: {
      type: [String, Number],
      default: null,
    },
    domainPolicy: {
      type: Object,
      default: undefined,
    },
  },
  setup() {
    return { v$: useVuelidate() };
  },
  validations() {
    return {
      selectedPolicy: {
        spam: {
          score: required,
          enabled: required,
          discard_score: required,
        },
        qreport: {
          exclude_score: required,
        },
      },
    };
  },
  data() {
    return {
      buttonVariant: "outline-secondury",
      isChange: false,
      loading: true,
      fetching: false,
      error: this.$t("This field is a required field."),
      selectedPolicy: null,
      options: [
        { label: this.$t("On"), value: true },
        { label: this.$t("Off"), value: false },
      ],
      spam: {
        actionObjList: Object.entries(SPAM_ACTION).map(([value, text]) => ({
          value,
          text: this.$t(text),
        })),
      },
      virus: {
        actionObjList: Object.entries(VIRUS_ACTION).map(([value, text]) => ({
          value,
          text: this.$t(text),
        })),
      },
      banned: {
        actionObjList: Object.entries(BANNED_ACTION).map(([value, text]) => ({
          value,
          text: this.$t(text),
        })),
      },
      qreport: {
        languageObjList: Object.entries(QREPORT_LANGUAGE).map(
          ([value, text]) => ({
            value,
            text: this.$t(text),
          })
        ),
        frequencyObjList: Object.entries(QREPORT_FREQUENCY).map(
          ([value, label]) => ({
            value,
            text: this.$t(label),
          })
        ),
        containsObjList: Object.entries(QREPORT_CONTAINS).map(
          ([value, text]) => ({
            value,
            text: this.$t(text),
          })
        ),
      },
    };
  },
  watch: {
    domainPolicy: {
      handler(domainPolicy) {
        this.selectedPolicy = JSON.parse(JSON.stringify(domainPolicy));
      },
      immediate: true,
      deep: true,
    },
    selectedPolicy: {
      handler(selectedPolicy) {
        if (selectedPolicy !== null && typeof selectedPolicy === "object") {
          const setDefaultsIfEnabled = (policyKey, setDefaultsFn) => {
            if (
              selectedPolicy[policyKey]?.enabled &&
              Object.keys(selectedPolicy[policyKey]).length <= 1
            ) {
              setDefaultsFn();
            }
          };

          setDefaultsIfEnabled("spam", this.setSpamDefaults);
          setDefaultsIfEnabled("virus", this.setVirusDefaults);
          setDefaultsIfEnabled("banned", this.setBannedDefaults);
          setDefaultsIfEnabled("qreport", this.setQReportDefaults);
        }
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    ...mapActions("domainPolicy", [
      "updateDomainPolicy",
      "fetchDomainPolicy",
      "clearCache",
    ]),
    ...mapActions("toast", ["displayToast"]),
    checkChanges() {
      this.isChange = true;
    },
    setSpamDefaults() {
      this.selectedPolicy.spam.score = DEFAULT_SPAM_SCORE;
      this.selectedPolicy.spam.discard_score = DEFAULT_SCORE;
      this.selectedPolicy.spam.add_headers = false;
      this.selectedPolicy.spam.send_ndr = true;
      this.selectedPolicy.spam.action = this.spam.actionObjList[0].value;
    },
    setVirusDefaults() {
      this.selectedPolicy.virus.action = this.virus.actionObjList[0].value;
      this.selectedPolicy.virus.sandbox = true;
    },
    setBannedDefaults() {
      this.selectedPolicy.banned.action = this.banned.actionObjList[0].value;
    },
    setQReportDefaults() {
      this.selectedPolicy.qreport.language =
        this.qreport.languageObjList[0].value;
      this.selectedPolicy.qreport.frequency =
        this.qreport.frequencyObjList[0].value;
      this.selectedPolicy.qreport.type = this.qreport.containsObjList[1].value;
      this.selectedPolicy.qreport.exclude_score = DEFAULT_SCORE;
    },
    setInputState(input) {
      return input.$dirty ? !input.$error : null;
    },
    async submit() {
      const isValid = await this.v$.$validate();

      if (!isValid) {
        return;
      }

      try {
        this.fetching = true;

        await this.updateDomainPolicy({
          id: this.id,
          value: {
            ...this.selectedPolicy,
          },
        });

        this.displayToast({
          title: this.$t("Success"),
          message: this.$t("Domain Policy updated"),
          duration: 2000,
          variant: "success",
        });
      } catch (_err) {
        // stub
      } finally {
        this.fetching = false;
      }
    },
  },
  created() {
    this.clearCache();
  },
  async mounted() {
    try {
      this.loading = true;

      await this.fetchDomainPolicy({ id: this.id });
    } catch (_err) {
      // stub
    } finally {
      this.loading = false;
    }
  },
};
</script>
