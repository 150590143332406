<template>
  <section v-if="selectedDomain">
    <t-messages-stats :tier="tierType" :id="selectedDomain.id" />
    <users-list :tier="tierType" :id="selectedDomain.id" />
  </section>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import TMessagesStats from "@/components/TMessagesStats";

import UsersList from "@/views/Domain/Overview/Tabs/Users";

import { TIER } from "@/constants";

export default {
  name: "DomainConfiguration",
  components: {
    TMessagesStats,
    UsersList,
  },
  data() {
    return {
      tierType: TIER.domains,
    };
  },
  computed: {
    ...mapGetters("authentication", ["selectedDomain"]),
  },
  methods: {
    ...mapActions("pageMeta", ["setPageTitle", "setPageSubtitle", "setDomain"]),
  },
  mounted() {
    if (this.selectedDomain) {
      this.setDomain(this.selectedDomain);
      this.setPageTitle(this.selectedDomain.domain || this.selectedDomain.name);
      this.setPageSubtitle([]);
    } else {
      this.$router.push("/logout");
    }
  },
  beforeUnmount() {
    this.setPageTitle("");
  },
};
</script>
