<template>
  <edit-rule v-if="selectedMsp.id" :tier="tierType" :id="selectedMsp.id" />
</template>
<script>
import { mapActions, mapGetters } from "vuex";

import EditRule from "@/views/Common/GeoBlocking/Rules/Edit/index.vue";

import { TIER } from "@/constants";

export default {
  name: "GeoblockingRuleEditWrap",
  components: {
    EditRule,
  },
  data() {
    return {
      tierType: TIER.msps,
    };
  },
  methods: {
    ...mapActions("pageMeta", ["setPageTitle", "setPageSubtitle"]),
  },
  computed: {
    ...mapGetters("authentication", ["selectedMsp"]),
  },
  created() {
    this.setPageTitle(this.$t("Geoblocking"));
    this.setPageSubtitle([]);
  },
};
</script>
