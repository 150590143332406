<template>
  <div>
    <t-skeleton v-if="loading" />
    <form v-else @submit.prevent="submit" autocomplete="off" @keyup="changed">
      <div class="tw-grid tw-gap-x-4 md:tw-grid-cols-2">
        <cl-form-group>
          <cl-form-label label-for="name">
            {{ $t("Name") }}
          </cl-form-label>
          <cl-form-input
            id="name"
            name="name"
            v-model:value="form.name"
            :placeholder="$t('Customer Name')"
            :state="setInputState(v$.form.name)"
            @on-blur="v$.form.name.$touch"
          />
          <span
            class="tw-block tw-pt-1 tw-text-sm tw-text-danger-500"
            v-if="v$.form.name.$error"
            >{{ $t("This field is a required field") }}</span
          >
        </cl-form-group>

        <cl-form-group>
          <cl-form-label label-for="description">
            {{ $t("Description (optional)") }}
          </cl-form-label>
          <cl-form-input
            autofocus
            id="description"
            name="description"
            v-model:value="form.description"
            :placeholder="$t('Description')"
          />
        </cl-form-group>
      </div>
      <div class="tw-flex tw-justify-end">
        <cl-button v-if="saving" :variant="'secondary'" disabled>
          <cl-spinner :size="'small'" />
          <span>Saving...</span>
        </cl-button>
        <cl-button
          v-else
          :variant="'secondary'"
          data-test-id="submit-button"
          :disabled="!isChanged"
          type="submit"
        >
          <span>{{ this.$t("Save changes") }}</span>
        </cl-button>
      </div>
    </form>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { useVuelidate } from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import TSkeleton from "@/components/TSkeleton";

export default {
  name: "EditCustomer",
  components: {
    TSkeleton,
  },
  setup() {
    return { v$: useVuelidate() };
  },
  validations() {
    return {
      form: {
        name: {
          required,
        },
      },
    };
  },
  data() {
    return {
      loading: false,
      isChanged: false,
      saving: false,
      form: {
        name: "",
        description: "",
      },
    };
  },
  watch: {
    selectedMspCustomer: {
      immediate: true,
      deep: true,
      handler(customer) {
        if (!customer) {
          return;
        }
        const { name, description } = customer;

        this.form = {
          name,
          description,
        };
      },
    },
  },
  computed: {
    ...mapGetters("msps", ["selectedMspCustomer"]),
    customerId() {
      return this.$route.params.id || null;
    },
  },
  methods: {
    ...mapActions("msps", ["fetchMspCustomer", "updateMspCustomer"]),
    ...mapActions("pageMeta", ["setPageTitle", "setPageSubtitle"]),
    ...mapActions("toast", ["displayToast"]),
    changed() {
      this.isChanged = true;
    },
    setInputState(input) {
      return input.$dirty ? !input.$error : null;
    },
    async submit() {
      const isValid = await this.v$.form.$validate();

      if (!isValid) {
        return;
      }

      try {
        this.saving = true;

        await this.updateMspCustomer({
          customerId: this.customerId,
          formData: this.form,
        });

        this.displayToast({
          title: this.$t("Success"),
          message: this.$t("Customer details updated successfully"),
          duration: 2000,
          variant: "success",
        });

        this.isChanged = false;
      } catch (_err) {
        // stub
      } finally {
        this.saving = false;
      }
    },
  },
  async mounted() {
    this.setPageTitle("Customer");
    this.setPageSubtitle([]);
    try {
      this.loading = true;
      await this.fetchMspCustomer({
        customerId: this.customerId,
      });
      this.setPageTitle(this.selectedMspCustomer.name);
    } catch (_err) {
      // stub
    } finally {
      this.loading = false;
    }
  },
};
</script>
