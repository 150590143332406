<template>
  <section v-if="selectedCustomer">
    <t-messages-stats :tier="tierType" :id="selectedCustomer.id" />
    <t-quick-actions :actions="quickActions" @action-clicked="actionClicked" />
    <processed-messages-chart :tier="tierType" :id="selectedCustomer.id" />
    <domains-list
      v-if="selectedCustomer.id"
      :tier="tierType"
      :id="selectedCustomer.id"
      @add="activateModal"
    />
    <t-add-allow-block-email
      v-if="isAllowBlockModalActive"
      :isAllow="isAllow"
      :tier="tierType"
      :id="selectedCustomer.id"
    />
    <t-add-domain
      v-if="isAddDomainModalActive"
      :tier="tierType"
      :id="selectedCustomer.id"
    />
  </section>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import TMessagesStats from "@/components/TMessagesStats";
import DomainsList from "@/views/Customer/Overview/Domains";
import ProcessedMessagesChart from "@/views/Common/ProcessedMessages/index.vue";
import { TIER } from "@/constants";
import TQuickActions from "@/components/TQuickActions";
import TAddAllowBlockEmail from "@/modals/TAddAllowBlockEmail.vue";
import TAddDomain from "@/modals/TAddDomain.vue";

const ADD_DOMAIN_ACTION = "add-domain";
const ALLOW_SENDER_ACTION = "allow-sender";
const BLOCK_SENDER_ACTION = "block-sender";
const ADD_DOMAIN_MODAL = "t-add-domain";
const ADD_ALLOW_BLOCK_EMAIL_MODAL = "t-add-allow-block-email";

export default {
  name: "Overview",
  components: {
    ProcessedMessagesChart,
    DomainsList,
    TMessagesStats,
    TQuickActions,
    TAddAllowBlockEmail,
    TAddDomain,
  },
  data() {
    return {
      tierType: TIER.domainGroups,
      activeModal: null,
      isAllow: true,
    };
  },
  computed: {
    ...mapGetters("authentication", ["selectedCustomer"]),
    ...mapGetters("userInterface", ["uiTitle"]),
    quickActions() {
      return [
        {
          id: ADD_DOMAIN_ACTION,
          icon: "cl-icon-tabler-user-plus",
          label: this.$t("Add Domain"),
        },
        {
          id: ALLOW_SENDER_ACTION,
          icon: "cl-icon-tabler-mail-check",
          label: this.$t("Allow Sender"),
        },
        {
          id: BLOCK_SENDER_ACTION,
          icon: "cl-icon-tabler-forbid",
          label: this.$t("Block Sender"),
        },
      ];
    },
    isAddDomainModalActive() {
      return this.activeModal === ADD_DOMAIN_MODAL;
    },
    isAllowBlockModalActive() {
      return this.activeModal === ADD_ALLOW_BLOCK_EMAIL_MODAL;
    },
  },
  watch: {
    uiTitle: {
      handler() {
        this.setPageTitle(this.$t("Overview"));
        this.setPageSubtitle([]);
      },
      immediate: true,
    },
  },
  methods: {
    ...mapActions("pageMeta", [
      "setPageTitle",
      "setPageSubtitle",
      "setCustomer",
    ]),
    ...mapActions("modal", ["openModal"]),
    activateModal(modal = ADD_DOMAIN_MODAL) {
      this.activeModal = modal;
      this.openModal();
    },
    actionClicked(id) {
      if (id === ADD_DOMAIN_ACTION) {
        this.activateModal(ADD_DOMAIN_MODAL);
      } else if (id === ALLOW_SENDER_ACTION) {
        this.isAllow = true;
        this.activateModal(ADD_ALLOW_BLOCK_EMAIL_MODAL);
      } else if (id === BLOCK_SENDER_ACTION) {
        this.isAllow = false;
        this.activateModal(ADD_ALLOW_BLOCK_EMAIL_MODAL);
      } else {
        return;
      }
    },
  },
  mounted() {
    if (this.selectedCustomer) {
      this.setCustomer(this.selectedCustomer.name);
    } else {
      this.$router.push("/logout");
    }
  },
};
</script>
