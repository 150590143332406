<template>
  <div></div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "index.vue",
  mounted() {
    localStorage.clear();
    this.clearToken();
    this.$router.push("/");
  },
  methods: {
    ...mapActions("authentication", ["clearToken"]),
  },
};
</script>
