<template>
  <administrators-list />
</template>
<script>
import { mapActions } from "vuex";

import AdministratorsList from "@/views/Common/Administrators/index.vue";

export default {
  components: {
    AdministratorsList,
  },
  methods: {
    ...mapActions("pageMeta", ["setPageTitle", "setPageSubtitle"]),
  },
  created() {
    this.setPageTitle();
    this.setPageTitle(this.$t("Administrators"));
    this.setPageSubtitle([]);
  },
};
</script>
