<template>
  <div class="page-wrap-domain-policy">
    <domain-policy
      class="tw-mb-6"
      :id="selectedDomain.id"
      :domainPolicy="domainPolicy"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import DomainPolicy from "@/views/Common/Policies/DomainPolicy";

export default {
  components: {
    DomainPolicy,
  },
  methods: {
    ...mapActions("pageMeta", ["setPageTitle", "setPageSubtitle"]),
  },
  computed: {
    ...mapGetters("authentication", ["selectedDomain"]),
    ...mapGetters("domainPolicy", ["domainPolicy"]),
  },

  async mounted() {
    this.setPageTitle(this.selectedDomain.domain);
    this.setPageSubtitle([]);
  },
};
</script>
