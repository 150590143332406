import { useCookies } from "@vueuse/integrations/useCookies";

const COOKIE_SETTINGS = {
  path: "/", // Important for SPA
  maxAge: 365 * 86400, // 86400 seconds in one day
};

export const useDataTableCookies = (
  { sortByKey, sortDirKey, itemsPerPageKey, selectedColumnsKey },
  { sortBy, sortDir, itemsPerPage, selectedColumns }
) => {
  const cookies = useCookies();

  const storeObjName = "data-table";

  const cookiesObject = cookies.get(storeObjName) || {};

  const sortByCookie = cookiesObject[sortByKey] || sortBy;
  const sortDirCookie = cookiesObject[sortDirKey] || sortDir;
  const itemsPerPageCookie = cookiesObject[itemsPerPageKey] || itemsPerPage;
  const selectedColumnsCookie =
    cookiesObject[selectedColumnsKey] || selectedColumns;

  const setSortItemsCookie = (by, order) => {
    cookiesObject[sortByKey] = by;
    cookiesObject[sortDirKey] = order;
    cookies.set(storeObjName, cookiesObject, COOKIE_SETTINGS);
  };

  const setItemsPerPageCookie = (val) => {
    cookiesObject[itemsPerPageKey] = val;
    cookies.set(storeObjName, cookiesObject, COOKIE_SETTINGS);
  };

  const setSelectedColumnsCookie = (val) => {
    cookiesObject[selectedColumnsKey] = val;
    cookies.set(storeObjName, cookiesObject, COOKIE_SETTINGS);
  };

  return {
    sortByCookie,
    sortDirCookie,
    itemsPerPageCookie,
    selectedColumnsCookie,
    setSortItemsCookie,
    setItemsPerPageCookie,
    setSelectedColumnsCookie,
  };
};

export default { useDataTableCookies };
