<template>
  <div class="attachments-wrap">
    <t-tabs :items="tabNavItems" />
    <apply-filters
      v-if="selectedDomain.id"
      :tier="tierType"
      :id="selectedDomain.id"
    ></apply-filters>
    <router-view> </router-view>
  </div>
</template>

<script>
import ApplyFilters from "@/views/Common/AttachmentFilters/ApplyFilters";
import { mapGetters } from "vuex";
import { TIER } from "@/constants";
import TTabs from "@/components/TTabs";

export default {
  name: "Attachments",
  components: {
    ApplyFilters,
    TTabs,
  },
  data() {
    return {
      tabNavItems: this.$secondaryNav.domainAttachmentsFiltering(),
      tierType: TIER.domains,
    };
  },
  computed: {
    ...mapGetters("authentication", ["selectedDomain"]),
  },
};
</script>
