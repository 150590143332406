<template>
  <Teleport to="body">
    <cl-modal
      :visible="showModal"
      @on-close="onCloseModal"
      headerLabel="Add IP"
    >
      <form @submit.prevent="submit" autocomplete="off">
        <div class="tw-grid tw-grid-cols-3 tw-gap-4">
          <div class="tw-col-span-2">
            <cl-form-group>
              <cl-form-label label-for="ip">
                {{ $t("IP/hostname") }}
              </cl-form-label>
              <cl-form-input
                data-lpignore="true"
                id="ip"
                v-model:value="form.ip"
                :placeholder="$t('IP / Network')"
                :state="setInputState(v$.form.ip)"
                @on-blur="v$.form.ip.$touch"
              />
              <div
                class="tw-block tw-text-sm tw-text-danger-500"
                v-if="v$.form.ip.$error"
              >
                {{ $t("You should provide valid IP address") }}
              </div>
            </cl-form-group>
          </div>
          <div class="tw-col-span-1">
            <cl-form-group>
              <cl-form-label label-for="last_modify">
                {{ $t("Netmask") }}
              </cl-form-label>
              <cl-form-input
                data-lpignore="true"
                id="last_modify"
                v-model:value="form.netmask"
                :placeholder="$t('32')"
                :state="setInputState(v$.form.netmask)"
                @on-blur="v$.form.netmask.$touch"
              />
              <div
                class="tw-block tw-text-sm tw-text-danger-500"
                v-if="v$.form.netmask.$error"
              >
                {{ $t("Netmask must be between the number range 8 and 32.") }}
              </div>
            </cl-form-group>
          </div>
          <div class="tw-col-span-3">
            <cl-form-group>
              <cl-form-label label-for="comments">
                {{ $t("Comments (optional)") }}
              </cl-form-label>
              <cl-form-textarea
                id="comments"
                v-model:value="form.comment"
                placeholder="Enter comment here..."
                rows="3"
                max-rows="6"
              ></cl-form-textarea>
            </cl-form-group>
          </div>
        </div>
      </form>

      <template v-slot:[`footer.decline`]>
        <cl-button
          :disabled="fetching"
          @on-click="closeModal"
          variant="link-secondary"
        >
          <span>{{ $t("Cancel") }}</span>
        </cl-button>
      </template>
      <template v-slot:[`footer.accept`]>
        <cl-button
          :variant="'secondary'"
          @on-click="submit"
          data-test-id="modal-ip-add-button"
        >
          <span v-if="!fetching">{{ this.$t("Add") }}</span>
          <cl-spinner v-else :size="'small'" />
        </cl-button>
      </template>
    </cl-modal>
  </Teleport>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { useFocusElement } from "@/composables/useFocusElement";
import { useVuelidate } from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import { REGEX } from "@/constants";

const DEFAULT_FORM = Object.freeze({
  ip: null,
  netmask: null,
  comment: null,
});

export default {
  setup() {
    const { focusElementById } = useFocusElement();
    return { v$: useVuelidate(), focusElementById };
  },
  validations() {
    return {
      form: {
        ip: {
          required,
          matchRegex: (value) => REGEX.IP.test(value),
        },
        netmask: {
          required,
          minValue: (value) => REGEX.NETMASK.test(value),
        },
      },
    };
  },
  data() {
    return {
      fetching: false,
      form: {
        ...DEFAULT_FORM,
      },
    };
  },
  computed: {
    ...mapGetters("authentication", ["selectedDomain"]),
    ...mapGetters("modal", ["showModal"]),
  },
  watch: {
    showModal: {
      handler() {
        this.focusElementById("ip");
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    ...mapActions("toast", ["displayToast"]),
    ...mapActions("antiSpoofing", ["createSender"]),
    ...mapActions("modal", ["closeModal"]),
    setInputState(input) {
      return input.$dirty ? !input.$error : null;
    },
    onCloseModal() {
      this.closeModal();
      this.form = { ...DEFAULT_FORM };
      this.v$.$reset();
    },
    async submit() {
      const isValid = await this.v$.form.$validate();

      if (!isValid) {
        return;
      }

      try {
        this.fetching = true;

        await this.createSender({
          domainId: this.selectedDomain.id,
          formData: this.form,
        });

        this.displayToast({
          title: this.$t("Success"),
          message: this.$t("Created"),
          duration: 2000,
          variant: "success",
        });

        this.onCloseModal();
      } catch (_err) {
        // stub
      } finally {
        this.fetching = false;
      }
    },
  },
};
</script>
