<template>
  <archive-reports-list
    v-if="selectedCustomer.id"
    :tier="tierType"
    :id="selectedCustomer.id"
  />
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import ArchiveReportsList from "@/views/Common/Reports/Archive/List/index.vue";

import { TIER } from "@/constants";

export default {
  name: "ArchiveReportsWrapper",
  components: {
    ArchiveReportsList,
  },
  data() {
    return {
      tierType: TIER.domainGroups,
    };
  },
  computed: {
    ...mapGetters("authentication", ["selectedCustomer"]),
  },
  methods: {
    ...mapActions("pageMeta", ["setPageTitle", "setPageSubtitle"]),
  },
  created() {
    this.setPageTitle(this.$t("Archive"));
    this.setPageSubtitle([]);
  },
};
</script>
