<template>
  <div>
    <div class="tw-mb-3">
      <h2>{{ this.$t("Edit Exemption details") }}</h2>
    </div>
    <t-skeleton v-if="loading" />
    <form @submit.prevent="submit" autocomplete="off" v-else>
      <div class="tw-grid tw-gap-4 sm:tw-grid-cols-2">
        <div v-if="this.selectedGeoBlockingExemption.email">
          <cl-form-group>
            <cl-form-label label-for="emailt">
              {{ $t("Sender Email") }}
            </cl-form-label>
            <cl-form-input
              autofocus
              testId="email"
              id="email"
              name="email"
              v-model:value="form.email"
              size="medium"
              :placeholder="$t('example@mail.com')"
              :state="setInputState(v$.form.email)"
              @on-blur="v$.form.email.$touch"
            />
            <span
              class="tw-block tw-pt-1 tw-text-sm tw-text-danger-500"
              v-if="v$.form.email.$error"
              >{{ $t("This email should be valid.") }}</span
            >
          </cl-form-group>
        </div>
        <div v-if="this.selectedGeoBlockingExemption.domain">
          <cl-form-group>
            <cl-form-label label-for="domain">
              {{ $t("Sender Domain") }}
            </cl-form-label>
            <cl-form-input
              id="domain"
              name="domain"
              v-model:value="form.domain"
              size="medium"
              :placeholder="$t('example.com')"
              :state="setInputState(v$.form.domain)"
              @on-blur="v$.form.domain.$touch"
            />
            <span
              class="tw-block tw-pt-1 tw-text-sm tw-text-danger-500"
              v-if="v$.form.domain.$error"
              >{{ $t("You should provide valid domain address") }}</span
            >
          </cl-form-group>
          <div class="tw-mb-3 tw-rounded-md tw-bg-light tw-p-2">
            <cl-checkbox
              id="subdomains"
              testId="modal-domain-subdomains"
              v-model:model-value="form.subdomains"
            >
              {{ $t("Include Subdomains") }}
            </cl-checkbox>
          </div>
        </div>
        <div v-if="this.selectedGeoBlockingExemption.ip">
          <cl-form-group>
            <cl-form-label label-for="ip">
              {{ $t("Sender IP") }}
            </cl-form-label>
            <cl-form-input
              autofocus
              id="ip"
              testId="ip"
              name="ip"
              v-model:value="form.ip"
              size="medium"
              :placeholder="$t('0.0.0.0')"
              :state="setInputState(v$.form.ip)"
              @on-blur="v$.form.ip.$touch"
            />
            <span
              class="tw-block tw-pt-1 tw-text-sm tw-text-danger-500"
              v-if="v$.form.ip.$error"
              >{{ $t("This field is a required field") }}</span
            >
          </cl-form-group>
        </div>
        <div v-if="this.selectedGeoBlockingExemption.mask">
          <cl-form-group>
            <cl-form-label label-for="netmask">
              {{ $t("Netmask") }}
            </cl-form-label>
            <cl-form-input
              type="number"
              data-lpignore="true"
              id="mask"
              name="mask"
              v-model:value="form.mask"
              :placeholder="$t('Netmask')"
              :state="setInputState(v$.form.mask)"
              @on-blur="v$.form.mask.$touch"
            />
            <span
              class="tw-mt-1 tw-block tw-text-sm tw-text-danger-500"
              v-if="v$.form.mask.$error"
              data-test-id="validation-netmask-field"
            >
              {{ $t("Netmask must be between the number range 8 and 32.") }}
            </span>
          </cl-form-group>
        </div>
        <div>
          <cl-form-group>
            <cl-form-label label-for="description">
              {{ $t("Comment") }}
            </cl-form-label>
            <cl-form-input
              id="description"
              name="description"
              :placeholder="$t('Comment')"
              v-model:value="form.comment"
              size="medium"
            />
          </cl-form-group>
        </div>
      </div>
      <div class="tw-flex tw-justify-end">
        <cl-button type="submit" class="tw-my-4" variant="secondary">
          <span v-if="!saving"> {{ $t("Save changes") }} </span>
          <span class="px-2" v-else><cl-spinner size="small" /></span>
        </cl-button>
      </div>
    </form>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { useVuelidate } from "@vuelidate/core";
import { required, email, ipAddress } from "@vuelidate/validators";

import { REGEX } from "@/constants";
import TSkeleton from "@/components/TSkeleton";

export default {
  components: {
    TSkeleton,
  },
  props: {
    id: {
      type: [String, Number],
      default: null,
    },
    tier: {
      type: String,
      default: null,
    },
  },
  setup() {
    return { v$: useVuelidate() };
  },
  validations() {
    return {
      form: {
        email: {
          required,
          email,
        },
        domain: {
          required,
          matchRegex: (value) => REGEX.DOMAIN.test(value),
        },
        ip: {
          required,
          ipAddress,
        },
        mask: {
          required,
          minValue: (value) => REGEX.NETMASK.test(value),
        },
      },
    };
  },
  data() {
    return {
      DOMAIN: REGEX.DOMAIN,
      IP: REGEX.IP,
      loading: true,
      isChanged: false,
      saving: false,
      fetching: false,
      form: {
        comment: null,
        email: null,
        domain: null,
        ip: null,
        subdomains: false,
        mask: null,
      },
    };
  },
  computed: {
    ...mapGetters("geoBlockingExemptions", ["selectedGeoBlockingExemption"]),
    exemptionId() {
      return this.$route.params.id || null;
    },
  },
  watch: {
    selectedGeoBlockingExemption: {
      immediate: true,
      deep: true,
      handler(exemption) {
        if (!exemption) {
          return;
        }
        const { email, domain, ip, comment, subdomains, mask } = exemption;

        this.form = {
          email,
          domain,
          ip,
          comment,
          subdomains,
          mask,
        };
      },
    },
  },
  methods: {
    ...mapActions("toast", ["displayToast"]),
    ...mapActions("geoBlockingExemptions", [
      "getGeoBlockingExemption",
      "updateGeoBlockingExemption",
    ]),
    setInputState(input) {
      return input.$dirty ? !input.$error : null;
    },
    async submit() {
      let formData;
      if (this.form.email) {
        const isValid = await this.v$.form.email.$validate();

        if (!isValid) {
          return;
        }

        formData = {
          comment: this.form.comment,
          email: this.form.email,
        };
      }
      if (this.form.domain) {
        const isValid = await this.v$.form.domain.$validate();

        if (!isValid) {
          return;
        }
        formData = {
          comment: this.form.comment,
          domain: this.form.domain,
          subdomains: this.form.subdomains,
        };
      }
      if (this.form.ip) {
        const isIpValid = await this.v$.form.ip.$validate();
        const isMaskValid = await this.v$.form.mask.$validate();

        if (!isIpValid || !isMaskValid) {
          return;
        }

        formData = {
          comment: this.form.comment,
          ip: this.form.ip,
          mask: this.form.mask,
        };
      }

      try {
        this.saving = true;

        await this.updateGeoBlockingExemption({
          tier: this.tier,
          id: this.id,
          exemptionId: this.exemptionId,
          formData: formData,
        });
        this.displayToast({
          title: this.$t("Success"),
          message: this.$t("Exemption has been updated"),
          duration: 2000,
          variant: "success",
        });
        this.isChanged = false;
        this.$router.back();
      } catch (_err) {
        // stub
      } finally {
        this.saving = false;
      }
    },
  },
  async mounted() {
    try {
      this.loading = true;

      await this.getGeoBlockingExemption({
        tier: this.tier,
        id: this.id,
        exemptionId: this.exemptionId,
      });
    } catch (_err) {
      // stub
    } finally {
      this.loading = false;
    }
  },
};
</script>
