<template>
  <cl-skeleton>
    <cl-skeleton-row />
    <cl-skeleton-row bg="light" width="lg" />
    <cl-skeleton-row width="sm" />
    <cl-skeleton-row bg="light" width="md" />
    <cl-skeleton-row width="lg" />
    <cl-skeleton-row bg="light" width="sm" />
  </cl-skeleton>
</template>
<script>
export default {
  name: "TSkeleton",
};
</script>
