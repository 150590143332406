<template>
  <div class="policy-wrap">
    <router-view> </router-view>
  </div>
</template>

<script>
export default {
  name: "Policy",
};
</script>
