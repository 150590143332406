<template>
  <t-skeleton v-if="loading" />
  <section v-else>
    <form @submit.prevent="submit" autocomplete="off">
      <div class="tw-grid tw-gap-4 sm:tw-grid-cols-2">
        <div>
          <cl-form-group>
            <cl-form-label label-for="Type">
              {{ $t("Type") }}
            </cl-form-label>

            <cl-form-select
              autofocus
              id="type"
              v-model:value="v$.form.type.$model"
              :options="typeOptions"
              @change="onTypeChange($event)"
            ></cl-form-select>
          </cl-form-group>
        </div>
        <div>
          <cl-form-group>
            <cl-form-label label-for="subject">
              {{ $t("Subject") }}
            </cl-form-label>
            <cl-form-input
              id="subject"
              name="subject"
              v-model:value="v$.form.subject.$model"
              size="medium"
              :state="setInputState(v$.form.subject)"
              :placeholder="$t('Subject')"
            />
            <span
              class="tw-block tw-pt-1 tw-text-sm tw-text-danger-500"
              v-if="v$.form.subject.$error"
              >{{ $t("This field is a required field") }}</span
            >
          </cl-form-group>
        </div>
      </div>
      <cl-hr />
      <div class="sm:tw-col-span-2">
        <t-frequency
          v-model:frequency="v$.form.frequency.$model"
          :items="items"
        />
      </div>
      <cl-hr />
      <div>
        <cl-form-group class="tw-mb-0">
          <cl-form-label label-for="format-radio">
            {{ $t("Format") }}
          </cl-form-label>
          <cl-form-radio-group
            id="format-radio"
            name="format-radio"
            :selectedValue="v$.form.format.$model"
            @update:selected="(selected) => (v$.form.format.$model = selected)"
            :options="formatOptions"
            class="tw--ml-2"
          />
          <span
            v-if="v$.form.format.$error"
            class="tw-block tw-pt-1 tw-text-sm tw-text-danger-500"
            >{{ $t("This field is a required field") }}</span
          >
        </cl-form-group>
      </div>
      <cl-hr />
      <div class="tw-flex tw-flex-row tw-items-center tw-justify-between">
        <span>
          {{ $t("Archive") }}
        </span>
        <cl-form-radio-group
          id="archive-switcher"
          name="archive-switcher"
          :selectedValue="v$.form.archive.$model"
          @update:selected="(selected) => (v$.form.archive.$model = selected)"
          button
          :options="radioOptions"
        />
      </div>
      <cl-hr />
      <div>
        <cl-form-group>
          <cl-form-label label-for="recipients">
            {{ $t("Recipients") }}
          </cl-form-label>
          <cl-form-input
            id="recipients"
            name="recipients"
            v-model:value="v$.form.recipients.$model"
            size="medium"
            :placeholder="$t('Recipients separated by comma')"
            :state="setInputState(v$.form.recipients)"
          />
          <span
            class="tw-block tw-pt-1 tw-text-sm tw-text-danger-500"
            v-if="v$.form.recipients.$error"
            >{{ $t("This field is a required field") }}</span
          >
        </cl-form-group>
      </div>
    </form>

    <div class="tw-flex tw-justify-end">
      <cl-button
        @on-click="submit"
        type="submit"
        :disabled="!v$.form.$anyDirty"
        class="tw-my-4"
        variant="secondary"
      >
        <span v-if="!saving"> {{ $t("Save changes") }} </span>
        <span class="px-2" v-else><cl-spinner size="small" /></span>
      </cl-button>
    </div>
  </section>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { useVuelidate } from "@vuelidate/core";
import { required } from "@vuelidate/validators";

import {
  TIER,
  REPORT_CUSTOMER_TYPE,
  REPORT_DOMAIN_TYPE,
  EXTENSION,
} from "@/constants";
import TSkeleton from "@/components/TSkeleton";
import TFrequency from "@/components/TFrequency";

export default {
  name: "ScheduledReportEdit",
  components: { TSkeleton, TFrequency },
  props: {
    id: {
      type: [String, Number],
      default: null,
    },
    tier: {
      type: String,
      default: null,
    },
    reportId: {
      type: [String, Number],
      default: null,
    },
  },
  setup() {
    return { v$: useVuelidate() };
  },
  validations() {
    return {
      form: {
        type: {},
        frequency: {},
        archive: {},
        subject: {
          required,
        },
        format: {
          required,
        },
        recipients: {
          required,
        },
      },
    };
  },
  data() {
    return {
      loading: true,
      saving: false,
      typeOptions: [],
      formatOptions: [],
      radioOptions: [
        { label: this.$t("Yes"), value: true },
        { label: this.$t("No"), value: false },
      ],
      // Note. Periods reference - https://gitlab.titanhq.com/titanhq/dev/st/spamtitan-api/-/issues/397
      frequencyOptions: [
        {
          text: this.$t("Daily"),
          id: "scheduled-report-frequency-daily",
          testID: "scheduled-report-frequency-daily",
          value: "yesterday",
        },
        {
          text: this.$t("Weekly"),
          id: "scheduled-report-frequency-weekly",
          testID: "scheduled-report-frequency-weekly",
          value: "prev_week",
        },
        {
          text: this.$t("Monthly"),
          id: "scheduled-report-frequency-monthly",
          testID: "scheduled-report-frequency-monthly",
          value: "prev_month",
        },
      ],
      frequencyOptionsLR: [
        {
          text: this.$t("Daily"),
          id: "scheduled-report-frequency-daily",
          testID: "scheduled-report-frequency-daily",
          value: "yesterday",
        },
      ],
      form: {
        type: "",
        subject: "",
        frequency: "yesterday",
        format: "",
        recipients: "",
        archive: null,
      },
    };
  },
  computed: {
    ...mapGetters("scheduledReports", ["selectedReport"]),
    items() {
      return this.form.type === "licensereport"
        ? this.frequencyOptionsLR
        : this.frequencyOptions;
    },
  },
  watch: {
    selectedReport: {
      immediate: true,
      deep: true,
      handler(selectedReport) {
        if (!selectedReport) {
          return;
        }

        const { type, subject, frequency, format, recipients, archive } =
          selectedReport;

        this.form = {
          type,
          subject,
          frequency,
          format,
          archive,
          recipients: recipients.join(", "),
        };
      },
    },
  },
  methods: {
    ...mapActions("modal", ["openModal", "closeModal"]),
    ...mapActions("toast", ["displayToast"]),
    ...mapActions("scheduledReports", [
      "fetchReport",
      "updateReport",
      "clearCache",
    ]),
    onTypeChange(type) {
      this.form.type = type;
      // Note. Formats reference - https://gitlab.titanhq.com/titanhq/dev/st/spamtitan-api/-/issues/464
      if (type === "domaingroup" || type === "domainreport") {
        this.formatOptions = [
          { label: this.$t("CSV"), value: EXTENSION.csv },
          { label: this.$t("TXT"), value: EXTENSION.txt },
        ];
        this.form.format =
          this.formatOptions.filter(
            (e) => e.value === this.selectedReport.format
          ).length > 0
            ? this.selectedReport.format
            : EXTENSION.csv;

        return;
      }

      if (type === "licensereport") {
        this.formatOptions = [{ label: this.$t("CSV"), value: EXTENSION.csv }];
        this.form.format = EXTENSION.csv;

        this.form.frequency = "yesterday";

        return;
      }

      if (
        type === "linklock" ||
        type === "linklockrecip" ||
        type === "linklocksender"
      ) {
        this.formatOptions = [{ label: this.$t("PDF"), value: EXTENSION.pdf }];
        this.form.format = EXTENSION.pdf;

        return;
      }

      this.setDefaultFormatOptions();
    },
    setAvailableFormatsByType(type) {
      this.onTypeChange(type);
    },
    setDefaultFormatOptions() {
      // Default
      this.formatOptions = [
        { label: this.$t("PDF"), value: EXTENSION.pdf },
        { label: this.$t("CSV"), value: EXTENSION.csv },
        { label: this.$t("TXT"), value: EXTENSION.txt },
      ];

      this.form.format =
        this.formatOptions.filter((e) => e.value === this.selectedReport.format)
          .length > 0
          ? this.selectedReport.format
          : EXTENSION.pdf;
    },
    mapTypeOptions() {
      if (this.tier === TIER.domainGroups) {
        this.typeOptions = Object.entries(REPORT_CUSTOMER_TYPE).map(
          ([value, text]) => ({
            value,
            text: this.$t(text),
          })
        );
      }

      if (this.tier === TIER.domains) {
        this.typeOptions = Object.entries(REPORT_DOMAIN_TYPE).map(
          ([value, text]) => ({
            value,
            text: this.$t(text),
          })
        );
      }

      this.form.type = this.typeOptions[0].value;
    },
    setInputState(input) {
      return input.$dirty ? !input.$error : null;
    },
    async submit() {
      const isValid = await this.v$.form.$validate();

      if (!isValid) {
        return;
      }

      try {
        this.saving = true;

        await this.updateReport({
          tier: this.tier,
          id: this.id,
          reportId: this.reportId,
          formData: {
            ...this.form,
            recipients: this.form.recipients.split(","),
          },
        });

        this.displayToast({
          title: this.$t("Success"),
          message: this.$t("Report has been updated"),
          duration: 2000,
          variant: "success",
        });
        this.v$.form.$reset();
      } finally {
        this.saving = false;
      }
    },
  },
  async mounted() {
    try {
      await this.fetchReport({
        tier: this.tier,
        id: this.id,
        reportId: this.reportId,
      });
    } finally {
      this.loading = false;
    }
    this.setAvailableFormatsByType(this.selectedReport.type);
  },
  created() {
    this.clearCache();
    this.mapTypeOptions();
  },
};
</script>
