<template>
  <t-block-allow-ip :tier="TIER.msps" :id="selectedMsp.id" />
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import TBlockAllowIp from "@/views/Common/TBlockAllowIP";
import { TIER } from "@/constants/tier";

export default {
  name: "Ips",
  components: {
    TBlockAllowIp,
  },
  data() {
    return {
      TIER,
    };
  },
  computed: {
    ...mapGetters("authentication", ["selectedMsp"]),
  },
  methods: {
    ...mapActions("pageMeta", ["setPageTitle", "setPageSubtitle"]),
    ...mapActions("modal", ["openModal", "closeModal"]),
  },
  mounted() {
    this.setPageTitle(this.$t("Allow & Block List"));
    this.setPageSubtitle([]);
  },
};
</script>
