<template>
  <div>
    <div v-if="isShowHeader" class="tw-flex tw-justify-end">
      <cl-button
        @on-click="showHeaders"
        class="tw-absolute tw-max-w-sm"
        v-if="isShow"
        variant="secondary"
        >{{ $t("Show all Headers") }}</cl-button
      >
      <cl-button
        @on-click="hideHeaders"
        class="tw-absolute tw-max-w-sm"
        v-if="!isShow"
        variant="secondary"
        >{{ $t("Hide all Headers") }}</cl-button
      >
    </div>
    <div class="tw-overflow-y-auto" v-html="sanitizedMessage" />
  </div>
</template>

<script>
import DOMPurify from "dompurify";

export default {
  name: "Message",
  props: {
    message: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      isShow: true,
    };
  },
  computed: {
    sanitizedMessage() {
      return DOMPurify.sanitize(this.message, {
        FORBID_TAGS: ["script"],
      });
    },
    isShowHeader() {
      const exampleText = "X-Quarantine-Id:";
      return (
        typeof this.message === "string" && this.message.includes(exampleText)
      );
    },
  },
  methods: {
    showHeaders() {
      let element = document.getElementsByClassName("secondary-headers")[0];
      element.className = "hidden-headers";
      this.isShow = false;
    },
    hideHeaders() {
      let element = document.getElementsByClassName("hidden-headers")[0];
      element.className = "secondary-headers";
      this.isShow = true;
    },
  },
};
</script>
<style scoped lang="scss">
:deep(.primary-headers) {
  .header-name {
    font-weight: 900;
  }
  .header-value {
    font-weight: 300;
    padding-left: 54px;
  }
}
:deep(.secondary-headers) {
  display: none;
}
:deep(.content) {
  margin-top: 6px;
}
:deep(.hidden-headers) {
  border-top: 1px solid lightgray;
  border-bottom: 1px solid lightgray;
  .header-name {
    font-weight: 900;
  }
}
</style>
