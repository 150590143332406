<template>
  <div>
    <div>
      {{ $t("Customise the user interface to fit your brand.") }}
    </div>

    <div>
      <h4 v-if="!loading" class="tw-my-6">{{ $t("Company Details") }}</h4>
      <cl-form-group>
        <cl-form-label label-for="title">
          {{ $t("Page Title") }}
        </cl-form-label>
        <cl-form-input
          autofocus
          id="title"
          name="title"
          v-model:value="form.title"
          :placeholder="this.$t('My Company Name')"
        />
      </cl-form-group>
      <p class="tw-mb-2">
        * {{ $t("This will be displayed in the browser tab.") }}
      </p>
      <cl-form-label label-for="company-logo">
        {{ $t("Your Company Logo") }}
      </cl-form-label>
      <div class="tw-max-w-sm">
        <img
          v-if="!resetting"
          class="logo-image tw-h-full tw-w-full tw-bg-repeat tw-p-2"
          :src="form.logoPreview"
        />
        <cl-spinner v-else size="small" />
      </div>
      <div class="tw-col-span-12 sm:tw-col-span-8 md:tw-col-span-10">
        <cl-form-group v-if="!loading">
          <p class="tw-mt-2">
            *
            {{
              $t("Supports images up to 800 × 200 with the max size of 1MB.", {
                width: imageUploaderRules.maxWidth,
                height: imageUploaderRules.maxHeight,
              })
            }}
          </p>
          <input
            data-test-id="interface-logo"
            id="company-logo-input"
            type="file"
            class="file:tw-mr-4 file:tw-cursor-pointer file:tw-rounded-md file:tw-border-none file:tw-bg-secondary-600 file:tw-px-6 file:tw-py-2 file:tw-font-semibold file:tw-text-white focus:tw-outline-none"
            :accept="imageUploaderRules.supportedTypes"
            @change="uploadLogo"
          />
        </cl-form-group>
      </div>
      <div v-if="!loading">
        <h4 v-if="!loading" class="tw-my-4">
          {{ $t("Reset to TitanHQ default Interface Settings") }}
        </h4>
        <cl-button
          variant="danger"
          @on-click="resetToDefaultSettings"
          data-test-id="reset-to-default"
          :disabled="resetting"
        >
          <span>{{ this.$t("Reset to default") }}</span>
        </cl-button>
      </div>
    </div>
    <div class="tw-mb-4 tw-flex tw-justify-end">
      <cl-button v-if="fetching" variant="secondary" disabled>
        <cl-spinner :size="'small'" />
      </cl-button>
      <cl-button
        v-else
        :variant="'secondary'"
        @on-click="onSubmit"
        data-test-id="interface-save"
      >
        <span>{{ this.$t("Save Changes") }}</span>
      </cl-button>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import { TIER } from "@/constants";
import uploadLogo from "@/composables/useUploadLogo";

export default {
  name: "mspInterface",
  setup() {
    const {
      loading,
      errorMessage,
      errorCatch,
      uploadedImage,
      logoPreview,
      customLogoFile,
      imageUploaderRules,
      upload,
      removeLogo,
    } = uploadLogo.useUploadLogo();

    return {
      imageLoading: loading,
      errorMessage,
      errorCatch,
      uploadedImage,
      logoPreview,
      customLogoFile,
      imageUploaderRules,
      upload,
      removeLogo,
    };
  },
  data() {
    return {
      loading: false,
      fetching: false,
      resetting: false,
      form: {
        logoPreview: null,
        logo: null,
        title: null,
      },
    };
  },
  computed: {
    ...mapGetters("authentication", ["selectedMsp"]),
    ...mapGetters("userInterface", ["uiTitle", "uiLogo"]),
  },
  watch: {
    uiLogo() {
      this.form.logoPreview = this.uiLogo;
    },
    logoPreview() {
      this.form.logoPreview = this.logoPreview;
    },
    customLogoFile() {
      this.form.logo = this.customLogoFile;
    },
    uiTitle() {
      this.form.title = this.uiTitle;
    },
    errorMessage() {
      this.form.logo = null;
      this.form.logoPreview = this.uiLogo;
      this.displayToast({
        title: this.$t("Error"),
        message: this.$t(this.errorMessage),
        duration: 2000,
        variant: "danger",
      });
    },
    errorCatch() {
      this.form.logoPreview = this.uiLogo;
      const searchString = "parameter 1 is not of type 'Blob'";
      if (!this.errorCatch.message.includes(searchString)) {
        this.displayToast({
          title: this.$t("Error"),
          message: this.$t(this.errorCatch.message),
          duration: 2000,
          variant: "danger",
        });
      }
    },
  },
  methods: {
    ...mapActions("pageMeta", ["setPageTitle", "setPageSubtitle"]),
    ...mapActions("toast", ["displayToast"]),
    ...mapActions("userInterface", [
      "updateInterface",
      "fetchInterface",
      "deleteInterfaceUi",
    ]),
    async resetToDefaultSettings() {
      this.resetting = true;
      this.fetching = true;
      this.removeLogo();
      this.form.logoPreview = this.uiLogo;
      try {
        await this.deleteInterfaceUi({
          type: TIER.msps,
          type_id: this.selectedMsp.id,
        });
        await this.fetchInterface({
          type: TIER.msps,
          type_id: this.selectedMsp.id,
        });
      } finally {
        this.displayToast({
          title: this.$t("Success"),
          message: "The Interface Settings have been reset to default",
          duration: 2000,
          variant: "success",
        });
        this.resetting = false;
        this.fetching = false;
        this.setPageTitle(this.$t("Interface"));
      }
    },
    uploadLogo(event) {
      this.upload(event);
    },
    async onSubmit() {
      this.fetching = true;
      try {
        const body = new FormData();
        if (this.form.logo) body.append("logo", this.form.logo);
        if (this.form.title) body.append("title", this.form.title);
        body.append("powered_by", "TitanHQ");
        // body.append("footer", ""); // it should be off until API will fixed and updated
        await this.updateInterface({
          body,
          tier: TIER.msps,
          id: this.selectedMsp.id,
        });
        this.displayToast({
          title: this.$t("Success"),
          message: "The Interface Settings have been updated",
          duration: 2000,
          variant: "success",
        });
      } finally {
        this.fetching = false;
        this.setPageTitle(this.$t("Interface"));
      }
    },
  },
  created() {
    this.form.logoPreview = this.uiLogo;
  },
  mounted() {
    this.setPageTitle();
    this.setPageTitle(this.$t("Interface"));
    this.setPageSubtitle([]);
    this.form.title = this.uiTitle;
  },
};
</script>

<style lang="scss" scoped>
$dark: #dfdfdf;
$light: #e6e6e6;

.logo-image {
  background-size: 1.4rem 1.4rem;
  background-position:
    0 0,
    0 0,
    -0.7rem -0.7rem,
    0.7rem 0.7rem;
  background-image: linear-gradient(45deg, $dark 25%, transparent 25%),
    linear-gradient(45deg, transparent 75%, $dark 75%),
    linear-gradient(45deg, transparent 75%, $dark 75%),
    linear-gradient(45deg, $dark 25%, $light 25%);
}
</style>
