<template>
  <message v-if="!loading" :message="messageBody" />
  <div v-else><cl-spinner size="small" /></div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { TIER } from "@/constants";

import Message from "@/views/Common/MessageOverview/Message";

export default {
  name: "DetailsTab",
  components: {
    Message,
  },
  data() {
    return {
      tierType: TIER.users,
    };
  },
  computed: {
    ...mapGetters("message", ["messageBody", "messageDetails", "loading"]),
    ...mapGetters("authentication", ["selectedUser"]),
  },
  methods: {
    ...mapActions("message", ["fetchMessageBody"]),
  },
  async mounted() {
    try {
      await this.fetchMessageBody({
        id: this.$route.params.id,
        tier: this.tierType,
        tier_id: this.selectedUser.id,
      });
    } catch (_err) {
      // stub
    } finally {
      // stub
    }
  },
};
</script>
