import { useStore } from "vuex";
import { checkDateRange } from "@/composables/useDateRange";
import { initialFiltersState } from "@/store/modules/emailsHistory.js";

export const usePills = ({
  scoreKey,
  scoreCompareKey,
  typeKey,
  deliveryStatusKey = "",
  senderKey,
  recipientKey,
  envelopeFromKey,
  subjectKey,
  idKey,
  ipKey,
  domainKey = "",
  domainGroupKey = "",
  dateRangeKey = "",
  startDateKey,
  endDateKey,
}) => {
  const storeObjName = "pills";

  const localStorageObject =
    JSON.parse(localStorage.getItem(storeObjName)) || {};

  const deliveryStatusLS = localStorageObject[deliveryStatusKey] || null;
  const scoreLS = localStorageObject[scoreKey] || "";
  const scoreCompareLS = localStorageObject[scoreCompareKey] || "";
  const typeLS = localStorageObject[typeKey] || [];
  const senderLS = localStorageObject[senderKey] || "";
  const recipientLS = localStorageObject[recipientKey] || "";
  const envelopeFromLS = localStorageObject[envelopeFromKey] || "";
  const subjectLS = localStorageObject[subjectKey] || "";
  const idLS = localStorageObject[idKey] || "";
  const ipLS = localStorageObject[ipKey] || "";
  const domainLS = localStorageObject[domainKey] || "";
  const domainGroupLS = localStorageObject[domainGroupKey] || "";
  const dateRangeLS = localStorageObject[dateRangeKey] || "";
  const startDateLS = localStorageObject[startDateKey] || "";
  const endDateLS = localStorageObject[endDateKey] || "";

  const cookiesSet = (storeModule) => {
    const store = useStore();
    const keys = [
      {
        key: recipientLS,
        mutation: `${storeModule}/setRecipient`,
      },
      { key: subjectLS, mutation: `${storeModule}/setSubject` },
      { key: senderLS, mutation: `${storeModule}/setSender` },
      {
        key: envelopeFromLS,
        mutation: `${storeModule}/setEnvelopeFrom`,
      },
      {
        key: deliveryStatusLS,
        mutation: `${storeModule}/setDeliveryStatus`,
      },
      { key: idLS, mutation: `${storeModule}/setId` },
      { key: ipLS, mutation: `${storeModule}/setIp` },
      { key: scoreLS, mutation: `${storeModule}/setScore` },
      {
        key: scoreCompareLS,
        mutation: `${storeModule}/setScoreCompare`,
      },
      {
        key: dateRangeLS,
        mutation: `${storeModule}/setDateRange`,
      },
      {
        key: typeLS,
        mutation: `${storeModule}/setMessageType`,
      },
      {
        key: startDateLS || initialFiltersState.start_date,
        mutation: `${storeModule}/setStartDate`,
      },
      {
        key: endDateLS || initialFiltersState.end_date,
        mutation: `${storeModule}/setEndDate`,
      },
    ];

    keys.forEach(({ key, mutation }) => {
      if (key) {
        store.commit(mutation, key);
        if (key === dateRangeLS) {
          checkDateRange(key, storeModule);
        }
      }
    });
  };

  const setPills = (formCookies) => {
    localStorageObject[deliveryStatusKey] = formCookies.delivery_status || null;
    localStorageObject[scoreKey] = formCookies.score || "";
    localStorageObject[scoreCompareKey] = formCookies.score_compare || "";
    localStorageObject[typeKey] = formCookies.type || [];
    localStorageObject[senderKey] = formCookies.sender || "";
    localStorageObject[recipientKey] = formCookies.recipient || "";
    localStorageObject[envelopeFromKey] = formCookies.envelope_from || "";
    localStorageObject[subjectKey] = formCookies.subject || "";
    localStorageObject[idKey] = formCookies.id || "";
    localStorageObject[ipKey] = formCookies.ip || "";
    localStorageObject[domainKey] = formCookies.domain || "";
    localStorageObject[domainGroupKey] = formCookies.domain_group || "";
    localStorageObject[dateRangeKey] = formCookies.date_range || "";
    localStorageObject[startDateKey] = formCookies.start_date || "";
    localStorageObject[endDateKey] = formCookies.end_date || "";
    localStorage.setItem(storeObjName, JSON.stringify(localStorageObject));
  };
  const deletePill = (key) => {
    delete localStorageObject[key];
    localStorage.setItem(storeObjName, JSON.stringify(localStorageObject));
  };

  const clearPills = (isEndUser) => {
    delete localStorageObject[deliveryStatusKey];
    delete localStorageObject[dateRangeKey];
    delete localStorageObject[scoreKey];
    delete localStorageObject[scoreCompareKey];
    delete localStorageObject[typeKey];
    delete localStorageObject[senderKey];
    isEndUser ? "" : delete localStorageObject[recipientKey];
    delete localStorageObject[envelopeFromKey];
    delete localStorageObject[subjectKey];
    delete localStorageObject[idKey];
    delete localStorageObject[ipKey];
    delete localStorageObject[domainKey];
    delete localStorageObject[domainGroupKey];
    delete localStorageObject[startDateKey];
    delete localStorageObject[endDateKey];
    localStorage.setItem(storeObjName, JSON.stringify(localStorageObject));
  };

  return {
    scoreLS,
    scoreCompareLS,
    typeLS,
    deliveryStatusLS,
    senderLS,
    recipientLS,
    envelopeFromLS,
    subjectLS,
    idLS,
    ipLS,
    setPills,
    deletePill,
    domainLS,
    domainGroupLS,
    dateRangeLS,
    startDateLS,
    endDateLS,
    clearPills,
    cookiesSet,
  };
};

export default { usePills };
