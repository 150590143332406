<template>
  <t-block-allow-domain
    v-if="selectedDomain.id"
    :id="selectedDomain.id"
    :tier="TIER.domains"
  ></t-block-allow-domain>
</template>
<script>
import { mapActions, mapGetters } from "vuex";

import TBlockAllowDomain from "@/views/Common/TBlockAllowDomain";

import { TIER } from "@/constants/tier";

export default {
  name: "Domains",
  components: {
    TBlockAllowDomain,
  },
  data() {
    return {
      TIER,
    };
  },
  methods: {
    ...mapActions("pageMeta", ["setPageTitle", "setPageSubtitle"]),
    ...mapActions("modal", ["openModal", "closeModal"]),
  },
  computed: {
    ...mapGetters("authentication", ["selectedDomain"]),
  },
  mounted() {
    this.setPageTitle(this.$t("Allow & Block List"));
    this.setPageSubtitle([]);
  },
};
</script>
