<template>
  <message-source v-if="!loading" :messageSource="messageSource" />
  <div v-else><cl-spinner size="small" /></div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { TIER } from "@/constants";

import MessageSource from "@/views/Common/MessageOverview/Source";

export default {
  name: "DetailsTab",
  components: {
    MessageSource,
  },
  data() {
    return {
      tierType: TIER.domainGroups,
    };
  },
  computed: {
    ...mapGetters("message", ["messageSource", "loading"]),
    ...mapGetters("authentication", ["selectedCustomer"]),
  },
  methods: {
    ...mapActions("message", ["fetchMessageSource"]),
  },
  async mounted() {
    try {
      await this.fetchMessageSource({
        id: this.$route.params.id,
        tier: this.tierType,
        tier_id: this.selectedCustomer.id,
      });
    } catch (_err) {
      // stub
    }
  },
};
</script>
