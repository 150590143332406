<template>
  <edit-ip :ip-id="ipId" :tier="null" />
</template>
<script>
import { mapActions, mapGetters } from "vuex";

import EditIp from "@/views/Common/TBlockAllowIP/Edit/index.vue";

export default {
  name: "IpEditWrap",
  components: {
    EditIp,
  },
  data() {
    return {
      ipId: this.$route.params.id,
    };
  },
  methods: {
    ...mapActions("pageMeta", ["setPageTitle", "setPageSubtitle"]),
  },
  computed: {
    ...mapGetters("authentication", ["selectedMsp"]),
  },
  created() {
    this.setPageTitle(this.$t("IP:") + this.ipId);
    this.setPageSubtitle([this.$t("Edit Allow/Block IP")]);
  },
};
</script>
